import handleFetch from '../utils/handleFetch';
import { getAuthHeaders } from '../utils/auth';
import { sapphirePlayerApiUrl } from './constants';

const getPlayerExclusion = (playerId) => {
	return handleFetch(
		`${sapphirePlayerApiUrl}/exclusions?playerId=${playerId}`,
		{
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
		}
	);
};

const createPlayerExclusion = (data) => {
	return handleFetch(`${sapphirePlayerApiUrl}/exclusions`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify(data),
		method: 'POST',
	});
};

const deletePlayerExclusion = (playerExclusionId) => {
	return handleFetch(
		`${sapphirePlayerApiUrl}/exclusions/${playerExclusionId}`,
		{
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
			method: 'DELETE',
		}
	);
};

export { getPlayerExclusion, createPlayerExclusion, deletePlayerExclusion };
