import { useMemo, useEffect } from 'preact/hooks';

import { useTranslation } from 'react-i18next';
import { Router as PreactRouter } from 'preact-router';

import KYC from './kyc';
import Communication from './communication';
import PersonalDetails from './personal-details';
import Redirect from '../../../components/redirect';
import CommonContentPage from './../common-content-page';
import { pathnames } from '../../../constants/pathnames';

import MyAccountIcon from '../../../assets/icons/my-account-orange.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerData, selectPlayerData } from '../../../store/player-slice';
import { selectUserSession } from '../../../store/authentication-slice';

const {
	account: { personalDetailsPath, communicationPath, kycPath, restProfilePath },
} = pathnames;

const ProfilePage = () => {
	const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSession = useSelector(selectUserSession);
  const player = useSelector(selectPlayerData);

  useEffect(() => {
    if (userSession && !player) {
      dispatch(getPlayerData(userSession.id));
    }
  }, [dispatch, userSession, player]);


  const tabs = useMemo(
		() => [
			{
				path: personalDetailsPath,
				name: t('personal_details'),
			},
			{
				path: communicationPath,
				name: t('communication'),
			},
			{
				path: kycPath,
				name: 'KYC',
			},
		],
		[t]
	);

	return (
		<CommonContentPage
			tabs={tabs}
			title={
				<>
					<img src={MyAccountIcon} />

					<div>{t('profile')}</div>
				</>
			}
		>
			<PreactRouter>
				<PersonalDetails path={personalDetailsPath} />

				<Communication path={communicationPath} />

				<KYC path={kycPath} />

				<Redirect path={restProfilePath} to={personalDetailsPath} />
			</PreactRouter>
		</CommonContentPage>
	);
};

export default ProfilePage;
