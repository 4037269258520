import { useMemo, useState } from 'preact/hooks';
import { formatDate } from '../../utils/date';
import classes from './style.module.scss';
import { Typography } from '../typography';
import { BonusStatus } from '../../constants/enums';
import { useTranslation } from 'react-i18next';

const BonusCard = ({ bonus }) => {
	const { t } = useTranslation();

	const [showTerms, setShowTerms] = useState(false);

	const statusColor = useMemo(() => {
		return {
			[BonusStatus.ACTIVE]: 'green',
			[BonusStatus.INACTIVE]: 'lightgrey',
			[BonusStatus.COMPLETED]: 'white',
			[BonusStatus.EXPIRED]: 'red',
		};
	}, []);

	return (
		<div className={classes.bonusWrapper}>
			<div className={classes.bonusStatusWrapper}>
				<Typography type="st2" fontColor={statusColor[bonus.status]}>
					{bonus.status}
				</Typography>
			</div>
			<div className={classes.bonusFieldsWrapper}>
				<Typography type="st2" fontColor="primary">
					{t('bonus_name')}:
				</Typography>
				<Typography type="st2" fontColor="white">
					{bonus.bonusName || '-'}
				</Typography>
				<Typography type="st2" fontColor="primary">
					{t('bonus_type')}:
				</Typography>
				<Typography type="st2" fontColor="white">
					{bonus.bonusType || '-'}
				</Typography>
				<Typography type="st2" fontColor="primary">
					{t('bonus_amount')}:
				</Typography>
				<Typography type="st2" fontColor="white">
					{(bonus.amount && `${bonus.amount} ${bonus.currency}`) || '-'}
				</Typography>
				<Typography type="st2" fontColor="primary">
					{t('expires_in')}:
				</Typography>
				<Typography type="st2" fontColor="white">
					{formatDate(bonus.bonusConfiguration.endingDate, true)}
				</Typography>
			</div>
			<div className={classes.rolloverWrapper}>
				{bonus.rolloverLeftAmount && bonus.rolledoverAmount ? (
					<span className={classes.rollover}>{`${
						Number(bonus.rolledoverAmount) - Number(bonus.rolloverLeftAmount)
					}/${bonus.rolledoverAmount}`}</span>
				) : (
					<span className={classes.rollover}> 0/300</span>
				)}
			</div>
			<div
				className={classes.termsWrapper}
				onClick={() => setShowTerms(!showTerms)}
			>
				<Typography
					className={classes.termsAccordion}
					type="st2"
					fontColor="white"
				>
					{t('terms_and_conditions')}
				</Typography>
				{showTerms && (
					<Typography
						type="st2"
						fontColor="white"
						className={classes.termsText}
					>
						Lorem ipsum
					</Typography>
				)}
			</div>
		</div>
	);
};

export default BonusCard;
