import * as XLSX from 'xlsx';

const exportData = (data, reportName) => {
	const ws = XLSX.utils.json_to_sheet(data);
	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
	XLSX.writeFile(wb, `${reportName}.xlsx`);
};

const generateDataForExport = (data, colModels) => {
	const result = data.map((record) => {
		const exportRecord = {};
		colModels.forEach((colModel) => {
			const field = colModel.hasCurrency
				? `${record[colModel.field]} ${record.currency}`
				: record[colModel.field];
			exportRecord[`${colModel.header}`] = colModel.renderCell
				? colModel.renderCell(field, record)
				: field;
		});

		return exportRecord;
	});
	return result;
};

const generateAndExportData = (data, colModels, reportName) => {
	const dataForExport = generateDataForExport(data, colModels);
	exportData(dataForExport, reportName);
};

export { exportData, generateDataForExport, generateAndExportData };
