import { useMemo } from 'preact/hooks';
import clsx from 'clsx';
import style from '../style.module.scss';
import { paymentProviders } from '../../constants';
import { useViewport } from '../../../../../hooks/useViewport';
import { useTranslation } from 'react-i18next';

const PaymentProvidersContainer = ({
	onPaymentProviderSelect,
	selectedPaymentProvider,
}) => {
	const { t } = useTranslation();
	const { isMobileScreen } = useViewport();
	const paymentMethods = useMemo(() => {
		return isMobileScreen ? paymentProviders.slice(0, 4) : paymentProviders;
	}, [isMobileScreen]);

	const handleChangePaymentProvider = (providerId) => {
		const newProvider =
			providerId === selectedPaymentProvider ? '' : providerId;
		if (onPaymentProviderSelect) {
			onPaymentProviderSelect(newProvider);
		}
	};

	return (
		<div>
			<p className={style.sectionTitle}>{t('payment_method')}:</p>
			<div className={style.paymentProvidersContainer}>
				{paymentMethods.map((provider) => (
					<div
						key={provider.id}
						className={clsx(
							style.paymentProviderSingleContainer,
							selectedPaymentProvider === provider.payment_method &&
								style.paymentProviderSingleContainerClicked
						)}
						onClick={() => handleChangePaymentProvider(provider.payment_method)}
					>
						<img alt={provider.id} width={'90%'} src={provider.src} />
					</div>
				))}
			</div>
			<span className={style.showMore}>{t('show_more')}</span>
		</div>
	);
};

export default PaymentProvidersContainer;
