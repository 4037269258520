import Banner from '../../components/banner';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import TournamentCard from '../../components/tournament-card';
import { tournamentsMockData } from './tournaments-mock-data';

const TournamentsPage = () => {
	const { t } = useTranslation();

	return (
		<div className={style.promotionPageWrapper}>
			<Banner label={t('tournaments')} containerClassName={style.banner} />

			<div className={style.promotionsWrapper}>
				{tournamentsMockData?.map((tournament, index) => (
					<TournamentCard key={index} tournament={tournament} />
				))}
			</div>
		</div>
	);
};

export default TournamentsPage;
