import bank_transfer from "../../../assets/images/bank-transfer.png";
import neteller from "../../../assets/images/neteller.png";
import paypal from "../../../assets/images/paypal.png";
import googlePay from "../../../assets/images/googlePay.png";
import applePay from "../../../assets/images/applePay.png";
import paysafecard from "../../../assets/images/paysafecard.png";
import skrill from "../../../assets/images/skrill.png";
import skrill1tap from "../../../assets/images/skrill1tap.png";

export const amountConstants = ["50", "100", "200"];
export const paymentProviders = [
  // cc_card
  { id: "bank_transfer", src: bank_transfer, payment_method: "bank_transfer" },
  // Neteller
  { id: "neteller", src: neteller, payment_method: "apmgw_Neteller" },
  // Paypal
  { id: "paypal", src: paypal, payment_method: "apmgw_expresscheckout" },
  // Google Pay
  { id: "googlePay", src: googlePay, payment_method: "googlePay" },
  // Apple Pay
  { id: "applePay", src: applePay, payment_method: "applePay" },
  // PaysafeCard
  { id: "paysafecard", src: paysafecard, payment_method: "apmgw_PaySafeCard" },
  // SKRILL
  { id: "skrill", src: skrill, payment_method: "apmgw_MoneyBookers" },
  // SKRILL 1 tap
  { id: "skrill1tap", src: skrill1tap, payment_method: "apmgw_Skrill_One_Tap" },
];