import { useSelector } from 'react-redux';
import style from './style.module.scss';
import { selectSelectedWallet } from '../../../store/wallet-slice';
import hideBalanceIcon from '../../../assets/icons/hide-balance-icon.svg';
import showBalanceIcon from '../../../assets/icons/show-balance-icon.svg';
import addBalanceIcon from '../../../assets/icons/add-balance-icon.svg';
import withdrawBalanceIcon from '../../../assets/icons/withdraw-balance-icon.svg';
import { useState } from 'preact/hooks';
import DashboardWalletRow from './dashboard-wallet-row';
import { route } from 'preact-router';
import { useTranslation } from 'react-i18next';

const DashboardWallet = () => {
    const { t } = useTranslation();

    const selectedWallet = useSelector(selectSelectedWallet);

    const [showBalance, setShowBalance] = useState(false);

    const handleToggle = () => {
        setShowBalance(!showBalance);
    };

    const handleBalanceAction = () => {
        route('/account/wallet/deposits');
    };

    const handleWithdrawAction = () => {
        route('/account/wallet/withdrawals');
    };
    const balanceAmount = selectedWallet?.totalAmount || '0.00';
    const withdrawableAmount = selectedWallet?.totalCashAmount || '0.00';
    const bonusAmount = (parseFloat(balanceAmount) - parseFloat(withdrawableAmount)).toFixed(2);

    return (
        <div className={style.gradientBorder}>
            <div className={style.wallet}>
                <DashboardWalletRow
                    title={t('balance')}
                    value={showBalance ? balanceAmount : '*****'}
                    toggleIcon={showBalance ? hideBalanceIcon : showBalanceIcon}
                    actionIcon={addBalanceIcon}
                    handleToggle={handleToggle}
                    handleClick={handleBalanceAction}
					currency={selectedWallet?.currency}
                />

                <DashboardWalletRow
                    title={t('withdrawable')}
                    value={showBalance ? withdrawableAmount : '*****'}
                    actionIcon={withdrawBalanceIcon}
                    handleClick={handleWithdrawAction}
					currency={selectedWallet?.currency}
                />

                <DashboardWalletRow
                    title={t('bonus')}
                    value={showBalance ? bonusAmount : '*****'}
					currency={selectedWallet?.currency}
                />
            </div>
        </div>
    );
};

export default DashboardWallet;
