import clsx from 'clsx';
import { useMemo, useState, useCallback } from 'preact/hooks';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { MenuItem, TextField, Skeleton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GameCard from '../game-card-new';
import { Typography } from '../typography';
import { useSearch } from '../../hooks/useSearch';
import { StyledMenu } from '../../components/styled-menu/style';

import { useSelector } from 'react-redux';
import { selectUserSession } from '../../store/authentication-slice';

import CloseIcon from '../../assets/icons/x-icon.svg';
import SearchIcon from '../../assets/icons/search-icon.svg';
import DropdownIcon from '../../assets/icons/dropdown-icon-purple.svg';
import CloseErrorToastMessageIcon from '../../assets/icons/close-error-toast-message-icon.svg';

import style from './style.module.scss';

const searchMenuId = 'searchMenu';

const Search = ({
	isFocused = false,
	isFullWidth = true,
	isLiveCasino = false,
	searchBarClassName = '',
	isDropdownVisible = false,
	onBackgroundClickCallback = () => {},
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [isSearchBarFocused, setIsSearchBarFocused] = useState(
		isFocused || false
	);
	const [isInputValueChanged, setIsInputValueChanged] = useState(false);
	const [selectedSearchMenuValue, setSelectedSearchMenuValue] = useState(false);

	const isSearchMenuOpen = Boolean(anchorEl);
	const userSession = useSelector(selectUserSession);

	const { t } = useTranslation();

	const searchMenuItems = useMemo(
		() => [
			{
				text: t('casino'),
				onClickHandler: () => {
					setSelectedSearchMenuValue(false);
				},
			},
			{
				text: t('live_casino'),
				onClickHandler: () => {
					setSelectedSearchMenuValue(true);
				},
			},
		],
		[t]
	);

	const { data, isLoading, recentSearches, onClearSearch, onDeleteSearchItem } =
		useSearch(
			searchQuery,
			isDropdownVisible ? selectedSearchMenuValue : isLiveCasino
		);

	const handleFocus = useCallback((event) => {
		if (!event.target.closest('.jsCloseSearchButton')) {
			setIsSearchBarFocused(true);
		}
	}, []);

	const handleChange = useCallback(
		(e) => {
			setSearchQuery(e.target.value);
			if (!isInputValueChanged) {
				setIsInputValueChanged(true);
			}
		},
		[isInputValueChanged]
	);

	const handleRecentSearchClick = useCallback(
		(value = '') =>
			() => {
				setSearchQuery(value);
			},
		[]
	);

	const handleDeleteSearchItem = useCallback(
		(id = 1) =>
			(e) => {
				e.stopPropagation();
				onDeleteSearchItem(id);
			},
		[onDeleteSearchItem]
	);

	const handleBackgroundClick = useCallback(
		(event) => {
			if (event.target.closest(`#${searchMenuId}`)) {
				event.preventDefault();
			} else {
				setIsSearchBarFocused(false);

				onBackgroundClickCallback();
			}
		},
		[onBackgroundClickCallback]
	);

	const onSearchMenuButtonClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeSearchMenu = () => {
		setAnchorEl(null);
	};

	const renderContent = () => {
		if (
			(isFocused && !isInputValueChanged) ||
			(!isLoading && searchQuery.length < 3)
		) {
			return (
				<p className={style.textCenter}>
					{t('search_requires_at_least_3_characters')}
				</p>
			);
		} else if (!isLoading && !data?.length) {
			return <p className={style.textCenter}>{t('no_results_found')}!</p>;
		} else if (isLoading) {
			const array = [1, 2, 3, 4, 5];

			return array.map((item) => (
				<Skeleton
					key={item}
					variant="rounded"
					width={160}
					height={215}
					animation="pulse"
				/>
			));
		}

		return data?.map((game, index) => (
			<GameCard
				key={`${game.id}-${index}`}
				game={game}
				onClickGameCallback={onBackgroundClickCallback}
			/>
		));
	};

	const renderRecentSearches = () => {
		return (
			<>
				<div className={style.middleDiv}>
					<p>{t('recent_searches')}</p>
					<p onClick={onClearSearch} className={style.clearSearch}>
						{t('clear_search')} ({recentSearches?.length ?? 0})
					</p>
				</div>
				<div className={style.recentSearchWrapper}>
					{recentSearches.map((item) => (
						<span
							key={item.id}
							className={style.recentSearch}
							onClick={handleRecentSearchClick(item.value)}
						>
							{item.value}
							<img src={CloseIcon} onClick={handleDeleteSearchItem(item.id)} />
						</span>
					))}
				</div>
			</>
		);
	};

	return (
		<ClickAwayListener onClickAway={handleBackgroundClick}>
			<div className={style.wrapper}>
				{isSearchBarFocused && <div className={style.blurBackground} />}
				<TextField
					variant="standard"
					inputRef={(input) => isFocused && input && input.focus()}
					fullWidth={isFullWidth}
					className={clsx(style.searchBar, searchBarClassName)}
					placeholder={t('search_your_game')}
					value={searchQuery}
					onClick={handleFocus}
					autoComplete="off"
					onChange={handleChange}
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<>
								{isDropdownVisible && (
									<InputAdornment position="start">
										<div
											className={style.searchOptionMenu}
											onClick={onSearchMenuButtonClick}
										>
											<Typography
												type="st4"
												fontColor="white"
												className={style.selectedSearchMenuValue}
											>
												{selectedSearchMenuValue
													? t('live_casino')
													: t('casino')}
											</Typography>

											<img
												src={DropdownIcon}
												className={anchorEl && style.rotated}
												alt="dropdown-icon"
											/>
										</div>

										<StyledMenu
											type="light"
											id={searchMenuId}
											anchorEl={anchorEl}
											open={isSearchMenuOpen}
											disableScrollLock={true}
											transformOrigin={{
												horizontal: 'center',
												vertical: 'top',
											}}
											anchorOrigin={{
												horizontal: 'center',
												vertical: 'bottom',
											}}
											variant="menu"
											onClose={closeSearchMenu}
											onClick={closeSearchMenu}
										>
											{searchMenuItems.map((menuItem, index) => (
												<MenuItem
													key={`search-menu-item-${index}`}
													onClick={menuItem.onClickHandler}
												>
													<span>{menuItem.text}</span>
												</MenuItem>
											))}
										</StyledMenu>
									</InputAdornment>
								)}

								<InputAdornment position="start">
									<img src={SearchIcon} />
								</InputAdornment>
							</>
						),
						endAdornment: isSearchBarFocused && (
							<img
								src={CloseErrorToastMessageIcon}
								alt="close-search-icon"
								className={clsx(style.closeSearchButton, 'jsCloseSearchButton')}
								onClick={handleBackgroundClick}
							/>
						),
						className: style.inputWrapper,
						inputProps: {
							className: style.customInput,
						},
					}}
				/>

				{isSearchBarFocused && (
					<div className={style.searchOpen}>
						<div className={style.results}>{renderContent()}</div>
						{recentSearches?.length && userSession ? renderRecentSearches() : null}
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};

export default Search;
