import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '../../../../components/typography';
import { deletePlayerRealityCheck, getPlayerRealityChecks } from '../../../../store/realityCheck-slice';
import playerProtectionCommonClasses from '../style.module.scss';
import styles from '../style.module.scss';
import Button from '../../../../components/forms-new/button/button';
import { clearRealityCheckTimer } from '../../../../utils/reality-check';

const RealityCheckPanel = ({ data }) => {
	const dispatch = useDispatch();

	const handleDelete = async () => {
		if (data?.id) {
			try {
				await dispatch(deletePlayerRealityCheck(data.id)).unwrap();
				await dispatch(getPlayerRealityChecks()).unwrap();
				clearRealityCheckTimer();
			} catch (error) {
				console.error('Failed to delete reality check or fetch updated data:', error);
			}
		}
	};

	const formatTime = (minutes) => {
		if (minutes >= 60) {
		  const hours = minutes / 60;
		  return hours === 1 ? "1 hour" : `${hours} hours`;
		}
		return `${minutes} minutes`;
	  };

	return (
		<div className={playerProtectionCommonClasses.appliedLimitWrapper}>
			<div className={playerProtectionCommonClasses.limitFieldsWrapper}>
				<Typography type="st2" fontColor="primary">
					Active Reality Check:
				</Typography>
				<Typography type="st2" fontColor="white">
					{formatTime(data?.intervalInMinutes)}
				</Typography>
			</div>
			<div className={styles.buttonMargin}>
				<Button onClick={handleDelete}>Remove</Button>
			</div>
		</div>
	);
};

export default RealityCheckPanel;
