import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as service from '../services/player-service';
import { UserActiveStatus } from '../constants/enums';
import { createPlayerExclusion } from './exclusion-slice';

const initialState = {
	player: null,
	playerTransactions: null,
	playerSessionTransactions: null,
	error: '',
};

const getPlayerData = createAsyncThunk(
	'player/get',
	async (id, { rejectWithValue }) => {
		try {
			const response = await service.getPlayerData(id);
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const getPlayerTransactions = createAsyncThunk(
	'player/getPlayerTransactions',
	async (id, { rejectWithValue }) => {
		try {
			const response = await service.getPlayerTransactions(id);
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

// const getPlayerSessionTransactions = createAsyncThunk(
// 	'player/getPlayerSessionTransactions',
// 	async (params, { rejectWithValue }) => {
// 		const response = await service.getPlayerSessionTransactions(params);

// 		if (response.status !== 200) {
// 			return rejectWithValue(response.data);
// 		}

// 		return response.data;
// 	}
// );

// const updatePlayerActiveStatus = createAsyncThunk(
// 	'player/updateActiveStatus',
// 	async (data, { rejectWithValue }) => {
// 		try {
// 			const response = await service.updatePlayerActiveStatus(data);

// 			if (response.status !== 200) {
// 				return rejectWithValue(response.data);
// 			}

// 			return response.data;
// 		} catch (e) {
// 			return rejectWithValue(e.response.data);
// 		}
// 	}
// );

const playerSlice = createSlice({
	name: 'player',
	initialState,
	reducers: {
		updatePlayerData: (state, action) => {
			state.player = action.payload;
		},
		updatePlayerStatus: (state, action) => {
			if (state?.player?.userActiveStatus) {
				state.player.userActiveStatus = action.payload;
			}
		},
		resetUpdatePlayerStatusError: (state) => {
			state.error = '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getPlayerData.fulfilled, (state, action) => {
			state.player = action.payload;
		});
		builder.addCase(getPlayerTransactions.fulfilled, (state, action) => {
			state.playerTransactions = action.payload;
		});
		// builder.addCase(getPlayerSessionTransactions.fulfilled, (state, action) => {
		// 	state.playerSessionTransactions = action.payload;
		// });
		// builder.addCase(updatePlayerActiveStatus.fulfilled, (state, action) => {
		// 	state.player = action.payload.data;
		// });
		// builder.addCase(updatePlayerActiveStatus.rejected, (state, action) => {
		// 	state.error = action.payload.data;
		// });
		builder.addCase(createPlayerExclusion.fulfilled, (state) => {
			state.player = {
				...state.player,
				userActiveStatus: UserActiveStatus.SUSPENDED,
			};
		});
	},
});

export const {
	updatePlayerData,
	updatePlayerStatus,
	resetUpdatePlayerStatusError,
} = playerSlice.actions;

const selectPlayerData = (state) => state.player.player;
const selectPlayerTransactions = (state) => state.player.playerTransactions;
const selectPlayerSessionTransactions = (state) =>
	state.player?.playerSessionTransactions;
const selectUpdatePlayerStatusError = (state) => state.player.error;

export {
	selectPlayerData,
	selectPlayerTransactions,
	selectUpdatePlayerStatusError,
	selectPlayerSessionTransactions,
}; // selectors

export {
	getPlayerData,
	getPlayerTransactions
	// updatePlayerActiveStatus,
	// getPlayerSessionTransactions,
}; // thunks

export default playerSlice.reducer;
