import {memo} from "preact/compat";
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import CompanyLogo from '../company-logo';
import { Typography } from '../typography';
import FooterDivider from '../footer-divider';
import FooterCategories from '../footer-categories';
import FooterImagesSection from '../footer-images-section';
import { selectFooterContent } from '../../store/content-slice';

const Footer = ({language}) => {
	const footerInfo = useSelector(selectFooterContent(language));

	if (!footerInfo) {
		return null;
	}

	return (
		<div className={style.footerWrapper}>
			<FooterCategories language={language} />
			<FooterDivider />
			<FooterImagesSection images={footerInfo.paymentMethods} />
			<FooterDivider />
			<div className={style.bottomSectionWrapper}>
				<CompanyLogo variant="5" />

				<div className={style.content1Wrapper}>
					<Typography type="st2" fontColor="white" className={style.content}>
						Copyright © 2024 | All Rights Reserved | 4Play Gaming
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default memo(Footer);
