import { Typography } from '../typography/index.js';
import { ToggleButtonGroup, ToggleButton } from './style.js';

const ToggleButtons = ({
	alignment,
	handleChange,
	items,
	isMobile = false,
}) => {
	return (
		<ToggleButtonGroup
			exclusive
			isMobile={isMobile}
			color="primary"
			value={alignment}
			aria-label="Platform"
			onChange={handleChange}
		>
			{items.map((item, idx) => (
				<ToggleButton key={idx} isMobile={isMobile} value={item.value}>
					{item?.icon ? <img src={item.icon} /> : null}
					<Typography type="caption3" fontColor="white">
						{item.label}
					</Typography>
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

export default ToggleButtons;
