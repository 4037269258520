import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as service from '../services/deposit-service';

const initialState = {
	lastDeposit: null,
	suggestedAmounts: [],
	loading: false,
	error: null,
};

const getLastSuccessfullDeposit = createAsyncThunk(
	'deposit/get',
	async (_, { rejectWithValue }) => {
		try {
			const response = await service.getLastSuccessfulDeposit();
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);
const getMedianValueAndSugestedOnes = createAsyncThunk(
	'deposit/getMedianValue',
	async (_, { rejectWithValue }) => {
		try {
			const response = await service.getMedianValues();
			return response.data
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const depositSlice = createSlice({
	name: 'deposit',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getLastSuccessfullDeposit.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getLastSuccessfullDeposit.fulfilled, (state, action) => {
				state.lastDeposit = action.payload;
				state.loading = false;
			})
			.addCase(getLastSuccessfullDeposit.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			})
			.addCase(getMedianValueAndSugestedOnes.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getMedianValueAndSugestedOnes.fulfilled, (state, action) => {
				state.suggestedAmounts = action.payload;
				state.loading = false;
			})
			.addCase(getMedianValueAndSugestedOnes.rejected, (state, action) => {
				state.error = action.payload;
				state.loading = false;
			});
	},
});

const selectLastDeposit = (state) => state.deposit.lastDeposit;
const selectSuggestedAmounts = (state) => state.deposit.suggestedAmounts;
const selectDepositLoading = (state) => state.deposit.loading;
const selectDepositError = (state) => state.deposit.error;

export {
	selectLastDeposit,
	selectDepositLoading,
	selectDepositError,
	selectSuggestedAmounts,
};
export { getLastSuccessfullDeposit, getMedianValueAndSugestedOnes };
export default depositSlice.reducer;
