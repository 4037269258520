import { useDispatch, useSelector } from 'react-redux';

import { selectProviders } from '../../store/content-slice';

import Slider from '../slider';

import ProvidersIcon from '../../assets/icons/providers-icon.svg';
import {
	changeLiveCasinoSelectedProviders,
	selectFilterProviders,
} from '../../store/filter-slice';
import { useCallback } from 'preact/hooks';
import style from './style.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const ProvidersSlider = ({ hasIcon = false, categoriesContainerRef, providers }) => {
	const { t } = useTranslation();
	const activeProviders = useSelector(selectFilterProviders);
	const dispatch = useDispatch();

	const onProviderClick = useCallback(
		(provider) => {
			let updatedProviders = [...activeProviders];
			if (activeProviders.includes(provider)) {
				updatedProviders = updatedProviders.filter((p) => p !== provider);
			} else {
				updatedProviders.push(provider);
			}
			dispatch(changeLiveCasinoSelectedProviders(updatedProviders));
			categoriesContainerRef?.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		},
		[dispatch, activeProviders, categoriesContainerRef]
	);

	return (
		<Slider
			isProvider
			title={t('providers')}
			items={providers}
			activeItems={activeProviders}
			onClick={onProviderClick}
			icon={hasIcon && ProvidersIcon}
			classes={{
				carousel: clsx(style.providerCarousel, style.moreSpecificSelector),
				arrowButton: clsx(style.providerArrow, style.moreSpecificSelector),
			}}
		/>
	);
};

export default ProvidersSlider;
