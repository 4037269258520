import JackpotCard from "../jackpot-card";
import styles from "./style.module.scss";
import {useMemo} from "preact/hooks";
import avatarOne from "../../assets/images/jackpot-avatar-1.png";
import avatarTwo from "../../assets/images/jackpot-avatar-2.png";
import avatarThree from "../../assets/images/jackpot-avatar-3.png";
import avatarFour from "../../assets/images/jackpot-avatar-4.png";
import {useViewport} from "../../hooks/useViewport";
import Carousel from "../carousel";

const JackpotSection = () => {
  const {isMobileScreen} = useViewport();

  const jackpotItems = useMemo(() => {
    return [
      {initialAmount: 5000, targetAmount: 100000},
      {initialAmount: 10000, targetAmount: 200000},
      {initialAmount: 15000, targetAmount: 300000},
      {initialAmount: 20000, targetAmount: 400000},
    ]
  }, []);

  const backgroundClass = useMemo(() => {
    return {
      0: styles.firstContainer,
      1: styles.secondContainer,
      2: styles.thirdContainer,
      3: styles.fourthContainer,
    }
  }, [])

  const avatars = useMemo(() => {
    return {
      0: avatarOne,
      1: avatarTwo,
      2: avatarThree,
      3: avatarFour,
    }
  }, [])

  return (
    <>
      {!isMobileScreen && (
        <div className={styles.root}>
          {jackpotItems.map((item, index) =>
            <JackpotCard key={`jackpot-${index}`}
                         storageKey={`jackpot-${index}`}
                         backgroundClass={backgroundClass[index]}
                         avatar={avatars[index]}
                         initialAmount={item.initialAmount}
                         targetAmount={item.targetAmount}
            />)}
        </div>
      )
      }
      {isMobileScreen && (
        <Carousel classes={{itemContainer: styles.itemContainer}}>
          {jackpotItems.map((item, index) =>
            <JackpotCard key={`jackpot-${index}`}
                         storageKey={`jackpot-${index}`}
                         backgroundClass={backgroundClass[index]}
                         avatar={avatars[index]}
                         initialAmount={item.initialAmount}
                         targetAmount={item.targetAmount}
            />)}
        </Carousel>
      )}
    </>
  )
};

export default JackpotSection