import { Router as PreactRouter } from 'preact-router';

import LiveCasinoHomePage from './components/live-casino-home';
import LiveCasinoGroupsPage from './components/live-casino-groups';
import Redirect from '../../components/redirect';
import LiveCasinoGamesPage from './components/live-casino-games-page';

const LiveCasinoPage = () => {
	return (
		<PreactRouter>
			<LiveCasinoHomePage path="/live-casino/home" />
			<LiveCasinoGroupsPage path="/live-casino/group/:tagName" />
			<LiveCasinoGamesPage path="/live-casino/game-categories/:categoryId" />
			<Redirect path="/live-casino/:rest*" to="/live-casino/home" />
		</PreactRouter>
	);
};

export default LiveCasinoPage;
