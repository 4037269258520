import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import { baseSapphireApiUrl } from './constants';

const getPlayerRealityChecks= () => {
    return handleFetch(`${baseSapphireApiUrl}/casino/reality-check/`, {
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(),
        },        
    });
}

const createPlayerRealityCheck = (data) => {
    return handleFetch(`${baseSapphireApiUrl}/casino/reality-check/`, {
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(),
        },
        body: JSON.stringify(data),
        method: 'POST',
    });
};

const deletePlayerRealityCheck = (id) => {
    return handleFetch(`${baseSapphireApiUrl}/casino/reality-check/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(),
        },
        method: 'DELETE',
    });
};

export { getPlayerRealityChecks, createPlayerRealityCheck, deletePlayerRealityCheck };