import { useFormik } from 'formik';
import { verifyUserSession } from '../../services/authentication-service';
import { claimBonus } from '../../services/bonus-service';
import classes from './style.module.scss';
import clsx from 'clsx';
import Input from '../forms/input';
import Button from '../forms-new/button/button';
import { useTranslation } from 'react-i18next';

import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import { useContext } from 'preact/hooks';

const BonusClaimForm = ({ user, dispatch }) => {
	const { t } = useTranslation();
	const { onPushMessage } = useContext(ToastMessagesContext);
	const formik = useFormik({
		initialValues: {
			code: '',
		},
		// validationSchema: SignupSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				await claimBonus(user.id, values.code);
				dispatch(verifyUserSession(true));
				onPushMessage({
					message: 'Successfully claimed',
					type: 'info',
					title: 'Claimed',
				});
				resetForm();
			} catch (e) {
				onPushMessage({
					message: e.message || 'An error occured',
					type: 'error',
					title: 'Error',
				});
			}
		},
	});

	return (
		<div className={classes.bonusCodeContainer}>
			<Input
				name="code"
				value={formik.values.code}
				placeholder={t('enter_bonus_code')}
				customType="dark"
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				wrapperClassName={clsx(classes.bonusCode, classes.moreSpecificSelector)}
				isEmptyBottomTextContainerVisible={false}
			/>

			<Button onClick={formik.handleSubmit} className={classes.claimButton}>
				{t('claim')}
			</Button>
		</div>
	);
};

export default BonusClaimForm;
