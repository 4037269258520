import { useMemo, useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { route, Route } from 'preact-router';

import { selectUserSession } from '../../store/authentication-slice';

const ProtectedRoute = (props) => {
	const userSession = useSelector(selectUserSession);
	const isAuthenticated = useMemo(() => Boolean(userSession), [userSession]);

	useEffect(() => {
		if (!isAuthenticated) {
			route('/', true);
		}
	}, [isAuthenticated]);

	return <Route {...props} />;
};

export default ProtectedRoute;
