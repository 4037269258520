const handleFetch = async (url = '', data = {}) => {
	const response = await fetch(url, data);

	const result = await response.json();

	if (!response.ok) {
		throw new Error(result.data || result);
	}

	return result;
};

export default handleFetch;
