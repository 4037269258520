import {
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

const initialState = {
  selectedProviders: [],
};

const changeLiveCasinoSelectedProviders = createAction(
  "@@FILTER/CHANGE_SELECTED_PROVIDERS",
  (providers) => ({
    payload: providers,
  })
);

const filterSlice = createSlice({
  name: "filter",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(changeLiveCasinoSelectedProviders, (state, action) => {
      state.selectedProviders = action.payload;
    });
  }
})

const selectFilterProviders = (state) => state.filter.selectedProviders;

export {
  selectFilterProviders,
}

export {
  changeLiveCasinoSelectedProviders,
}

export default filterSlice.reducer;