const Status = {
	PENDING: 'pending',
	REJECTED: 'rejected',
	SUCCESS: 'success',
	FULFILLED: 'fulfilled',
};

Object.freeze(Status);

const CommunicationPreference = {
	SMS: 'sms',
	EMAIL: 'email',
	MOBILE: 'mobile',
};

Object.freeze(CommunicationPreference);

const UserActiveStatus = {
	ACTIVE: 'Active',
	FROZEN: 'Frozen',
	SUSPENDED: 'Suspended',
};

Object.freeze(UserActiveStatus);

const BonusStatus = {
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	EXPIRED: 'Expired',
	COMPLETED: 'Completed',
};

Object.freeze(BonusStatus);

export { Status, UserActiveStatus, CommunicationPreference, BonusStatus };
