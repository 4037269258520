import clsx from 'clsx';
import { Card } from '@mui/material';
import { Link, getCurrentUrl, Router as PreactRouter } from 'preact-router';

import Redirect from '../../components/redirect';
import { Typography } from '../../components/typography';

import Bonuses from './components/bonuses';
import Deposits from './components/deposits';
import Withdraws from './components/withdraws';
import { pathnames } from '../../constants/pathnames';

import style from './style.module.scss';

import gearIcon from '../../assets/icons/gear-icon.svg';

const {
	transactions: { /* betsPath, */ bonusesPath, depositsPath, withdrawsPath },
} = pathnames;

const navigationItems = [
	{
		text: 'Deposits',
		url: depositsPath,
	},
	{
		text: 'Withdrawals',
		url: withdrawsPath,
	},
	// {
	// 	text: 'Bets',
	// 	url: betsPath,
	// },
	{
		text: 'Bonuses',
		url: bonusesPath,
	},
];

const TransactionsPage = () => {
	const currentUrl = getCurrentUrl();

	return (
		<div className={style.pageWrapper}>
			<div className={style.titleWrapper}>
				<img src={gearIcon} />

				<Typography type="h6" fontColor="white" className={style.title}>
					Transactions
				</Typography>
			</div>

			<div className={style.contentWrapper}>
				<Card className={style.navigation}>
					{navigationItems.map((item, index) => (
						<Link
							key={`${item.text.toLowerCase().replace(/\s/g, '')}-${index}`}
							href={item.url}
							className={clsx(item.url === currentUrl && style.activeNavItem)}
						>
							{item.text}
						</Link>
					))}
				</Card>

				<Card
					className={clsx(
						style.content,
						currentUrl === bonusesPath && style.autoHeightContent
					)}
				>
					<PreactRouter>
						<Deposits path={depositsPath} />

						<Withdraws path={withdrawsPath} />

						{/* <Bets path={betsPath} /> */}

						<Bonuses path={bonusesPath} />

						<Redirect path="/transactions/:rest*" to={depositsPath} />
					</PreactRouter>
				</Card>
			</div>
		</div>
	);
};

export default TransactionsPage;
