import { useSelector } from 'react-redux';
import TransactionFilterBar from '../../../../components/transaction-filter-bar';
import TransactionPagination from '../../../../components/transaction-pagination';
import TransactionTable from '../../../../components/transaction-table';
import {
	DATE_FILTERS,
	DATE_FILTERS_EPOCH_TIME_MS,
} from '../../../../utils/consts';
import commonClasses from '../../style.module.scss';
import {useEffect, useMemo, useState} from 'preact/hooks';
import { selectPlayerTransactions } from '../../../../store/player-slice';
import { generateAndExportData } from '../../../../utils/export-data-utils';
import { selectUserSession } from '../../../../store/authentication-slice';
import {useTranslation} from "react-i18next";

const ITEMS_PER_PAGE = 5;

const GamingHistory = () => {
	const {t} = useTranslation();
	const playerTransactions = useSelector(selectPlayerTransactions)
	const userSession = useSelector(selectUserSession);
	const [currentPage, setCurrentPage] = useState();
	const [filteredData, setFilteredData] = useState();
	const [dataInView, setDataInView] = useState();
	const [showPageIndicators, setShowPageIndicators] = useState(false);
	const [filters, setFilters] = useState({
		date: DATE_FILTERS[0],
		from: undefined,
		to: undefined,
	});
	const [triggerFilter, setTriggerFilter] = useState(false);

	const GAMING_HISTORY_COLUMN_MAPPING = useMemo(() => [
		{
			header: t('date'),
			field: 'createdAt',
			isDate: true
		},
		{
			header: t('round_id'),
			field: 'roundId',
		},
		{
			header: t('game'),
			field: 'gameName',
		},
		{
			header: t('bet'),
			field: 'stake',
			hasCurrency: true
		},
		{
			header: t('bonus'),
			field: 'bonusBalance',
			hasCurrency: true
		},
		{
			header: t('win'),
			field: 'win',
			hasCurrency: true
		},
		{
			header: t('total_amount'),
			field: 'totalAmount',
			hasCurrency: true
		},
	], [t]);

	const runDataExport = (data, filters, reportName) => {
		reportName += `_${userSession?.username}`;
		if (filters.from && filters.to) {
			reportName += `_${new Date(filters.from).toDateString()}-${new Date(
				filters.to
			).toDateString()}`;
		}
		generateAndExportData(data, GAMING_HISTORY_COLUMN_MAPPING, reportName);
	};

	const getPaginationSlices = (page) => {
		return {
			min: page * ITEMS_PER_PAGE,
			max: (page + 1) * ITEMS_PER_PAGE,
		};
	};

	const onPageChange = ({ selected }) => {
		setCurrentPage(selected);
		const { min, max } = getPaginationSlices(selected);
		filteredData.length > ITEMS_PER_PAGE
			? setShowPageIndicators(true)
			: setShowPageIndicators(false);
		setDataInView(filteredData.slice(min, max));
	};

	const filterDataByDateType = (data, filters) => {
		switch (filters.date) {
			case DATE_FILTERS[1]:
			case DATE_FILTERS[2]:
			case DATE_FILTERS[3]:
			case DATE_FILTERS[4]: {
				const from = DATE_FILTERS_EPOCH_TIME_MS[filters.date];
				return data.filter((transaction) => {
					const transactionTime = new Date(transaction.createdAt);
					return transactionTime > from;
				});
			}
			case DATE_FILTERS[5]: {
				const to = new Date(filters.to).getTime();
				const from = new Date(filters.from).getTime();
				return data.filter((transaction) => {
					const transactionTime = new Date(transaction.createdAt).getTime();
					return transactionTime > from && transactionTime < to;
				});
			}

			default:
				return data;
		}
	};

	useEffect(() => {
		const transactions = playerTransactions?.gaming_activity_transactions_aggr;
		if (
			transactions &&
			transactions.length > ITEMS_PER_PAGE &&
			!dataInView &&
			!filteredData
		) {
			setFilteredData(transactions);
			setDataInView(transactions.slice(0, ITEMS_PER_PAGE));
			transactions.length > ITEMS_PER_PAGE
				? setShowPageIndicators(true)
				: setShowPageIndicators(false);
			setCurrentPage(0);
		}
	}, [playerTransactions?.gaming_activity_transactions_aggr, dataInView, filteredData]);

	useEffect(() => {
		if (filteredData) {
			const filteredByDate = filterDataByDateType(
				playerTransactions?.gaming_activity_transactions_aggr,
				filters
			);
			const currentPage = 0;
			setCurrentPage(currentPage);
			const { min, max } = getPaginationSlices(currentPage);
			filteredByDate.length > ITEMS_PER_PAGE
				? setShowPageIndicators(true)
				: setShowPageIndicators(false);
			setFilteredData(filteredByDate);
			setDataInView(filteredByDate?.slice(min, max));
		}
	}, [
		triggerFilter,
		playerTransactions?.gaming_activity_transactions_aggr,
		setCurrentPage,
		setFilteredData,
		setDataInView,
		setShowPageIndicators,
	]);

	return (
		<div className={commonClasses.tabContent}>
			<TransactionFilterBar
				filters={filters}
				setFilters={setFilters}
				setTriggerFilter={setTriggerFilter}
				filteredData={filteredData}
				reportName={'Gaming_History'}
				runDataExport={runDataExport}
			/>
			<TransactionTable
				transactions={dataInView}
				columntCount={7}
				tableMapping={GAMING_HISTORY_COLUMN_MAPPING}
			/>
			{showPageIndicators && (
				<TransactionPagination
					pageCount={Math.ceil(
						playerTransactions?.gaming_activity_transactions_aggr.length / ITEMS_PER_PAGE
					)}
					selectedPage={currentPage}
					onPageChange={onPageChange}
				/>
			)}
		</div>
	);
};

export default GamingHistory;
