import style from './style.module.scss';

import ErrorIcon from '../../../assets/icons/error-icon.svg';

const InputError = ({ errorMessage }) => {
	return (
		<p className={style.error}>
			<ErrorIcon className={style.icon} />

			<span className={style.errorMessage}>{errorMessage}</span>
		</p>
	);
};

export default InputError;
