import { useEffect } from 'preact/hooks';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectLimits,
	getPlayerLimits,
	selectShouldRefetchPlayerLimitData,
} from '../../../../store/limit-slice';
import LimitPanel from '../limit-panel';
import AddLimitPanel from '../add-limits-panel';
import { Typography } from '../../../../components/typography';
import { selectUserSession } from '../../../../store/authentication-slice';

import commonClasses from '../../style.module.scss';
import playerProtectionCommonClasses from '../style.module.scss';

const LossLimits = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const shouldRefetchPlayerLimitData = useSelector(
		selectShouldRefetchPlayerLimitData
	);
	const userSession = useSelector(selectUserSession);
	const lossLimits = useSelector((state) => selectLimits(state, 'loss'));

	useEffect(() => {
		if (userSession) {
			dispatch(getPlayerLimits(userSession.id));
		}
	}, [dispatch, userSession]);

	useEffect(() => {
		if (userSession && shouldRefetchPlayerLimitData) {
			dispatch(getPlayerLimits(userSession.id));
		}
	}, [dispatch, userSession, shouldRefetchPlayerLimitData]);

	return (
		<>
			<div className={commonClasses.tabContent}>
				<AddLimitPanel
					isButtonDisabled={lossLimits?.length > 2}
					playerId={userSession?.id}
					limitType="loss"
				/>

				{lossLimits?.length === 0 && (
					<Typography
						type="st2"
						fontColor="white"
						className={playerProtectionCommonClasses.noLimitsText}
					>
						{t('no_limit_defined')}
					</Typography>
				)}
			</div>

			{lossLimits?.length > 0 && (
				<div className={playerProtectionCommonClasses.limitsWrapper}>
					{lossLimits?.map((limit, index) => (
						<div key={`limit-${index}`} className={commonClasses.tabContent}>
							<LimitPanel
								data={limit}
								type="loss"
								limitsCount={lossLimits?.length}
							/>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default LossLimits;
