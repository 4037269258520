import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const MUIButton = styled(Button)(({ theme, opacity, backgorundColor }) => {
	const backgorundOpacity = opacity / 100;
	const bgColor = theme.palette[backgorundColor]['100'];
	return {
		[`&.MuiButton-root`]: {
			width: 'auto',
			height: '42px',
			paddingInline: '14px',
			textTransform: 'none',
			backgroundColor: bgColor,
			opacity: backgorundOpacity,
		},

		[`&.fullWidth`]: {
			width: '100%',
		},

		[`&.betsino-blue`]: {
			color: theme.palette.white.regular,
		},

		[`&.betsino-transparent`]: {
			backgroundColor: 'transparent',
			color: theme.palette.white.regular,
		},

		[`&.betsino-yellow`]: {
			color: theme.palette.black.regular,
		},

		[`&.betsino-black`]: {
			color: theme.palette.white.regular,
		},

		[`&.betsino-white`]: {
			color: theme.palette.black.regular,
		},

		'&:hover': {
			backgroundColor: bgColor,
		},

		'&.Mui-disabled': {
			backgroundColor: 'var(--color-grey-2-100)',
		},
	};
});

export default MUIButton;
