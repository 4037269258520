import React, { useState, useEffect } from 'react';
import { Modal } from '../../../../components/modal';
import style from './style.module.scss';
import { Button } from '../../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../../store/authentication-slice';
import { setRealityCheckTimer } from '../../../../utils/reality-check';
import { getPlayerRealityChecks, selectRealityCheck } from '../../../../store/realityCheck-slice';

const RealityCheckPopup = ({ socket }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(() => {
        return localStorage.getItem('isRealityCheckModalOpen') === 'true';
    });

    const [realityCheckData, setRealityCheckData] = useState(() => {
        const storedData = localStorage.getItem('realityCheckData');
        return storedData ? JSON.parse(storedData) : {
            realityCheckTimer: 0,
            profit: 0,
            loss: 0,
            casinoSessionStartTime: '',
        };
    });

    const [timer, setTimer] = useState(() => {
        const storedStartTime = localStorage.getItem('realityCheckStartTime');
        if (storedStartTime) {
            const startTime = new Date(parseInt(storedStartTime, 10));
            const elapsed = Math.floor((new Date() - startTime) / 1000);
            const initialTimer = realityCheckData.realityCheckTimer * 60;
            return Math.max(initialTimer - elapsed, 0);
        }
        return 180; 
    });

    const realityCheck = useSelector(selectRealityCheck);
    const sessionStartTime = new Date(realityCheckData.casinoSessionStartTime);
    const formattedStartTime = sessionStartTime.toLocaleString();

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    useEffect(() => {
        if (socket) {
            socket.on('reality-check', (msg) => {
                if (typeof msg === 'object') {
                    const newRealityCheckData = {
                        realityCheckTimer: msg.realityCheckTimer,
                        profit: msg.profit,
                        loss: msg.loss,
                        casinoSessionStartTime: msg.casinoSessionStartTime,
                    };
                    setRealityCheckData(newRealityCheckData);
                    localStorage.setItem('realityCheckData', JSON.stringify(newRealityCheckData));

                    const newTimer = msg.realityCheckTimer * 60;
                    setTimer(newTimer);
                    localStorage.setItem('realityCheckStartTime', new Date().getTime().toString());
                }
                setIsOpen(true);
                localStorage.setItem('isRealityCheckModalOpen', 'true');
            });

            return () => {
                socket.off('reality-check');
            };
        }
    }, [socket]);

    useEffect(() => {
        dispatch(getPlayerRealityChecks());
    }, [dispatch]);

    useEffect(() => {
        let interval;
        if (isOpen && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    const newTimer = prevTimer - 1;
                    return newTimer;
                });
            }, 1000);
        } else if (isOpen && timer <= 0) {
            handleLogout();
        }

        return () => clearInterval(interval);
    }, [isOpen, timer]);

    const handleCloseModal = () => {
        setIsOpen(false);
        localStorage.removeItem('isRealityCheckModalOpen');
        localStorage.removeItem('realityCheckStartTime');
    };

    const handleContinuePlaying = () => {
        setRealityCheckTimer(realityCheck?.intervalInMinutes);
        setIsOpen(false);
        localStorage.removeItem('isRealityCheckModalOpen');
        localStorage.removeItem('realityCheckStartTime');
    };

    const handleLogout = () => {
        setIsOpen(false);
        dispatch(logoutUser());
        localStorage.removeItem('isRealityCheckModalOpen');
        localStorage.removeItem('realityCheckStartTime');
    };

    return (
        <Modal
            open={isOpen}
            setOpen={setIsOpen}
            onCloseModal={handleCloseModal}
            closeOnBackdropClick={false}
            showCloseButton={false}
            className={style.realityCheckModalWrapper}
        >
            <div className={`${style.textWrapper} ${style.topWrapper}`}>
                <p className={style.modalBigText}>Reality Check</p>
            </div>
            <div className={`${style.textWrapper} ${style.secondHeader}`}>
                <p className={style.modalBigText}>
                    Your session has been active since:
                </p>
            </div>
            <div className={style.textWrapper}>
                <p className={style.modalBigText}>Expires in:</p>
                <span className={style.timer}>{formatTime(timer)}</span>
            </div>
            <div className={style.textWrapper}>
                <span className={`${style.currencyStyle} ${style.dateSpan}`}>
                    {formattedStartTime}
                </span>
            </div>

            <div>
                <div className={style.profitLossPosition}>
                    <label className={style.modalBigText}>Profit</label>
                    <label className={style.modalBigText}>Loss</label>
                </div>
                <div className={style.currencyPosition}>
                    <span className={style.currencyStyle}>
                        {realityCheckData.profit}$
                    </span>
                    <span className={style.currencyStyle}>{realityCheckData.loss}$</span>
                </div>
            </div>

            <div className={style.textWrapper}>
                <p className={style.modalText}>Do you want to remain active?</p>
            </div>

            <div className={style.buttons}>
                <Button className={style.logoutButton} onClick={handleLogout}>
                    Logout
                </Button>
                <Button
                    className={style.continueButton}
                    onClick={handleContinuePlaying}
                >
                    Continue Playing
                </Button>
            </div>
        </Modal>
    );
};

export default RealityCheckPopup;
