import clsx from 'clsx';

import style from './style.module.scss';

export const Typography = ({
	display = 'block',
	fontColor = 'white', 
	type = 'h1',
	colorType = '100',
	className,
	children,
	...rest
}) => {
	return (
		<p
			{...rest}
			className={clsx(style.typography, style[type], className)}
			style={{
				display,
				color:
					colorType !== '100'
						? `var(--color-${fontColor}-${colorType})`
						: `var(--color-${fontColor})`,
			}}
		>
			{children}
		</p>
	);
};
