import { Typography } from '../typography';
import FlameIcon from '../../assets/icons/flame.svg';
import style from './style.module.scss';
import GameCardTopTen from '../game-card-top-ten';
import Carousel from '../carousel';
import { useTranslation } from 'react-i18next';

const GamesTopTenLayout = ({ title = '', games = [] }) => {
	const { t } = useTranslation();

	return (
		<div className={style.wrapper}>
			<div className={style.heading}>
				<Typography type="h7" fontColor="white" className={style.title}>
					<img src={FlameIcon} alt={'flame'} />
					{title}
				</Typography>
				<div className={style.icon}>{t('powered_by')} AI</div>
			</div>

			<Carousel
				className={style.gamesGroupWrapper}
				classes={{ itemContainer: style.itemContainer }}
			>
				{games.map((game, index) => (
					<GameCardTopTen
						key={game.externalGameId || game.gameId}
						number={index + 1}
						game={game}
					/>
				))}
			</Carousel>
		</div>
	);
};

export default GamesTopTenLayout;
