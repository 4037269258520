import style from './style.module.scss';
import { Typography } from '../typography';
import ButtonRound from '../button-round';
import { route } from 'preact-router';
import Countdown from 'react-countdown';

const TournamentCard = ({ tournament }) => {
	return (
		<div className={style.tournamentContainer}>
			<div className={style.tournamentCard}>
				<Typography
					type="h5"
					fontColor="white"
					className={style.tournamentTitle}
				>
					{tournament.name}
				</Typography>

				<div className={style.rowContainer}>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						Ends in:
					</Typography>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						<Countdown date={Date.now() + Math.random() * 1000000000} />
					</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						Entry Fee:
					</Typography>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						{tournament.entry_fee}
					</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						Payout:
					</Typography>
					<Typography type="caption3" fontColor="white" display="-webkit-box">
						{tournament.payout}
					</Typography>
				</div>

				<div className={style.btnWrapper}>
					<ButtonRound
						buttonStyle={'primary'}
						onClick={() => {
							route(`/tournaments/${tournament.id}`);
						}}
					>
						<Typography type="st4" fontColor="blue-grey">
							Details
						</Typography>
					</ButtonRound>
				</div>
			</div>
			{tournament.image && (
				<div className={style.imageContainer}>
					<img
						src={tournament.image.default}
						className={style.tournamentImage}
					/>
				</div>
			)}
		</div>
	);
};

export default TournamentCard;
