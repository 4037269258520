import { useEffect, useMemo, useRef } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectUserSession,
	verifyUserSession,
} from '../store/authentication-slice';
import { clearUserBalance } from '../store/wallet-slice';
import { fetchUser, clearUser } from '../store/user-slice';
import constants from '../constants/general';

const useUserSession = () => {
	const dispatch = useDispatch();
	const userSession = useSelector(selectUserSession);
	const hasUserBeenActive = useRef(true);
	// const timeoutId = useRef(0);

	// const interval = useMemo(() => 1000 * 60 * 60 * 24 * 7, []);

	// useEffect(() => {
	// 	const handleActivity = () => {
	// 		hasUserBeenActive.current = true;
	// 		clearTimeout(timeoutId);

	// 		timeoutId.current = setTimeout(() => {
	// 			hasUserBeenActive.current = false;
	// 		}, interval);
	// 	};

	// 	document.addEventListener('click', handleActivity);
	// 	document.addEventListener('keydown', handleActivity);
	// 	document.addEventListener('scroll', handleActivity);

	// 	return () => {
	// 		document.removeEventListener('click', handleActivity);
	// 		document.removeEventListener('keydown', handleActivity);
	// 		document.removeEventListener('scroll', handleActivity);
	// 	};
	// }, []);

	useEffect(() => {
		dispatch(verifyUserSession(hasUserBeenActive.current));

		// const intervalId = setInterval(() => {
		// 	dispatch(verifyUserSession(hasUserBeenActive.current));
		// }, interval);

		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, []);

	useEffect(() => {
		if (userSession) {
			dispatch(fetchUser(userSession));

			if (sessionStorage.getItem(constants.SESSION_STORAGE_SITE_LANGUAGE)) {
				sessionStorage.removeItem(constants.SESSION_STORAGE_SITE_LANGUAGE);
			}
		} else {
			dispatch(clearUser());
			dispatch(clearUserBalance());
		}
	}, [dispatch, userSession]);
};

export default useUserSession;
