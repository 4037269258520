// import React, { ReactNode } from 'react';
import clsx from 'clsx';

import style from './style.module.scss';

// type Props = {
//   className?: string;
//   disabled?: boolean;
//   type?: "button" | "submit" | "reset" | undefined;
//   onClick: () => void;
//   children: ReactNode;
// };

// const Button: React.FC<Props> = ({
const Button = ({
	className,
	onClick,
	type = 'button',
	disabled = false,
	children,
}) => {
	return (
		<button
			type={type}
			className={clsx(style.button, className)}
			disabled={disabled}
			onClick={onClick}
			style={{
				background: disabled ? 'grey' : '',
				cursor: disabled ? 'not-allowed' : 'pointer',
			}}
		>
			{children}
		</button>
	);
};

export default Button;
