import { route } from 'preact-router';
import { useMemo } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';

import style from './style.module.scss';

import MenuIcon from '../../assets/icons/mobile-navigation/menu-icon.svg';
import PromotionsIcon from '../../assets/icons/mobile-navigation/promotions-icon.svg';
import WalletIcon from '../../assets/icons/mobile-navigation/wallet-icon.svg';
import CasinoIcon from '../../assets/icons/mobile-navigation/casino-icon.svg';

const MobileNavigation = ({ onClickMenu, onClickLink }) => {
	const { t } = useTranslation();

	const navigationItems = useMemo(() => {
		return [
			{
				label: t('menu'),
				icon: MenuIcon,
				altText: 'menu-icon',
				onClick: onClickMenu,
			},
			{
				label: t('promotions'),
				icon: PromotionsIcon,
				altText: 'promo-icon',
				onClick: () => {
					onClickLink();
					route('/promotions');
				},
			},
			{
				label: t('wallet'),
				icon: WalletIcon,
				altText: 'chat-icon',
				onClick: () => {
					onClickLink();
					route('/account/wallet');
				},
			},
			{
				label: t('casino'),
				icon: CasinoIcon,
				altText: 'casino-icon',
				onClick: () => {
					onClickLink();
					route('/casino/home');
				},
			},
			{
				label: t('live_casino'),
				icon: CasinoIcon,
				altText: 'live-casino-icon',
				onClick: () => {
					onClickLink();
					route('/live-casino/home');
				},
			},
		];
	}, [t]);

	return (
		<div className={style.mobileNavigationWrapper}>
			{navigationItems.map((item, index) => (
				<div
					key={`menu-navigation-item-${index}`}
					className={style.navigationItem}
					onClick={item.onClick}
				>
					<img src={item.icon} alt={item.altText} />

					<Typography
						type="body2"
						fontColor="light-1"
						className={style.navigationItemLabel}
					>
						{item.label}
					</Typography>
				</div>
			))}
		</div>
	);
};

export default MobileNavigation;
