import { useMemo, useEffect, useContext, useCallback } from 'preact/hooks';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectPlayerData, updatePlayerData } from '../../../../store/player-slice';
import SwitchButton from '../../../../components/switch-button';
import * as playerService from '../../../../services/player-service';
import { CommunicationPreference } from '../../../../constants/enums';
import { selectUserSession } from '../../../../store/authentication-slice';
import { ToastMessagesContext } from '../../../../providers/toast-messages-provider';

import commonClasses from '../../style.module.scss';
import classes from './style.module.scss';

const Communication = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const playerData = useSelector(selectPlayerData);
	const userSession = useSelector(selectUserSession);
	const { onPushMessage } = useContext(ToastMessagesContext);

	const updateCommunicationPreferences = useCallback(
		async (communicationPreference) => {
			if (userSession && playerData) {
				let communicationPreferences = playerData.communicationPreferences
					? playerData.communicationPreferences?.map((item) => item)
					: [];

				const preferenceIndex = communicationPreferences.indexOf(
					communicationPreference
				);

				if (preferenceIndex > -1) {
					communicationPreferences.splice(preferenceIndex, 1);
				} else {
					communicationPreferences.push(communicationPreference);
				}

				try {
					const response = await playerService.updatePlayerData(
						userSession?.id,
						{
							communicationPreferences,
						}
					);

					dispatch(updatePlayerData(response.data));
				} catch {
					onPushMessage({
						message: t('an_error_occurred'),
						type: 'error',
						title: t('error'),
					});
				}
			}
		},
		[t, dispatch, onPushMessage, playerData, userSession]
	);

	const switchPreferencesData = useMemo(() => {
		if (!playerData) {
			return [];
		}

		const { communicationPreferences } = playerData;

		return [
			{
				isChecked:
					communicationPreferences?.indexOf(CommunicationPreference.EMAIL) > -1,
				label1: t('email_marketing'),
				label2: t('i_want_to_receive_marketing_communications_via_email'),
				onChange: () =>
					updateCommunicationPreferences(CommunicationPreference.EMAIL),
			},
			{
				isChecked:
					communicationPreferences?.indexOf(CommunicationPreference.SMS) > -1,
				label1: t('sms_marketing'),
				label2: t('i_want_to_receive_marketing_communications_via_sms'),
				onChange: () =>
					updateCommunicationPreferences(CommunicationPreference.SMS),
			},
			{
				isChecked:
					communicationPreferences?.indexOf(CommunicationPreference.MOBILE) >
					-1,
				label1: t('third_party_marketing'),
				label2: t('i_want_to_receive_marketing_from_3rd_parties'),
				onChange: () =>
					updateCommunicationPreferences(CommunicationPreference.MOBILE),
			},
		];
	}, [t, playerData, updateCommunicationPreferences]);

	return (
		<div className={commonClasses.tabContent}>
			<div className={classes.communicationWrapper}>
				<div className={classes.toggleOptionsWrapper}>
					{switchPreferencesData.map((switchPreferenceData, index) => (
						<SwitchButton
							key={`preference-${index}`}
							isChecked={switchPreferenceData.isChecked}
							onChange={switchPreferenceData.onChange}
							label={
								<div className={classes.switchButtonLabelWrapper}>
									<div className={classes.firstLabel}>
										{switchPreferenceData.label1}
									</div>

									<div className={classes.secondLabel}>
										{switchPreferenceData.label2}
									</div>
								</div>
							}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Communication;
