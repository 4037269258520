import { useState, useEffect, useContext, useCallback } from 'preact/hooks';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SumsubWebSdk from '@sumsub/websdk-react';

import { selectUserSession } from '../../../../store/authentication-slice';
import { generateSumsubAccessToken } from '../../../../services/kyc-service';
import { ToastMessagesContext } from '../../../../providers/toast-messages-provider';

import commonClasses from '../../style.module.scss';

const KYC = () => {
	const { t } = useTranslation();

	const [accessToken, setAccessToken] = useState();
	const userSession = useSelector(selectUserSession);
	const { onPushMessage } = useContext(ToastMessagesContext);

	const generateAccessToken = useCallback(async () => {
		try {
			const response = await generateSumsubAccessToken();
			setAccessToken(response);
		} catch {
			onPushMessage({
				message: t('an_error_occured'),
				type: 'error',
				title: t('error'),
			});
		}
	}, [t, onPushMessage]);

	const accessTokenExpirationHandler = async () => {
		try {
			const response = await generateSumsubAccessToken();
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	};

	let lang = userSession?.language.toLowerCase();
	const email = userSession?.email;

	// greek language is 'el' in Sumsub
	if (lang == 'gr') {
		lang = 'el';
	}

	const config = {
		lang,
		email,
	};

	const options = {};

	useEffect(() => {
		generateAccessToken();
	}, [generateAccessToken]);

	return accessToken ? (
		<div className={commonClasses.tabContent}>
			<SumsubWebSdk
				accessToken={accessToken}
				expirationHandler={accessTokenExpirationHandler}
				config={config}
				options={options}
				onMessage={(type, payload) => {
					// below code can be used for testing purposes only
					// return true;

					if (
						type === 'idCheck.onApplicantStatusChanged' &&
						payload.reviewStatus === 'completed'
					) {
						if (payload.reviewResult.reviewAnswer === 'RED') {
							console.log('KYC rejected');
						} else if (payload.reviewResult.reviewAnswer === 'GREEN') {
							console.log('KYC approved');
						} else if (payload.reviewResult.reviewAnswer === 'YELLOW') {
							console.log('KYC pending');
						}
					}
				}}
				onError={(error) => {
					console.log('Kyc error:', error);
				}}
			/>
		</div>
	) : null;
};

export default KYC;
