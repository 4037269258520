import { useMemo, useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSession } from '../../../store/authentication-slice';
import { getPlayerData, selectPlayerData } from '../../../store/player-slice';
import { useTranslation } from 'react-i18next';
import { Router as PreactRouter } from 'preact-router';

import AllBonuses from './all-bonuses';
import ActiveBonuses from './active-bonuses';
import ExpiredBonuses from './expired-bonuses';
import InactiveBonuses from './inactive-bonuses';
import CompletedBonuses from './completed-bonuses';
import Redirect from '../../../components/redirect';
import { pathnames } from '../../../constants/pathnames';
import CommonContentPage from './../common-content-page';

import BonusesIcon from '../../../assets/icons/bonuses-orange.svg';

const {
	account: {
		activeBonusesPath,
		inactiveBonusesPath,
		expiredBonusesPath,
		completedBonusesPath,
		allBonusesPath,
		restBonusesPath,
	},
} = pathnames;

const BonusesPage = () => {
	const { t } = useTranslation();

  const dispatch = useDispatch();
  const userSession = useSelector(selectUserSession);
  const player = useSelector(selectPlayerData);

  useEffect(() => {
    if (userSession && !player) {
      dispatch(getPlayerData(userSession.id));
    }
  }, [dispatch, userSession, player]);

	const tabs = useMemo(
		() => [
			{
				path: activeBonusesPath,
				name: t('active'),
			},
			{
				path: inactiveBonusesPath,
				name: t('inactive'),
			},
			{
				path: expiredBonusesPath,
				name: t('expired'),
			},
			{
				path: completedBonusesPath,
				name: t('completed'),
			},
			{
				path: allBonusesPath,
				name: t('all'),
			},
		],
		[t]
	);

	return (
		<CommonContentPage
			tabs={tabs}
			title={
				<>
					<img src={BonusesIcon} />

					<div>{t('bonuses')}</div>
				</>
			}
		>
			<PreactRouter>
				<ActiveBonuses path={activeBonusesPath} />

				<InactiveBonuses path={inactiveBonusesPath} />

				<ExpiredBonuses path={expiredBonusesPath} />

				<CompletedBonuses path={completedBonusesPath} />

				<AllBonuses path={allBonusesPath} />

				<Redirect path={restBonusesPath} to={activeBonusesPath} />
			</PreactRouter>
		</CommonContentPage>
	);
};

export default BonusesPage;
