import {useSelector} from "react-redux";
import {selectFilteredByProviderLiveCasinoGames} from "../../store/game-slice";
import GamesLayout from "../games-layout";

const GamesFilterLiveLayout = () => {
  const filteredGames = useSelector(selectFilteredByProviderLiveCasinoGames);

  return <GamesLayout games={filteredGames} />;
}

export default GamesFilterLiveLayout;