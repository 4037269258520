import { useState } from 'preact/hooks';

import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import { ConfirmationModal } from '../confirmation-modal';

import style from './style.module.scss';

import removeLimitCloseIcon from '../../../../assets/icons/remove-limit-close-icon.svg';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { formatCurrency } from '../../../../utils/currency';
import { formatDate } from '../../../../utils/date';
import { useDispatch } from 'react-redux';
import { deletePlayerLimit, getPlayerLimits } from '../../../../store/limit-slice';


export const LimitPanel = ({ data = {}}) => {
  const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const onRemoveLimitButtonClick = () => {
		setIsModalOpen(true);
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

  const confirmDeleteHandler = async () => {
    setIsModalOpen(false);
		await dispatch(deletePlayerLimit(data.id));
    await dispatch(getPlayerLimits(data.playerId));
	};

	return (
		<div className={style.limitWrapper}>
			<div className={style.limitFieldsWrapper}>
				<Typography type="st2" fontColor="purple-2">
					Limit type:
				</Typography>

				<Typography type="st2" fontColor="white">
					{capitalizeFirstLetter(data.period)}
				</Typography>

				<Typography type="st2" fontColor="purple-2">
					Current limit:
				</Typography>

				<Typography type="st2" fontColor="white">
					{formatCurrency(data.amount, data.currency)}
				</Typography>

				<Typography type="st2" fontColor="purple-2">
					Remaining amount:
				</Typography>

				<Typography type="st2" fontColor="white">
          {formatCurrency(data.remainingAmount, data.currency)}
				</Typography>

				<Typography type="st2" fontColor="purple-2">
					Started on:
				</Typography>

				<Typography type="st2" fontColor="white">
          {formatDate(data.startDate, true)}
				</Typography>

				<Typography type="st2" fontColor="purple-2">
					Ending on:
				</Typography>

				<Typography type="st2" fontColor="white">
        {formatDate(data.endDate, true)}
				</Typography>
			</div>

			<div className={style.buttonWrapper}>
				<ButtonRound
					buttonType="secondary"
					className={style.removeLimitButton}
					onClick={onRemoveLimitButtonClick}
				>
					<Typography type="st4" fontColor="green">
						Remove limit
					</Typography>

					<img src={removeLimitCloseIcon} alt="close-icon" />
				</ButtonRound>
			</div>

			<ConfirmationModal
				isModalOpen={isModalOpen}
				title="Are you sure you want to delete this limit?"
				onCloseModal={onCloseModal}
				onConfirmationClick={confirmDeleteHandler}
			/>
		</div>
	);
};
