import { Link } from 'preact-router';

import { Typography } from '../typography';

import style from './style.module.scss';

const FooterCategory = ({ sectionName = '', sectionLinks = [] }) => {
	return (
		<div className={style.category}>
			<Typography
				key={sectionName}
				type="st4"
				fontColor="contrast"
				colorType="primary"
				className={style.categoryTitle}
			>
				{sectionName}
			</Typography>

			<div className={style.links}>
				{sectionLinks.map(({ id, label, href, external }) => {
					if (external) {
						return (
							<a
								key={id}
								href={href}
								target="_blank"
								className={style.link}
								rel="noreferrer"
							>
								{label}
							</a>
						);
					}
					return (
						<Link key={id} className={style.link} href={href}>
							{label}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default FooterCategory;
