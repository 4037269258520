import clsx from 'clsx';
import { Link, getCurrentUrl } from 'preact-router';

import style from './../style.module.scss';

const TabsNavigation = ({ tabs }) => {
	const currentUrl = getCurrentUrl();

	return (
		<div className={style.tabs}>
			{tabs.map((tab, index) => (
				<Link
					key={`${tab.name.toLowerCase().replace(' ', '-')}-${index}`}
					href={tab.path}
					className={clsx(
						style.tabWrapper,
						tab.path === currentUrl && style.activeTabWrapper
					)}
				>
					<div
						className={clsx(
							style.tab,
							tab.path === currentUrl && style.activeTab
						)}
					>
						{tab.name}
					</div>
				</Link>
			))}
		</div>
	);
};

export default TabsNavigation;
