import { useMemo, useState, useCallback } from 'preact/hooks';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../../../components/modal';
import Input from '../../../../components/forms/input';
import Button from '../../../../components/forms-new/button/button';
import Select from '../../../../components/forms-new/select/select';
import { createPlayerTimeout, getPlayerTimeout } from '../../../../store/timeout-slice';
import { createPlayerExclusion } from '../../../../store/exclusion-slice';

import style from './style.module.scss';

import PasswordIcon from '../../../../assets/icons/password-icon-blue.svg';
import PasswordIconHidden from '../../../../assets/icons/password-icon.svg';
import ToggleHideIcon from '../../../../assets/icons/dropdown-arrow-up-orange.svg';
import ToggleShowIcon from '../../../../assets/icons/dropdown-arrow-down-orange.svg';

const AddRestrictionPanel = ({ type = '', isButtonDisabled = false }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const gamingBreakOptions = useMemo(
		() => [
			t('number_h', {
				number: 24,
			}),
			t('number_h', {
				number: 48,
			}),
			t('one_week'),
		],
		[t]
	);

	const selfExclusionOptions = useMemo(
		() => [
			t('one_month'),
			t('number_months', {
				number: 2,
			}),
			t('number_months', {
				number: 3,
			}),
			t('number_months', {
				number: 6,
			}),
			t('number_months', {
				number: 9,
			}),
			t('permanent'),
		],
		[t]
	);

	const [password, setPassword] = useState('');
	const [selfExclusionPeriod, setSelfExclusionPeriod] = useState(
		selfExclusionOptions[0]
	);
	const [gamingBreak, setGamingBreak] = useState(gamingBreakOptions[0]);
	const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
	const [shouldShowOldPassword, setShouldShowOldPassword] = useState(false);

	const OldPasswordIcon = useMemo(
		() => (shouldShowOldPassword ? PasswordIconHidden : PasswordIcon),
		[shouldShowOldPassword]
	);

	const onCloseModal = () => {
		setIsPasswordModalOpen(false);
		setShouldShowOldPassword(false);
		setPassword('');
	};

	const addSelfExclusion = () => {
		let numberOfPeriods = -1;
		switch (selfExclusionPeriod) {
			case selfExclusionOptions[0]:
				numberOfPeriods = 1; // 1 month
				break;
			case selfExclusionOptions[1]:
				numberOfPeriods = 2; // 2 months
				break;
			case selfExclusionOptions[2]:
				numberOfPeriods = 3; // 3 months
				break;
			case selfExclusionOptions[3]:
				numberOfPeriods = 6; // 6 months
				break;
			case selfExclusionOptions[4]:
				numberOfPeriods = 9; // 9 months
				break;
			default:
				numberOfPeriods = -1; // permanent
		}
		if (numberOfPeriods !== -1) {
			dispatch(
				createPlayerExclusion({
					numberOfPeriods,
					period: 'month',
					password,
				})
			);
		} else {
			dispatch(
				createPlayerExclusion({
					numberOfPeriods,
					period: 'permanent',
					password,
				})
			);
		}
		onCloseModal();
	};

	const addBreakHandler = () => {
		const period = gamingBreak === gamingBreakOptions[2] ? 'week' : 'day';

		let numberOfPeriods = 0;
		switch (gamingBreak) {
			case gamingBreakOptions[0]:
				numberOfPeriods = 1; // 24 hours = 1 day
				break;
			case gamingBreakOptions[1]:
				numberOfPeriods = 2; // 48 hours = 2 days
				break;
			case gamingBreakOptions[2]:
				numberOfPeriods = 1; // 1 week
				break;
			default:
				numberOfPeriods = 1;
		}

		dispatch(
			createPlayerTimeout({
				numberOfPeriods,
				period,
			})
		);
		onCloseModal();
	};

	return (
		<div className={style.inputControlsWrapper}>
			<Select
				label={t('period')}
				isRequired={false}
				value={type === 'exclusion' ? selfExclusionPeriod : gamingBreak}
				options={
					type === 'exclusion' ? selfExclusionOptions : gamingBreakOptions
				}
				dropdownIconOpen={ToggleHideIcon}
				dropdownIconClosed={ToggleShowIcon}
				className={style.select}
				onChange={(value) =>
					type === 'exclusion'
						? setSelfExclusionPeriod(value)
						: setGamingBreak(value)
				}
			/>

			<Button
				onClick={() => {
					setIsPasswordModalOpen(true);
				}}
				disabled={isButtonDisabled}
			>
				{t('apply')}
			</Button>

			<Modal
				open={isPasswordModalOpen}
				className={style.passwordModal}
				onCloseModal={onCloseModal}
			>
				<div className={style.passwordModalTitle}>
					{t('enter_password_to_confirm_your_action')}:
				</div>

				<Input
					name="password"
					value={password}
					wrapperClassName={style.inputWrapper}
					type={shouldShowOldPassword ? 'text' : 'password'}
					Icon={
						<img
							src={OldPasswordIcon}
							onClick={() => {
								setShouldShowOldPassword((prev) => !prev);
							}}
						/>
					}
					onBlur={(event) => setPassword(event.target.value)}
					onChange={(event) => setPassword(event.target.value)}
				/>

				<Button
					onClick={() => {
						type === 'exclusion' ? addSelfExclusion() : addBreakHandler();
					}}
				>
					{t('confirm')}
				</Button>
			</Modal>
		</div>
	);
};

export default AddRestrictionPanel;
