import { useMemo } from 'preact/hooks';

import useUrl from './../../hooks/useUrl';
import HomePageHeadImageSection from './../../pages/home-page/components/head-image-section';

const SubHeader = () => {
	const { url } = useUrl();

	const subheader = useMemo(() => {
		switch (url) {
			case '/':
				return <HomePageHeadImageSection />;
			default:
				<></>;
		}
	}, [url]);

	return subheader;
};

export default SubHeader;
