import { Box } from '@mui/material';
import { useMemo, useCallback } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import { setShowSignUp } from '../../../../store/theme-slice';
import { selectUserSession } from '../../../../store/authentication-slice';

import styles from './style.module.scss';

// import GoogleIcon from '../../../../assets/icons/google-icon.svg';
// import SocialIcon1 from '../../../../assets/icons/social-icon-1.svg';
// import SocialIcon2 from '../../../../assets/icons/social-icon-2.svg';
// import FacebookIcon from '../../../../assets/icons/facebook-icon.svg';
import { selectMainBanner } from '../../../../store/content-slice';

const HomePageHeadImageSection = () => {
	const dispatch = useDispatch();
	const mainBanner = useSelector(selectMainBanner);
	const userSession = useSelector(selectUserSession);

	const isAuthenticated = useMemo(() => Boolean(userSession), [userSession]);

	const handleSignUpClick = useCallback(
		() => dispatch(setShowSignUp(true)),
		[dispatch]
	);

	if (!mainBanner) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			<Box
				className={`${styles.boxWrapper} row`}
				sx={{
					backgroundImage: `url(${mainBanner.image})`,
				}}
			>
				{!isAuthenticated && (
					<div className={`${styles.contentWrapper}`}>
						<Typography type="h4" fontColor="white">
							{mainBanner.title}
						</Typography>

						<ButtonRound
							backgorundColor="blue"
							className={styles.headerBtn}
							onClick={handleSignUpClick}
						>
							<Typography type="btn2" fontColor="blue-1">
								{mainBanner.ctaFirstTimeButtonText}
							</Typography>
						</ButtonRound>

					</div>
				)}
			</Box>
		</div>
	);
};

export default HomePageHeadImageSection;
