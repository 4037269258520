import { useMemo } from 'preact/hooks';
import CherryIcon from "../assets/icons/cherry-icon.svg";
import {useTranslation} from "react-i18next";

const useCategoriesTab = (rawCategories) => {
	const { t } = useTranslation();

	const casinoCategories = useMemo(() => {
		const mappedCategories = {};

		if (!rawCategories) {
			return mappedCategories;
		}

		rawCategories.forEach(({ id, name, games: { items } }) => {
			mappedCategories[id] = {
				id,
				name : t(name),
				items,
			};
		});

		return mappedCategories;
	}, [rawCategories, t]);

	const tabButtons = useMemo(() => {

		const categoriesToggleButtons = Object.keys(casinoCategories).map(
			(categoryId) => {
				const { id, name } = casinoCategories[categoryId];

				return {
					value: id,
					label: name,
					icon: CherryIcon,
				};
			}
		);

		return categoriesToggleButtons;
	}, [casinoCategories]);

	return { casinoCategories, tabButtons };
};

export default useCategoriesTab;

