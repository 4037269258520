import { useSelector } from 'react-redux';
import { selectUserSession } from '../../../store/authentication-slice';
import userIcon from '../../../assets/icons/dashboard-user-icon.svg';
import style from './style.module.scss';

const DashboardHeader = () => {
	const userSession = useSelector(selectUserSession);
	return (
		<div className={style.header}>
			<img style={style.userIcon} src={userIcon} />
			<div className={style.userInfo}>
				<h1>
					{userSession?.firstname?.toLowerCase()} {userSession?.lastname?.toLowerCase()}
				</h1>
				<p>{userSession?.username}</p>
			</div>
		</div>
	);
};

export default DashboardHeader;
