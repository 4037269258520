import Banner from '../../components/banner';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';
import PromotionCard from "../../components/promotion-card";
import {useSelector} from "react-redux";

const PromotionsPage = () => {
	const promotions = useSelector((state) => state.content.promotions);

	const { t } = useTranslation();

	return (
		<div className={style.promotionPageWrapper}>
			<Banner label={t('promotions')} containerClassName={style.banner} />

			<div className={style.promotionsWrapper}>
				{!!promotions.length &&
					promotions.map((promotion, index) => (
						<PromotionCard key={index} promotion={promotion} />
					))}
			</div>
		</div>
	);
};

export default PromotionsPage;
