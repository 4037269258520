import clsx from 'clsx';
import { useCallback, useContext, useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import {
	selectResetPasswordEmailData,
	sendResetPasswordEmail,
} from '../../store/authentication-slice';

import { Modal } from '../modal';
import Input from '../forms/input';
import ButtonRound from '../button-round';
import { Typography } from '../typography';

import style from './style.module.scss';

const ForgotPassword = ({ open, onCloseModal }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { onPushMessage } = useContext(ToastMessagesContext);
	const resetPasswordState = useSelector(selectResetPasswordEmailData);

	const handleSubmit = useCallback(
		({ email = '' }) => dispatch(sendResetPasswordEmail(email)),
		[dispatch, sendResetPasswordEmail]
	);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email(t('please_include_an_at_the_rate_sign_in_your_email'))
				.required(t('email_is_required')),
		}),
		onSubmit: handleSubmit,
	});

	useEffect(() => {
		if (resetPasswordState) {
			onCloseModal();
			onPushMessage({
				message: t('we_have_sent_an_account_recovery_email_to', {
					email: formik.values.email,
				}),
				type: 'info',
				title: t('reset_password'),
			});
		}
	}, [t, resetPasswordState]);

	useEffect(() => {
		formik.resetForm();
	}, [open]);

	return (
		<Modal
			open={open}
			title={t('reset_password')}
			className={style.modal}
			onCloseModal={onCloseModal}
		>
			<div className={style.loginWrapper}>
				<form className={style.formWrapper}>
					<Input
						name="email"
						label={t('email')}
						value={formik.values.email}
						errorMessage={formik.errors.email}
						hasError={formik.errors.email && formik.touched.email}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
					/>

					<ButtonRound
						disabled={false}
						className={clsx(style.recoverPasswordButton, style.continueButton)}
						onClick={formik.handleSubmit}
					>
						<Typography type="btn3" fontColor="blue-3">
							{t('recover_password')}
						</Typography>
					</ButtonRound>
				</form>
			</div>
		</Modal>
	);
};

export default ForgotPassword;
