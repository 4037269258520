import moment from 'moment';

export const formatDate = (date, includeTime = false, dateFormat = 'D.M.YYYY', convertToUtc = false) => {
    if (!date) {
        return '';
    }

    let momentDate = moment(date, 'YYYY-MM-DD HH:mm:ss.SSS Z');
    // fallback
    if (!momentDate.isValid()) 
        momentDate = moment(date);
    if (convertToUtc)
        momentDate = momentDate.utc();

    return includeTime ? momentDate.format(`${dateFormat}, HH:mm`) : momentDate.format(dateFormat);
};