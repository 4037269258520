import { useMemo, useState } from 'preact/hooks';

import Input from '../../../../components/forms/input';
import Label from '../../../../components/forms/label';
import Select from '../../../../components/forms/select';
import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';
import { DatePicker } from '../../../../components/forms/date-picker';

import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createPlayerLimit, getPlayerLimits } from '../../../../store/limit-slice';

export const AddLimitPanel = ({ isButtonDisabled = false, playerId, limitType }) => {
  const dispatch = useDispatch();

	const [amount, setAmount] = useState('');
	const [date, setDate] = useState('');
	const [period, setPeriod] = useState('');
	const [currency, setCurrency] = useState('');

	const currencyOptions = useMemo(
		() => [
			{ name: 'EUR', value: 'EUR' },
			{ name: 'USD', value: 'USD' },
			{ name: 'GBP', value: 'GBP' },
		],
		[]
	);

	const periodOptions = useMemo(
		() => [
			{ name: 'Daily', value: 'daily' },
			{ name: 'Weekly', value: 'weekly' },
			{ name: 'Monthly', value: 'monthly' },
		],
		[]
	);

  
  const createLimitHandler = async () => {
    if (!date || !period || !amount || !currency)
      return;

		await dispatch(createPlayerLimit({
      playerId, 
      type: limitType, 
      startDate: new Date(date).toLocaleDateString(),
      period, 
      amount, 
      currency
    }));
    await dispatch(getPlayerLimits(playerId));
	};

	return (
		<div className={style.panelWrapper}>
			<Label label="Limit" hasError={false} />

			<div className={style.inputsWrapper}>
				<Input
					name="amount"
          value={amount}
					placeholder="Amount"
					wrapperClassName={style.amountInput}
          onChange={(event) => setAmount(event.target.value)}
				/>

				<Select
					name="currency"
					value={currency}
					options={currencyOptions}
					wrapperClassName={style.currencyDropdown}
					displayEmpty
					renderValue={currency !== '' ? undefined : () => 'Currency'}
					onChange={(event) => {
						setCurrency(event.target.value);
					}}
				/>

				<Select
					name="period"
					value={period}
					options={periodOptions}
					wrapperClassName={style.periodDropdown}
					displayEmpty
					renderValue={period !== '' ? undefined : () => 'Period'}
					onChange={(event) => {
						setPeriod(event.target.value);
					}}
				/>

				<DatePicker
					value={date}
					wrapperClassName={style.datePicker}
					onChange={(newValue) => setDate(newValue)}
				/>

				<ButtonRound disabled={isButtonDisabled} className={style.applyButton}
          onClick={createLimitHandler}>
					<Typography type="btn3" fontColor="blue-3">
						Apply
					</Typography>
				</ButtonRound>
			</div>
		</div>
	);
};
