import { useTranslation } from 'react-i18next';

import { StyledDatePicker } from './style.js';

export const DatePicker = ({
	value = '',
	placeholder = '',
	wrapperClassName = '',
	onChange = () => {},
	...rest
}) => {
	const { t } = useTranslation();
	const inputProps = placeholder
		? { value: placeholder }
		: !value
		? { value: t('start_date') }
		: {};

	return (
		<StyledDatePicker
			{...rest}
			value={value}
			className={wrapperClassName}
			onChange={onChange}
			slotProps={{
				textField: { InputProps: inputProps },
			}}
		/>
	);
};
