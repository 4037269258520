import clsx from 'clsx';

import style from './style.module.scss';

// type LabelProps = {
//   label: string;
//   hasError: boolean;
//   isRequired?: boolean;
//   wrapperClassName?: string;
//   extraTextClassName?: string;
//   extraText?: string;
// };

const Label = ({
	label = '',
	hasError = false,
	isRequired = true,
	wrapperClassName = '',
	extraTextClassName = '',
	extraText = '',
}) => {
	return (
		<label className={clsx(style.label, wrapperClassName)}>
			<span>{label}</span>
			{isRequired && (
				<span className={clsx(style.requiredFlag, hasError && style.error)}>
					*
				</span>
			)}
			{extraText && (
				<span className={clsx(style.extraText, extraTextClassName)}>
					{extraText}
				</span>
			)}
		</label>
	);
};

export default Label;
