import { selectPlayerTransactions } from '../../../../store/player-slice';
import commonClasses from '../../style.module.scss';
import { useSelector } from 'react-redux';
import TransactionTable from '../../../../components/transaction-table';
import TransactionFilterBar from '../../../../components/transaction-filter-bar';
import TransactionPagination from '../../../../components/transaction-pagination';
import {useEffect, useMemo, useState} from 'preact/hooks';
import { generateAndExportData } from '../../../../utils/export-data-utils';
import {
	DATE_FILTERS,
	DATE_FILTERS_EPOCH_TIME_MS,
	TRANSACTION_FILTERS,
} from '../../../../utils/consts';
import { selectUserSession } from '../../../../store/authentication-slice';
import {useTranslation} from "react-i18next";

const ITEMS_PER_PAGE = 5;

const transactionTypes = ['deposit', 'withdrawal'];

const TransactionsHistory = () => {
	const { t } = useTranslation();
	const playerTransactions = useSelector(selectPlayerTransactions);
	const userSession = useSelector(selectUserSession);
	const [currentPage, setCurrentPage] = useState();
	const [filteredData, setFilteredData] = useState();
	const [dataInView, setDataInView] = useState();
	const [showPageIndicators, setShowPageIndicators] = useState(false);
	const [filters, setFilters] = useState({
		transaction: TRANSACTION_FILTERS[0],
		date: DATE_FILTERS[0],
		from: undefined,
		to: undefined,
	});
	const [triggerFilter, setTriggerFilter] = useState(false);

	const TRANSATION_HISTORY_COLUMN_MAPPING = useMemo(() => [
		{
			header: t('date'),
			field: 'date',
			isDate: true,
		},
		{
			header: t('amount'),
			field: 'amount',
			hasCurrency: true,
		},
		{
			header: t('type'),
			field: 'transactionType',
		},
		{
			header: t('status'),
			field: 'status',
		},
		{
			header: t('transaction_number'),
			field: 'transactionId',
		},
		{
			header: t('actions'),
			field: 'id',
			isWithdrawalRow: true,
		},
	], [t]);

	const runDataExport = (data, filters, reportName) => {
		reportName += `_${userSession?.username}`;
		if (filters.from && filters.to) {
			reportName += `_${new Date(filters.from).toDateString()}-${new Date(
				filters.to
			).toDateString()}`;
		}
		const columnsForExport = TRANSATION_HISTORY_COLUMN_MAPPING.filter(
			(col) => col.header !== 'Actions'
		);
		generateAndExportData(data, columnsForExport, reportName);
	};

	const filterDataByTransactionType = (data, type) => {
		const normalizedType = type.split(' ')[0].toLowerCase();
		switch (normalizedType) {
			case 'deposit':
			case 'withdrawal':
				return data.filter(
					(transaction) =>
						transaction.transactionType.toLowerCase() === normalizedType
				);
			default:
				return data;
		}
	};

	const filterDataByDateType = (data, filters) => {
		switch (filters.date) {
			case DATE_FILTERS[1]:
			case DATE_FILTERS[2]:
			case DATE_FILTERS[3]:
			case DATE_FILTERS[4]: {
				const from = DATE_FILTERS_EPOCH_TIME_MS[filters.date];
				return data.filter((transaction) => {
					const transactionTime = new Date(transaction.date);
					return transactionTime > from;
				});
			}
			case DATE_FILTERS[5]: {
				const to = new Date(filters.to).getTime();
				const from = new Date(filters.from).getTime();
				return data.filter((transaction) => {
					const transactionTime = new Date(transaction.date).getTime();
					return transactionTime > from && transactionTime < to;
				});
			}

			default:
				return data;
		}
	};

	const getPaginationSlices = (page) => {
		return {
			min: page * ITEMS_PER_PAGE,
			max: (page + 1) * ITEMS_PER_PAGE,
		};
	};

	const filterWallet = (data) =>
		data.filter((transaction) =>
			transactionTypes.includes(transaction.transactionType.toLowerCase())
		);

	const onPageChange = ({ selected }) => {
		setCurrentPage(selected);
		const { min, max } = getPaginationSlices(selected);
		filteredData.length > ITEMS_PER_PAGE
			? setShowPageIndicators(true)
			: setShowPageIndicators(false);
		setDataInView(filteredData.slice(min, max));
	};

	useEffect(() => {
		const transactions = playerTransactions?.wallet_transactions;
		if (
			transactions &&
			transactions.length > ITEMS_PER_PAGE &&
			!dataInView &&
			!filteredData
		) {
			const filteredWalletTransactions = filterWallet(transactions);
			setFilteredData(filteredWalletTransactions);
			setDataInView(filteredWalletTransactions.slice(0, ITEMS_PER_PAGE));
			filteredWalletTransactions.length > ITEMS_PER_PAGE
				? setShowPageIndicators(true)
				: setShowPageIndicators(false);
			setCurrentPage(0);
		}
	}, [playerTransactions?.wallet_transactions, dataInView, filteredData]);

	useEffect(() => {
		if (filteredData) {
			const filteredByType = filterDataByTransactionType(
				filterWallet(playerTransactions?.wallet_transactions),
				filters.transaction
			);
			const filteredByDate = filterDataByDateType(filteredByType, filters);
			const currentPage = 0;
			setCurrentPage(currentPage);
			const { min, max } = getPaginationSlices(currentPage);
			filteredByDate.length > ITEMS_PER_PAGE
				? setShowPageIndicators(true)
				: setShowPageIndicators(false);
			setFilteredData(filteredByDate);
			setDataInView(filteredByDate?.slice(min, max));
		}
	}, [
		triggerFilter,
		playerTransactions?.wallet_transactions,
		setCurrentPage,
		setFilteredData,
		setDataInView,
		setShowPageIndicators,
	]);

	return (
		<div className={commonClasses.tabContent}>
			<TransactionFilterBar
				filters={filters}
				setFilters={setFilters}
				setTriggerFilter={setTriggerFilter}
				filteredData={filteredData}
				runDataExport={runDataExport}
				reportName={'Transaction_History'}
				withTransactionFilter={true}
			/>
			<TransactionTable
				transactions={dataInView}
				columntCount={6}
				tableMapping={TRANSATION_HISTORY_COLUMN_MAPPING}
			/>
			{showPageIndicators && (
				<TransactionPagination
					pageCount={Math.ceil(
						playerTransactions?.wallet_transactions.length / ITEMS_PER_PAGE
					)}
					selectedPage={currentPage}
					onPageChange={onPageChange}
				/>
			)}
		</div>
	);
};

export default TransactionsHistory;
