const basePathnames = {
	account: 'account',
	settings: 'settings',
	transactions: 'transactions',
	responsibleGaming: 'responsible-gaming',
};

const accountBasePathnames = {
	wallet: `/${basePathnames.account}/wallet`,
	profile: `/${basePathnames.account}/profile`,
	bonuses: `/${basePathnames.account}/bonuses`,
	transactions: `/${basePathnames.account}/transactions`,
	playerProtection: `/${basePathnames.account}/player-protection`,
};

const profileBasePathnames = {
	baseProfilePath: accountBasePathnames.profile,
	personalDetailsPath: `${accountBasePathnames.profile}/personal-details`,
	communicationPath: `${accountBasePathnames.profile}/communication`,
	kycPath: `${accountBasePathnames.profile}/kyc`,
	restProfilePath: `${accountBasePathnames.profile}/:rest*`,
};

const walletBasePathnames = {
	baseWalletPath: accountBasePathnames.wallet,
	depositsPath: `${accountBasePathnames.wallet}/deposits`,
	withdrawalsPath: `${accountBasePathnames.wallet}/withdrawals`,
	restWalletPath: `${accountBasePathnames.wallet}/:rest*`,
};

const bonusesBasePathnames = {
	baseBonusesPath: accountBasePathnames.bonuses,
	activeBonusesPath: `${accountBasePathnames.bonuses}/active`,
	inactiveBonusesPath: `${accountBasePathnames.bonuses}/inactive`,
	expiredBonusesPath: `${accountBasePathnames.bonuses}/expired`,
	completedBonusesPath: `${accountBasePathnames.bonuses}/completed`,
	allBonusesPath: `${accountBasePathnames.bonuses}/all`,
	restBonusesPath: `${accountBasePathnames.bonuses}/:rest*`,
};

const transactionsBasePathnames = {
	baseTransactionsPath: accountBasePathnames.transactions,
	transactionHistoryPath: `${accountBasePathnames.transactions}/transaction-history`,
	gamingHistoryPath: `${accountBasePathnames.transactions}/gaming-history`,
	restTransactionsPath: `${accountBasePathnames.transactions}/:rest*`,
};

const playerProtectionBasePathnames = {
	basePlayerProtectionPath: accountBasePathnames.playerProtection,
	depositLimitsPath: `${accountBasePathnames.playerProtection}/deposit-limits`,
	bettingLimitsPath: `${accountBasePathnames.playerProtection}/betting-limits`,
	lossLimitsPath: `${accountBasePathnames.playerProtection}/loss-limits`,
	takeABreakPath: `${accountBasePathnames.playerProtection}/take-a-break`,
	selfExclusionPath: `${accountBasePathnames.playerProtection}/self-exclusion`,
	realityCheckPath: `${accountBasePathnames.playerProtection}/reality-check`,
	restPlayerProtectionPath: `${accountBasePathnames.playerProtection}/:rest*`,
};

export const pathnames = {
	account: {
		...profileBasePathnames,
		...walletBasePathnames,
		...bonusesBasePathnames,
		...transactionsBasePathnames,
		...playerProtectionBasePathnames,
	},
	transactions: {
		betsPath: `/${basePathnames.transactions}/bets`,
		bonusesPath: `/${basePathnames.transactions}/bonuses`,
		depositsPath: `/${basePathnames.transactions}/deposits`,
		withdrawsPath: `/${basePathnames.transactions}/withdrawals`,
	},
	settings: {
		offersPath: `/${basePathnames.settings}/offers`,
		verifyPath: `/${basePathnames.settings}/verify`,
		generalPath: `/${basePathnames.settings}/general`,
		securityPath: `/${basePathnames.settings}/security`,
		communicationPath: `/${basePathnames.settings}/communication`,
	},
	responsibleGaming: {
		timeoutPath: `/${basePathnames.responsibleGaming}/timeout`,
		lossLimitsPath: `/${basePathnames.responsibleGaming}/loss-limits`,
		depositLimitsPath: `/${basePathnames.responsibleGaming}/deposit-limits`,
		bettingLimitsPath: `/${basePathnames.responsibleGaming}/betting-limits`,
		selfExclusionPath: `/${basePathnames.responsibleGaming}/self-exclusion`,
		realityCheckPath: `/${basePathnames.responsibleGaming}/reality-check`,
	},
};
