import { useEffect, useMemo } from 'preact/hooks';
import { getCurrentUrl } from 'preact-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectGame, fetchGame } from '../../store/game-slice.js';
import {
	fetchRecommendedGames,
	selectRecommendedGames,
} from '../../store/user-slice.js';

import Slider from '../../components/slider';
import AboutUs from '../../components/about-us';
import { Accordion } from '../../components/accordion';
import GameWrapper from '../../components/game-wrapper';
import LeaderboardWraper from '../../components/leaderboard-wrapper';

import RecommendedGamesIcon from '../../assets/icons/recommended-games-icon.svg';

import style from './style.module.scss';

const CasinoGamePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const game = useSelector(selectGame);
	const recommendedGames = useSelector(selectRecommendedGames);

	const gameId = useMemo(
		() => Number(getCurrentUrl().split('/').pop()),
		[getCurrentUrl]
	);

	useEffect(() => {
		dispatch(fetchGame(gameId));
		// dispatch(fetchRecommendedGames());
	}, [gameId]);

	return (
		<div className={style.wrapper}>
			<div className={style.leaderboardWraper}>
				<GameWrapper game={game} />

				<Accordion game={game} />
				{recommendedGames.length > 0 && (
					<Slider
						icon={RecommendedGamesIcon}
						title={t('recommended')}
						items={recommendedGames}
						backgroundProperty="defaultThumbnail"
					/>
				)}
				<LeaderboardWraper />
			</div>

			{/*<AboutUs />*/}
		</div>
	);
};

export default CasinoGamePage;
