export const topWinnersMockData = [
	{
		gameName: 'Boss the Lotto',
		user: 'Chris*****',
		time: '2024-Sep-02 01:30 AM',
		betAmount: 22.0,
		multiplier: 'x50.75',
		amount: '1116.50',
	},
	{
		gameName: '20 Super Hot',
		user: 'Emma*****',
		time: '2024-Sep-09 05:25 PM',
		betAmount: 8.45,
		multiplier: 'x112.00',
		amount: '946.40',
	},
	{
		gameName: 'Desert Temple',
		user: 'Johan*****',
		time: '2024-Aug-25 07:30 AM',
		betAmount: 20.0,
		multiplier: 'x40.25',
		amount: '805.00',
	},
	{
		gameName: 'Zodiac Wheel',
		user: 'AmyLy*****',
		time: '2024-Sep-12 09:12 PM',
		betAmount: 15.5,
		multiplier: 'x32.10',
		amount: '497.55',
	},
	{
		gameName: 'Diamond Deal',
		user: 'Sophi*****',
		time: '2024-Sep-03 08:50 PM',
		betAmount: 12.3,
		multiplier: 'x18.42',
		amount: '226.57',
	},
	{
		gameName: 'Dice & Roll',
		user: 'Marke*****',
		time: '2024-Aug-31 06:40 AM',
		betAmount: 6.25,
		multiplier: 'x25.67',
		amount: '160.44',
	},
	{
		gameName: 'Burning Hot',
		user: 'Olive*****',
		time: '2024-Sep-11 02:22 PM',
		betAmount: 9.99,
		multiplier: 'x15.33',
		amount: '153.18',
	},
	{
		gameName: '40 Bulky Dice',
		user: 'User*****',
		time: '2024-Sep-07 10:15 AM',
		betAmount: 10.0,
		multiplier: 'x9.56',
		amount: '95.60',
	},
	{
		gameName: 'Epic Gems',
		user: 'Alpha*****',
		time: '2024-Aug-28 11:11 AM',
		betAmount: 7.75,
		multiplier: 'x9.89',
		amount: '76.65',
	},
	{
		gameName: 'Mega Fairies',
		user: 'Playe*****',
		time: '2024-Sep-05 03:45 PM',
		betAmount: 5.5,
		multiplier: 'x11.20',
		amount: '61.60',
	},
];

export const recentWinnersMockData = [
	{
		gameName: 'Voodop People',
		user: 'Dani*****',
		time: '12:15 AM',
		betAmount: 11.0,
		multiplier: 'x25.60',
		amount: '281.60',
	},
	{
		gameName: 'Mighty Wins',
		user: 'Jess*****',
		time: '06:05 PM',
		betAmount: 4.25,
		multiplier: 'x56.00',
		amount: '238.00',
	},
	{
		gameName: 'Flaming Hot',
		user: 'Mike*****',
		time: '07:55 AM',
		betAmount: 10.0,
		multiplier: 'x21.50',
		amount: '215.00',
	},
	{
		gameName: 'Blue Heart',
		user: 'Lara*****',
		time: '10:45 PM',
		betAmount: 7.75,
		multiplier: 'x16.05',
		amount: '124.39',
	},
	{
		gameName: '7-UP',
		user: 'Davi*****',
		time: '07:30 PM',
		betAmount: 6.15,
		multiplier: 'x9.21',
		amount: '56.64',
	},
	{
		gameName: 'Dice & Roll',
		user: 'Sara*****',
		time: '04:20 AM',
		betAmount: 3.1,
		multiplier: 'x12.80',
		amount: '39.68',
	},
	{
		gameName: 'Boss the Lotto',
		user: 'Alex*****',
		time: '01:15 PM',
		betAmount: 5.0,
		multiplier: 'x7.66',
		amount: '38.30',
	},
	{
		gameName: 'Mega Fairies',
		user: 'Luci*****',
		time: '11:45 AM',
		betAmount: 5.0,
		multiplier: 'x5.10',
		amount: '25.50',
	},
	{
		gameName: 'Epic Gems',
		user: 'Anna*****',
		time: '08:05 AM',
		betAmount: 3.85,
		multiplier: 'x4.95',
		amount: '19.06',
	},
	{
		gameName: 'Halloween',
		user: 'John*****',
		time: '05:40 PM',
		betAmount: 2.75,
		multiplier: 'x5.60',
		amount: '15.40',
	},
];
