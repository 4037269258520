import clsx from 'clsx';
import MUIButton from './style';

export const Button = ({
	backgorundColor = 'transparent',
	opacity = 100,
	width = 'auto',
	children,
	className,
	...rest
}) => {
	return (
		<MUIButton
			opacity={opacity}
			backgorundColor={backgorundColor}
			className={clsx(
				`betsino-${backgorundColor} ${width} browser-default`,
				className && className
			)}
			{...rest}
		>
			{children}
		</MUIButton>
	);
};

