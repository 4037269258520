import { useMemo } from 'preact/hooks';

import Banner from '../../components/banner';
import Search from '../../components/search';
import GameCard from '../../components/game-card-new';
import ProvidersSlider from '../../components/providers-slider';
import LeaderboardWraper from '../../components/leaderboard-wrapper';

import style from './style.module.scss';
import CategoryTabs from "../../components/category-tabs";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectCasinoProviders, selectLiveCasinoProviders} from "../../store/content-slice";

const GamesPage = ({
	isLiveCasino = false,
	categoryId = '',
	categories = [],
}) => {
	const {t} = useTranslation();
  const liveCasinoProviders = useSelector(selectLiveCasinoProviders);
  const casinoProviders = useSelector(selectCasinoProviders);

	const category = useMemo(() => {
		if (!categories || !categoryId) {
			return null;
		}

		const categoryItem = categories.find(({ id }) => id === categoryId);

		return categoryItem;
	}, [categories, categoryId]);

	if (!category) {
		return null;
	}

  return (
    <div className={style.wrapper}>
      <Banner label={t(category.name)} />
      <div className={style.searchAndToggleButtonsContainer}>
        <Search isLiveCasino={isLiveCasino} searchBarClassName={style.searchBar} />
        <div className={style.toggleButtonsWrapper}>
          <CategoryTabs categories={categories} categoryId={categoryId} />
        </div>
      </div>
      <ProvidersSlider providers={isLiveCasino ? liveCasinoProviders : casinoProviders} />
      <div className={style.gameContainer}>
        <div className={style.groupWrapper}>
          {category.games.items.map(game => (
              <GameCard
                key={game.id}
                game={game}
                category={category.name}
              />
            )
          )}
        </div>
      </div>
      <div className={style.leaderboardWraper}>
        <LeaderboardWraper />
      </div>
    </div>
  );
};

export default GamesPage;
