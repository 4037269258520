import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
	name: 'theme',
	initialState: {
		showLogin: false,
		showSignUp: false,
		showForgotPassword: false,
		toaserMessage: {
			status: '',
			message: '',
		},
	},
	reducers: {
		setShowLogin: (state, action) => {
			state.showLogin = action.payload;
		},
		setShowSignUp: (state, action) => {
			state.showSignUp = action.payload;
		},
		setShowForgotPassword: (state, action) => {
			state.showForgotPassword = action.payload;
		},
	},
});

const selectShowLogin = (state) => state.themeSlice.showLogin;
const selectShowSignUp = (state) => state.themeSlice.showSignUp;
const selectShowForgotPassword = (state) => state.themeSlice.showForgotPassword;

export const { setShowLogin, setShowSignUp, setShowForgotPassword } =
	themeSlice.actions;

export { selectShowLogin, selectShowSignUp, selectShowForgotPassword };

export default themeSlice.reducer;
