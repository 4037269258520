import { useState } from 'preact/hooks';

import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import { ConfirmationModal } from '../confirmation-modal';

import style from './style.module.scss';

import removeLimitCloseIcon from '../../../../assets/icons/remove-limit-close-icon.svg';
import { formatDate } from '../../../../utils/date';
import { useDispatch } from 'react-redux';
import { deletePlayerExclusion, getPlayerExclusion } from '../../../../store/exclusion-slice';
import { deletePlayerTimeout, getPlayerTimeout } from '../../../../store/timeout-slice';

export const RestrictionPanel = ({
	data = {},
	removeButtonText = '',
	confirmationModalTitle = '',
  type = '',
  playerId = '',
}) => {
  const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const onRemoveButtonClick = () => {
		setIsModalOpen(true);
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

  const confirmDeleteHandler = async () => {
    setIsModalOpen(false);
		await dispatch(type == 'exclusion' ? deletePlayerExclusion(data.id) : deletePlayerTimeout(data.id));
    await dispatch(type == 'exclusion' ? getPlayerExclusion(playerId) : getPlayerTimeout(playerId));
	};

	return (
		<div className={style.restrictionWrapper}>
			<div className={style.restrictionFieldsWrapper}>
				<Typography type="st2" fontColor="purple-2">
					Started on:
				</Typography>

				<Typography type="st2" fontColor="white">
					{formatDate(data?.startDate, true)}
				</Typography>

				<Typography type="st2" fontColor="purple-2">
					Ending on:
				</Typography>

				<Typography type="st2" fontColor="white">
        {formatDate(data?.endDate, true)}
				</Typography>
			</div>

			<div className={style.buttonWrapper}>
				<ButtonRound
					buttonType="secondary"
					className={style.removeLimitButton}
					onClick={onRemoveButtonClick}
				>
					<Typography type="st4" fontColor="green">
						{removeButtonText}
					</Typography>

					<img src={removeLimitCloseIcon} alt="close-icon" />
				</ButtonRound>
			</div>

			<ConfirmationModal
				isModalOpen={isModalOpen}
				title={confirmationModalTitle}
				onCloseModal={onCloseModal}
				onConfirmationClick={confirmDeleteHandler}
			/>
		</div>
	);
};
