import clsx from 'clsx';
import { useRef, useCallback } from 'preact/hooks';

import Label from '../label';
import { Typography } from '../../typography';

import style from './style.module.scss';

import UploadIcon from '../../../assets/icons/upload-icon.svg';

const InputFile = ({
	label = '',
	filename = '',
	inputProps = {},
	hasError = false,
	isRequired = true,
	wrapperClassName = '',
	onFileSelect = () => {},
}) => {
	const inputFileRef = useRef(null);

	const onButtonClick = useCallback(() => {
		inputFileRef.current && inputFileRef.current.click();
	}, []);

	const onInputFileChange = useCallback(
		(event) => {
			const files = event.target.files;

			onFileSelect(files);
		},
		[onFileSelect]
	);

	return (
		<div className={clsx(style.wrapper, wrapperClassName)}>
			{label && (
				<Label label={label} hasError={hasError} isRequired={isRequired} />
			)}

			<button
				type="button"
				className={style.buttonWrapper}
				onClick={onButtonClick}
			>
				<Typography type="st1" className={style.filenameLabel}>
					{filename}
				</Typography>

				<img src={UploadIcon} alt="upload-icon" />

				<input
					hidden
					type="file"
					ref={inputFileRef}
					onChange={onInputFileChange}
					{...inputProps}
				/>
			</button>
		</div>
	);
};

export default InputFile;
