import clsx from 'clsx';
import { useMemo } from 'preact/hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';
import Checkbox from '../forms/checkbox';
import Box from '../scroll-box';

import style from './style.module.scss';
import ButtonRound from '../button-round';

const RegistrationStepThree = ({ buttonText = '', onSubmit = () => {} }) => {
	const { t } = useTranslation();

	const TermsAndConditionsSchema = useMemo(
		() =>
			Yup.object().shape({
				acceptedTerms: Yup.boolean()
					.required(t('you_must_accept_the_terms_of_service_to_proceed'))
					.oneOf(
						[true],
						t(
							'please_read_the_terms_and_conditions_in_full_and_scroll_to_the_end_to_accept'
						)
					),
			}),
		[t, Yup]
	);

	const formik = useFormik({
		initialValues: {
			acceptedTerms: false,
		},
		validationSchema: TermsAndConditionsSchema,
		onSubmit,
	});

	const terms = [
		{
			title: '1. Lorem ispum',
			conditions: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas
					maecenas pharetra convallis posuere morbi leo urna molestie at.
					Quisque sagittis purus sit amet. Suspendisse faucibus interdum posuere
					lorem ipsum. Quisque egestas diam in arcu cursus euismod quis viverra
					nibh. Libero id faucibus nisl tincidunt eget nullam non nisi.
					Vulputate odio uturna nunc. Donec pretium vulputate sapien nec
					sagittis aliquam malesuada bibendum. Facilisi morbi tempus iaculis
					urna. Quam nulla porttitor massa id. Porttitor eget dolor morbi non
					arcu risus quis. Tristique risus nec feugiat in fermentum posuere
					urna. Tortor consequat id porta nibh. Faucibus turpis in eu mi
					bibendum neque egestas. Faucibus in ornare quam viverra orci sagittis
					eu volutpat odio. In fermentum posuere urna nec tincidunt praesent
					semper feugiat. Nunc mattis enim ut tellus elementum sagittis. Velit
					ut tortor pretium viverra suspendisse potenti nullam.`,
		},
		{
			title: '2. Lorem ispum',
			conditions: `
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas
					maecenas pharetra convallis posuere morbi leo urna molestie at.
					Quisque sagittis purus sit amet. Suspendisse faucibus interdum posuere
					lorem ipsum. Quisque egestas diam in arcu cursus euismod quis viverra
					nibh. Libero id faucibus nisl tincidunt eget nullam non nisi.
					Vulputate odio uturna nunc. Donec pretium vulputate sapien nec
					sagittis aliquam malesuada bibendum. Facilisi morbi tempus iaculis
					urna. Quam nulla porttitor massa id. Porttitor eget dolor morbi non
					arcu risus quis. Tristique risus nec feugiat in fermentum posuere
					urna. Tortor consequat id porta nibh. Faucibus turpis in eu mi
					bibendum neque egestas. Faucibus in ornare quam viverra orci sagittis
					eu volutpat odio. In fermentum posuere urna nec tincidunt praesent
					semper feugiat. Nunc mattis enim ut tellus elementum sagittis. Velit
					ut tortor pretium viverra suspendisse potenti nullam.`,
		},
	];

	return (
		<>
			<div className={style.boxWrapper}>
				<Box className={style.box}>
					<Typography type="h5">{t('terms_and_conditions')}</Typography>
					{terms.map(({ title, conditions }) => (
						<div className={style.condition} key={title}>
							<Typography type="h5">{title}</Typography>

							<Typography className={style.terms} type="st1">
								{conditions}
							</Typography>
						</div>
					))}
				</Box>
			</div>

			<div className={style.checkboxWrapper}>
				<Checkbox
					name="acceptedTerms"
					label={t('i_have_read_and_agree_to_the_terms_and_conditions')}
					errorMessage={formik.errors.acceptedTerms}
					checked={formik.values.acceptedTerms}
					hasError={formik.errors.acceptedTerms && formik.touched.acceptedTerms}
					wrapperClassName={style.checkbox}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
				/>
			</div>

			<ButtonRound
				className={clsx(
					style.registrationStepThreeButton,
					style.continueButton
				)}
				onClick={formik.handleSubmit}
				buttonStyle={'primary'}
			>
				<Typography type="btn3" fontColor="blue-3">
					{buttonText}
				</Typography>
			</ButtonRound>
		</>
	);
};

export default RegistrationStepThree;
