import { playerApiUrlV1, sapphirePlayerApiUrl } from './constants';
import { getAuthHeaders } from '../utils/auth';

const getUserBalance = (userId = '') => {
	return fetch(`${playerApiUrlV1}/${userId}/balance`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getUserWallets = (userId = '') => {
	return fetch(`${sapphirePlayerApiUrl}/${userId}/wallets`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getWalletById = (walletId) => {
	return fetch(`${sapphirePlayerApiUrl}/wallet/${walletId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getUserWalletByCurrency = (currency) => {	
	return fetch(`${sapphirePlayerApiUrl}/wallet/by-currency/data?currency=${currency}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};
export {
	getUserBalance,
	getUserWalletByCurrency,
	getUserWallets,
	getWalletById,
};
