import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		new: {
			primary: `#F8931D`,
			blue1: `rgba(2, 0, 36, 1)` /* #020024 */,
			blue2: `rgba(21, 13, 80, 1)` /* #150D50 */,
			blue3: `rgba(35, 25, 106, 1)` /* #23196A */,
			blue4: `rgba(32, 22, 99, 1)` /* #201663 */,
			blueGrey: `rgba(7, 29, 42, 1)` /* 071D2A */,

			green: `rgba(41, 254, 21, 1)` /* #29FE15 */,

			white: ` rgba(255, 255, 255, 1)` /* #FFFFFF */,

			purple1: `rgba(34, 26, 92, 1)` /* #221A5C */,
			purple2: `rgba(116, 77, 255, 1)` /* #744DFF */,
			purple3: `rgba(38, 28, 113, 1)` /* #261c71 */,

			pink: `rgba(135, 65, 220, 1)` /* #8741DC */,

			grey: `rgba(81, 81, 81, 1)` /* #515151 */,

			light: `rgba(233, 233, 233, 1)` /* #E9E9E9 */,

			danger: `rgba(237, 65, 99, 1)` /* #ED4163 */,
			error100: `rgba(237, 65, 99, 1)`,
			error80: `rgba(237, 65, 99, 0.8)`,
			error60: `rgba(237, 65, 99, 0.6)`,
			error40: `rgba(237, 65, 99, 0.4)`,
			error20: `rgba(237, 65, 99, 0.2)`,

			purplePinkGradient: `linear-gradient(
      270.19deg,
      #a94dff 36.42%,
      rgba(158, 77, 255, 0.780978) 56.84%,
      rgba(116, 77, 255, 0) 129.68%
    )`,

			blueGradient: `linear-gradient(180deg, #23196a 0%, #150d50 100%)`,
		},

		transparent: {
			regular: `rgba(36, 152, 218, 1)`,
			100: `rgba(36, 152, 218, 1)`,
		},

		blue: {
			regular: `rgba(36, 152, 218, 1)`,
			100: `rgba(36, 152, 218, 1)`,
			80: `rgba(36, 152, 218, 0.8)`,
			60: `rgba(36, 152, 218, 0.6)`,
			40: `rgba(36, 152, 218, 0.4)`,
			20: `rgba(36, 152, 218, 0.2)`,
		},

		white: {
			regular: `rgba(255, 255, 255, 1)`,
			100: `rgba(255, 255, 255, 1)`,
			80: `rgba(255, 255, 255, 0.8)`,
			60: `rgba(255, 255, 255, 0.6)`,
			50: `rgba(255, 255, 255, 0.5)`,
			40: `rgba(255, 255, 255, 0.4)`,
			20: `rgba(255, 255, 255, 0.2)`,
			0: `rgba(255, 255, 255, 0)`,
		},

		yellow: {
			regular: `rgba(223, 172, 61, 1)`,
			100: `rgba(223, 172, 61, 1)`,
			80: `rgba(223, 172, 61, 0.8)`,
			60: `rgba(223, 172, 61, 0.6)`,
			40: `rgba(223, 172, 61, 0.4)`,
			20: `rgba(223, 172, 61, 0.2)`,
		},

		black: {
			regular: `rgba(0, 0, 0, 1)`,
			100: `rgba(0, 0, 0, 1)`,
		},

		danger: {
			regular: '#ED4163',
			100: '#ED4163',
		},

		grey1: {
			regular: '#515151',
			100: '#515151',
		},

		grey2: {
			regular: '#C6C6C6',
			100: '#C6C6C6',
		},

		dark1: {
			regular: '#2B2B2B',
			100: '#2B2B2B',
		},

		grey: {
			1: '#E9E9E9',
			2: '#C6C6C6',
		},
	},
});

export default theme;
