import { useEffect, useContext } from 'preact/hooks';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	resetUpdatePlayerStatusError,
	selectUpdatePlayerStatusError,
} from '../../../../store/player-slice';
import {
	selectExclusion,
	getPlayerExclusion,
	resetExclusionError,
	selectExclusionError,
} from '../../../../store/exclusion-slice';
import AddRestrictionPanel from '../add-restriction-panel';
import { selectUserSession } from '../../../../store/authentication-slice';
import { ToastMessagesContext } from '../../../../providers/toast-messages-provider';

import commonClasses from '../../style.module.scss';

const SelfExclusion = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const exclusion = useSelector(selectExclusion);
	const userSession = useSelector(selectUserSession);
	const exclusionError = useSelector(selectExclusionError);
	const updatePlayerStatusError = useSelector(selectUpdatePlayerStatusError);

	const { onPushMessage } = useContext(ToastMessagesContext);

	useEffect(() => {
		if (userSession) {
			dispatch(getPlayerExclusion(userSession.id));
		}
	}, [dispatch, userSession]);

	useEffect(() => {
		if (exclusionError || updatePlayerStatusError) {
			onPushMessage({
				message: exclusionError || updatePlayerStatusError,
				type: 'error',
				title: t('error'),
			});

			if (exclusionError) {
				dispatch(resetExclusionError());
			}

			if (updatePlayerStatusError) {
				dispatch(resetUpdatePlayerStatusError());
			}
		}
	}, [t, dispatch, exclusionError, onPushMessage, updatePlayerStatusError]);

	return (
		<div className={commonClasses.tabContent}>
			<AddRestrictionPanel
				isButtonDisabled={exclusion?.length}
				type="exclusion"
			/>
		</div>
	);
};

export default SelfExclusion;
