import CircularProgress from '@mui/material/CircularProgress';

import { Select } from '../../../../components/select';
import { Typography } from '../../../../components/typography';
// import { ButtonNew } from '../../../../components/button-design-system';
import { BasicTable } from '../../../../components/basic-table/basic-table';

import { periods } from './helper';

import commonStyle from '../../style.module.scss';
import style from './style.module.scss';

const Transactions = ({
	error,
	transactions,
	noDataMessage,
	selectedPeriod,
	basicTableColumns,
	transactionsTitle,
	onClickNextButton,
	filteredTransactions,
	onChangePeriodSelect,
	noTransactionsMessage,
	onClickPreviousButton,
	areTransactionsLoading,
	areTransactionsReceived,
}) => {
	return (
		<>
			{areTransactionsLoading && (
				<div className={style.loaderWrapper}>
					<CircularProgress color="primary" className={style.loader} />
				</div>
			)}

			{!areTransactionsLoading && transactions.length > 0 && (
				<div className={style.transactionsPageWrapper}>
					<Typography type="h5" className={style.contentTitle}>
						{transactionsTitle}
					</Typography>

					<div className={style.filterWrapper}>
						<Typography type="body1">Period:</Typography>

						<Select
							items={periods}
							value={selectedPeriod}
							onChange={onChangePeriodSelect}
						/>
					</div>

					{!areTransactionsLoading && (
						<BasicTable
							columns={basicTableColumns}
							rows={filteredTransactions}
							noDataMessage={noDataMessage}
							pagination={{
								onClickNextButton,
								onClickPreviousButton,
							}}
						/>
					)}

					{/* <div className={style.buttonWrapper}>
            <ButtonNew
              disabled={areDepositsLoading || filterDeposits.length === 0}
              onClick={onClickDownloadAllDepositsButton}
            >
              <Typography type="btn3" className={style.buttonLabel}>
                Download all deposits
              </Typography>
            </ButtonNew>
          </div> */}
				</div>
			)}

			{!areTransactionsLoading &&
				areTransactionsReceived &&
				!error &&
				transactions.length === 0 && (
					<div className={commonStyle.messageContainer}>
						{noTransactionsMessage}
					</div>
				)}

			{!areTransactionsLoading && error && (
				<div className={commonStyle.messageContainer}>{error}</div>
			)}
		</>
	);
};

export default Transactions;
