import Switch from './../basic';

import StyledFormControlLabel from './style';

const SwitchWithLabel = ({
	label,
	checked,
	className = '',
	onClick = () => {},
	...rest
}) => {
	return (
		<StyledFormControlLabel
			{...rest}
			label={label}
			className={className}
			control={<Switch checked={checked} onClick={onClick} />}
		/>
	);
};
export default SwitchWithLabel;
