import { useEffect, useMemo } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';

import {
	selectLiveCasinoCategories,
	fetchLiveCasinoCategories,
} from '../../../../store/categories-slice';
import useUrl from '../../../../hooks/useUrl';

import GamesPage from '../../../games-page';

const LiveCasinoGamesPage = () => {
	const dispatch = useDispatch();
	const liveCasinoCategories = useSelector(selectLiveCasinoCategories);
	const { getLastParam } = useUrl();

	const categoryId = useMemo(() => getLastParam(), [getLastParam]);

	useEffect(() => {
		dispatch(fetchLiveCasinoCategories());
	}, []);

	return (
		<GamesPage isLiveCasino categoryId={categoryId} categories={liveCasinoCategories} />
	);
};

export default LiveCasinoGamesPage;

