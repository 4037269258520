import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/configuration-service';

const initialState = {
	countries: [],
	phoneCodes: [],
	languages: [],
	minimumAge: 18,
};

const fetchRegistrationConfiguration = createAsyncThunk(
	'configuration/fetchRegistrationConfiguration',
	async () => {
		const response = await service.getRegistrationConfiguration();
		const data = await response.json();

		return data.data;
	}
);

const configurationSlice = createSlice({
	name: 'configuration',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(fetchRegistrationConfiguration.fulfilled, (state, action) => {
			const { countries, phoneCodes, languages, minimumAge } = action.payload;
			state.countries = countries;
			state.phoneCodes = phoneCodes;
			state.languages = languages;
			state.minimumAge = minimumAge;
		}) ;
	},
});

const selectCountries = (state) => state.configuration.countries;
const selectPhoneCodes = (state) => state.configuration.phoneCodes;
const selectLanguages = (state) => state.configuration.languages;
const selectMinimumAge = (state) => state.configuration.minimumAge;

export { selectCountries, selectPhoneCodes, selectLanguages, selectMinimumAge }; // selectors

export { fetchRegistrationConfiguration }; // thunks

export default configurationSlice.reducer;

