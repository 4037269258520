import { createSlice } from '@reduxjs/toolkit';

export const CATEGORIES = {
	TOP_WINNERS: 'topWinners',
	RECENT_WINNERS: 'recentWinners',
};

export const leaderBoardSlice = createSlice({
	name: 'leaderBoard',
	initialState: {
		selectedCategory: CATEGORIES.TOP_WINNERS,
		categories: {
			topWinners: {},
			recentWinners: {},
		},
	},
	reducers: {
		setSelectedLeaderBoardCategory: (state, action) => {
			state.selectedCategory = action.payload;
		},

		setTopWinners: (state, action) => {
			state.categories.topWinners = {
				category: CATEGORIES.TOP_WINNERS,
				...action.payload,
			};
		},

		setRecentWinners: (state, action) => {
			state.categories.recentWinners = {
				category: CATEGORIES.RECENT_WINNERS,
				...action.payload,
			};
		},
	},
});

export const {
	setSelectedLeaderBoardCategory,
	setTopWinners,
	setRecentWinners,
} = leaderBoardSlice.actions;

export const selectCurrentLeaderBoardCategory = (state) =>
	state.leaderBoard.selectedCategory;

export const selectCurrentLeaderBoardItems = (state) => {
	const currentCategory = state.leaderBoard.selectedCategory;

	return state.leaderBoard.categories?.[currentCategory]?.items;
};

export default leaderBoardSlice.reducer;
