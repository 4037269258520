import clsx from 'clsx';

import classes from './style.module.scss';

const ButtonWithGradientBorder = ({
	icon,
	children,
	className,
	type = 'button',
	disabled = false,
	onClick,
}) => {
	return (
		<button
			type={type}
			className={clsx(classes.button, className)}
			disabled={disabled}
			onClick={onClick}
		>
			<div className={classes.innerWrapper}>
				<div>{children}</div>

				{icon && <img src={icon} />}
			</div>
		</button>
	);
};

export default ButtonWithGradientBorder;
