import { createContext } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import clsx from 'clsx';

import { Snackbar } from '@mui/material';

import Alert from '../../components/alert';

import style from './style.module.scss';

const ToastMessagesContext = createContext({
	// eslint-disable-next-line no-unused-vars
	onPushMessage: ({ message = '', type = 'info', title = '' }) => {},
});

const ToastMessagesProvider = ({ children }) => {
	const [message, setMessage] = useState(null);
	const [shouldShowMessage, setShouldShowMessage] = useState(false);

	const autohideMessageDuration = useMemo(() => 8000, []);

	const snackbarPosition = useMemo(
		() => ({ vertical: 'top', horizontal: 'left' }),
		[]
	);

	useEffect(() => {
		if (message) {
			setShouldShowMessage(true);
		}
	}, [message]);

	// type: 'info' | 'error';
	const handlePushMessage = useCallback(
		(newMessage = { message: '', type: 'info' }) => setMessage(newMessage),
		[]
	);

	const handleCloseMessage = useCallback(() => setShouldShowMessage(false), []);

	return (
		<ToastMessagesContext.Provider value={{ onPushMessage: handlePushMessage }}>
			<Snackbar
				classes={{ root: clsx(style.snackbar, style.root, style.overriden) }}
				anchorOrigin={snackbarPosition}
				open={shouldShowMessage}
				autoHideDuration={autohideMessageDuration}
				onClose={handleCloseMessage}
			>
				<Alert
					message={message?.message}
					type={message?.type}
					title={message?.title}
					onClose={handleCloseMessage}
				/>
			</Snackbar>
			{children}
		</ToastMessagesContext.Provider>
	);
};

export { ToastMessagesContext };

export default ToastMessagesProvider;

