import { useMemo } from 'preact/hooks';

import { useTranslation } from 'react-i18next';
import { Router as PreactRouter } from 'preact-router';

import LossLimits from './loss-limits';
import TakeABreak from './take-a-break';
import DepositLimits from './deposit-limits';
import BettingLimits from './betting-limits';
import SelfExclusion from './self-exclusion';
import Redirect from '../../../components/redirect';
import CommonContentPage from './../common-content-page';
import { pathnames } from '../../../constants/pathnames';

import PlayerProtectionIcon from '../../../assets/icons/player-protection-orange.svg';
import RealityCheck from './reality-check';

const {
	account: {
		depositLimitsPath,
		bettingLimitsPath,
		lossLimitsPath,
		takeABreakPath,
		selfExclusionPath,
		restPlayerProtectionPath,
		realityCheckPath,
	},
} = pathnames;

const PlayerProtectionPage = () => {
	const { t } = useTranslation();

	const tabs = useMemo(
		() => [
			{
				path: depositLimitsPath,
				name: t('deposit_limits'),
			},
			{
				path: bettingLimitsPath,
				name: t('betting_limits'),
			},
			{
				path: lossLimitsPath,
				name: t('loss_limits'),
			},
			{
				path: takeABreakPath,
				name: t('take_a_break'),
			},
			{
				path: selfExclusionPath,
				name: t('self_exclusion'),
			},
      {
        path: realityCheckPath,
        name: 'Reality Check',
      },
		],
		[t]
	);

	return (
		<CommonContentPage
			tabs={tabs}
			title={
				<>
					<img src={PlayerProtectionIcon} />

					<div>{t('player_protection')}</div>
				</>
			}
		>
			<PreactRouter>
				<DepositLimits path={depositLimitsPath} />

				<BettingLimits path={bettingLimitsPath} />

				<LossLimits path={lossLimitsPath} />

				<TakeABreak path={takeABreakPath} />

				<SelfExclusion path={selfExclusionPath} />

        		<RealityCheck path={realityCheckPath} />

				<Redirect path={restPlayerProtectionPath} to={depositLimitsPath} />
			</PreactRouter>
		</CommonContentPage>
	);
};

export default PlayerProtectionPage;
