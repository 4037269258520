import {
	useMemo,
	useState,
	useEffect,
	useContext,
	useCallback,
} from 'preact/hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setShowSignUp } from '../../store/theme-slice';
import {
	addGameToFavourites,
	fetchUserFavouriteGames,
	removeGameFromFavourites,
	selectFavouriteGames,
	selectUser,
} from '../../store/user-slice';
import { selectUserSession } from '../../store/authentication-slice';
import { ToastMessagesContext } from '../../providers/toast-messages-provider';

import GameModePopUp from '../game-mode-pop-up';
import GameLauncherMenu from '../game-launcher-menu';

import style from './style.module.scss';

const GameWrapper = ({ isLiveCasino, game }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const userSession = useSelector(selectUserSession);
	const favouriteGames = useSelector(selectFavouriteGames);
	const [gameMode, setGameMode] = useState('UNSET');
	const [isFullScreen, setIsFullScreen] = useState(false);

	const { onPushMessage } = useContext(ToastMessagesContext);

	const isAuthenticated = useMemo(() => Boolean(userSession), [userSession]);

	useEffect(() => {
		if (user) {
			dispatch(fetchUserFavouriteGames());
		}
	}, [user, dispatch]);

	useEffect(() => {
		const handleExitFullScreen = (event) =>
			event.key === 'Escape' && setIsFullScreen(false);

		window.addEventListener('keydown', handleExitFullScreen);

		return () => {
			window.removeEventListener('keydown', handleExitFullScreen);
		};
	}, []);

	const gameUrl = useMemo(() => {
		const sessionId = userSession?.Guid;

		if (!sessionId) {
			return game?.launchUrl;
		}

		const unionSign = game?.launchUrl.indexOf('?') > -1 ? '&' : '/?';

		if (gameMode === 'FUN') {
			return `${game?.launchUrl}${unionSign}_sid=${sessionId}&funMode=True`;
		}

		return `${game?.launchUrl}${unionSign}language=en&funMode=false&_sid=${sessionId}`;
	}, [game, gameMode, userSession]);

	const isSwitchChecked = useMemo(
		() => gameMode !== 'FUN' && isAuthenticated,
		[gameMode, isAuthenticated]
	);

	const isFavouriteGame = useMemo(
		() => favouriteGames?.includes(game?.gameId),
		[favouriteGames, game]
	);

	const handleClickFullScreen = useCallback(() => {
		const elem = document.getElementById('game_canvas_iframe');
		if (!elem) return false;

		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Safari */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			/* IE11 */
			elem.msRequestFullscreen();
		}
	}, []);

	const handleRealPlayClick = useCallback(() => setGameMode('REAL'), []);

	const handleFunPlayClick = useCallback(() => setGameMode('FUN'), []);

	const handleSignUpClick = useCallback(
		() => dispatch(setShowSignUp(true)),
		[dispatch]
	);

	const handleSwitchGameMode = useCallback(() => {
		if (!isAuthenticated) {
			onPushMessage({
				message: t('please_log_in'),
				type: 'error',
				title: t('error'),
			});
		} else if (isSwitchChecked) {
			handleFunPlayClick();
		} else {
			handleRealPlayClick();
		}
	}, [
		t,
		isAuthenticated,
		isSwitchChecked,
		onPushMessage,
		handleFunPlayClick,
		handleRealPlayClick,
	]);

	const handleAddGameToFavourites = useCallback(() => {
		if (!user || !game) {
			return;
		}

		if (isFavouriteGame) {
			dispatch(removeGameFromFavourites({ gameId: game.gameId?.toString() || game.externalGameId }));
		} else {
			dispatch(addGameToFavourites({ gameId: game.gameId?.toString() || game.externalGameId }));
		}
	}, [dispatch, isFavouriteGame, user, game]);

	return (
		<div className={clsx(style.wrapper, isFullScreen && style.fullScreen)}>
			<div className={style.screen}>
				<iframe
					id="game_canvas_iframe"
					title={game?.id}
					src={gameUrl}
					className={style.iframe}
				/>
				{gameMode === 'UNSET' && (
					<div className={style.popup}>
						<GameModePopUp
							game={game}
							isAuthenticated={isAuthenticated}
							onRealPlayClick={handleRealPlayClick}
							onFunPlayClick={handleFunPlayClick}
							onRegisterClick={handleSignUpClick}
						/>
					</div>
				)}
			</div>
			<GameLauncherMenu
				isSwitchChecked={isSwitchChecked}
				isFavouriteGame={isFavouriteGame}
				isLiveCasino={isLiveCasino}
				onSwitchGameMode={handleSwitchGameMode}
				onAddGameToFavourites={handleAddGameToFavourites}
				onClickFullScreen={handleClickFullScreen}
			/>
		</div>
	);
};

export default GameWrapper;
