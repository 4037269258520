import { Switch } from '../switch';

import FavouritesIcon from '../../assets/icons/favourites-icon.svg';
import FullscreenIcon from '../../assets/icons/fullscreen-icon.svg';
import FavouritedIcon from '../../assets/icons/favourites-icon-filled.svg';
import Logo from '../../assets/images/4play-logo-short.svg';

import style from './style.module.scss';

const GameLauncherMenu = ({
	isSwitchChecked = false,
	isFavouriteGame = false,
	isLiveCasino = false,
	onSwitchGameMode = () => {},
	onAddGameToFavourites = () => {},
	onClickFullScreen = () => {},
}) => {
	return (
		<div className={style.menu}>
			<div className={style.modesWrapper}>
				<img
					className={style.icon}
					src={FullscreenIcon}
					alt="fullscreen"
					onClick={onClickFullScreen}
				/>
				<img
					className={style.icon}
					src={isFavouriteGame ? FavouritedIcon : FavouritesIcon}
					alt="add_to_favourites"
					onClick={onAddGameToFavourites}
				/>
			</div>

				<img className={style.logo} src={Logo} />

			<div className={style.playTypeWrapper}>
				{!isLiveCasino && (
					<Switch checked={isSwitchChecked} onChange={onSwitchGameMode} />
				)}
			</div>
		</div>
	);
};

export default GameLauncherMenu;
