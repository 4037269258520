import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/timeout-service';

const initialState = {
	data: null,
	shouldRefetchTimeouts: false,
};

const getPlayerTimeout = createAsyncThunk(
	'timeout/get',
	async (playerId, { rejectWithValue }) => {
		try {
			const response = await service.getPlayerTimeout(playerId);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const createPlayerTimeout = createAsyncThunk(
	'timeout/create',
	async (data, { rejectWithValue }) => {
		try {
			const response = await service.createPlayerTimeout(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const deletePlayerTimeout = createAsyncThunk(
	'timeout/delete',
	async (playerTimeoutId, { rejectWithValue }) => {
		try {
			const response = await service.deletePlayerTimeout(playerTimeoutId);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const timeoutSlice = createSlice({
	name: 'timeout',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPlayerTimeout.fulfilled, (state, action) => {
			if (action.payload.length) {
				state.data = action.payload[0];
				state.shouldRefetchTimeouts = true;
			}
		});
		builder.addCase(createPlayerTimeout.fulfilled, (state) => {
			state.shouldRefetchTimeouts = true;
		});
	},
});

const selectTimeout = (state) => state.timeout?.data;

const selectShouldRefetchTimeouts = (state) =>
	state.timeout?.shouldRefetchTimeouts;

export { selectTimeout, selectShouldRefetchTimeouts }; // selectors

export { getPlayerTimeout, createPlayerTimeout, deletePlayerTimeout }; // thunks

export default timeoutSlice.reducer;
