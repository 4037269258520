import clsx from 'clsx';

import style from './style.module.scss';

const Label = ({ label = '', isRequired = true, hasError = false }) => {
	return (
		<label className={style.label}>
			<span>{label}</span>
			{isRequired && (
				<span className={clsx(style.requiredFlag, hasError && style.error)}>
					*
				</span>
			)}
		</label>
	);
};

export default Label;

