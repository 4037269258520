import { Typography } from '../typography';
import GameCard from '../game-card-new';

import style from './style.module.scss';

const GamesLayout = ({ title = '', games = [] }) => {
	return (
		<div className={style.wrapper}>
			<Typography type="h7" fontColor="white" className={style.title}>
				{title}
			</Typography>

			<div className={style.gamesGroupWrapper}>
				{games.map(game => (
					<GameCard
						key={game.id}
						game={game}
					/>
				))}
			</div>
		</div>
	);
};

export default GamesLayout;
