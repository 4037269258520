import { createContext } from 'preact';
import { getCurrentUrl } from 'preact-router';
import { useState } from 'preact/hooks';

const UrlContext = createContext({ url: '', onUpdateUrl: () => {} });

const UrlProvider = ({ children }) => {
	const [url, setUrl] = useState(getCurrentUrl());

	return (
		<UrlContext.Provider value={{ url, onUpdateUrl: setUrl }}>
			{children}
		</UrlContext.Provider>
	);
};

export { UrlContext };

export default UrlProvider;

