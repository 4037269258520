import style from "../style.module.scss";
import {amountConstants} from "../../constants";
import clsx from "clsx";
import {useCallback, useEffect, useState} from "preact/hooks";
import OpenDropdownIcon from "../../../../../assets/icons/dropdown-icon-orange-open.svg";
import CloseDropdownIcon from "../../../../../assets/icons/dropdown-icon-orange-close.svg";
import Select from "../../../../../components/forms-new/select/select";
import { useTranslation } from 'react-i18next';

const AmountContainer = ({type, setIsAmountSelected,initialAmount,initialCurrency,suggestedAmounts }) => {
  const [selectedDepositOrWithdrawalAmount, setSelectedDepositOrWithdrawalAmount] = useState(null);
  const [manuallyChosenDepositOrWithdrawalAmount, setManuallyChosenDepositOrWithdrawalAmount] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const { t } = useTranslation();
  const amounts=suggestedAmounts && suggestedAmounts.length > 0 ? suggestedAmounts : amountConstants

  useEffect(() => {
    if (initialAmount) {
      setSelectedDepositOrWithdrawalAmount(initialAmount);
      setManuallyChosenDepositOrWithdrawalAmount(initialAmount);
      setIsAmountSelected(true);
    }
  }, [initialAmount, setIsAmountSelected]);

  useEffect(() => {
    if (initialCurrency) {
      setCurrency(initialCurrency);
    }
  }, [initialCurrency]);
  
  const handleChangeAmountClassName = (amount) => {
    setSelectedDepositOrWithdrawalAmount(
      selectedDepositOrWithdrawalAmount === amount ? null : amount,
    );
    setIsAmountSelected(true);
    setManuallyChosenDepositOrWithdrawalAmount("");
  };	

	const handleChangeManualDepositOrWithdrawalAmount = (e) => {
		const amount = e.target.value;

		setManuallyChosenDepositOrWithdrawalAmount(amount);
		setIsAmountSelected(true);
		if (amounts.some((item) => item == amount)) {
			setSelectedDepositOrWithdrawalAmount(
				amounts.filter((item) => item == amount)[0]
			);
		} else {
			setSelectedDepositOrWithdrawalAmount(null);
		}
	};

	const onChangeSelectedDateRange = useCallback((value) => {
		setCurrency(value);
	}, []);

	const handleKeyDown = (event) => {
		if (
			!/[0-9]/.test(event.key) &&
			!['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(
				event.key
			)
		) {
			event.preventDefault();
		}
	};

	return (
		<div className={style.depositOrWithdrawalAmountsContainer}>
			<div
				className={clsx(
					style.depositOrWithdrawalAmountsInnerContainer,
					style.firstContainer
				)}
			>
				<p className={style.sectionTitle}>
					{t(`amount_of_${type === 'deposit' ? 'deposit' : 'withdrawal'}`)}
				</p>
				<div className={style.depositAmountsOptionsContainer}>
					{amounts.map((amount) => (
						<div
							className={clsx(
								style.depositAmountValueWrapper,
								selectedDepositOrWithdrawalAmount === amount &&
									style.depositAmountValueWrapperClicked
							)}
							key={amount}
							onClick={() => handleChangeAmountClassName(amount)}
						>
							<div
								className={clsx(
									selectedDepositOrWithdrawalAmount === amount &&
										style.depositAmountValueContainerClicked
								)}
							>
								{amount}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className={style.customAmountContainer}>
				<div
					className={clsx(
						style.depositOrWithdrawalAmountsInnerContainer,
						style.secondContainer
					)}
				>
					<p className={style.sectionTitle}>
						{t('amount')} <span className={style.mandatory}>*</span>
					</p>
					<div className={style.depositOrWithdrawalAmountChosenValueContainer}>
						<input
							className={style.depositOrWithdrawalAmountChosenValue}
							value={
								selectedDepositOrWithdrawalAmount ||
								manuallyChosenDepositOrWithdrawalAmount
							}
							onKeyDown={handleKeyDown}
							onChange={handleChangeManualDepositOrWithdrawalAmount}
						/>
					</div>
				</div>
				<div
					className={clsx(
						style.depositOrWithdrawalAmountsInnerContainer,
						style.thirdContainer
					)}
				>
					<p className={style.sectionTitle}>
						{t('currency')} <span className={style.mandatory}>*</span>
					</p>
					<Select
						value={currency}
						options={['EUR', 'USD', 'BTC', 'ETH']}
						// className={clsx(
						//   commonClasses.commonSelect,
						//   classes.dateRangeSelect,
						// )}
						dropdownIconOpen={CloseDropdownIcon}
						dropdownIconClosed={OpenDropdownIcon}
						// selectButtonClassName={commonClasses.commonSelectButton}
						// selectedValueClassName={commonClasses.commonSelectValue}
						// selectOptionsClassName={clsx(
						//   commonClasses.commonSelectOptions,
						//   classes.dateRangeSelectOptions,
						// )}
						onChange={onChangeSelectedDateRange}
					/>
				</div>
			</div>
		</div>
	);
};

export default AmountContainer;
