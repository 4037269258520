import { ButtonNew } from "../../components/button-design-system";

import { Select, MenuItem } from "@mui/material";
import { useState } from "preact/hooks";
import { InputField } from "../../components/text-field";

const ButtonsDesignSystemPage = () => {
  const [label, setLabel] = useState("Click me");
  const [size, setSize] = useState("large");
  const [color, setColor] = useState("blue");
  const [variant, setVariant] = useState("primary");

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleVariantChange = (event) => {
    setVariant(event.target.value);
  };
  return (
    <>
      <div
        style={{ margin: "20px", padding: "20px", border: "1px solid blue" }}
      >
        <ButtonNew variant={variant} color={color} size={size}>
          <p>{label}</p>
        </ButtonNew>
      </div>

      <div style={{ marginTop: "20px" }}>
        <p>Props</p>
      </div>

      <div style={{ marginTop: "10px" }}>
        <InputField
          label="button label"
          defaultValue={label}
          onChange={(e) => {
            setLabel(e?.target?.value);
          }}
        />
      </div>

      <div style={{ marginTop: "10px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={size}
          label="Size"
          onChange={handleSizeChange}
        >
          <MenuItem value="large">Large</MenuItem>
          <MenuItem value="mid">mid</MenuItem>
          <MenuItem value="big1">big1</MenuItem>
          <MenuItem value="big2">big2</MenuItem>
          <MenuItem value="mid1">mid1</MenuItem>
          <MenuItem value="mid2">mid2</MenuItem>
          <MenuItem value="mid3">mid3</MenuItem>
          <MenuItem value="small">small</MenuItem>
        </Select>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={variant}
          label="variant"
          onChange={handleVariantChange}
        >
          <MenuItem value="primary">primary</MenuItem>
          <MenuItem value="secondary">secondary</MenuItem>
          <MenuItem value="ghost">ghost</MenuItem>
        </Select>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={color}
          label="color"
          onChange={handleColorChange}
        >
          <MenuItem value="blue">blue</MenuItem>
          <MenuItem value="yellow">yellow</MenuItem>
          <MenuItem value="danger">danger</MenuItem>
          <MenuItem value="white">white</MenuItem>
          <MenuItem value="dark1">dark1</MenuItem>
          <MenuItem value="grey1">grey1</MenuItem>
          <MenuItem value="grey2">grey2</MenuItem>
        </Select>
      </div>
    </>
  );
};
export default ButtonsDesignSystemPage;
