import { useTranslation } from 'react-i18next';

import BonusCard from '../bonus-card';

const BonusList = ({ bonuses, status }) => {
	const { t } = useTranslation();

	return (
		<>
			{bonuses?.length > 0 &&
				bonuses
					?.filter((bonus) => (status ? bonus.status === status : bonus))
					.map((bonus, index) => <BonusCard key={index} bonus={bonus} />)}

			{!bonuses?.length && (
				<p>
					{t('you_currently_dont_have_any_status_bonuses', {
						status: status ? `${t(status.toLowerCase()).toLowerCase()} ` : '',
					})}
				</p>
			)}
		</>
	);
};

export default BonusList;
