import clsx from 'clsx';

import { useCallback } from 'preact/hooks';

import { MenuItem } from '@mui/material';
import InputError from '../input-error';
import Label from '../label';

import DropdownIcon from '../../../assets/icons/select-icon.svg';

import StyledSelect from './style';
import style from './style.module.scss';

const Select = ({
	name = '',
	value = '',
	label = '',
	hasError = false,
	isRequired = true,
	errorMessage = '',
	options = [],
	disabled = false,
	wrapperClassName = '',
	displayEmpty = false,
	renderValue = undefined,
	onBlur = () => {},
	onChange = () => {},
}) => {
	const Icon = useCallback(
		(props) => (
			<img
				className={style.icon}
				src={DropdownIcon}
				alt="arrow_down"
				{...props}
			/>
		),
		[]
	);

	return (
		<div className={clsx(style.wrapper, wrapperClassName)}>
			{label && (
				<Label label={label} hasError={hasError} isRequired={isRequired} />
			)}

			<div className={style.inputWrapper}>
				<StyledSelect
					className={clsx(
						style.input,
						hasError && style.inputError,
						value !== '' && style.hasValue
					)}
					variant="filled"
					value={value}
					name={name}
					label={label}
					disabled={disabled}
					displayEmpty={displayEmpty}
					renderValue={renderValue}
					MenuProps={{
						slotProps: {
							paper: { className: clsx(style.styledSelect, style.overriden) },
						},
					}}
					IconComponent={Icon}
					onChange={onChange}
					onBlur={onBlur}
				>
					{options.map(({ name, value }) => (
						<MenuItem key={value} value={value}>
							{name}
						</MenuItem>
					))}
				</StyledSelect>
				{hasError && <InputError errorMessage={errorMessage} />}
			</div>
		</div>
	);
};

export default Select;
