import clsx from 'clsx';
import { useCallback, useMemo } from 'preact/hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	selectCountries,
	selectLanguages,
} from '../../store/configuration-slice';

import Input from '../forms/input';
import Select from '../forms/select';
import { Typography } from '../typography';

import style from './style.module.scss';
import { selectStepTwoValidation } from '../../store/authentication-slice';
import { Status } from '../../constants/enums';
import InputError from '../forms/input-error';
import ButtonRound from '../button-round';

const RegistrationStepTwo = ({
	buttonText = '',
	values = null,
	onSubmit = () => {},
}) => {
	const { t } = useTranslation();

	const stepTwoValidationResult = useSelector(selectStepTwoValidation);
	const countries = useSelector(selectCountries);
	const languages = useSelector(selectLanguages);

	const requiredField = useMemo(() => t('this_field_is_required'), [t]);

	const SignupSchema = useMemo(
		() =>
			Yup.object().shape({
				address: Yup.string().required(requiredField),
				country: Yup.string().required(requiredField),
				city: Yup.string().required(requiredField),
				postalCode: Yup.string().required(requiredField),
				language: Yup.string().required(requiredField),
			}),
		[Yup]
	);

	const initialValues = useMemo(() => {
		if (values) {
			return values;
		}

		return {
			address: '',
			country: '',
			city: '',
			postalCode: '',
			language: '',
		};
	}, [values]);

	const formik = useFormik({
		initialValues,
		validationSchema: SignupSchema,
		onSubmit,
	});
	const getSelectOptions = useCallback(
		(items) => items.map((item) => ({ name: item, value: item })),
		[]
	);

	const stepTwoRegistrationError = useMemo(() => {
		if (stepTwoValidationResult.status === Status.REJECTED) {
			return stepTwoValidationResult.error;
		}

		return '';
	}, [stepTwoValidationResult]);

	return (
		<>
			<Input
				name="address"
				label={t('address')}
				value={formik.values.address}
				errorMessage={formik.errors.address}
				hasError={formik.errors.address && formik.touched.address}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
			/>

			<Select
				name="country"
				label={t('country')}
				value={formik.values.country}
				options={getSelectOptions(countries)}
				errorMessage={formik.errors.country}
				hasError={formik.errors.country && formik.touched.country}
				onBlur={formik.handleBlur}
				onChange={formik.handleChange}
			/>

			<div className={style.section}>
				<Input
					name="city"
					label={t('city')}
					value={formik.values.city}
					errorMessage={formik.errors.city}
					hasError={formik.errors.city && formik.touched.city}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
				/>
				<Input
					name="postalCode"
					label={t('postal_code')}
					value={formik.values.postalCode}
					errorMessage={formik.errors.postalCode}
					hasError={formik.errors?.postalCode && formik.touched?.postalCode}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
				/>
			</div>

			<div className={style.selectLimit}>
				<Select
					label={t('language')}
					name="language"
					value={formik.values.language}
					errorMessage={formik.errors?.language}
					options={getSelectOptions(languages)}
					hasError={formik.errors?.language && formik.touched?.language}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
				/>
			</div>

			<ButtonRound
				className={clsx(style.registrationStepOneButton, style.continueButton)}
				onClick={formik.handleSubmit}
				buttonStyle={'primary'}
			>
				<Typography type="btn3" fontColor="blue-3">
					{buttonText}
				</Typography>
			</ButtonRound>
			{stepTwoRegistrationError && (
				<InputError errorMessage={stepTwoRegistrationError} />
			)}
		</>
	);
};

export default RegistrationStepTwo;
