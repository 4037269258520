import {getCurrentUrl} from 'preact-router';
import {useSelector} from 'react-redux';
import {selectInfoPages} from '../../store/content-slice';
import style from './style.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import {Typography} from '../../components/typography';
import {useTranslation} from "react-i18next";
import {clsx} from "clsx";
import i18n from "i18next";

const InfoPage = () => {
  const lang = i18n.language;
  const pages = useSelector(selectInfoPages(lang));
  const {t} = useTranslation();
  const infoSlug = getCurrentUrl().split('/').pop();
  const currentPage = pages.find((page) => page.slug.includes(infoSlug));

  if (infoSlug === 'rtp-volatility') {
    return (
      <>
        <Typography type="h3" fontColor="white">
          {t('rtp_title')}
        </Typography>
        <Typography
          className={style.textContent}
          type="st3"
          fontColor="white"
          dangerouslySetInnerHTML={{
            __html: t('rtp_text', {
              interpolation: {escapeValue: false},
            })
          }}
        />
      </>
    );
  }

  if (!currentPage && infoSlug !== 'rtp-volatility') {
    return (
      <div className={style.loaderWrapper}>
        <CircularProgress color="primary"/>
      </div>
    );
  }

  return (
    <div className={clsx(style.infoPageContainer, style.moreSpecificSelector)}>
      <Typography type="h3" fontColor="white" className={style.textContent}>
        {currentPage.title}
      </Typography>
      <Typography
        type="st3"
        fontColor="white"
        className={style.textContent}
        dangerouslySetInnerHTML={{__html: currentPage.mainContent}}
      />
    </div>
  );
};

export default InfoPage;
