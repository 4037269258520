import style from "./style.module.scss";
import {Typography} from "../../components/typography";
import {useSelector} from "react-redux";
import {selectPromotions} from "../../store/content-slice";
import {getCurrentUrl} from "preact-router";

const PromotionsDetailsPage = () => {
  const promoSlug = getCurrentUrl().split('/').pop();
  const promotions = useSelector(selectPromotions);
  const promotion = promotions.find(promo => promo.slug === promoSlug);

  if (!promotion) {
    return null;
  }

  return (
    <div className={style.promotionContainer}>
      <img className={style.image} src={promotion.image} alt={promotion.title} />
      <Typography type="h5" className={style.title}>
        {promotion.title}
      </Typography>
      <p dangerouslySetInnerHTML={{__html: promotion.description}} />
    </div>
  )
};

export default PromotionsDetailsPage;