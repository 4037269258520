import style from './style.module.scss';
import {Typography} from '../typography';
import GameCardNew from "../game-card-new";
import ProvidersItem from "../providers-item";
import {useEffect, useMemo, useRef, useState} from 'preact/hooks';
import Carousel from "../carousel";
import {route} from "preact-router";
import { useTranslation } from 'react-i18next';

const MAX_ITEMS_PER_PAGE = 11;

const Slider = ({
                  title,
                  categoryId,
                  icon,
                  items,
                  isProvider,
                  backgroundProperty = 'logo',
                  onClick,
                  activeItems,
                  classes,
                  isLive,
                }) => {
  // Refs
  const parentContainerRef = useRef(null);

  // State
  const [isMobile, setIsMobile] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(MAX_ITEMS_PER_PAGE);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();

  const ITEM_WIDTH = isMobile ? 128 : isProvider ? 120 : 160;

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const containerWidth = parentContainerRef.current.clientWidth;
      const itemWidth = ITEM_WIDTH + 10;
      const calculatedItemsPerPage = Math.floor(containerWidth / itemWidth);

      setItemsPerPage(Math.min(calculatedItemsPerPage, MAX_ITEMS_PER_PAGE));
    };

    const checkIsMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    const handleResize = () => {
      calculateItemsPerPage();
      checkIsMobile();
    };

    calculateItemsPerPage();
    checkIsMobile();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [items, ITEM_WIDTH]);

  const handleNextClick = () => {
    const lastItemIndexOnCurrentPage = (currentIndex + 1) * itemsPerPage - 1;

    if (lastItemIndexOnCurrentPage >= items.length - 1) {
      return;
    }

    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const handlePrevClick = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
    } else {
      setCurrentIndex(Math.ceil(items.length / itemsPerPage) - 1);
    }
    if (currentIndex === 0) {
      setCurrentIndex(0);
    }
  };

  const hasPoweredByAiDecoration = useMemo(() => {
    if (title === t('special_for_you')) {
      return true;
    }
  }, [title, t])

  const firstVisibleItemIndex = currentIndex * itemsPerPage;

  const lastVisibleItemIndex = Math.min(
    firstVisibleItemIndex + itemsPerPage,
    items.length - 1
  );

  const onCategoryClick = () => {
    route(`/${isLive ? 'live-casino' : 'casino'}/game-categories/${categoryId}`);
  }

  return (
    <div
      className={style.wrapper}
      ref={parentContainerRef}
      style={!items.length && {display: 'none'}}
    >
      {!isProvider && <div className={style.titleWrapper}>
        <Typography type="h7" fontColor="white" className={style.title} onClick={onCategoryClick}>
          {icon && <img src={icon} alt="cards" />}
          {title}
        </Typography>
        {hasPoweredByAiDecoration && <div className={style.icon}>{t('powered_by')} AI</div>}
      </div>}

      <Carousel classes={{itemContainer: style.itemContainer, arrows: style.itemArrows}}>
        {isProvider && items.map((item, index) => <ProvidersItem
          key={index}
          item={item}
          index={index}
          onClick={onClick}
          isActive={activeItems?.includes(item.title)}
        />)}
        {!isProvider && items.map((item, index) => (
          <GameCardNew
            key={`${item.name}-${index}`}
            game={item}
            category={title}
            isLastVisibleItem={index === lastVisibleItemIndex && lastVisibleItemIndex !== items.length - 1} />
        ))}

      </Carousel>
    </div>
  );
};

export default Slider;
