import { useCallback, useMemo } from 'preact/hooks';
import { route } from 'preact-router';
import clsx from 'clsx';

import Logo1 from '../../assets/images/ag-logo.png';
import Logo2 from '../../assets/images/ag-logo-2.png';
import SapphireLogo from '../../assets/images/sapphire-logo.svg';
import PlayLogoShort from '../../assets/images/4play-logo-short.svg';
import PlayLogoLong from '../../assets/images/4play-logo-long.svg';

import style from './style.module.scss';

const CompanyLogo = ({ variant = '4' }) => {
	const handleLogoClick = useCallback(() => route('/'), []);

	const logoProps = useMemo(
		() => ({
			1: {
				src: Logo1,
				className: style.logo1,
			},
			2: {
				src: Logo2,
				className: style.logo2,
			},
			3: {
				src: SapphireLogo,
				className: '',
			},
			4: {
				src: PlayLogoShort,
				className: '',
			},
			5: {
				src: PlayLogoLong,
				className: '',
			},
		}),
		[]
	);

	const { src, className } = useMemo(
		() => logoProps[variant],
		[variant, logoProps]
	);

	return (
		<img
			className={clsx(style.logo, className)}
			src={src}
			alt="company_logo"
			onClick={handleLogoClick}
		/>
	);
};

export default CompanyLogo;
