import React from 'react'
import AddRestrictionPanel from '../add-restriction-panel'
import commonClasses from '../../style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerRealityChecks, selectRealityCheck } from '../../../../store/realityCheck-slice';
import { selectUserSession } from '../../../../store/authentication-slice';

import { useEffect } from 'preact/hooks';
import RestrictionPanel from '../restriction-panel';
import RealityCheckPanel from '../realityCheck-panel';
import AddRealityCheckPanel from '../add-realityCheck-panel';

const RealityCheck = () => {
    const dispatch = useDispatch();
    const realityChecks = useSelector(selectRealityCheck);
    const userSession = useSelector(selectUserSession);

    useEffect(() => {
        if (userSession) {
            dispatch(getPlayerRealityChecks());
        }
    }, [dispatch, userSession]);

    return (
        <>
            <div className={commonClasses.tabContent}>
                <AddRealityCheckPanel
                    isButtonDisabled={realityChecks?.length}
                    type="realityCheck"
                />
            </div>
            {realityChecks && (
                <div className={commonClasses.tabContent}>
                    <RealityCheckPanel data={realityChecks} />
                </div>
            )}

        </>
    )
}

export default RealityCheck