export const tournamentsMockData = [
	{
		id: 1,
		name: 'Ultimate Poker Showdown',
		description:
			"Test your poker skills against top players from around the world. With a substantial prize pool and high-stakes action, this tournament promises thrilling competition. Don't miss your chance to claim the jackpot!",
		current_participants: 150,
		min_participants: 50,
		entry_fee: 50.0,
		min_bet: 10.0,
		max_bet: 500.0,
		payout: 5000.0,
		leaderboard: [
			{ rank: 1, username: 'AceKing*****', points: 13232, prize: 1000 },
			{ rank: 2, username: 'PokerPro*****', points: 12014, prize: 850 },
			{ rank: 3, username: 'BigBlind*****', points: 11530, prize: 700 },
			{ rank: 4, username: 'FlushMas*****', points: 11011, prize: 600 },
			{ rank: 5, username: 'RiverKin*****', points: 10123, prize: 500 },
			{ rank: 6, username: 'AllinPl*****', points: 9832, prize: 400 },
			{ rank: 7, username: 'BluffMas*****', points: 8503, prize: 350 },
			{ rank: 8, username: 'CheckCra*****', points: 7634, prize: 300 },
			{ rank: 9, username: 'PocketRo*****', points: 6412, prize: 250 },
			{ rank: 10, username: 'Showdown*****', points: 5401, prize: 200 },
			{ rank: 11, username: 'FlopMon*****', points: 5234, prize: 150 },
			{ rank: 12, username: 'ChipLead*****', points: 4321, prize: 120 },
			{ rank: 13, username: 'TurnCoi*****', points: 3652, prize: 100 },
			{ rank: 14, username: 'FullHous*****', points: 3412, prize: 90 },
			{ rank: 15, username: 'FlushRoy*****', points: 3010, prize: 80 },
			{ rank: 16, username: 'DeucesWi*****', points: 2451, prize: 70 },
			{ rank: 17, username: 'Straight*****', points: 1975, prize: 60 },
			{ rank: 18, username: 'TripsMa*****', points: 1432, prize: 50 },
			{ rank: 19, username: 'BigStack*****', points: 912, prize: 50 },
			{ rank: 20, username: 'ChipsGal*****', points: 521, prize: 50 },
		],
		image: require('../../assets/images/poker.png')
	},
	{
		id: 2,
		name: 'Blackjack Masters',
		description:
			'Join the elite and prove your mastery of Blackjack in this high-stakes event. A perfect tournament for seasoned players looking for serious rewards. With a large bonus payout, your strategy could make all the difference.',
		current_participants: 200,
		min_participants: 100,
		entry_fee: 75.0,
		min_bet: 20.0,
		max_bet: 300.0,
		payout: 7500.0,
		leaderboard: [
			{ rank: 1, username: 'Blackjack*****', points: 14321, prize: 1500 },
			{ rank: 2, username: '21Master*****', points: 13420, prize: 1300 },
			{ rank: 3, username: 'CardKing*****', points: 12211, prize: 1100 },
			{ rank: 4, username: 'HouseWin*****', points: 11345, prize: 900 },
			{ rank: 5, username: 'DoubleDo*****', points: 10432, prize: 800 },
			{ rank: 6, username: 'DealerWi*****', points: 9241, prize: 650 },
			{ rank: 7, username: 'BustOrWi*****', points: 8502, prize: 500 },
			{ rank: 8, username: 'SplitMas*****', points: 7630, prize: 400 },
			{ rank: 9, username: 'Soft17Wi*****', points: 6521, prize: 350 },
			{ rank: 10, username: 'PushItA*****', points: 5432, prize: 300 },
			{ rank: 11, username: 'BigCard*****', points: 4789, prize: 250 },
			{ rank: 12, username: 'TableAce*****', points: 4031, prize: 200 },
			{ rank: 13, username: 'HitOrSta*****', points: 3892, prize: 150 },
			{ rank: 14, username: 'TenAceM*****', points: 3521, prize: 120 },
			{ rank: 15, username: 'AceInHan*****', points: 3125, prize: 100 },
			{ rank: 16, username: 'ChipLead*****', points: 2713, prize: 80 },
			{ rank: 17, username: 'WinOrBus*****', points: 2214, prize: 70 },
			{ rank: 18, username: 'Counting*****', points: 1812, prize: 60 },
			{ rank: 19, username: 'DealerIs*****', points: 1235, prize: 50 },
			{ rank: 20, username: 'HardHitt*****', points: 634, prize: 50 },
		],
		image: require('../../assets/images/blackjack.png')
	},
	{
		id: 3,
		name: 'Slot Machine Frenzy',
		description:
			'Spin the reels and see if luck is on your side in this exciting slot tournament. Open to all players, this tournament offers easy entry and the chance to win big with every spin. A fun and fast-paced experience for everyone!',
		current_participants: 500,
		min_participants: 250,
		entry_fee: 10.0,
		min_bet: 1.0,
		max_bet: 50.0,
		payout: 1000.0,
		leaderboard: [
			{ rank: 1, username: 'ReelSpin*****', points: 5230, prize: 300 },
			{ rank: 2, username: 'JackpotW*****', points: 4891, prize: 200 },
			{ rank: 3, username: 'LuckyRee*****', points: 4512, prize: 150 },
			{ rank: 4, username: 'Spin2Wi*****', points: 4315, prize: 100 },
			{ rank: 5, username: 'SlotMas*****', points: 4210, prize: 80 },
			{ rank: 6, username: 'BonusHut*****', points: 3890, prize: 70 },
			{ rank: 7, username: 'CherryRe*****', points: 3621, prize: 60 },
			{ rank: 8, username: 'MegaPayo*****', points: 3425, prize: 50 },
			{ rank: 9, username: 'BigSpinW*****', points: 3102, prize: 40 },
			{ rank: 10, username: 'WinReels*****', points: 2951, prize: 40 },
			{ rank: 11, username: 'CashSpin*****', points: 2701, prize: 30 },
			{ rank: 12, username: 'DiamondS*****', points: 2412, prize: 30 },
			{ rank: 13, username: 'HotSlots*****', points: 2301, prize: 20 },
			{ rank: 14, username: 'TripleLu*****', points: 2195, prize: 20 },
			{ rank: 15, username: 'Spin2Cas*****', points: 2103, prize: 20 },
			{ rank: 16, username: 'WheelofW*****', points: 1856, prize: 15 },
			{ rank: 17, username: 'SlotsFun*****', points: 1721, prize: 15 },
			{ rank: 18, username: 'BonusKi*****', points: 1503, prize: 15 },
			{ rank: 19, username: 'SevenRee*****', points: 1230, prize: 10 },
			{ rank: 20, username: 'SpinToWi*****', points: 1031, prize: 10 },
		],
		image: require('../../assets/images/slots.png')
	},
	{
		id: 4,
		name: 'Roulette Royale',
		description:
			'Experience the thrill of the roulette wheel in this elegant and exciting tournament. With a large number of participants, this event promises excitement and potentially massive winnings. Place your bets wisely and win big!',
		current_participants: 300,
		min_participants: 150,
		entry_fee: 30.0,
		min_bet: 5.0,
		max_bet: 200.0,
		payout: 3000.0,
		leaderboard: [
			{ rank: 1, username: 'RedorBla*****', points: 7120, prize: 800 },
			{ rank: 2, username: 'SpinMaster*****', points: 6812, prize: 700 },
			{ rank: 3, username: 'WheelWin*****', points: 6230, prize: 600 },
			{ rank: 4, username: 'LuckyNum*****', points: 5902, prize: 500 },
			{ rank: 5, username: 'ZeroHero*****', points: 5321, prize: 350 },
			{ rank: 6, username: 'EvenBetW*****', points: 4870, prize: 250 },
			{ rank: 7, username: 'Roulette*****', points: 4502, prize: 200 },
			{ rank: 8, username: 'SpinAndW*****', points: 4210, prize: 150 },
			{ rank: 9, username: 'LuckySpin*****', points: 3803, prize: 120 },
			{ rank: 10, username: 'NumberSta*****', points: 3501, prize: 100 },
			{ rank: 11, username: 'SpinWheel*****', points: 3205, prize: 90 },
			{ rank: 12, username: 'DoubleZero*****', points: 3010, prize: 80 },
			{ rank: 13, username: 'HighRoll*****', points: 2712, prize: 70 },
			{ rank: 14, username: 'BigWinBl*****', points: 2501, prize: 60 },
			{ rank: 15, username: 'WheelWin*****', points: 2220, prize: 50 },
			{ rank: 16, username: 'WinningSt*****', points: 1903, prize: 40 },
			{ rank: 17, username: 'BetOnBl*****', points: 1702, prize: 30 },
			{ rank: 18, username: 'SpinRed*****', points: 1403, prize: 25 },
			{ rank: 19, username: 'BlackBet*****', points: 1103, prize: 20 },
			{ rank: 20, username: 'SpinForWi*****', points: 902, prize: 20 },
		],
		image: require('../../assets/images/roulette.png')
	},
	{
		id: 5,
		name: 'Baccarat Elite Challenge',
		description:
			'Compete against the best in this prestigious Baccarat tournament. Designed for players with refined skills, this event offers high stakes and impressive payouts. Enter now and secure your place at the top of the leaderboard!',
		current_participants: 120,
		min_participants: 60,
		entry_fee: 100.0,
		min_bet: 25.0,
		max_bet: 400.0,
		payout: 10000.0,
		leaderboard: [
			{ rank: 1, username: 'EliteBac*****', points: 15321, prize: 3000 },
			{ rank: 2, username: 'BankerWi*****', points: 14322, prize: 2000 },
			{ rank: 3, username: 'PlayerF*****', points: 13411, prize: 1500 },
			{ rank: 4, username: 'TieBetMa*****', points: 12300, prize: 1200 },
			{ rank: 5, username: 'Baccarat*****', points: 11430, prize: 800 },
			{ rank: 6, username: 'Natural9*****', points: 10800, prize: 600 },
			{ rank: 7, username: 'BigBanke*****', points: 9200, prize: 500 },
			{ rank: 8, username: 'NaturalW*****', points: 8701, prize: 400 },
			{ rank: 9, username: 'Baccarat*****', points: 8234, prize: 350 },
			{ rank: 10, username: 'BetTieWi*****', points: 7430, prize: 300 },
			{ rank: 11, username: 'HighRoll*****', points: 6742, prize: 250 },
			{ rank: 12, username: 'Natural88*****', points: 6123, prize: 200 },
			{ rank: 13, username: 'WinPlayer*****', points: 5512, prize: 150 },
			{ rank: 14, username: 'Perfect9*****', points: 4901, prize: 120 },
			{ rank: 15, username: 'BaccaraW*****', points: 4321, prize: 100 },
			{ rank: 16, username: 'BankRollW*****', points: 3902, prize: 90 },
			{ rank: 17, username: 'TieBreaker*****', points: 3410, prize: 80 },
			{ rank: 18, username: 'HighStakes*****', points: 2931, prize: 70 },
			{ rank: 19, username: 'PlayerW*****', points: 2510, prize: 60 },
			{ rank: 20, username: 'NaturalBo*****', points: 1932, prize: 50 },
		],
		image: require('../../assets/images/baccarat.png')
	},
	{
		id: 6,
		name: 'Craps High Roller Showdown',
		description:
			'Step up to the table and roll the dice in this high-stakes Craps tournament. With large payouts and intense competition, only the boldest players will come out on top. Do you have what it takes to beat the odds and walk away a winner?',
		current_participants: 180,
		min_participants: 80,
		entry_fee: 60.0,
		min_bet: 15.0,
		max_bet: 600.0,
		payout: 7500.0,
		leaderboard: [
			{ rank: 1, username: 'DiceKing*****', points: 17200, prize: 2000 },
			{ rank: 2, username: 'HighRoll*****', points: 16012, prize: 1500 },
			{ rank: 3, username: 'CrapsMas*****', points: 15230, prize: 1200 },
			{ rank: 4, username: 'ComeBetWi*****', points: 14021, prize: 900 },
			{ rank: 5, username: 'FieldBetW*****', points: 13322, prize: 700 },
			{ rank: 6, username: 'SnakeEyes*****', points: 12401, prize: 600 },
			{ rank: 7, username: 'RollerEx*****', points: 11450, prize: 500 },
			{ rank: 8, username: 'SevenOut*****', points: 10534, prize: 400 },
			{ rank: 9, username: 'PassLine*****', points: 9723, prize: 350 },
			{ rank: 10, username: 'RollDoub*****', points: 8911, prize: 300 },
			{ rank: 11, username: 'OddsOnW*****', points: 8122, prize: 250 },
			{ rank: 12, username: 'ShooterWi*****', points: 7435, prize: 200 },
			{ rank: 13, username: 'BigRedWi*****', points: 6723, prize: 150 },
			{ rank: 14, username: 'Boxcars*****', points: 6214, prize: 120 },
			{ rank: 15, username: 'CrapOutW*****', points: 5730, prize: 100 },
			{ rank: 16, username: 'Hardways*****', points: 5132, prize: 90 },
			{ rank: 17, username: 'RollinHot*****', points: 4512, prize: 80 },
			{ rank: 18, username: 'CrapsSho*****', points: 3902, prize: 70 },
			{ rank: 19, username: 'LuckySho*****', points: 3210, prize: 60 },
			{ rank: 20, username: 'RollerSt*****', points: 2834, prize: 50 },
		],
		image: require('../../assets/images/craps.png')
	},
];
