import clsx from 'clsx';
import { useMemo } from 'preact/hooks';
import { forwardRef } from 'preact/compat';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';

import InfoMessageIcon from '../../assets/icons/info-message-icon.svg';
import ErrorMessageIcon from '../../assets/icons/error-message-icon.svg';
import CloseInfoToastMessageIcon from '../../assets/icons/close-info-toast-message-icon.svg';
import CloseErrorToastMessageIcon from '../../assets/icons/close-error-toast-message-icon.svg';

import style from './style.module.scss';

const Alert = forwardRef(
	(
		{ title = 'Error', type = 'info', message = '', onClose = () => {} },
		ref
	) => {
		const { t } = useTranslation();

		const Icon = useMemo(() => {
			return type === 'info' ? InfoMessageIcon : ErrorMessageIcon;
		}, [type]);

		return (
			<div
				ref={ref}
				className={clsx(
					style.alertContainer,
					type === 'info' ? style.infoContainer : style.errorContainer
				)}
			>
				<div className={style.iconWrapper}>
					<img src={Icon} alt="message-icon" />
				</div>

				<div className={style.wrapper}>
					<div className={style.messageDetailsWrapper}>
						<Typography
							type="st2"
							fontColor={type === 'info' ? 'blue-3' : 'white'}
							className={style.messageTitle}
						>
							{title === 'Error' ? t('error') : title}
						</Typography>

						<Typography
							fontColor={type === 'info' ? 'blue-3' : 'white'}
							className={style.message}
							type="st3"
						>
							{message}
						</Typography>
					</div>

					<img
						className={style.closeIcon}
						src={
							type === 'info'
								? CloseInfoToastMessageIcon
								: CloseErrorToastMessageIcon
						}
						alt="close-icon"
						onClick={onClose}
					/>
				</div>
			</div>
		);
	}
);

export default Alert;
