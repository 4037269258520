import io from 'socket.io-client';
import { createContext } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { useSelector } from 'react-redux';

import { selectUserSession } from '../../store/authentication-slice';
import { getSessionToken } from '../../utils/auth';

import { socketConnectionUrl } from '../../services/constants';

const SocketContext = createContext(null);

export const useSocketContext = () => {
	return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
	const [socket, setSocket] = useState(null);
	const user = useSelector(selectUserSession);
	const token = getSessionToken();

	useEffect(() => {
		if (user && user?.id) {
			const socket = io(
				socketConnectionUrl,
				{
					auth: {
						token,
					},
				}
			);

			setSocket(socket);

			return () => {
				socket.close();
			};
		} else if (socket) {
			socket.close();
			setSocket(null);
		}
	}, [user?.id]);

	return (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	);
};
