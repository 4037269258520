import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/limit-service';

const initialState = {
	data: null,
	shouldRefetchPlayerLimitData: false,
};

const getPlayerLimits = createAsyncThunk(
	'limits/get',
	async (playerId, { rejectWithValue }) => {
		try {
			const response = await service.getPlayerLimits(playerId);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const createPlayerLimit = createAsyncThunk(
	'limits/create',
	async (data, { rejectWithValue }) => {
		try {
			const response = await service.createPlayerLimit(data);
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const deletePlayerLimit = createAsyncThunk(
	'limits/delete',
	async (playerLimitId, { rejectWithValue }) => {
		try {
			const response = await service.deletePlayerLimit(playerLimitId);
			return response.deleted;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const limitsSlice = createSlice({
	name: 'limits',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPlayerLimits.fulfilled, (state, action) => {
			return { data: action.payload, shouldRefetchPlayerLimitData: false };
		});
		builder.addCase(createPlayerLimit.fulfilled, (state) => {
			state.shouldRefetchPlayerLimitData = true;
		});
		builder.addCase(deletePlayerLimit.fulfilled, (state, action) => {
			state.shouldRefetchPlayerLimitData = action.payload;
		});
	},
});

const selectLimits = (state, limitType) =>
	state.limits?.data?.filter((limit) => limit.type === limitType);

const selectShouldRefetchPlayerLimitData = (state) =>
	state.limits?.shouldRefetchPlayerLimitData;

export { selectLimits, selectShouldRefetchPlayerLimitData }; // selectors

export { getPlayerLimits, createPlayerLimit, deletePlayerLimit }; // thunks

export default limitsSlice.reducer;
