const bgTranslations = {
	apply: 'Приложи',
	search_your_game: 'Потърси своята игра',
	challenges: 'Предизвикателства',
	description: 'Описание',
	hidden: 'Скрито',
	error: 'Грешка',
	sign_in: 'Вход',
	register: 'Регистрация',
	no_records: 'Няма записи',
	previous: 'Предишна',
	next: 'Следваща',
	yes: 'Да',
	no: 'Не',
	no_rows: 'Няма редове',
	email_verification: 'Email верификация',
	email_address_already_verified: 'Email адресът вече е верифициран!',
	email_verification_sent_successfully: 'Успешно изпратен email за верификация',
	the_confirmation_link_has_expired: 'Връзката за потвърждение е изтекла.',
	resend_email: 'Изпрати нов email',
	your_email_has_been_successfully_confirmed:
		'Вашият e-mail адрес е успешно потвърден.',
	thank_you_for_verifying_your_address:
		'Благодарим, че верифицирахте адреса си!',
	please_include_an_at_the_rate_sign_in_your_email:
		'Моля, добавете символа @ във вашия e-mail',
	email_is_required: 'E-mail е задължително поле!',
	we_have_sent_an_account_recovery_email_to:
		'Изпратен е email за възстановяване на акаунта на {{email}}',
	reset_password: 'Възстановяване на парола',
	email: 'E-mail',
	recover_password: 'Възстанови парола',
	start_date: 'Начална дата',
	edge: 'Предимство',
	real_play: 'Реална игра',
	fun_play: 'Забавна игра',
	please_log_in: 'Моля, влезте в профила си.',
	an_error_occurred: 'Възникна грешка',
	settings: 'Настройки',
	wallet: 'Портфейл',
	transactions: 'Трансакции',
	bonuses: 'Бонуси',
	kyc: 'KYC',
	player_protection: 'Защита на играча',
	support: 'Поддръжка',
	logout: 'Изход',
	search: 'Търсене',
	balance: 'Баланс',
	bonus: 'Бонус',
	deposit: 'Депозит',
	withdraw: 'Теглене',
	top_winners: 'Водещи победители',
	recent_winners: 'Последни победители',
	game: 'Игра',
	user: 'Потребител',
	time: 'Време',
	bet_amount: 'Сума на залога',
	multiplier: 'Множител',
	payout: 'Изплащане',
	casino: 'Казино',
	live_casino: 'Казино на живо',
	displaying_of_games:
		'Показване на {{displayedGamesCount}} от {{totalGamesCount}} игри',
	load_more: 'Зареди още',
	menu: 'Меню',
	promotions: 'Промоции',
	show_less: 'По-малко',
	see_more: 'Повече',
	password_change_successful: 'Успешна смяна на парола',
	your_password_has_been_updated_be_sure_to_use_your_new_password_the_next_time_you_log_in:
		'Вашата парола беше сменена. Уверете се да използвате новата си парола следващия път като се влизате.',
	withdrawable: 'Изтегляема сума',
	read_more: 'Прочети повече',
	providers: 'Доставчици',
	recommended: 'Препоръчани',
	most_played: 'Най-играни в',
	favourites: 'Любими',
	active: 'Активни',
	inactive: 'Неактивни',
	expired: 'Изтекли',
	completed: 'Завършени',
	powered_by: 'Предоставено от',
	all: 'Всички',
	enter_bonus_code: 'Въведете бонус код',
	claim: 'Заяви',
	you_currently_dont_have_any_status_bonuses:
		'В момента нямате {{status}}бонуси',
	bonus_name: 'Име на бонуса',
	bonus_type: 'Тип на бонуса',
	bonus_amount: 'Сума на бонуса',
	expires_in: 'Изтича на',
	terms_and_conditions: 'Правила и Условия',
	daily: 'Дневно',
	weekly: 'Седмично',
	monthly: 'Месечно',
	minimum_amount_is_10_EUR: 'Минималната сума е 10 евро',
	value_must_be_a_whole_number: 'Стойността трябва да е цяло число.',
	value_must_be_between_1_and_24: 'Стойността трябва да е между 1 и 24',
	limit: 'Лимит',
	amount: 'Сума',
	currency: 'Валута',
	period: 'Период',
	enter_password_to_confirm_your_action:
		'Въведете парола, за да потвърдите действието си',
	confirm: 'Потвърди',
	number_h: '{{number}} часа',
	one_week: '1 седмица',
	one_month: '1 месец',
	number_months: '{{number}} месеца',
	permanent: 'Постоянно',
	no_limit_defined: 'Няма зададен лимит',
	limit_type: 'Тип на лимита',
	time_limit: 'Времеви лимит',
	current_limit: 'Текущ лимит',
	remaining_amount: 'Остатъчна сума',
	started_on: 'Започнал',
	pending: 'Чакащ',
	remove_Limit: 'Премахни Лимит',
	delete_limit: 'Изтрий лимит',
	ending_on: 'Приключващ',
	deposit_limits: 'Депозитни Лимити',
	betting_limits: 'Лимити за Залагане',
	loss_limits: 'Лимити на Загубите',
	take_a_break: 'Вземете Почивка',
	self_exclusion: 'Самоизключване',
	communication: 'Комуникация',
	email_marketing: 'E-mail Маркетинг',
	i_want_to_receive_marketing_communications_via_email:
		'Искам да получавам маркетингови съобщения по e-mail',
	sms_marketing: 'SMS Маркетинг',
	i_want_to_receive_marketing_communications_via_sms:
		'Искам да получавам маркетингови съобщения по SMS',
	third_party_marketing: 'Маркетинг от трети страни',
	i_want_to_receive_marketing_from_3rd_parties:
		'Искам да получавам маркетинг от трети страни',
	street_field_is_mandatory: 'Улицата е задължително поле.',
	city_field_is_mandatory: 'Градът е задължително поле.',
	update_successful: 'Обновяването е успешно.',
	saved: 'Запазено',
	the_password_must_be_at_least_8_characters:
		'Паролата трябва да бъде поне 8 символа.',
	password_is_mandatory: 'Паролата е задължително поле.',
	passwords_must_match: 'Паролите трябва да съвпадат',
	first_name: 'Собствено име',
	last_name: 'Фамилно име',
	country_code: 'Код на държавата',
	phone_number: 'Телефонен номер',
	vip_status: 'ВИП статус',
	city: 'Град',
	address: 'Адрес',
	id_type: 'Тип на документа',
	id_number: 'Номер на документа',
	password: 'Парола',
	old_password: 'Стара парола',
	new_password: 'Нова парола',
	confirm_new_password: 'Потвърди новата парола',
	are_you_sure_you_want_to_change_your_details:
		'Сигурни ли сте, че искате да промените вашите данни?',
	are_you_sure_you_want_to_change_your_password:
		'Сигурни ли сте, че искате да смените вашата парола?',
	save: 'Запази',
	personal_details: 'Лични Данни',
	profile: 'Профил',
	all_transactions: 'Всички трансакции',
	deposit_history: 'История на депозитите',
	withdrawal_history: 'История на тегленията',
	transaction_history: 'История на трансакциите',
	gaming_history: 'История на Игрите',
	amount_of_deposit: 'Сума на депозита',
	amount_of_withdrawal: 'Сума за теглене',
	payment_method: 'Метод на плащане',
	show_more: 'Покажи повече',
	the_deposit_request_cannot_be_processed_at_the_moment:
		'Заявката за депозит не може да бъде обработена в момента',
	the_withdrawal_request_cannot_be_processed_at_the_moment:
		'Заявката за теглене не може да бъде обработена в момента',
	withdrawal: 'Теглене',
	my_account: 'Моят профил',
	recent: 'Последни',
	change_your_password: 'Сменете вашата парола',
	enter_a_new_password_below_to_change_your_password:
		'Въведете новата си парола отдолу, за да смените вашата парола',
	temporary_password: 'Временна парола',
	invalid_date: 'Невалидна дата.',
	invalid_name: 'Невалидно име.',
	mr: 'Г-н',
	mrs: 'Г-жа',
	mx: 'Предпочитам да не казвам',
	your_username_must_be_at_least_3_characters:
		'Вашето потребителско име трябва да е дълго поне 3 символа.',
	your_username_cannot_exceed_14_characters:
		'Вашето потребителско име не може да надвишава 14 символа',
	no_spaces_allowed_in_username:
		'Не са позволени интервали в потребителското име.',
	please_enter_a_valid_email_address: 'Моля, въведете валиден e-mail адрес',
	your_password_must_be_at_least_8_characters:
		'Вашата парола трябва да е поне 8 символа',
	your_password_must_be_at_least_8_characters_with_at_least_one_capital_and_one_lower_case_and_one_digit_and_one_special_character:
		'Вашата парола трябва да е поне 8 символа с поне една главна, една малка буква, една цифра и един специален символ',
	day_should_be_a_number: 'Денят трябва да е число',
	month_should_be_a_number: 'Месецът трябва да е число',
	year_should_be_a_number: 'Годината трябва да е число',
	invalid_number: 'Невалидно число',
	username: 'Потребителско име',
	title: 'Титла',
	date_of_birth: 'Дата на раждане',
	date: 'Дата',
	month: 'Месец',
	year: 'Година',
	all_time: 'Всички',
	last_24_hours: 'Последни 24 часа',
	last_week: 'Последна седмица',
	last_month: 'Последен месец',
	last_3_months: 'Последни 3 месеца',
	custom: 'Специфичен период',
	export: 'Експорт',
	type: 'Тип',
	status: 'Статус',
	transaction_number: 'Номер на трансакция',
	actions: 'Действия',
	no_transactions: 'Няма трансакции за показване',
	you_need_to_be_at_least_18_years_old: 'Трябва да имате навършени 18 години',
	this_field_is_required: 'Това поле е задължително!',
	your_username_must_be_3_14_characters_long:
		'Вашето потребителско име трябва да е дълго от 3 до 14 символа.',
	language: 'Език',
	country: 'Държава',
	postal_code: 'Пощенски код',
	you_must_accept_the_terms_of_service_to_proceed:
		'Трябва да приемете условията за ползване, за да продължите',
	please_read_the_terms_and_conditions_in_full_and_scroll_to_the_end_to_accept:
		'Моля, прочетете нацяло правилата и условията като скролнете до края, за да ги приемете',
	i_have_read_and_agree_to_the_terms_and_conditions:
		'Прочел съм и съм съгласен с правилата и условията',
	play_now: 'Играй сега',
	registration_successful: 'Регистрацията е успешна',
	your_registration_is_underway_please_check_your_inbox_for_an_email_with_a_confirmation_link_you_need_to_verify_your_email_to_unlock_all_the_excitement_ahead:
		'Вашата регистрация е в ход. Моля, проверете вашият e-mail за линк за потвърждение. Трябва да верифицирате вашия e-mail, за да отключите всичко вълнуващо, което предстои.',
	step_1_of_3_fill_out_your_details: 'Стъпка 1/3: Попълнете вашите детайли',
	continue: 'Продължи',
	step_2_of_3_fill_out_your_details: 'Стъпка 2/3: Попълнете вашите детайли',
	step_3_of_3_read_and_accept_the_terms_and_conditions:
		'Стъпка 3/3: Прочетете и приемете правилата и условията',
	create_an_account: 'Създаване на профил',
	already_have_an_account: 'Вече имате профил?',
	recent_searches: 'Последни Търсения',
	clear_search: 'Изчисти Търсенето',
	sign_In: 'Вход',
	email_or_username: 'E-mail или потребителско име',
	forgot_password: 'Забравена парола',
	dont_have_an_account: 'Нямате профил?',
	register_an_account: 'Регистрирайте профил',
	no_results_found: 'Не бяха открити резултати',
	search_requires_at_least_3_characters: 'Търсенето изисква поне 3 символа.',
	username_is_required: 'Потребителското име е задължително',
	password_is_required: 'Паролата е задължителна',
	responsibleGamingContent: `<h5>Отговорна игра</h5>
			<p>
				Добре дошли в нашата страница за отговорен хазарт, където можете да
				получите информация за това как да запазите Вашата игра отговорна, а
				забавлението - пълноценно!
			</p>

			<p>Нека е само игра, останете в безопасност</p>
			<p>
				Използваме най-модерните технологии, за да предложим оптимално
				изживяване на тези, които се доверяват на нашите продукти и услуги за
				забавление – това е нашата мисия.
			</p>

			<p>
				Ако смятате, че Вие или някой от Вашите близки може да се пристрастява
				или по някакъв начин да загуби контрол над хазарта, незабавно трябва да
				се консултирате със специалист:
				<a href="https://www.drugsinfo-bg.org/spisak-s-mesta-za-pomosht/spetsialisti/">https://www.drugsinfo-bg.org/spisak-s-mesta-za-pomosht/spetsialisti/.</a>
			</p>

			<h5>Отговорен оператор</h5>
			<p>
				Ние непрекъснато полагаме усилия да осигурим безопасна и сигурна онлайн
				среда за развлечение, за да Ви гарантираме приятно и вълнуващо
				изживяване. Тъй като по-голямата част от нашите потребители използват
				услугите ни по отговорен начин, ние вярваме, че сме в правилната посока,
				за целта предприемаме следните стъпки:
			</p>

			<p>
				Иновация - Използваме високо усъвършенствани и иновативни инструменти,
				гарантиращи, че нашите клиенти се възползват от безопасно и сигурно
				игрово изживяване, чрез:
			</p>

			<p>
				- Iovation - усъвършенстван инструмент, който използваме, за да осигурим
				регистрацията и използването на нашите услуги само от пълнолетни
				потребители, които нямат наложена забрана за участие в хазартни игри.
			</p>

			<p>Обслужване на клиенти във връзка с отговорен хазарт</p>
			<p>
				Нашите специалисти от отдел „Обслужване на клиенти“ и посланици,
				преминали обучение по отговорно залагане са винаги на разположение за
				разговор, като при необходимост предоставят консултации и се уверяват,
				че нашите потребители знаят кога да спрат и как да продължат да
				използват нашите продукти по отговорен начин, възприемайки ги само като
				игра във всеки един момент.
			</p>

			<p>В основата на самоконтрола е самопознанието</p>
			<p>
				Преди да предприемем всички правилни стъпки, нека направим крачка назад
				и наистина да разберем собственото си аз. Разгледайте този инструмент за
				самооценка, за да разберете на какво трябва да обърнете допълнително
				внимание, за да запазите играта безопасна.
			</p>

			<p>
				Ако се притеснявате, че хазартът е има отрицателно въздействие върху Вас
				или Ваш близък, следните въпроси могат да помогнат да разберете повече
				за това дали съответното хазартно поведение е проблематично.
			</p>

			<p>
				Случвало ли се е да пренебрегвате обучението или работата си поради
				извършване на хазартна дейност?
			</p>

			<p>Залагате ли, за да прекарате време или от скука?</p>

			<p>Играете ли сами и за дълги периоди от време?</p>

			<p>Били ли сте критикувани за хазартното си поведение?</p>

			<p>
				Губили ли сте интерес към семейството, приятелите или хобита си поради
				хазарт?
			</p>

			<p>
				Случвало ли се е да лъжете, да вземете назаем или дори да откраднете
				пари, за да покриете загуби от хазарт?
			</p>

			<p>
				Случвало ли се е да лъжете, да взимате назаем или дори да крадете пари
				за хазарт?
			</p>

			<p>
				Не сте склонни да харчите пари, предназначени за хазарт, за други цели?
			</p>

			<p>Залагате ли, докато не загубите всичките си пари?</p>

			<p>
				След загуба, чувствате ли нужда да играете възможно най-скоро, за да
				“избиете” загубите?
			</p>

			<p>
				Има ли моменти, когато хазартът е вследствие на спорове, депресия или
				разочарования?
			</p>

			<p>Хазартът причинява ли ви депресия или мисли за самоубийство?</p>

			<p>
				Залагате ли, за да платите дългове или да решите финансови проблеми?
			</p>

			<p>
				Случвало ли ви се е да лъжете за сумата и времето, които отделяте за
				хазарт?
			</p>

			<p>
				Колкото повече въпроси отговаряте с „да“, толкова по-вероятно е да имате
				затруднения с хазарта.
			</p>

			<p>
				При необходимост от консултация, в случай на загуба на контрол върху
				хазарта, Ви препоръчваме да се свържете с български терапевт,
				специализиран в тази област. Можете да използвате предложените контакти
				на специалисти тук:
			</p>

			<p>Терапевти по хазартна зависимост България</p>

			<p>
				Информирайте нашия отдел "Обслужване на клиенти" за Вашите опасения, за
				да получите съдействие и насоки.
			</p>

			<h5>Участие на непълнолетни в хазартни игри</h5>
			<p>
				Моля, обърнете внимание, че е незаконно лица под 18-годишна възраст да
				откриват акаунти или да залагат. Ние предприемаме строги мерки за
				контрол и ограничение на това изискване, като проверяваме възрастта на
				всички клиенти, които използват платежни методи, достъпни за лица под 18
				години. Освен това извършваме произволни проверки за възрастта на
				клиенти, които използват други платежни методи.
			</p>

			<p>
				ПРИ УСТАНОВЯВАНЕ НА ПОТРЕБИТЕЛ ПОД 18 ГОДИШНА ВЪЗРАСТ, КОЙТО ИЗПОЛЗВА
				УСЛУГИТЕ НА САЙТА, НЕГОВИТЕ ПЕЧАЛБИ ЩЕ БЪДАТ АНУЛИРАНИ И МОЖЕ ДА БЪДЕ
				ДОКЛАДВАН НА ОРГАНИТЕ НА МВР.
			</p>

			<h5>Родителски филтриращи системи</h5>
			<p>
				Ако споделяте Вашите устройства с приятели или членове на семейството,
				които не са навършили пълнолетие, помислете за родителски решения за
				филтриране на съдържанието като <a href="www.netnanny.com">www.netnanny.com</a> и <a href="www.cyberpatrol.com">www.cyberpatrol.com</a>.
			</p>

			<h5>Съвети за отговорно залагане</h5>
			<p>
				Нашите продукти са проектирани да предлагат забавно и вълнуващо
				изживяване. Следвайте описаните по-долу съвети, за да запазите играта
				отговорна:
			</p>

			<p>
				- Играйте за забавление, нека е само игра; Забавлението е ключовата дума
				тук и поставянето на залог, който можете да си позволите, е единственият
				начин да го постигнете. Поставянето на залози за да “избиете” загубите,
				за да спечелите пари или с какъвто и да е друг мотив, различен от
				удоволствие, може да се разглежда като рисков и неоправдан. За да се
				предпазите от ирационален и безотговорен хазарт, се въздържайте от
				хазартни игри, когато сте в лошо настроение, чувствате разочарование,
				гняв, тъга или скука.
			</p>

			<p>
				- Да познаваш себе си, означава да знаеш кога да спреш; Ако се чувствате
				несигурни за риска от развиване на зависимост или имате съмнения, че
				играете твърде много, използвайте въпросите за самооценка по-горе и
				получете информация на какво да обърнете допълнително внимание, за да
				запазите играта си безопасна, както и не се колебайте да се свържете с
				Обслужване на клиенти за съвети и съдействие при необходимост.
			</p>

			<p>
				- Дръжте играта си под контрол с нашите инструменти за отговорно
				залагане; Винаги задавайте лимити, които са оптимални за Вашите хазартни
				навици. Можете сами да изпробвате инструментите тук или да се
				възползвате от безплатна експертна консултация с наш представител от
				отдел „Обслужване на клиенти“.
			</p>

			<p>
				- Следете колко време прекарвате в игра; Времето лети, когато се
				забавлявате! И това е чудесно, просто се уверете, че предварително сте
				определили разумното време, което да прекарате на нашия сайт, за да сте
				сигурни, че използвате нашите услуги по предназначение - за забавление.
				Никога не отделяйте ценно си време за хазарт, когато графикът не Ви
				позволява или когато установите, че това би повлияло на Вашата работа,
				взаимоотношения, психическо или физическо здраве или семейни отношения.
			</p>

			<p>
				- Следете изразходваните пари за хазарт; Уверете се, че предварително
				сте изчислили сумата, която можете да си позволите да загубите, като
				направете бюджет и се придържате към него. Използвайте нашите
				инструменти, за да зададете лимити или се свържете с наш представител от
				отдел „Обслужване на клиенти“ за безплатна консултация относно
				инструментите за отговорно залагане и как най-добре да оптимизирате
				Вашия бюджет.
			</p>

			<p>
				- Останете социални; Залагайте за забавление и се срещайте с приятелите
				и семейството си не по-малко, отколкото бихте, ако не залагате. Полагаме
				усилия непрекъснато да повишаваме усведомеността за потенциалните
				рискове от хазарт, като същевременно се грижим максимално да насърчаваме
				отговорната игра, а Вие не забравяйте да обръщате внимание на Вашите
				близки. Не се отчуждавайте от тях заради хазарта!
			</p>

			<p>
				- Не играйте в нетрезво състояние; Разумното практикуване на хазартът
				налага уравновесени, контролирани решения и всяко опиянение ще намали
				способността Ви за правилна преценка. Използвайте нашите инструменти за
				отговорно залагане, за да си вземете почивка, преди да участвате в
				хазартна дейност под въздействието на опиати и да избегнете
				неконтролирани загуби.
			</p>

			<p>
				- Играйте отговорно и се уверете, че разбирате какво означава това; Ако
				имате стратегия, която Ви кара да вярвате, че Ви помага да печелите,
				помислете отново, тъй като хазартът е предназначен да се използва само
				за развлечение ... и това е всичко. Имате късмет? Спечелихте? - Това е
				страхотно! Но не го превръщайте във Ваша основна дейност, залагането
				носи печалби и загуби - това е дефиницията за „хазарт“. Уверете се, че
				залагането е само забавно занимание и никога не се опитвате да заменяте
				Вашия източник на доходи с хазарт. Това не е начин за препитание, всяка
				печалба е приятно предимство, но късметът Ви никога не е гарантиран.
				Няма стратегия за привличане на късмет, която да Ви гарантира печалби -
				не забравяйте да това!
			</p>`,
	rtp_title: 'Възвръщаемост към Играч и Променливост',
	rtp_text: `<h5><strong>Възвръщаемост към Играч</strong></h5>
	
	<p>Възвръщаемост към Играч (RTP) за някоя игра е очакваният процент от залози, който тази игра ще върне към играча за период от време.</p>
	
	<p>Но, важно е да се разбере, че процентът RTP е осреднена (средноаритметична) стойност, която се постига при значително голям брой изиграни игри, а не всеки път, когато играта бъде играна.</p>
	
	<p>Средният процент RTP обикновено се измерва за голям брой изиграни ръце или завъртания.</p>

  <p>Например, ако играта показва 85% RTP, не се очаква, че играчът би спечелил средно 85 валутни единици за всеки заложени 100 валутни единици в игрална сесия.</p>

  <p>RTP може да варира много и в двете посоки поради статистическа променливост и се различава от игра на игра.</p>
  
  <h5><strong>Променливост</strong></h5>
  
  <p>Променливостта - често наричана и дисперсия - се предоставя от Доставчика на Игри. Това се използва за информация за играча колко често да очаква ротативката да плати и колко голяма ще бъде тази печалба.</p>
  
  <p>Ротативка с ниска променливост ще дава чести печалби за малки и средни суми, докато ротативка с висока променливост ще дава много по-големи печалби при много непостоянни интервали.</p>
  `,
	new_games: 'Нови игри',
	special_for_you: 'Специално за теб',
	jackpot_games: 'Джакпот игри',
	all_games: 'Всички игри',
	table_games: 'Игри на маса',
	live_games: 'Игри на живо',
	roulette: 'Рулетка',
	blackjack: 'Блекджек',
	total_amount: 'Обща Сума',
	round_id: 'Рунд ID',
	bet: 'Залог',
	win: 'Печалба',
	no_timeout_defined: 'Няма зададен таймаут',
	tournaments: 'Турнири',
	AF: 'Афганистан',
	AL: 'Албания',
	DZ: 'Алжир',
	AD: 'Андора',
	AO: 'Ангола',
	AG: 'Антигуа и Барбуда',
	AR: 'Аржентина',
	AM: 'Армения',
	AU: 'Австралия',
	AT: 'Австрия',
	AZ: 'Азербайджан',
	BS: 'Бахамски острови',
	BH: 'Бахрейн',
	BD: 'Бангладеш',
	BB: 'Барбадос',
	BY: 'Беларус',
	BE: 'Белгия',
	BZ: 'Белиз',
	BJ: 'Бенин',
	BT: 'Бутан',
	BO: 'Боливия',
	BA: 'Босна и Херцеговина',
	BW: 'Ботсвана',
	BR: 'Бразилия',
	BN: 'Бруней',
	BG: 'България',
	BF: 'Буркина Фасо',
	BI: 'Бурунди',
	CV: 'Кабо Верде',
	KH: 'Камбоджа',
	CM: 'Камерун',
	CA: 'Канада',
	CF: 'Централноафриканска република',
	TD: 'Чад',
	CL: 'Чили',
	CN: 'Китай',
	CO: 'Колумбия',
	KM: 'Коморски острови',
	CG: 'Конго (Бразавил)',
	CR: 'Коста Рика',
	HR: 'Хърватия',
	CU: 'Куба',
	CY: 'Кипър',
	CZ: 'Чехия',
	CD: 'Демократична република Конго',
	DK: 'Дания',
	DJ: 'Джибути',
	DM: 'Доминика',
	DO: 'Доминиканска република',
	EC: 'Еквадор',
	EG: 'Египет',
	SV: 'Салвадор',
	GQ: 'Екваториална Гвинея',
	ER: 'Еритрея',
	EE: 'Естония',
	SZ: "Есватини (бивш 'Свазиленд')",
	ET: 'Етиопия',
	FJ: 'Фиджи',
	FI: 'Финландия',
	FR: 'Франция',
	GA: 'Габон',
	GM: 'Гамбия',
	GE: 'Грузия',
	DE: 'Германия',
	GH: 'Гана',
	GR: 'Гърция',
	GD: 'Гренада',
	GT: 'Гватемала',
	GN: 'Гвинея',
	GW: 'Гвинея-Бисау',
	GY: 'Гвиана',
	HT: 'Хаити',
	HN: 'Хондурас',
	HU: 'Унгария',
	IS: 'Исландия',
	IN: 'Индия',
	ID: 'Индонезия',
	IR: 'Иран',
	IQ: 'Ирак',
	IE: 'Ирландия',
	IL: 'Израел',
	IT: 'Италия',
	JM: 'Ямайка',
	JP: 'Япония',
	JO: 'Йордания',
	KZ: 'Казахстан',
	KE: 'Кения',
	KI: 'Кирибати',
	KW: 'Кувейт',
	KG: 'Киргизстан',
	LA: 'Лаос',
	LV: 'Латвия',
	LB: 'Ливан',
	LS: 'Лесото',
	LR: 'Либерия',
	LY: 'Либия',
	LI: 'Лихтенщайн',
	LT: 'Литва',
	LU: 'Люксембург',
	MG: 'Мадагаскар',
	MW: 'Малави',
	MY: 'Малайзия',
	MV: 'Малдиви',
	ML: 'Мали',
	MT: 'Малта',
	MH: 'Маршалови острови',
	MR: 'Мавритания',
	MU: 'Мавриций',
	MX: 'Мексико',
	FM: 'Микронезия',
	MD: 'Молдова',
	MC: 'Монако',
	MN: 'Монголия',
	ME: 'Черна гора',
	MA: 'Мароко',
	MZ: 'Мозамбик',
	MM: 'Мианмар (бивша Бирма)',
	NA: 'Намибия',
	NR: 'Науру',
	NP: 'Непал',
	NL: 'Нидерландия',
	NZ: 'Нова Зеландия',
	NI: 'Никарагуа',
	NE: 'Нигер',
	NG: 'Нигерия',
	KP: 'Северна Корея',
	MK: 'Северна Македония',
	NO: 'Норвегия',
	OM: 'Оман',
	PK: 'Пакистан',
	PW: 'Палау',
	PS: 'Държава Палестина',
	PA: 'Панама',
	PG: 'Папуа Нова Гвинея',
	PY: 'Парагвай',
	PE: 'Перу',
	PH: 'Филипини',
	PL: 'Полша',
	PT: 'Португалия',
	QA: 'Катар',
	RO: 'Румъния',
	RU: 'Русия',
	RW: 'Руанда',
	KN: 'Сейнт Китс и Невис',
	LC: 'Сейнт Лусия',
	VC: 'Сейнт Винсент и Гренадини',
	WS: 'Самоа',
	SM: 'Сан Марино',
	ST: 'Сао Томе и Принсипи',
	SA: 'Саудитска Арабия',
	SN: 'Сенегал',
	RS: 'Сърбия',
	SC: 'Сейшели',
	SL: 'Сиера Леоне',
	SG: 'Сингапур',
	SK: 'Словакия',
	SI: 'Словения',
	SB: 'Соломонови острови',
	SO: 'Сомалия',
	ZA: 'Южна Африка',
	KR: 'Южна Корея',
	SS: 'Южен Судан',
	ES: 'Испания',
	LK: 'Шри Ланка',
	SD: 'Судан',
	SR: 'Суринам',
	SE: 'Швеция',
	CH: 'Швейцария',
	SY: 'Сирия',
	TJ: 'Таджикистан',
	TZ: 'Танзания',
	TH: 'Тайланд',
	TL: 'Тимор-Лесте',
	TG: 'Того',
	TO: 'Тонга',
	TT: 'Тринидад и Тобаго',
	TN: 'Тунис',
	TR: 'Турция',
	TM: 'Туркменистан',
	TV: 'Тувалу',
	UG: 'Уганда',
	UA: 'Украйна',
	AE: 'Обединени арабски емирства',
	GB: 'Обединено кралство',
	US: 'Съединени щати',
	UY: 'Уругвай',
	UZ: 'Узбекистан',
	VU: 'Вануату',
	VA: 'Ватикан',
	VE: 'Венецуела',
	VN: 'Виетнам',
	YE: 'Йемен',
	ZM: 'Замбия',
	ZW: 'Зимбабве',
};

export default bgTranslations;
