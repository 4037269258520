import { useMemo, useState } from 'preact/hooks';

import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Input from '../../../../components/forms/input';
import { createPlayerLimit } from '../../../../store/limit-slice';
import Button from '../../../../components/forms-new/button/button';
import Select from '../../../../components/forms-new/select/select';

import style from './style.module.scss';

import ToggleHideIcon from '../../../../assets/icons/dropdown-arrow-up-orange.svg';
import ToggleShowIcon from '../../../../assets/icons/dropdown-arrow-down-orange.svg';

const AddLimitPanel = ({ isButtonDisabled = false, playerId, limitType }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [amount, setAmount] = useState('');
	const [period, setPeriod] = useState('');
	const [currency, setCurrency] = useState('');
	const [error, setError] = useState('');

	const currencyOptions = ['EUR'];
	const periodOptions = useMemo(
		() => [
			{value: 'daily', label: t('daily')},
			{value: 'weekly', label: t('weekly')},
			{value: 'monthly', label: t('monthly')},
			],
		[t]
	);

	const createLimitHandler = async () => {
		if (!period || !amount || !currency) return;

		if (
			['bet', 'deposit', 'loss'].includes(limitType) &&
			parseFloat(amount) < 10
		) {
			setError(t('minimum_amount_is_10_EUR'));
			return;
		}

		if (limitType === 'time') {
			if (parseFloat(amount) % 1 !== 0) {
				setError(t('value_must_be_a_whole_number'));
				return;
			} else if (parseFloat(amount) < 1 || parseFloat(amount) > 24) {
				setError(t('value_must_be_between_1_and_24'));
				return;
			}
		}

		dispatch(
			createPlayerLimit({
				playerId,
				type: limitType,
				startDate: new Date().toLocaleDateString(),
				period: period.toLowerCase(),
				amount,
				currency,
			})
		);
		setError('');
	};

	return (
		<div className={style.panelWrapper}>
			<div className={style.inputsAndButtonWrapper}>
				<div className={style.inputsWrapper}>
					<Input
						name="amount"
						value={amount}
						customType="dark"
						label={t('limit')}
						isRequired={false}
						placeholder={limitType !== 'time' ? t('amount') : undefined}
						wrapperClassName={style.limitAmountInputWrapper}
						isEmptyBottomTextContainerVisible={false}
						onChange={(event) => setAmount(event.target.value)}
						onBlur={() => {}}
					/>

					<Select
						label={t('currency')}
						value={currency}
						options={currencyOptions}
						dropdownIconOpen={ToggleHideIcon}
						className={clsx(style.periodSelect, style.moreSpecificSelector)}
						dropdownIconClosed={ToggleShowIcon}
						isRequired={false}
						onChange={(value) => {
							setCurrency(value);
						}}
					/>

					<Select
						label={t('period')}
						value={period ? t(period) : period}
						options={periodOptions}
						dropdownIconOpen={ToggleHideIcon}
						isRequired={false}
						className={clsx(style.periodSelect, style.moreSpecificSelector)}
						dropdownIconClosed={ToggleShowIcon}
						onChange={(value) => {
							setPeriod(value.value);
						}}
					/>
				</div>

				<div className={style.buttonWrapper}>
					<Button onClick={createLimitHandler}>{t('apply')}</Button>
				</div>
			</div>

			{error ? <div className={style.error}>{error}</div> : null}
		</div>
	);
};

export default AddLimitPanel;
