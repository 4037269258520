import { styled } from '@mui/material/styles';
import {
	ToggleButtonGroup as MUIToggleButtonGroup,
	ToggleButton as MUIToggleButton,
} from '@mui/material';

export const ToggleButtonGroup = styled(MUIToggleButtonGroup)(
	({ isMobile }) => {
		const height = isMobile ? '44px' : '60px';
		return {
			'&.MuiToggleButtonGroup-root': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				gap: '24px',

				height,
				width: 'fit-content',
				borderRadius: '10px',
				paddingInline: '5px',

				"@media screen and (max-width: 768px)": {
					width: "100vw",
					overflow: "auto hidden",
					justifyContent: "start",
					scrollbarWidth: "none",
				},

				'.MuiToggleButtonGroup-grouped': {
					borderTopRightRadius: '10px',
					borderTopLeftRadius: '10px',
					borderBottomRightRadius: '10px',
					borderBottomLeftRadius: '10px',
				},
			},
		};
	}
);

export const ToggleButton = styled(MUIToggleButton)(({ theme, isMobile }) => {
	const height = isMobile ? '34px' : '32px';
	const notSelectedButtonBackgroundColor = "var(--color-bg-tertiary)";

	return {
		'&.MuiToggleButton-root': {
			height,
			border: 'none',
			borderRadius: '10px',
			textTransform: 'none',
			background: notSelectedButtonBackgroundColor,
			flexShrink: 0,

			p: {
				color: 'var(--color-white)',
			},

			img: {
				marginRight: 5,
			}
		},

		'&:focus': {
			backgroundColor: 'transparent',
		},

		'&:not(.Mui-selected)': {
			border: `1px solid ${notSelectedButtonBackgroundColor}`,

			'&:hover': {
				boxShadow: '1px 1px 10.2px 0px #FC6F1E',
				img: {
					filter: "drop-shadow(1px 1px 3px rgb(255, 122, 0))",
				},
				p: {
					textShadow: '1px 1px 10px var(--color-contrast-primary)',
				}
			},
		},

		'&.Mui-selected': {
			border: `1px solid var(--color-contrast-primary)!important`,

			p: {
				fontWeight: 600,
				color: "var(--color-contrast-primary)!important",
			},

			img: {
				filter: "brightness(0) saturate(100%) invert(49%) sepia(96%) saturate(2007%) hue-rotate(0deg) brightness(101%) contrast(103%)",
			},
		},
	};
});
