import clsx from 'clsx';
import { Card } from '@mui/material';
import { Link, getCurrentUrl, Router as PreactRouter } from 'preact-router';

import Redirect from '../../components/redirect';
import { Typography } from '../../components/typography';

import { Timeout } from './components/timeout';
import { LossLimits } from './components/loss-limits';
import { pathnames } from '../../constants/pathnames';
import { DepositLimits } from './components/deposit-limits';
import { BettingLimits } from './components/betting-limits';
import { SelfExclusion } from './components/self-exclusion';

import style from './style.module.scss';

const {
	responsibleGaming: {
		depositLimitsPath,
		bettingLimitsPath,
		lossLimitsPath,
		timeoutPath,
		selfExclusionPath,
	},
} = pathnames;

const navigationItems = [
	{
		text: 'Deposit Limits',
		url: depositLimitsPath,
	},
	{
		text: 'Betting Limits',
		url: bettingLimitsPath,
	},
	{
		text: 'Loss Limits',
		url: lossLimitsPath,
	},
	{
		text: 'Timeout',
		url: timeoutPath,
	},
	{
		text: 'Self Exclusion',
		url: selfExclusionPath,
	},
];

const ResponsibleGamingSettings = () => {
	const currentUrl = getCurrentUrl();

	return (
		<div className={style.pageWrapper}>
			<div className={style.titleWrapper}>
				<Typography type="h6" fontColor="white">
					Responsible Gaming
				</Typography>
			</div>

			<div className={style.contentWrapper}>
				<Card className={style.navigation}>
					{navigationItems.map((item, index) => (
						<Link
							key={`${item.text.toLowerCase().replace(/\s/g, '')}-${index}`}
							href={item.url}
							className={clsx(item.url === currentUrl && style.activeNavItem)}
						>
							{item.text}
						</Link>
					))}
				</Card>

				<Card className={style.content}>
					<PreactRouter>
						<DepositLimits path={depositLimitsPath} />

						<BettingLimits path={bettingLimitsPath} />

						<LossLimits path={lossLimitsPath} />

						<Timeout path={timeoutPath} />

						<SelfExclusion path={selfExclusionPath} />

						<Redirect
							path="/responsible-gaming/:rest*"
							to={depositLimitsPath}
						/>
					</PreactRouter>
				</Card>
			</div>
		</div>
	);
};

export default ResponsibleGamingSettings;
