import clsx from 'clsx';
import { useState, useCallback } from 'preact/hooks';

import { Modal } from '../../../../components/modal';
import Input from '../../../../components/forms/input';
import ButtonRound from '../../../../components/button-round';
import Checkbox from '../../../../components/forms/checkbox';
import { Typography } from '../../../../components/typography';

import style from './style.module.scss';

const testData = [
	{
		status: 'Active',
		type: 'Challenge',
		wagered: '-',
		requiredForNextLevel: '-',
		expires: '12.2.2024, 00:00',
	},
	{
		status: 'Expired',
		type: 'Challenge',
		wagered: '-',
		requiredForNextLevel: '-',
		expires: '12.2.2024, 00:00',
	},
];

const Bonuses = () => {
	const [isChecked, setIsChecked] = useState(false);
	const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
		useState(false);

	const onCloseTermsAndConditionsModal = useCallback(() => {
		setIsTermsAndConditionsModalOpen(false);
	}, []);

	const onOpenTermsAndConditionsModal = useCallback(() => {
		setIsTermsAndConditionsModalOpen(true);
	}, []);

	return (
		<>
			<Typography type="h7" fontColor="white" className={style.contentTitle}>
				Bonuses
			</Typography>

			<Typography
				type="st4"
				fontColor="white"
				className={style.noActiveBonusesText}
			>
				You currently don't have any active bonuses
			</Typography>

			<div className={style.claimButtonWrapper}>
				<Input />

				{/* <ButtonSP className={style.claimButton}>
					<Typography type="btn3" fontColor="blue-3">
						Claim
					</Typography>
				</ButtonSP> */}
			</div>

			<div className={style.bonusesWrapper}>
				{testData.map((row, index) => (
					<div key={`bonus-${index}`} className={style.bonusWrapper}>
						<Typography type="h6" fontColor="white">
							Bonus Name
						</Typography>

						<div
							className={clsx(
								style.bonusStatusWrapper,
								row.status === 'Expired' ? style.expired : style.active
							)}
						>
							<Typography
								type="st1"
								fontColor="white"
								className={style.expiredLabel}
							>
								{row.status}
							</Typography>
						</div>

						<div className={style.bonusDataWrapper}>
							<Typography
								type="st2"
								fontColor="purple-2"
								className={style.bonusDataLabel}
							>
								Bonus type:
							</Typography>

							<Typography
								type="st2"
								fontColor="white"
								className={style.bonusDataValue}
							>
								{row.type}
							</Typography>

							<Typography
								type="st2"
								fontColor="purple-2"
								className={style.bonusDataLabel}
							>
								Wagered:
							</Typography>

							<Typography
								type="st2"
								fontColor="white"
								className={style.bonusDataValue}
							>
								{row.wagered}
							</Typography>

							<Typography
								type="st2"
								fontColor="purple-2"
								className={style.bonusDataLabel}
							>
								Required for next level:
							</Typography>

							<Typography
								type="st2"
								fontColor="white"
								className={style.bonusDataValue}
							>
								{row.requiredForNextLevel}
							</Typography>

							<Typography
								type="st2"
								fontColor="purple-2"
								className={style.bonusDataLabel}
							>
								Expires in:
							</Typography>

							<Typography
								type="st2"
								fontColor="white"
								className={style.bonusDataValue}
							>
								{row.expires}
							</Typography>
						</div>

						<div
							className={style.termsAndConditionsLink}
							onClick={onOpenTermsAndConditionsModal}
						>
							<Typography
								type="st2"
								fontColor="blue-100"
								className={style.termsAndConditionsLabel}
							>
								Terms and Conditions
							</Typography>
						</div>

						<Modal
							title="Terms and Conditions"
							open={isTermsAndConditionsModalOpen}
							className={style.termsAndConditionsModal}
							onCloseModal={onCloseTermsAndConditionsModal}
						>
							<div className={style.boxWrapper}>
								<div className={style.termsAndConditionsContent}>
									<Typography
										type="h5"
										className={style.termsAndConditionTitle}
									>
										1. Lorem ipsum
									</Typography>

									<Typography
										type="st1"
										className={style.singleTermConditionContent}
									>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Egestas maecenas pharetra convallis posuere morbi
										leo urna molestie at. Quisque sagittis purus sit amet.
										Suspendisse faucibus interdum posuere lorem ipsum. Quisque
										egestas diam in arcu cursus euismod quis viverra nibh.
										Libero id faucibus nisl tincidunt eget nullam non nisi.
										Vulputate odio ut enim blandit volutpat maecenas volutpat.
										Aliquet enim tortor at auctor urna nunc. Donec pretium
										vulputate sapien nec sagittis aliquam malesuada bibendum.
									</Typography>

									<Typography
										type="h5"
										className={style.termsAndConditionTitle}
									>
										2. Lorem ipsum
									</Typography>

									<Typography
										type="st1"
										className={style.singleTermConditionContent}
									>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Egestas maecenas pharetra convallis posuere morbi
										leo urna molestie at. Quisque sagittis purus sit amet.
									</Typography>

									<Typography
										type="h5"
										className={style.termsAndConditionTitle}
									>
										3. Lorem ipsum
									</Typography>

									<Typography
										type="st1"
										className={style.singleTermConditionContent}
									>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Egestas maecenas pharetra convallis posuere morbi
										leo urna molestie at. Quisque sagittis purus sit amet.
										Suspendisse faucibus interdum posuere lorem ipsum. Quisque
										egestas diam in arcu cursus euismod quis viverra nibh.
										Libero id faucibus nisl tincidunt eget nullam non nisi.
										Vulputate odio ut enim blandit volutpat maecenas volutpat.
										Aliquet enim tortor at auctor urna nunc. Donec pretium
										vulputate sapien nec sagittis aliquam malesuada bibendum.
									</Typography>

									<Typography
										type="h5"
										className={style.termsAndConditionTitle}
									>
										4. Lorem ipsum
									</Typography>

									<Typography
										type="st1"
										className={style.singleTermConditionContent}
									>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Egestas maecenas pharetra convallis posuere morbi
										leo urna molestie at. Quisque sagittis purus sit amet.
									</Typography>
								</div>
							</div>

							<Checkbox
								checked={isChecked}
								hasError={!isChecked}
								wrapperClassName={style.checkbox}
								label="I have read and agree to the terms and conditions"
								errorMessage="Please read the terms and conditions in full and scroll to the end to accept"
								onChange={() => setIsChecked((prev) => !prev)}
							/>

							<ButtonRound disabled={!isChecked} className={style.confirmButton}>
								<Typography type="btn3" fontColor="blue-3">
									Confirm
								</Typography>
							</ButtonRound>
						</Modal>
					</div>
				))}
			</div>
		</>
	);
};

export default Bonuses;
