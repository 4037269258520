import clsx from 'clsx';

import Input from '../../../../components/forms/input';
import Select from '../../../../components/forms/select';
import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import style from './../style.module.scss';

const General = () => {
	return (
		<div className={style.multiplePanels}>
			<div className={style.contentWrapper}>
				<div className={clsx(style.contentTitle, style.flexed)}>
					<Typography type="h5" fontColor="white" style={style.panelTitle}>
						Email
					</Typography>

					<Typography
						type="body2"
						fontColor="blue-3"
						className={style.customChip}
					>
						Verified
					</Typography>
				</div>

				<div className={style.switchesWrapper}>
					<Input label="Email" wrapperClassName={style.textInput} />
				</div>

				<div className={style.bottomContainer}>
					<div className={clsx(style.resendEmail, style.disabled)}>
						Resend Email
					</div>

					<ButtonRound
						disabled={true}
						className={style.saveButton}
						onClick={() => console.log('does nothing')}
					>
						<Typography type="btn3" fontColor="blue-3">
							Save
						</Typography>
					</ButtonRound>
				</div>
			</div>

			<div className={style.contentWrapper}>
				<Typography type="h5" fontColor="white" className={style.contentTitle}>
					Phone number
				</Typography>

				<Typography type="caption3" fontColor="white" className={style.note}>
					We only service areas that are listed in the available country code
					list.
				</Typography>

				<div className={style.switchesWrapper}>
					<Select
						disabled
						label="Country code"
						wrapperClassName={style.selectControl}
					/>

					<Input label="Phone number" wrapperClassName={style.textInput} />
				</div>

				<div className={style.bottomContainer}>
					<ButtonRound
						disabled={true}
						className={style.saveButton}
						onClick={() => console.log('does nothing')}
					>
						<Typography type="btn3" fontColor="blue-3">
							Save
						</Typography>
					</ButtonRound>
				</div>
			</div>
		</div>
	);
};

export default General;
