import { Link } from 'preact-router';

import { Typography } from '../typography';

import style from './style.module.scss';
import { useMemo } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

const GameDescription = ({
	isLiveCasino = false,
	defaultThumbnail = '',
	description = 'No description available',
	tags = { items: [] },
}) => {
	const { t } = useTranslation();

	const tagsHref = useMemo(
		() => (isLiveCasino ? 'live-casino' : 'casino'),
		[isLiveCasino]
	);

	return (
		<div className={style.descriptionWrapper}>
			<div className={style.header}>
				<div className={style.imageContainer}>
					<img className={style.image} src={defaultThumbnail} alt="thumbnail" />
				</div>
				<div className={style.tagWrapper}>
					<div className={style.topPart}>
						<Typography display="inline-block" type="st4">
							{t('edge')}:
						</Typography>
						<Typography display="inline-block" type="st4">
							0.60%
						</Typography>
					</div>

					<div className={style.bottomPart}>
						{tags?.items.map((item) => (
							<Link key={item?.id} href={`/${tagsHref}/group/${item?.id}`}>
								{item?.name}
							</Link>
						))}
					</div>

					<Typography className={style.textContent} type="st1">
						{description}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default GameDescription;
