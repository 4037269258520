import {useEffect, useMemo} from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	fetchUserFavouriteGames,
	selectFavouriteGames,
	selectUser,
} from '../../store/user-slice';

import LeaderboardWraper from '../../components/leaderboard-wrapper/index.js';
import Banner from '../../components/banner';
import Search from '../../components/search';
import GameCard from '../../components/game-card-new';

import styles from './style.module.scss';
import {fetchCasinoCategories, selectGames} from "../../store/categories-slice";

const FavouriteGamesPage = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const favouriteGames = useSelector(selectFavouriteGames);
	const games = useSelector(selectGames);

	const gamesMappedCMS = useMemo(() => favouriteGames?.map(game => games?.find(g => g.gameId === Number(game.externalGameId))).filter(game => game)
	, [favouriteGames, games])

	useEffect(() => {
		if (user) {
			dispatch(fetchUserFavouriteGames(user.id));
			dispatch(fetchCasinoCategories());
		}
	}, [dispatch, user]);

	return (
		<div className={styles.page}>
			<div className={styles.searchWrapper}>
				<Banner label={t('favourites')} />
				<Search />
			</div>
			<div className={styles.gamesContainer}>
				{gamesMappedCMS?.map((game) => (
					<GameCard
						key={game.id}
						game={game}
					/>
				))}
			</div>
			<LeaderboardWraper />
		</div>
	);
};

export default FavouriteGamesPage;
