import Carousel from '../carousel';
import PromotionCard from "../promotion-card";
import style from './style.module.scss';

const Promotions = ({ promotions }) => {
	return (
		<Carousel classes={{itemContainer: style.itemContainer}}>
			{promotions &&
				promotions?.map((promotion, idx) => (
					<PromotionCard key={idx} promotion={promotion} classes={{promotion: style.bannerPromotion}} />
				))}
		</Carousel>
	);
};

export default Promotions;
