import clsx from 'clsx';
import { useMemo } from 'preact/hooks';

import { Popover } from '@mui/material';

import style from './style.module.scss';

const CurrencySelector = ({
	isOpen = false,
	width = 200,
	anchorElement = null,
	onClose = () => {},
	children,
}) => {
	const attachmentPoint = useMemo(
		() => ({
			vertical: 'bottom',
			horizontal: 'left',
		}),
		[]
	);

	const slotProps = useMemo(
		() => ({
			paper: {
				style: {
					width,
				},
			},
		}),
		[width]
	);

	return (
		<Popover
			id="popover"
			classes={{ paper: clsx(style.menu, style.overriden) }}
			open={isOpen}
			anchorEl={anchorElement}
			anchorOrigin={attachmentPoint}
			slotProps={slotProps}
			onClose={onClose}
			disableScrollLock={true}
		>
			{children}
		</Popover>
	);
};

export default CurrencySelector;
