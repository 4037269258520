import { brandId, sapphirePlayerApiUrl } from './constants';

const getRegistrationConfiguration = () => {
	return fetch(`${sapphirePlayerApiUrl}/register/configuration?brandId=${brandId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

export {
	getRegistrationConfiguration,
};
