import clsx from 'clsx';
import { useMemo, useState, useCallback, useEffect } from 'preact/hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectStepOneValidation,
	clearStepsValidationResult,
} from '../../store/authentication-slice';
import {
	selectMinimumAge,
	selectPhoneCodes,
} from '../../store/configuration-slice';

import InputError from '../forms/input-error';
import Select from '../forms/select';
import Input from '../forms/input';
import Label from '../forms/label';
import { Typography } from '../typography';

import style from './style.module.scss';

import PasswordIcon from '../../assets/icons/password-icon.svg';
import PasswordIconHidden from '../../assets/icons/password-icon-hidden.svg';
import { Status } from '../../constants/enums';
import ButtonRound from '../button-round';
import { regexes } from './constants';
import { useTranslation } from 'react-i18next';

const RegistrationStepOne = ({
	buttonText = '',
	values = null,
	onSubmit = () => {},
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [shouldShowPassword, setShouldShowPassword] = useState(false);

	const stepOneValidationResult = useSelector(selectStepOneValidation);
	const phoneCodes = useSelector(selectPhoneCodes);
	const minimumAge = useSelector(selectMinimumAge);

	const invalidDateErrorMessage = useMemo(() => t('invalid_date'), [t]);

	const invalidNameErrorMessage = useMemo(() => t('invalid_name'), [t]);

	const requiredField = useMemo(() => t('this_field_is_required'), [t]);

	const titleOptions = useMemo(
		() => [
			{ name: t('mr'), value: 'Mr.' },
			{ name: t('mrs'), value: 'Ms.' },
			{ name: t('mx'), value: 'Mrs.' },
		],
		[t]
	);

	const SignupSchema = useMemo(
		() =>
			Yup.object().shape({
				username: Yup.string()
					.min(3, t('your_username_must_be_at_least_3_characters'))
					.max(14, t('your_username_cannot_exceed_14_characters'))
					.matches(regexes.username, t('no_spaces_allowed_in_username'))
					.required(requiredField),
				email: Yup.string()
					.matches(regexes.email, t('please_enter_a_valid_email_address'))
					.required(requiredField),
				password: Yup.string()
					.min(8, t('your_password_must_be_at_least_8_characters'))
					.matches(
						regexes.password,
						t(
							'your_password_must_be_at_least_8_characters_with_at_least_one_capital_and_one_lower_case_and_one_digit_and_one_special_character'
						)
					)
					.required(requiredField),
				title: Yup.string().required(requiredField),

				firstName: Yup.string()
					.matches(/^[A-Za-z]+$/, invalidNameErrorMessage)
					.required(requiredField),
				lastName: Yup.string()
					.matches(/^[A-Za-z]+$/, invalidNameErrorMessage)
					.required(requiredField),

				birth: Yup.object().shape({
					day: Yup.number()
						.typeError(t('day_should_be_a_number'))
						.min(1, invalidDateErrorMessage)
						.max(31, invalidDateErrorMessage)
						.required(requiredField),
					month: Yup.number()
						.typeError(t('month_should_be_a_number'))
						.min(1, invalidDateErrorMessage)
						.max(12, invalidDateErrorMessage)
						.required(requiredField),
					year: Yup.number()
						.typeError(t('year_should_be_a_number'))
						.min(1923, invalidDateErrorMessage)
						.required(requiredField),
				}),
				mobile: Yup.object().shape({
					prefix: Yup.string().required(requiredField),
					number: Yup.number()
						.typeError(t('invalid_number'))
						.required(requiredField),
				}),
			}),
		[t, Yup]
	);

	const initialValues = useMemo(() => {
		if (values) {
			return values;
		}

		return {
			email: '',
			username: '',
			password: '',
			title: '',
			firstName: '',
			lastName: '',
			birth: {
				day: '',
				month: '',
				year: '',
			},
			mobile: {
				prefix: '',
				number: '',
			},
		};
	}, [values]);

	const formik = useFormik({
		initialValues,
		validationSchema: SignupSchema,
		onSubmit,
	});

	const handleNameChange = (e) => {
		const { name, value } = e.target;
		formik.setFieldValue(name, value.toUpperCase());
	};

	const isValidBirthDate = useMemo(() => {
		const { day, month, year } = formik.values.birth;

		if (!day || !month || !year) {
			return true;
		}

		const userBirthDate = new Date();

		userBirthDate.setDate(day);
		userBirthDate.setMonth(month - 1); // -1 because month is zero-based
		userBirthDate.setFullYear(year);

		const earliestDatePossible = new Date();
		earliestDatePossible.setFullYear(
			earliestDatePossible.getFullYear() - minimumAge
		);

		return earliestDatePossible >= userBirthDate;
	}, [formik.values]);

	const handleClickPasswordIcon = useCallback(
		() => setShouldShowPassword((prev) => !prev),
		[]
	);

	const stepOneRegistrationError = useMemo(() => {
		if (stepOneValidationResult.status === Status.REJECTED) {
			return stepOneValidationResult.error;
		}

		return '';
	}, [stepOneValidationResult]);

	useEffect(() => {
		return () => {
			dispatch(clearStepsValidationResult());
		};
	}, [dispatch]);

	return (
		<>
			<Input
				name="username"
				label={t('username')}
				onBlur={formik.handleBlur}
				value={formik.values.username}
				onChange={formik.handleChange}
				errorMessage={formik.errors.username}
				helperText={t('your_username_must_be_3_14_characters_long')}
				hasError={formik.errors.username && formik.touched.username}
			/>

			<Input
				fullWidth
				name="email"
				label={t('email')}
				onBlur={formik.handleBlur}
				value={formik.values.email}
				onChange={formik.handleChange}
				defaultValue={formik.values.email}
				errorMessage={formik.errors.email}
				hasError={formik.errors.email && formik.touched.email}
			/>

			<Input
				fullWidth
				helperText=""
				name="password"
				label={t('password')}
				isPasswordField
				onBlur={formik.handleBlur}
				value={formik.values.password}
				onChange={formik.handleChange}
				errorMessage={formik.errors.password}
				wrapperClassName={style.passwordInput}
				type={shouldShowPassword ? 'text' : 'password'}
				hasError={formik.errors.password && formik.touched.password}
				Icon={
					<img
						className={style.icon}
						src={shouldShowPassword ? PasswordIconHidden : PasswordIcon}
						alt="show_password_icon"
						onClick={handleClickPasswordIcon}
					/>
				}
			/>

			<div className={style.fullName}>
				<Select
					name="title"
					label={t('title')}
					onBlur={formik.handleBlur}
					value={formik.values.title}
					options={titleOptions}
					onChange={formik.handleChange}
					errorMessage={formik.errors?.title}
					hasError={formik.errors?.title && formik.touched?.title}
				/>
				<Input
					name="firstName"
					label={t('first_name')}
					onBlur={formik.handleBlur}
					value={formik.values.firstName}
					onChange={handleNameChange}
					errorMessage={formik.errors?.firstName}
					hasError={formik.errors?.firstName && formik.touched?.firstName}
				/>
				<Input
					fullWidth
					name="lastName"
					label={t('last_name')}
					onBlur={formik.handleBlur}
					value={formik.values.lastName}
					onChange={handleNameChange}
					errorMessage={formik.errors?.lastName}
					hasError={formik.errors?.lastName && formik.touched?.lastName}
				/>
			</div>

			<div className={style.dateContainer}>
				<Label label={t('date_of_birth')} hasError={!isValidBirthDate} />
				<div className={style.dateWrapper}>
					<Input
						name="birth.day"
						placeholder={t('date')}
						onBlur={formik.handleBlur}
						value={formik.values.birth.day}
						errorMessage={formik.errors?.birth?.day}
						hasError={
							(formik.errors?.birth?.day && formik.touched?.birth?.day) ||
							!isValidBirthDate
						}
						onChange={formik.handleChange}
					/>
					<Input
						name="birth.month"
						placeholder={t('month')}
						onBlur={formik.handleBlur}
						value={formik.values.birth.month}
						errorMessage={formik.errors?.birth?.month}
						hasError={
							(formik.errors?.birth?.month && formik.touched?.birth?.month) ||
							!isValidBirthDate
						}
						onChange={formik.handleChange}
					/>
					<Input
						name="birth.year"
						placeholder={t('year')}
						onBlur={formik.handleBlur}
						value={formik.values.birth.year}
						errorMessage={formik.errors?.birth?.year}
						hasError={
							(formik.errors?.birth?.year && formik.touched?.birth?.year) ||
							!isValidBirthDate
						}
						onChange={formik.handleChange}
					/>
				</div>
				{!isValidBirthDate && (
					<div className={style.errorWrapper}>
						<InputError
							errorMessage={t('you_need_to_be_at_least_18_years_old')}
						/>
					</div>
				)}
			</div>

			<div className={style.phoneNumberWrapper}>
				<div className={style.selectLimit}>
					<Select
						name="mobile.prefix"
						label={t('country_code')}
						onBlur={formik.handleBlur}
						value={formik.values.mobile.prefix}
						options={phoneCodes.map((item) => ({ name: item, value: item }))}
						onChange={formik.handleChange}
						errorMessage={formik.errors?.mobile?.prefix}
						hasError={
							formik.errors?.mobile?.prefix && formik.touched?.mobile?.prefix
						}
					/>
				</div>
				<div className={style.inputLimit}>
					<Input
						fullWidth
						name="mobile.number"
						label={t('phone_number')}
						onBlur={formik.handleBlur}
						value={formik.values.mobile.number}
						onChange={formik.handleChange}
						errorMessage={formik.errors?.mobile?.number}
						hasError={
							formik.errors?.mobile?.number && formik.touched?.mobile?.number
						}
					/>
				</div>
			</div>

			<ButtonRound
				className={clsx(style.registrationStepOneButton, style.continueButton)}
				onClick={formik.handleSubmit}
				buttonStyle={'primary'}
			>
				<Typography type="btn3" fontColor="blue-3">
					{buttonText}
				</Typography>
			</ButtonRound>
			{stepOneRegistrationError && (
				<InputError errorMessage={stepOneRegistrationError} />
			)}
		</>
	);
};

export default RegistrationStepOne;
