export const camelCaseToSeparatedWords = (input) => {
	let result = '';

	for (let i = 0; i < input.length; i++) {
		const char = input[i];
		// If character is uppercase and not the first character, add a space before it
		if (char === char.toUpperCase() && i !== 0) {
			result += ' ';
		}
		result += char.toLowerCase(); // Append character to result
	}

	// Capitalize the first character of the result
	result = result.charAt(0).toUpperCase() + result.slice(1);

	return result;
};

export const capitalizeFirstLetter = (string) => {
	if (!string) return string;
	return string.charAt(0).toUpperCase() + string.slice(1);
};
