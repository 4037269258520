import { useCallback, useMemo, useState } from 'preact/hooks';
import { route } from 'preact-router';
import clsx from 'clsx';

import useUrl from '../../hooks/useUrl';
import { useViewport } from '../../hooks/useViewport';

import { Typography } from '../typography';

import DropdownIcon from '../../assets/icons/left-chevron.svg';

import style from './style.module.scss';

import DefaultIcon from '../../assets/icons/casino-icon.svg';
import { getPlayerData } from '../../store/player-slice';

const SideMenuItem = ({
	label = '',
	href = '',
	icon = {},
	items = [],
	isDisabled = false,
	isMenuOpen,
	expandedSideMenuItemClass = '',
	collapsedSideMenuItemClass = '',
	onToggleSidebarVisibility = () => {},
	dispatch,
	userSession,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const { url } = useUrl();
	const { isMobileScreen } = useViewport();

	const hasItems = useMemo(() => items.length > 0, [items]);

	const isActive = useMemo(() => url.includes(href), [url, href]);

	const classes = useMemo(
		() => [
			isActive ? style.activeCategory : isMenuOpen && style.notActiveCategory,
			isDisabled && style.disabled,
			!isDisabled && !isActive && style.menuItemHover,
		],
		[isActive, isDisabled, isMenuOpen]
	);

	const IconElement = useMemo(
		() => <img src={icon?.guid || DefaultIcon} />,
		[icon]
	);

	const handleExpandMenu = useCallback(
		() => setIsExpanded((prev) => !prev),
		[]
	);

	const handleClick = useCallback(() => {
		!isDisabled && route(href);
		isMobileScreen && setTimeout(onToggleSidebarVisibility, 300);

		if (label === 'My Account') {
			dispatch(getPlayerData(userSession.id));
		}
	}, [
		href,
		label,
		dispatch,
		userSession,
		isDisabled,
		isMobileScreen,
		onToggleSidebarVisibility,
	]);

	return (
		<>
			<div
				className={clsx(
					style.collapsedMenuItem,
					collapsedSideMenuItemClass,
					...classes
				)}
				onClick={handleClick}
			>
				{IconElement}
			</div>

			<div
				className={clsx(style.wrapper, expandedSideMenuItemClass, ...classes)}
				onClick={handleClick}
			>
				<div className={style.mainItemWrapper}>
					<div className={style.item}>
						{IconElement}
						<Typography
							type="st4"
							fontColor="white"
							className={style.typography}
						>
							{label}
						</Typography>
					</div>
					<div
						className={clsx(
							style.expanderIcon,
							isExpanded && style.expandedIcon
						)}
					>
						{hasItems && <img src={DropdownIcon} onClick={handleExpandMenu} />}
					</div>
				</div>
				<div
					className={clsx(
						style.subItemsWrapper,
						isExpanded && style.expandedSubItems
					)}
				>
					{items.map((item) => (
						<SideMenuItem
							key={item.id}
							{...item}
							onToggleSidebarVisibility={onToggleSidebarVisibility}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default SideMenuItem;
