import style from './style.module.scss';

const DashboardNavigationIcon = ({ handleClick, icon, text }) => {
	return (
		<div className={style.itemContainer}>
			{/* <div className={style.itemBradientBorder}> */}
				<div onClick={handleClick} className={style.itemIcon}>
					<img src={icon} />
				</div>
			{/* </div> */}
            <p className={style.title}>{text}</p>
		</div>
	);
};

export default DashboardNavigationIcon;
