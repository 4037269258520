import { useMemo, useState } from 'preact/hooks';

import Input from '../../../../components/forms/input';
import Label from '../../../../components/forms/label';
import Select from '../../../../components/forms/select';
import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import style from './style.module.scss';
import { useDispatch } from 'react-redux';
import { createPlayerExclusion, getPlayerExclusion } from '../../../../store/exclusion-slice';
import { createPlayerTimeout, getPlayerTimeout } from '../../../../store/timeout-slice';

export const AddRestrictionPanel = ({ isButtonDisabled = false, playerId = '', type = '' }) => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState('');
  const timeUnitOptions = useMemo(
		() => [
			{ name: 'Days', value: 'day' },
			{ name: 'Weeks', value: 'week' },
			{ name: 'Months', value: 'month' },
		],
		[]
	);
  const [timeUnit, setTimeUnit] = useState(timeUnitOptions[0].value);

  const createExclusionHandler = async () => {
    if (!number || !timeUnit)
      return;

		await dispatch(createPlayerExclusion({
      playerId, 
      numberOfPeriods: number, 
      period: timeUnit,
    }));
    await dispatch(getPlayerExclusion(playerId));
	};

  const createTimeoutHandler = async () => {
    if (!number || !timeUnit)
      return;

		await dispatch(createPlayerTimeout({
      playerId, 
      numberOfPeriods: number, 
      period: timeUnit,
    }));
    await dispatch(getPlayerTimeout(playerId));
	};

	return (
		<div className={style.panelWrapper}>
			<Label label="Limit" hasError={false} />

			<div className={style.inputsWrapper}>
				<Input
					name="number"
          value={number}
					placeholder="Number"
					wrapperClassName={style.numberInput}
          onChange={(event) => setNumber(event.target.value)}
				/>

				<Select
					name="timeUnit"
					value={timeUnit}
					options={timeUnitOptions}
					wrapperClassName={style.timeUnitDropdown}
					onChange={(event) => {
						setTimeUnit(event.target.value);
					}}
				/>

				<ButtonRound disabled={isButtonDisabled} className={style.applyButton}
          onClick={type == 'exclusion' ? createExclusionHandler : createTimeoutHandler}>
					<Typography type="btn3" fontColor="blue-3">
						Apply
					</Typography>
				</ButtonRound>
			</div>
		</div>
	);
};
