import { Typography } from '../../typography';

import style from './style.module.scss';

import ErrorIcon from '../../../assets/icons/error-icon.svg';

const InputError = ({ errorMessage }) => {
	return (
		<Typography type="caption3" className={style.error} display="flex">
			<img className={style.icon} src={ErrorIcon} alt="error_icon" />

			<span className={style.errorMessage}>{errorMessage}</span>
		</Typography>
	);
};

export default InputError;
