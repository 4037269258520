import { useEffect, useState } from 'preact/hooks';
import style from './style.module.scss';
import { Typography } from '../typography';

import PlayButton from '../../assets/icons/Vector.svg';
import { route } from 'preact-router';
import { useCustomGameImages } from '../../hooks/useCustomGameImages';

const GameCardTopTen = ({
	number,
	game: apiGame,
	onClickGameCallback = () => {},
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const { game } = useCustomGameImages({ apiGame })

	const renderHoveredState = () => {
		return (
			<div className={style.hoveredState}>
				<Typography type="h5" className={style.gameName} title={game?.name}>
					{game?.name}
				</Typography>
				<img src={PlayButton} />
				<Typography type="st1" className={style.providerName}>
					{game?.vendorName}
				</Typography>
			</div>
		);
	};

	const onClickGame = () => {
		route(`/casino/games/${game?.externalGameId || game.gameId}`);
		onClickGameCallback();
	};

	return (
		<div className={style.root}>
			<span className={style.number}>{number}</span>
			<div
				className={style.cardWrapper}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
				onClick={onClickGame}
			>
				<img src={game?.thumbnail} className={style.image} loading="lazy" />
				{isHovered ? renderHoveredState() : null}
			</div>
		</div>
	);
};

export default GameCardTopTen;
