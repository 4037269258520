const getTokenDuration = () => {
	const storedExpirationDate = localStorage.getItem('expiration');

	const expirationDate = new Date(storedExpirationDate);
	const now = new Date();

	const duration = expirationDate.getTime() - now.getTime();

	if (duration < 0) return 'EXPIRED';

	return duration;
};

const getCookie = (name) => {
	const cookieName = `${name}=`;
	const cookieArray = document.cookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i].trim();
		if (cookie.indexOf(cookieName) === 0) {
			return cookie.substring(cookieName.length, cookie.length);
		}
	}
	return '';
};

const setSessionToken = (token) =>
	localStorage.setItem('sapphire-session-token', token);

const getSessionToken = () => localStorage.getItem('sapphire-session-token');

const removeSessionToken = () =>
	localStorage.removeItem('sapphire-session-token');

const getAuthHeaders = () => {
	return {
		Authorization: `Bearer ${getSessionToken()}`,
	};
};

export {
	getTokenDuration,
	getCookie,
	getAuthHeaders,
	getSessionToken,
	setSessionToken,
	removeSessionToken,
};

