import { useState } from 'preact/hooks';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../utils/date';
import { formatCurrency } from '../../../../utils/currency';
import { Typography } from '../../../../components/typography';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { deletePlayerLimit } from '../../../../store/limit-slice';
import ConfirmationModal from '../../../../components/confirmation-modal';
import ButtonWithGradientBorder from '../../../../components/forms-new/button-with-gradient-border';

import playerProtectionCommonClasses from '../style.module.scss';
import style from './style.module.scss';

import CloseIcon from '../../../../assets/icons/close-icon-white.svg';

const LimitPanel = ({ data = {}, type = '', limitsCount }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const onRemoveButtonClick = () => {
		setIsModalOpen(true);
	};

	const onCloseModal = () => {
		setIsModalOpen(false);
	};

	const confirmDeleteHandler = async () => {
		setIsModalOpen(false);
		dispatch(deletePlayerLimit(data.id));
	};

	return (
		<div className={playerProtectionCommonClasses.appliedLimitWrapper}>
			<div className={playerProtectionCommonClasses.limitFieldsWrapper}>
				<Typography type="st2" fontColor="primary">
					{t('limit_type')}:
				</Typography>

				<Typography type="st2" fontColor="white">
					{capitalizeFirstLetter(t(data.period))}
				</Typography>

				<Typography type="st2" fontColor="primary">
					{type === 'time' ? `${t('time_limit')}:` : `${t('current_limit')}:`}
				</Typography>

				<Typography type="st2" fontColor="white">
					{type === 'time'
						? data.amount
						: formatCurrency(data.amount, data.currency)}
				</Typography>

				{type !== 'time' && (
					<>
						<Typography type="st2" fontColor="primary">
							{t('remaining_amount')}:
						</Typography>

						<Typography type="st2" fontColor="white">
							{formatCurrency(data.remainingAmount, data.currency)}
						</Typography>
					</>
				)}

				<Typography type="st2" fontColor="primary">
					{t('started_on')}:
				</Typography>

				<Typography type="st2" fontColor="white">
					{formatDate(data.startDate, true)}
				</Typography>

				<Typography type="st2" fontColor="primary">
					{formatDate(data.endDate, true)}
				</Typography>

				<Typography type="st2" fontColor="white">
					<span className={style.pending}>
						{!data.active ? t('pending') : null}
					</span>
				</Typography>
			</div>

			{limitsCount > 1 && (
				<ButtonWithGradientBorder
					icon={CloseIcon}
					className={style.removeLimitButton}
					onClick={onRemoveButtonClick}
				>
					{t('remove_Limit')}
				</ButtonWithGradientBorder>
			)}

			<ConfirmationModal
				title={t('delete_limit')}
				isModalOpen={isModalOpen}
				onCloseModal={onCloseModal}
				confirmDeleteHandler={confirmDeleteHandler}
			/>
		</div>
	);
};

export default LimitPanel;
