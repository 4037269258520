import { useCallback, useMemo, useState } from 'preact/hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import ButtonRound from '../button-round';
import { Typography } from '../typography';

import styles from './style.module.scss';

const MoreInfo = ({ section = [] }) => {
	const { t } = useTranslation();

	const [isExpanded, setIsExpanded] = useState(false);

	const buttonLabel = useMemo(
		() => (isExpanded ? t('show_less') : t('see_more')),
		[t, isExpanded]
	);

	const Paragraphs = useMemo(() => {
		return (
			<Typography
				key={'about'}
				type="h6"
				fontColor="white"
				dangerouslySetInnerHTML={{ __html: section }}
			/>
		);
	}, [section]);

	const handleExpandText = useCallback(
		() => setIsExpanded((prev) => !prev),
		[]
	);

	return (
		<div
			className={clsx(styles.moreInfoWrapper, isExpanded && styles.expanded)}
		>
			<div className={`${styles.moreInfoContent} row`}>{Paragraphs}</div>

			<ButtonRound
				buttonType="custom"
				backgroundColor="white"
				className={styles.button}
				onClick={handleExpandText}
			>
				{buttonLabel}
			</ButtonRound>
		</div>
	);
};

export default MoreInfo;
