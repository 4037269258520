import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const BasicTable = ({
	rows,
	columns,
	pagination,
	withPagination = true,
	noDataMessage = 'No records',
}) => {
	const { t } = useTranslation();

	return (
		<div className={style.tableWrapper}>
			<table className={style.basicTable}>
				<thead>
					<tr>
						{columns.map(
							(column, index) =>
								column.isVisible && (
									<th
										key={`${column.field}-${index}`}
										style={{
											width: `calc(100% / ${columns.length})`,
											textAlign: column.headerAlign || 'left',
										}}
									>
										{column.header}
									</th>
								)
						)}
					</tr>
				</thead>

				<tbody>
					{rows.map((row, index) => (
						<tr
							key={`row-${index}`}
							className={clsx(index % 2 === 0 ? style.even : style.odd)}
						>
							{columns.map(
								(col, idx) =>
									col.isVisible && (
										<td
											key={`cell-${col.field}-${idx}`}
											style={{ textAlign: col.align || 'left' }}
										>
											{col.renderCell
												? col.renderCell(row[col.field])
												: row[col.field]}
										</td>
									)
							)}
						</tr>
					))}

					{rows.length === 0 && (
						<tr>
							<td colSpan={columns.length} className={style.noRecordsCell}>
								{noDataMessage === 'No records'
									? t('no_records')
									: noDataMessage}
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{withPagination && rows.length > 0 && (
				<div className={style.pagination}>
					<button onClick={pagination.onClickPreviousButton}>
						{t('previous')}
					</button>

					<button onClick={pagination.onClickNextButton}>{t('next')}</button>
				</div>
			)}
		</div>
	);
};

export { BasicTable };
