import clsx from 'clsx';
import i18n from "i18next";
import style from './style.module.scss';
import {useState, useCallback, useRef} from 'preact/hooks';

import Backdrop from '@mui/material/Backdrop';
import Header from '../header';
import Footer from '../footer';
import SubHeader from '../subheader';
import CompanyLogo from '../company-logo';
import LeftPanelMenu from '../left-panel-menu';
import MobileNavigation from '../mobile-navigation';

import {useViewport} from "../../hooks/useViewport";
import useClickOutside from "../../hooks/useClickOutside";

const MainWrapper = ({ children }) => {
	const { isMobileScreen } = useViewport();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [language, setLanguage] = useState(i18n.language);
	const navRef = useRef(null);

	const closeMobileMenu = () => {
		setIsMenuOpen(false);
	}

	useClickOutside(navRef, closeMobileMenu);

	const handleTriggerMenu = useCallback(
		() => setIsMenuOpen((prev) => !prev),
		[]
	);

	const handleCloseMenu = useCallback(
		() => setIsMenuOpen(false),
		[]
	);

	const onMouseLeaveLogoWrapper = useCallback(
		(event) => {
			const leftPanelMenu = event.relatedTarget?.closest('.jsLeftPanelMenu');

			if (!leftPanelMenu && isMenuOpen) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen]
	);

	const onMouseEnterLeftPanelMenu = useCallback(() => {
		if (!isMenuOpen) {
			setIsMenuOpen(true);
		}
	}, [isMenuOpen]);

	const onMouseLeaveLeftPanelMenu = useCallback((event) => {
		const logoWrapper = event.relatedTarget?.closest('.jsLogoWrapper');

		if (!logoWrapper) {
			setIsMenuOpen(false);
		}
	}, []);

	return (
		<div
			className={clsx(style.mainWrapper, isMenuOpen && style.expandedSideMenu)}
		>
			<Backdrop
				open={isMenuOpen}
				className={style.overlay}
				onClick={handleTriggerMenu}
			/>

			<Header language={language} setLanguage={setLanguage} />

			<div
				ref={navRef}
				className={clsx(style.leftMenu, isMenuOpen && style.expandedLeftMenu)}
			>
				{!isMobileScreen && <div
					className={clsx(style.logoWrapper, 'jsLogoWrapper')}
					onMouseLeave={onMouseLeaveLogoWrapper}
				>
					<CompanyLogo variant={isMenuOpen ? "5" : "4"} />
				</div>}

				<LeftPanelMenu
				  isMenuOpen={isMenuOpen}
					menuCategoryClass={style.menuCategory}
					leftPanelMenuClass={style.leftPanelMenu}
					expandedSideMenuItemClass={style.expandedSideMenuItem}
					collapsedSideMenuItemClass={style.collapsedSideMenuItem}
					onTrigger={handleTriggerMenu}
					onMouseEnter={onMouseEnterLeftPanelMenu}
					onMouseLeave={onMouseLeaveLeftPanelMenu}
				  lang={language}
				/>
			</div>

			<div className={style.childrenWrapper}>
				<SubHeader />
				<div className={style.children}>{children}</div>
				<Footer language={language} />
			</div>

			<MobileNavigation onClickMenu={handleTriggerMenu} onClickLink={handleCloseMenu} />
		</div>
	);
};

export default MainWrapper;
