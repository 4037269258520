import clsx from 'clsx';
import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import ToggleButtons from '../toggle-button';
import { BasicTable } from '../basic-table/basic-table';

import {
	CATEGORIES,
	selectCurrentLeaderBoardCategory,
	selectCurrentLeaderBoardItems,
	setSelectedLeaderBoardCategory,
} from '../../store/leaderBoard';
import {
	topWinnersMockData,
	recentWinnersMockData,
} from './winners-mock-data.js';

import style from './style.module.scss';

import CardsIcon from '../../assets/icons/cards-icon.svg';
import { useViewport } from '../../hooks/useViewport';
import { useTranslation } from 'react-i18next';

const LeaderboardWraper = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { isMobileScreen } = useViewport();

	const leaderBoardCategory = useSelector(selectCurrentLeaderBoardCategory);
	const currentLeaderBoardItems = useSelector(selectCurrentLeaderBoardItems);

	const renderAmount = (amount, alignment) => {
		return (
			<div
				className={clsx(
					style.amountWrapper,
					alignment === 'right' && style.rightAligned
				)}
			>
				<span>{amount} €</span>
			</div>
		);
	};

	function addCurrentDateToTime(timeString) {
		const today = new Date();

		const year = today.getFullYear();
		const month = today.toLocaleString('default', { month: 'short' });
		const day = String(today.getDate()).padStart(2, '0');

		const fullDateTime = `${year}-${month}-${day} ${timeString}`;

		return fullDateTime;
	}

	const toggleButtons = useMemo(
		() => [
			{ label: t('top_winners'), value: CATEGORIES.TOP_WINNERS },
			{ label: t('recent_winners'), value: CATEGORIES.RECENT_WINNERS },
		],
		[t]
	);

	const basicTableColumns = useMemo(() => {
		return [
			{
				header: t('game'),
				field: 'gameName',
				renderCell: (value) => {
					return (
						<div className={style.gameNameWrapper}>
							<img src={CardsIcon} alt="cards" className={style.cardsIcon} />

							<span className={style.gameName}>{value}</span>
						</div>
					);
				},
				isVisible: true,
			},
			{
				align: 'center',
				header: t('user'),
				field: 'user',
				headerAlign: 'center',
				isVisible: true,
			},
			{
				field: 'time',
				align: 'center',
				headerAlign: 'center',
				header: t('time'),
				renderCell: (value) => {
					if (leaderBoardCategory === CATEGORIES.RECENT_WINNERS) {
						return addCurrentDateToTime(value);
					}
					return value;
				},
				isVisible: !isMobileScreen,
			},
			{
				field: 'betAmount',
				align: 'center',
				header: t('bet_amount'),
				headerAlign: 'center',
				renderCell: (value) => renderAmount(value, 'center'),
				isVisible: true,
			},
			{
				field: 'multiplier',
				align: 'center',
				headerAlign: 'center',
				header: t('multiplier'),
				isVisible: true,
			},
			{
				field: 'amount',
				headerAlign: 'right',
				header: t('payout'),
				renderCell: (value) => renderAmount(value, 'right'),
				isVisible: !isMobileScreen,
			},
		];
	}, [isMobileScreen, t, leaderBoardCategory]);

	const mockData = useMemo(() => {
		if (leaderBoardCategory === CATEGORIES.TOP_WINNERS) {
			return topWinnersMockData;
		} else {
			return recentWinnersMockData;
		}
	}, [leaderBoardCategory]);

	const handleChange = (event, newAlignment) => {
		if (newAlignment === null) return;
		dispatch(setSelectedLeaderBoardCategory(newAlignment));
	};

	useEffect(() => {
		return () =>
			dispatch(setSelectedLeaderBoardCategory(CATEGORIES.TOP_WINNERS));
	}, [dispatch]);

	return (
		<div className={style.wrapper}>
			<ToggleButtons
				items={toggleButtons}
				alignment={leaderBoardCategory}
				handleChange={handleChange}
			/>

			<BasicTable
				columns={basicTableColumns}
				rows={
					currentLeaderBoardItems?.length > 0
						? currentLeaderBoardItems
						: mockData.slice(0, 10) //This is temporary, should be removed when currentLeaderBoardItems returns data and have to be 10 again
				}
				withPagination={false}
			/>
		</div>
	);
};

export default LeaderboardWraper;
