import {
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import {
	selectResetPasswordData,
	selectResetPasswordError,
	clearResetPasswordError,
	resetPassword,
} from '../../store/authentication-slice';
import useUrl from '../../hooks/useUrl';

import Input from '../../components/forms/input';
import Button from '../../components/forms/button';
import { Typography } from '../../components/typography';
import showPasswordIcon from '../../assets/icons/show-password-icon.svg';
import hidePasswordIcon from '../../assets/icons/hide-password-icon.svg';
import style from './style.module.scss';
import PasswordReset from '../../components/password-reset';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const resetPasswordData = useSelector(selectResetPasswordData);
	const resetPasswordError = useSelector(selectResetPasswordError);
	const { onPushMessage } = useContext(ToastMessagesContext);
	const { getQueryParam } = useUrl();

	const requiredField = useMemo(() => t('this_field_is_required'), [t]);

	const [showTemporaryPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);

	const toggleTemporaryPasswordVisibility = () => {
		setShowPassword(!showTemporaryPassword);
	};
	const toggleNewPasswordVisibility = () => {
		setShowNewPassword(!showNewPassword);
	};

	const toggleRepeatedPasswordVisibility = () => {
		setShowRepeatedPassword(!showRepeatedPassword);
	};

	useEffect(() => {
		if (resetPasswordError) {
			onPushMessage({ message: resetPasswordError, type: 'error' });
			dispatch(clearResetPasswordError());
		}
	}, [resetPasswordError]);

	const playerEmail = useMemo(() => getQueryParam('email'), []);
	const ResetPasswordSchema = useMemo(
		() =>
			Yup.object().shape({
				newPassword: Yup.string()
					.min(8, t('the_password_must_be_at_least_8_characters'))
					.required(requiredField),
				newRepeatedPassword: Yup.string()
					.oneOf([Yup.ref('newPassword')], t('passwords_must_match'))
					.required(requiredField),
			}),
		[t, Yup]
	);
	const initialValues = {
		temporaryPassword: '',
		newPassword: '',
		newRepeatedPassword: '',
	};
	const handleSubmit = () => {
		dispatch(
			resetPassword({
				email: playerEmail,
				temporaryPassword: formik.values.temporaryPassword,
				newPassword: formik.values.newPassword,
			})
		);
	};

	const formik = useFormik({
		initialValues,
		validationSchema: ResetPasswordSchema,
		onSubmit: handleSubmit,
	});

	return (
		<div className={style.wrapper}>
			<Typography fontColor={'orange'} type="h5">
				{t('change_your_password')}
			</Typography>
			<p className={style.paragraph}>
				{t('enter_a_new_password_below_to_change_your_password')}
			</p>
			<div className={style.container}>
				<Input
					name="temporaryPassword"
					label={t('temporary_password')}
					type={showTemporaryPassword ? 'text' : 'password'}
					placeholder={t('temporary_password')}
					isPasswordField
					value={formik.values.temporaryPassword}
					errorMessage={formik.errors.temporaryPassword}
					hasError={
						formik.errors.temporaryPassword && formik.touched.temporaryPassword
					}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					Icon={
						<img
							className={style.icon}
							src={showTemporaryPassword ? hidePasswordIcon : showPasswordIcon}
							alt="show_password_icon"
							onClick={toggleTemporaryPasswordVisibility}
						/>
					}
				/>
				<Input
					name="newPassword"
					label={t('new_password')}
					type={showNewPassword ? 'text' : 'password'}
					placeholder={t('new_password')}
					isPasswordField
					value={formik.values.newPassword}
					errorMessage={formik.errors.newPassword}
					hasError={formik.errors.newPassword && formik.touched.newPassword}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					Icon={
						<img
							className={style.icon}
							src={showNewPassword ? hidePasswordIcon : showPasswordIcon}
							alt="show_password_icon"
							onClick={toggleNewPasswordVisibility}
						/>
					}
				/>
				<Input
					name="newRepeatedPassword"
					label={t('confirm_new_password')}
					type={showRepeatedPassword ? 'text' : 'password'}
					placeholder={t('confirm_new_password')}
					isPasswordField
					value={formik.values.newRepeatedPassword}
					errorMessage={formik.errors.newRepeatedPassword}
					hasError={
						formik.errors.newRepeatedPassword &&
						formik.touched.newRepeatedPassword
					}
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					Icon={
						<img
							className={style.icon}
							src={showRepeatedPassword ? hidePasswordIcon : showPasswordIcon}
							alt="show_password_icon"
							onClick={toggleRepeatedPasswordVisibility}
						/>
					}
				/>
				<Button className={style.button} onClick={handleSubmit}>
					{t('save')}
				</Button>
			</div>
		</div>
	);
};

export default ResetPasswordPage;
