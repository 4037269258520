import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import { sapphirePlayerApiUrl } from './constants';

const getPlayerLimits = (playerId) => {
	return handleFetch(`${sapphirePlayerApiUrl}/limits?playerId=${playerId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const createPlayerLimit = (data) => {
	return handleFetch(`${sapphirePlayerApiUrl}/limits`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify(data),
		method: 'POST',
	});
};

const deletePlayerLimit = (playerLimitId) => {
	return handleFetch(`${sapphirePlayerApiUrl}/limits/${playerLimitId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		method: 'DELETE',
	});
};

export { getPlayerLimits, createPlayerLimit, deletePlayerLimit };
