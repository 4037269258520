// components/CheckAndEmitTimer.js
import { useEffect } from 'preact/hooks';
import { useSocketContext } from '../../providers/socket-provider';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/user-slice';

const CheckAndEmitTimer = () => {
	const socket = useSocketContext();
	const user = useSelector(selectUser);

	useEffect(() => {
		const checkInterval = setInterval(() => {
			const expirationTime = localStorage.getItem('realityCheckTimer');
			const currentTime = new Date().getTime();        
			if (expirationTime && currentTime >= expirationTime) {
				if (socket && user?.id) {
					socket.emit('reality-check-reset');
					localStorage.removeItem('realityCheckTimer');
				}
			}
		}, 66000); 

		return () => clearInterval(checkInterval); 
	}, [socket, user]);

	return null; 
};

export default CheckAndEmitTimer;
