import { useCallback, useMemo, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';
import ToggleButton from '../toggle-button';
import GameDescription from '../game-description';

import trophyIcon from '../../assets/icons/trophy-icon.svg';
import hiddenIcon from '../../assets/icons/hidden-icon.svg';
import accordionDown from '../../assets/icons/accordion-chevron-down.svg';

import style from './style.module.scss';
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from './style.js';

export const Accordion = ({ game = null }) => {
	const [activeTab, setActiveTab] = useState('description');

	const { t } = useTranslation();

	const accordionItems = useMemo(
		() => [
			{
				label: t('challenges'),
				value: 'challenges',
			},
			{
				label: t('description'),
				value: 'description',
			},
		],
		[t]
	);

	const isLiveCasino = useMemo(() => !!game?.liveCasinoCategory, [game]);

	const TabContent = useMemo(() => {
		return activeTab === 'description' ? (
			<GameDescription
				isLiveCasino={isLiveCasino}
				defaultThumbnail={game?.defaultThumbnail}
				description={game?.description}
				tags={game?.tags}
			/>
		) : (
			<></>
		);
	}, [activeTab, game]);

	const handleActiveTabChange = useCallback(
		(_event, tab) => setActiveTab(tab),
		[]
	);

	return (
		<StyledAccordion>
			<StyledAccordionSummary
				expandIcon={<img src={accordionDown} />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<div className={style.titleWrapper}>
					<Typography type="st2">{game?.name}</Typography>

					<Typography fontColor="contrast-primary" type="st2">
						{game?.subVendor}
					</Typography>
				</div>

				<div className={style.accordionHeadRight}>
					<Typography fontColor="white" type="st4" display="flex">
						<img src={trophyIcon} />

						<span>3,181.20×</span>
					</Typography>

					<Typography fontColor="white" type="st4" display="flex">
						<img src={hiddenIcon} />

						<span>{t('hidden')}</span>
					</Typography>
				</div>
			</StyledAccordionSummary>

			<StyledAccordionDetails>
				<ToggleButton
					alignment={activeTab}
					items={accordionItems}
					handleChange={handleActiveTabChange}
				/>
				{TabContent}
			</StyledAccordionDetails>
		</StyledAccordion>
	);
};
