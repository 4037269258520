import { useEffect, useMemo, useState, useCallback, useRef } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';

import {
	selectLiveCasinoCategories,
	fetchLiveCasinoCategories,
} from '../../../../store/categories-slice';
import useCategoriesTab from '../../../../hooks/useCategoriesTab';

import LeaderboardWraper from '../../../../components/leaderboard-wrapper';
import ProvidersSlider from '../../../../components/providers-slider';
import ToggleButtons from '../../../../components/toggle-button';
import GamesLayout from '../../../../components/games-layout';
import AboutUs from '../../../../components/about-us';
import Slider from '../../../../components/slider';
import Search from '../../../../components/search';
import Promotions from '../../../../components/promotions';

import style from './style.module.scss';
import {
	selectLiveCasinoPromotions,
	selectLiveCasinoProviders
} from '../../../../store/content-slice';
import GamesFilterLiveLayout from "../../../../components/games-filter-live-layout";
import {selectFilterProviders} from "../../../../store/filter-slice";

const LiveCasinoHomePage = () => {
	const dispatch = useDispatch();
	const categoriesContainerRef = useRef(null);
	const categories = useSelector(selectLiveCasinoCategories);
	const selectedProviders = useSelector(selectFilterProviders);
	const [activeCategory, setActiveCategory] = useState('LOBBY');
	const { casinoCategories, tabButtons } = useCategoriesTab(categories);
	const promotions = useSelector(selectLiveCasinoPromotions);
	const providers = useSelector(selectLiveCasinoProviders);

	useEffect(() => {
		dispatch(fetchLiveCasinoCategories());
	}, []);

	const Categories = useMemo(() => {
		return Object.keys(casinoCategories).map((categoryId) => {
			const { id, name, items } = casinoCategories[categoryId];

			return (
				<Slider
					key={id}
					categoryId={id}
					title={name}
					items={items}
					backgroundProperty="thumbnail"
					isLive={true}
				/>
			);
		});
	}, [casinoCategories]);

	const Games = useMemo(() => {
		if (activeCategory === 'LOBBY') {
			return Categories;
		}

		const { name, items } = casinoCategories[activeCategory];

		return <GamesLayout title={name} games={items} />;
	}, [casinoCategories, activeCategory, Categories]);

	const handleChange = useCallback(
		(_, newActiveCategory) =>
			newActiveCategory && setActiveCategory(newActiveCategory),
		[]
	);

	return (
		<div className={style.wrapper}>
			<Promotions promotions={promotions} />

			<div className={style.searchAndToggleButtonsContainer}>
				<Search isLiveCasino searchBarClassName={style.searchBar} />

				<ToggleButtons
					items={tabButtons}
					alignment={activeCategory}
					handleChange={handleChange}
				/>
			</div>

			<div className={style.categoriesContainer} ref={categoriesContainerRef}>
				<ProvidersSlider categoriesContainerRef={categoriesContainerRef} providers={providers} />
				{!selectedProviders.length && Games}
				{!!selectedProviders.length && <GamesFilterLiveLayout />}
			</div>

			<div className={style.leaderboardWraper}>
				<LeaderboardWraper />
			</div>

			{/*<AboutUs />*/}
		</div>
	);
};

export default LiveCasinoHomePage;
