import {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'preact/hooks';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import ButtonRound from '../button-round';
import { Typography } from '../typography';
import CurrencySelect from '../currency-select';
import CurrencySelector from '../currency-selector';
import CurrencySelectorOption from '../currency-selector-option';
import { route } from 'preact-router';
import { selectUser } from '../../store/user-slice';
import {
    selectWallets,
    fetchWallets,
    selectSelectedWallet,
    fetchWalletByCurrency,
    selectBalance,
    updatePreferredCurrency,
} from '../../store/wallet-slice';

import style from './style.module.scss';
import CloseModalIcon from '../../assets/icons/close-modal-icon.svg';
import PlusIcon from '../../assets/icons/plus-circle-icon.svg';
import { getPlayerData, selectPlayerData } from '../../store/player-slice';

const HeaderWallet = () => {
    const { t } = useTranslation();
    const walletRef = useRef(null);

    const [isWalletVisible, setIsWalletVisible] = useState(false);
    const [popoverAnchorElement, setPopOverAnchorElement] = useState(null);
    const [selectedBalance, setSelectedBalance] = useState(null);

    const wallets = useSelector(selectWallets);
    const balance = useSelector(selectBalance);
    const selectedWallet = useSelector(selectSelectedWallet);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const player = useSelector(selectPlayerData);
    const isPopoverOpen = useMemo(
        () => Boolean(popoverAnchorElement),
        [popoverAnchorElement]
    );

    const currencyOptions = useMemo(() => {
        return wallets.map(({ currency, totalAmount }) => ({
            currency,
            amount: totalAmount ? totalAmount.toFixed(2) : '0.00',
        }));
    }, [wallets]);
	useEffect(() => {
        const preferredCurrency = localStorage.getItem('preferredCurrency');

        if (!preferredCurrency && player?.currency) {
            localStorage.setItem('preferredCurrency', player.currency);
            dispatch(fetchWalletByCurrency(player.currency));
        }
    }, [player, dispatch]);

    useEffect(() => {
        if (selectedWallet) {
            setSelectedBalance({
                currency: selectedWallet.currency,
                amount: selectedWallet.totalAmount.toFixed(2),
            });
        }
    }, [selectedWallet]);

    const handleOpenPopover = useCallback(() => {
        setIsWalletVisible(false);
        setPopOverAnchorElement(walletRef.current);
    }, []);

    const handleClosePopover = useCallback(() => {
        setIsWalletVisible(false);
        setPopOverAnchorElement(null);
    }, []);
	useEffect(() => {
		const preferredCurrency = localStorage.getItem('preferredCurrency');
		let selectedWallet;
	
		if (preferredCurrency) {
			selectedWallet = wallets.find(wallet => wallet.currency === preferredCurrency);
		}
	
		if (!selectedWallet && wallets.length > 0) {
			selectedWallet = wallets[0];
		}
	
		if (selectedWallet && (!selectedBalance || selectedBalance.currency !== selectedWallet.currency)) {
			setSelectedBalance({
				currency: selectedWallet.currency,
				amount: selectedWallet.totalAmount.toFixed(2),
			});
			localStorage.setItem('preferredCurrency', selectedWallet.currency);
			dispatch(fetchWalletByCurrency(selectedWallet.currency));
		}
	}, [wallets, selectedBalance, dispatch]);

    const handleSelectOption = useCallback(
        (currencyOption) => async () => {
            setSelectedBalance(currencyOption);
            handleClosePopover();
			localStorage.setItem('preferredCurrency', currencyOption.currency);
			dispatch(fetchWalletByCurrency(currencyOption.currency));
        },
        [handleClosePopover, dispatch]
    );

    const onClickWalletButton = useCallback(() => {
        setPopOverAnchorElement(null);
        setIsWalletVisible((prev) => !prev);
    }, []);

    const onClickAwayWalletWrapper = (event) => {
        if (
            event.target.closest('.jsWalletButton') ||
            event.target.closest('.jsCurrencySelect')
        ) {
            return;
        }
        if (isWalletVisible) {
            setIsWalletVisible(false);
        }
    };
    useEffect(() => {
        if (user) {
            dispatch(fetchWallets(user.id));
			dispatch(getPlayerData(user.id));
        }
    }, [user, dispatch]);	
    return (
        <>
            <div ref={walletRef} className={style.walletContainer}>
                <div className={style.circularButton} onClick={() => route('/account/wallet')}>
                    <img src={PlusIcon} alt="Add" />
                </div>
                <CurrencySelect
                    className="jsCurrencySelect"
                    selectedBalance={selectedBalance}
                    onClick={handleOpenPopover}
                />
                <ClickAwayListener onClickAway={onClickAwayWalletWrapper}>
                    <div
                        className={clsx(
                            style.walletWrapper,
                            !isWalletVisible && style.hidden
                        )}
                    >
                        <img
                            src={CloseModalIcon}
                            alt="close-icon"
                            className={style.closeIcon}
                            onClick={onClickWalletButton}
                        />
                        <Typography type="h4" fontColor="white" className={style.title}>
                            {t('balance')}
                        </Typography>
                        <div className={style.realAmount}>
                            <Typography type="h3" fontColor="white">
                                {selectedBalance?.amount || '0.00'}
                            </Typography>
                            <Typography type="h3" fontColor="yellow-100">
                                {selectedBalance?.currency || ''}
                            </Typography>
                        </div>
                        <div className={style.buttonsWrapper}>
                            <ButtonRound className={style.depositButton}>
                                <Typography type="st4" fontColor="blue-3">
                                    {t('deposit')}
                                </Typography>
                            </ButtonRound>
                            <ButtonRound className={style.withdrawButton}>
                                <Typography type="st4" fontColor="blue-3">
                                    {t('withdraw')}
                                </Typography>
                            </ButtonRound>
                        </div>
                    </div>
                </ClickAwayListener>
            </div>
            <CurrencySelector
                isOpen={isPopoverOpen}
                width={walletRef.current?.clientWidth}
                anchorElement={popoverAnchorElement}
                onClose={handleClosePopover}
            >
                {currencyOptions.map(({ amount, currency }) => (
                    <CurrencySelectorOption
                        key={currency}
                        amount={amount}
                        currency={currency}
                        onSelect={handleSelectOption({ amount, currency })}
                    />
                ))}
            </CurrencySelector>
        </>
    );
};

export default HeaderWallet;
