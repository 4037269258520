// import React, { ReactNode } from 'react';
import clsx from 'clsx';

import style from './style.module.scss';

// type Props = {
//   className?: string;
//   onClick: () => void;
//	 buttonStyle: "primary" | "ghost" | "danger"
//   type?: "button" | "submit" | "reset" | undefined;
//   disabled?: boolean;
//   children: ReactNode;
// };

// const Button: React.FC<Props> = ({
const ButtonRound = ({
									className,
									onClick,
									buttonStyle,
									type = 'button',
									disabled = false,
									children,
								}) => {
	return (
		<button
			type={type}
			className={clsx(style.button, style[buttonStyle], className)}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default ButtonRound;
