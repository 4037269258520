const enTranslations = {
	apply: 'Apply',
	search_your_game: 'Search your game',
	challenges: 'Challenges',
	description: 'Description',
	hidden: 'Hidden',
	error: 'Error',
	sign_in: 'Sign in',
	register: 'Register',
	no_records: 'No records',
	previous: 'Previous',
	next: 'Next',
	yes: 'Yes',
	no: 'No',
	no_rows: 'No rows',
	email_verification: 'Email verification',
	email_address_already_verified: 'Email address already verified!',
	email_verification_sent_successfully: 'Email verification sent successfully',
	the_confirmation_link_has_expired: 'The confirmation link has expired.',
	resend_email: 'Resend email',
	your_email_has_been_successfully_confirmed:
		'Your email has been successfully confirmed.',
	thank_you_for_verifying_your_address: 'Thank you for verifying your address!',
	please_include_an_at_the_rate_sign_in_your_email:
		'Please include an ‘@’ in your email',
	email_is_required: 'Email is required!',
	we_have_sent_an_account_recovery_email_to:
		'We have sent an account recovery email to {{email}}',
	reset_password: 'Reset password',
	email: 'Email',
	recover_password: 'Recover password',
	start_date: 'Start date',
	edge: 'Edge',
	real_play: 'Real Play',
	fun_play: 'Fun Play',
	please_log_in: 'Please log in.',
	an_error_occurred: 'An error occurred',
	settings: 'Settings',
	wallet: 'Wallet',
	transactions: 'Transactions',
	bonuses: 'Bonuses',
	kyc: 'KYC',
	player_protection: 'Player protection',
	support: 'Support',
	logout: 'Logout',
	search: 'Search',
	balance: 'Balance',
	bonus: 'Bonus',
	deposit: 'Deposit',
	withdraw: 'Withdraw',
	top_winners: 'Top Winners',
	recent_winners: 'Recent Winners',
	game: 'Game',
	user: 'User',
	time: 'Time',
	bet_amount: 'Bet Amount',
	multiplier: 'Multiplier',
	payout: 'Payout',
	casino: 'Casino',
	live_casino: 'Live Casino',
	displaying_of_games:
		'Displaying {{displayedGamesCount}} of {{totalGamesCount}} games',
	load_more: 'Load More',
	menu: 'Menu',
	promotions: 'Promotions',
	show_less: 'Show less',
	see_more: 'See more',
	password_change_successful: 'Password Change Successful',
	your_password_has_been_updated_be_sure_to_use_your_new_password_the_next_time_you_log_in:
		'Your password has been updated. Be sure to use your new password the next time you log in.',
	withdrawable: 'Withdrawable',
	read_more: 'Read more',
	providers: 'Providers',
	recommended: 'Recommended',
	most_played: 'Most played in',
	favourites: 'Favourites',
	active: 'Active',
	inactive: 'Inactive',
	expired: 'Expired',
	completed: 'Completed',
	powered_by: 'Powered by',
	all: 'All',
	enter_bonus_code: 'Enter Bonus Code',
	claim: 'Claim',
	you_currently_dont_have_any_status_bonuses:
		'You currently don’t have any {{status}}bonuses',
	bonus_name: 'Bonus name',
	bonus_type: 'Bonus type',
	bonus_amount: 'Bonus amount',
	expires_in: 'Expires in',
	terms_and_conditions: 'Terms and Conditions',
	daily: 'Daily',
	weekly: 'Weekly',
	monthly: 'Monthly',
	minimum_amount_is_10_EUR: 'Minimum amount is 10 EUR',
	value_must_be_a_whole_number: 'Value must be a whole number.',
	value_must_be_between_1_and_24: 'Value must be between 1 and 24',
	limit: 'Limit',
	amount: 'Amount',
	currency: 'Currency',
	period: 'Period',
	enter_password_to_confirm_your_action:
		'Enter password to confirm your action',
	confirm: 'Confirm',
	number_h: '{{number}}h',
	one_week: '1 week',
	one_month: '1 month',
	number_months: '{{number}} months',
	permanent: 'Permanent',
	no_limit_defined: 'No limit defined',
	limit_type: 'Limit type',
	time_limit: 'Time limit',
	current_limit: 'Current limit',
	remaining_amount: 'Remaining amount',
	started_on: 'Started on',
	pending: 'Pending',
	remove_Limit: 'Remove Limit',
	delete_limit: 'Delete limit',
	ending_on: 'Ending on',
	deposit_limits: 'Deposit Limits',
	betting_limits: 'Betting Limits',
	loss_limits: 'Loss Limits',
	take_a_break: 'Take A Break',
	self_exclusion: 'Self Exclusion',
	communication: 'Communication',
	email_marketing: 'E-mail Marketing',
	i_want_to_receive_marketing_communications_via_email:
		'I want to receive marketing communications via e-mail',
	sms_marketing: 'SMS Marketing',
	i_want_to_receive_marketing_communications_via_sms:
		'I want to receive marketing communications via SMS',
	third_party_marketing: '3rd Party Marketing',
	i_want_to_receive_marketing_from_3rd_parties:
		'I want to receive marketing from 3rd parties',
	street_field_is_mandatory: 'Street field is mandatory.',
	city_field_is_mandatory: 'City field is mandatory.',
	update_successful: 'Update successful',
	saved: 'Saved',
	the_password_must_be_at_least_8_characters:
		'The password must be at least 8 characters.',
	password_is_mandatory: 'Password is mandatory.',
	passwords_must_match: 'Passwords must match',
	first_name: 'First name',
	last_name: 'Last name',
	country_code: 'Country Code',
	phone_number: 'Phone Number',
	vip_status: 'VIP Status',
	city: 'City',
	address: 'Address',
	id_type: 'ID type',
	id_number: 'ID number',
	password: 'Password',
	old_password: 'Old Password',
	new_password: 'New Password',
	confirm_new_password: 'Confirm New Password',
	are_you_sure_you_want_to_change_your_details:
		'Are you sure you want to change your details?',
	are_you_sure_you_want_to_change_your_password:
		'Are you sure you want to change your password?',
	save: 'Save',
	personal_details: 'Personal Details',
	profile: 'Profile',
	all_transactions: 'All Transactions',
	deposit_history: 'Deposit History',
	withdrawal_history: 'Withdrawal History',
	transaction_history: 'Transaction History',
	gaming_history: 'Gaming History',
	amount_of_deposit: 'Amount of deposit',
	amount_of_withdrawal: 'Amount of withdrawal',
	payment_method: 'Payment method',
	show_more: 'Show more',
	the_deposit_request_cannot_be_processed_at_the_moment:
		'The deposit request cannot be processed at the moment',
	the_withdrawal_request_cannot_be_processed_at_the_moment:
		'The withdrawal request cannot be processed at the moment',
	withdrawal: 'Withdrawal',
	my_account: 'My account',
	recent: 'Recent',
	change_your_password: 'Change your password',
	enter_a_new_password_below_to_change_your_password:
		'Enter a new password below to change your password',
	temporary_password: 'Temporary password',
	invalid_date: 'Invalid date.',
	invalid_name: 'Invalid name.',
	mr: 'Mr.',
	mrs: 'Mrs.',
	mx: 'Mx.',
	your_username_must_be_at_least_3_characters:
		'Your username must be at least 3 characters.',
	your_username_cannot_exceed_14_characters:
		'Your username cannot exceed 14 characters',
	no_spaces_allowed_in_username: 'No spaces allowed in username.',
	please_enter_a_valid_email_address: 'Please enter a valid email address',
	your_password_must_be_at_least_8_characters:
		'Your password must be at least 8 characters',
	your_password_must_be_at_least_8_characters_with_at_least_one_capital_and_one_lower_case_and_one_digit_and_one_special_character:
		'Your password must be at least 8 characters with at least one capital and one lower case and one digit and one special character',
	day_should_be_a_number: 'Day should be a number',
	month_should_be_a_number: 'Month should be a number',
	year_should_be_a_number: 'Year should be a number',
	invalid_number: 'Invalid number',
	username: 'Username',
	title: 'Title',
	date_of_birth: 'Date of birth',
	date: 'Date',
	month: 'Month',
	year: 'Year',
	all_time: 'All Time',
	last_24_hours: 'Last 24 Hours',
	last_week: 'Last Week',
	last_month: 'Last Month',
	last_3_months: 'Last 3 Months',
	custom: 'Custom',
	export: 'Export',
	type: 'Type',
	status: 'Status',
	transaction_number: 'Transaction Number',
	actions: 'Actions',
	no_transactions: 'No transactions to display',
	you_need_to_be_at_least_18_years_old: 'You need to be at least 18 years old',
	this_field_is_required: 'This field is required!',
	your_username_must_be_3_14_characters_long:
		'Your username must be 3-14 characters long.',
	language: 'Language',
	country: 'Country',
	postal_code: 'Postal code',
	you_must_accept_the_terms_of_service_to_proceed:
		'You must accept the Terms of Service to proceed',
	please_read_the_terms_and_conditions_in_full_and_scroll_to_the_end_to_accept:
		'Please read the terms and conditions in full and scroll to the end to accept',
	i_have_read_and_agree_to_the_terms_and_conditions:
		'I have read and agree to the terms and conditions',
	play_now: 'Play now',
	registration_successful: 'Registration successful',
	your_registration_is_underway_please_check_your_inbox_for_an_email_with_a_confirmation_link_you_need_to_verify_your_email_to_unlock_all_the_excitement_ahead:
		'Your registration is underway. Please check your inbox for an email with a confirmation link. You need to verify your email to unlock all the excitement ahead',
	step_1_of_3_fill_out_your_details: 'Step 1/3: Fill out your details',
	continue: 'Continue',
	step_2_of_3_fill_out_your_details: 'Step 2/3: Fill out your details',
	step_3_of_3_read_and_accept_the_terms_and_conditions:
		'Step 3/3: Read and accept the terms and conditions',
	create_an_account: 'Create an Account',
	already_have_an_account: 'Already have an account?',
	recent_searches: 'Recent Searches',
	clear_search: 'Clear Search',
	sign_In: 'Sign In',
	email_or_username: 'Email or username',
	forgot_password: 'Forgot password',
	dont_have_an_account: 'Don’t have an account?',
	register_an_account: 'Register an Account',
	no_results_found: 'No results found',
	search_requires_at_least_3_characters:
		'Search requires at least 3 characters.',
	username_is_required: 'Username is required',
	password_is_required: 'Password is required',
	responsibleGamingContent: `<h5>Keeping it a game, staying safe</h5>
			<p>
				To leverage cutting edge technology to offer the optimum experience to
				those who trust our products and services for their entertainment.
			</p>
			<p>
				And this is why Responsible Gaming lies within our organisation's core
				DNA, as we are fully committed to offering you a fun and exciting
				experience that can be no less than safeguarded against the risks
				associated with immoderate gambling.
			</p>
			<p>
				Welcome you to our Responsible gambling page, where you can get all the
				right information on how we can keep together your game responsible and
				your fun... intact!
			</p>
			<p>
				If you think that you, or any of your close ones, could be becoming
				addicted or are in any way losing control over gambling, you should seek
				advice immediately from{' '}
				<a href="https://www.drugsinfo-bg.org/spisak-s-mesta-za-pomosht/spetsialisti/">
					https://www.drugsinfo-bg.org/spisak-s-mesta-za-pomosht/spetsialisti/
				</a>
				.
			</p>
			<h5>Responsible Operator</h5>
			<p>
				We do our best to provide a safe and secure gambling environment, make
				sure that you can keep having an enjoyable and thrilling experience. And
				as the vast majority of our customers enjoy our services frequently in
				an entertaining and responsible manner, we believe that we are in the
				right direction, taking these steps below:
			</p>
			<p>
				Innovation - Highly advanced and innovative tools are used, making sure
				that our customers benefit from a safe and secure gambling experience,
				as:
			</p>
			<p>
				RG-AID, our in-house developed AI detection system that identifies
				customers prone to developing an immoderate playing pattern in the
				future, if they do not receive RG consultation. RG Customer Support -
				Our RG-trained Customer Support agents, our Responsible gambling
				Ambassadors, are always available to exclusively handle all RG-related
				communications, providing consultation for staying in control, making
				sure that our customers know when to stop and how to continue to use our
				product in a responsible manner, keeping it a game at all times.
			</p>
			<p>First thing’s first, self-knowledge is the key to self-control</p>
			<p>
				Before taking all the right steps, let’s take a step back and really
				understand our own selves. Check this self-assessment tool so that you
				can see where you should be paying extra attention to keeping your game
				safe.
			</p>
			<p>
				If you are concerned that gambling has had a negative impact on your or
				someone else's life, then the following questions may help you find out
				more about your gambling behavior and whether it is problematic or not.
			</p>
			<p>Have you ever neglected your studies or work due to gambling?</p>
			<p>Do you gamble to pass time or out of boredom?</p>
			<p>
				Do you play alone and for long periods of time? Have others ever
				criticized your gambling?
			</p>
			<p>
				Have you ever lost interest in family, friends or hobbies due to
				gambling?
			</p>
			<p>
				Has it ever happened to you to lie, borrow or even steal money to cover
				gambling losses?
			</p>
			<p>
				Has it ever happened to you to lie, borrow or even steal money for
				gambling?
			</p>
			<p>
				Are you reluctant to spend money allocated for gambling on other goals?
			</p>
			<p>Do you gamble until you lose all your money?</p>
			<p>
				After losing, do you feel the need to play as soon as possible to cover
				losses?
			</p>
			<p>
				Are there times that gambling follows after arguments, frustrations or
				disappointments? Does gambling cause you depression or suicidal
				thoughts?
			</p>
			<p>
				Do you gamble to pay for your debts or solve your financial problems?
			</p>

			<p>
				Has it ever happened to you to lie about the amount and time spent on
				gambling?
			</p>

			<p>
				The more questions that you answer 'yes' to, the more likely it is that
				you are having difficulties with your gambling.
			</p>

			<p>
				For additional advice in case of a loss of control, we recommend you to
				contact a Bulgarian gambling addiction therapist from one of the
				suggested contacts in Gambling addiction therapists Bulgaria and inform
				our Customer Support about your experiences, so that they can guide and
				assist you.
			</p>

			<h5>Underage gambling</h5>

			<p>
				Please note that it is illegal for anyone under the age of 18 (the age
				limit for Bulgarian residents is 18 years of age) to open an account or
				to gamble on the Website. We take very serious measures in this
				direction. We carry out age verification checks on all customers who use
				payment methods which are available to under 18's. Additionally we
				randomly perform age verification checks on customers using other
				payment methods.
			</p>

			<p>
				PLEASE NOTE THAT ANYONE UNDER THE AGE OF 18 FOUND TO BE USING THIS SITE
				WILL HAVE ANY WINNINGS FORFEITED AND MAY ALSO BE REPORTED TO THE POLICE.
			</p>

			<h5>Parental filtering Systems</h5>

			<p>
				If you share your computer with friends or family who are under the
				legal age to register or bet on our site, please consider parental
				filtering solutions such as
				<a href="www.netnanny.com">www.netnanny.com</a> and
				<a href="www.cyberpatrol.com">www.cyberpatrol.com</a>.
			</p>

			<h5>Here are some great tips for responsible gambling</h5>

			<p>
				Our products are designed to offer a fun and thrilling experience so
				that you can have a great time using them. Here are some great tips to
				keep your game responsible.
			</p>

			<p>
				Play for fun, keep it a game - Fun is the keyword here and placing a bet
				for entertainment that you can afford is the only way to it; placing a
				bet too many to break even, make money or any other motive than just
				leisure or fun, is definitely not. Make sure you always avoid gambling
				when experiencing a bad mood, having feelings of frustrations, anger,
				sadness or boredom, to protect yourself against irrational and
				irresponsible gambling.
			</p>

			<p>
				To know yourself, is to know when to stop - If you are feeling unsure if
				you are at risk of developing any addiction or if you are concerned
				about playing too much, use our self-assessment questions above so that
				you can see where you should be paying extra attention to keeping your
				game safe and do not hesitate to reach our Customer Support to get some
				safe play advice.
			</p>

			<p>
				Keep your play in control with our RG tools - Always choose to use a
				limit that works best with your gambling habits; you can test out the RG
				tools here by yourself or to make use of our free RG tool consultation
				by our expert Customer Support agents.
			</p>

			<p>
				Keep an eye on the time spent - Time flies when you are having fun! And
				that is great, just make sure that you decide upfront how much time you
				are comfortable spending on our site per session, as to make sure that
				you use our services as intended, for fun! Never spend your precious
				time on gambling when your agenda does not permit you to or when you
				find it would affect your work, relationships, mental or physical health
				or family bands or relationships.
			</p>

			<p>
				Keep an eye on money spent - Make sure that you decide upfront how much
				money you are willing to spend on a maximum and that you calculate how
				much you can afford to lose. Make a budget and stick to it. Make use of
				our RG tools to set a limit or contact our expert Customer Support
				agents for a free consultation on RG tools and on how to best calculate
				your budget.
			</p>

			<p>
				Stay social - Gamble for fun and meet your friends or family no less
				than you would if you were not gambling. We surely do try our best to
				take good care of you, but no one knows you like your own loved ones. Do
				not alienate yourself to them for the sake of gambling.
			</p>

			<p>
				Do not play while intoxicated - gambling is to be practiced with calm
				and controlled decisions and any intoxication will decrease your ability
				to make reasonable decisions. Use our RG tools to make sure that you can
				take a break before taking part in any intoxicating activity, to avoid
				uncontrolled losses.
			</p>

			<p>
				Play responsibly and make sure that you understand what that means - If
				you think that you have a strategy that helps you win, think twice, as
				gambling is intended to be used as a pastime activity ...and that’s
				about it. Feeling lucky? Or won something? That is great! But don’t quit
				your job just yet, gambling involves winning and losing. That is why it
				is a ‘gamble’ that you take. Make sure to gamble as a fun pastime
				activity, but never replace gambling with your source of income. It is
				not a way of making a living, any winnings are a nice extra but your
				luck is not guaranteed. There is no strategy or lucky charm to guarantee
				you any winnings, so make sure to keep it a game!
			</p>`,
	rtp_title: 'RTP and Volatility',
	rtp_text: `<h5>Return to Player</h5>
	
	<p>Return to Player (RTP) for a game is the expected percentage of bets that game will return to the player over a period of time.</p>
	
	<p>But, it is important to understand that the RTP percentage is an average (arithmetic mean) value that is achieved over a significantly large number of games played, not every time the game is played.</p>
	
	<p>The average RTP percentage is usually measured over a large number of hands or spins played.</p>

  <p>For example, if the game shows an 85% RTP, it is not expected that the player would win an average of 85 currency units for every 100 currency units wagered in a gaming session.</p>

  <p>The RTP can vary greatly in either direction due to statistical variability and differs from game to game.</p>
  
  <h5>Volatility</h5>
  
  <p>Volatility - often referred to as variance - is provided by the Game Provider. This is used to inform the player how often to expect the slot to pay and how big that win will be.</p>
  
  <p>A low-volatility spinner will give frequent wins for small to medium amounts, while a high-volatility spinner will give much larger wins at very erratic intervals.</p>
  `,
	new_games: 'New Games',
	special_for_you: 'Special for you',
	jackpot_games: 'Jackpot Games',
	all_games: 'All Games',
	table_games: 'Table Games',
	live_games: 'Live Games',
	roulette: 'Roulette',
	blackjack: 'Blackjack',
	total_amount: 'Total Amount',
	round_id: 'Round ID',
	bet: 'Bet',
	win: 'Win',
	no_timeout_defined: 'No timeout defined',
	tournaments: 'Tournaments',
	AF: 'Afghanistan',
	AL: 'Albania',
	DZ: 'Algeria',
	AD: 'Andorra',
	AO: 'Angola',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BT: 'Bhutan',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BR: 'Brazil',
	BN: 'Brunei',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	CV: 'Cabo Verde',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	CF: 'Central African Republic',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CO: 'Colombia',
	KM: 'Comoros',
	CG: 'Congo (Congo-Brazzaville)',
	CR: 'Costa Rica',
	HR: 'Croatia',
	CU: 'Cuba',
	CY: 'Cyprus',
	CZ: 'Czechia (Czech Republic)',
	CD: 'Democratic Republic of the Congo',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	SZ: "Eswatini (fmr. 'Swaziland')",
	ET: 'Ethiopia',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GR: 'Greece',
	GD: 'Grenada',
	GT: 'Guatemala',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HT: 'Haiti',
	HN: 'Honduras',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran',
	IQ: 'Iraq',
	IE: 'Ireland',
	IL: 'Israel',
	IT: 'Italy',
	JM: 'Jamaica',
	JP: 'Japan',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: 'Laos',
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MR: 'Mauritania',
	MU: 'Mauritius',
	MX: 'Mexico',
	FM: 'Micronesia',
	MD: 'Moldova',
	MC: 'Monaco',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar (formerly Burma)',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	KP: 'North Korea',
	MK: 'North Macedonia',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PS: 'Palestine State',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PL: 'Poland',
	PT: 'Portugal',
	QA: 'Qatar',
	RO: 'Romania',
	RU: 'Russia',
	RW: 'Rwanda',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome and Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	KR: 'South Korea',
	SS: 'South Sudan',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan',
	SR: 'Suriname',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syria',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	US: 'United States of America',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VA: 'Vatican City',
	VE: 'Venezuela',
	VN: 'Vietnam',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
};

export default enTranslations;
