import { useDispatch, useSelector } from 'react-redux';

import { selectUserSession } from '../../../../store/authentication-slice';
import commonClasses from '../../style.module.scss';
import { BonusStatus } from '../../../../constants/enums';
import { selectPlayerData } from '../../../../store/player-slice';
import BonusList from '../../../../components/bonus-list';
import BonusClaimForm from '../../../../components/bonus-claim-form';

const ExpiredBonuses = () => {
	const dispatch = useDispatch();
	const player = useSelector(selectPlayerData);
	const user = useSelector(selectUserSession);

	return (
		<>
			<div className={commonClasses.tabContent}>
				<BonusClaimForm user={user} dispatch={dispatch} />
			</div>

			{player && (
				<div className={commonClasses.tabContent}>
					<BonusList bonuses={player.bonuses} status={BonusStatus.EXPIRED} />
				</div>
			)}
		</>
	);
};

export default ExpiredBonuses;
