import { Modal } from '../../../../components/modal';
import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';

import style from './style.module.scss';

export const ConfirmationModal = ({
	title = '',
	isModalOpen = false,
	onCloseModal = () => {},
	onConfirmationClick = () => {},
}) => {
	return (
		<Modal
			title={title}
			open={isModalOpen}
			className={style.modal}
			titleWrapperClassName={style.modalTitleWrapper}
			onCloseModal={onCloseModal}
		>
			<div className={style.buttonsWrapper}>
				<ButtonRound onClick={onConfirmationClick}>
					<Typography type="btn3" fontColor="blue-3">
						Yes
					</Typography>
				</ButtonRound>

				<ButtonRound
					buttonType="secondary"
					className={style.noButton}
					onClick={onCloseModal}
				>
					<Typography type="btn3" fontColor="green">
						No
					</Typography>
				</ButtonRound>
			</div>
		</Modal>
	);
};
