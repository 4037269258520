const periods = [
	{
		value: '1h',
		name: '1 hour',
	},
	{
		value: '24h',
		name: '24 hours',
	},
	{
		value: '7d',
		name: '7 days',
	},
	{
		value: '30d',
		name: '30 days',
	},
	{
		value: '3mo',
		name: '3 months',
	},
];

const getDateTime = (dateToFormat) => {
	const date = new Date(dateToFormat);

	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString().substring(2);
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${day}/${month}/${year} - ${hours}:${minutes}`;
};

export { periods, getDateTime };
