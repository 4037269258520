import clsx from 'clsx';
import SideMenuItem from '../side-menu-item';

import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSession } from '../../store/authentication-slice';

const CategoryMenu = ({
	categoryMenuItems = [],
	menuCategoryClass = '',
	isAuthenticated = false,
	expandedSideMenuItemClass = '',
	isMenuOpen,
	collapsedSideMenuItemClass = '',
	onToggleSidebarVisibility = () => {},
}) => {
	const dispatch = useDispatch()
	const userSession = useSelector(selectUserSession);

	return categoryMenuItems.map(({ categoryName, items }, index) => (
		<div
			key={`${categoryName}-${index}`}
			className={clsx(style.menuCategory, menuCategoryClass)}
		>
			{items.map(({ id, requiresAuth, ...props }) => (
				<SideMenuItem
					key={id}
					isDisabled={!isAuthenticated && requiresAuth}
					expandedSideMenuItemClass={expandedSideMenuItemClass}
					collapsedSideMenuItemClass={collapsedSideMenuItemClass}
					onToggleSidebarVisibility={onToggleSidebarVisibility}
					isMenuOpen={isMenuOpen}
					dispatch={dispatch}
					userSession={userSession}
					{...props}
				/>
			))}
		</div>
	));
};

export default CategoryMenu;
