import style from "./style.module.scss";
import BackIcon from "../../assets/icons/back-icon.svg";
import FavouriteFilledIcon from "../../assets/icons/favourite-icon-filled.svg";
import FavouriteIcon from "../../assets/icons/favourite-icon.svg";
import ButtonRound from "../button-round";
import {Typography} from "../typography";
import {addGameToFavourites, removeGameFromFavourites, selectFavouriteGames} from "../../store/user-slice";
import {useDispatch, useSelector} from "react-redux";
import {useViewport} from "../../hooks/useViewport";
import {useMemo} from "preact/hooks";

const GameCardInfo = ({game, onClickGame, setShowInfo, setAddedHoverAnimation}) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useViewport();
  const favouriteGames = useSelector(selectFavouriteGames);
  const isFavouriteGame = useMemo(
    () => favouriteGames?.find(favGame => favGame.externalGameId === game.gameId?.toString() || favGame.externalGameId === game.externalGameId),
    [favouriteGames, game]
  );

  const handleOnBackClick = () => {
    setShowInfo(false);
    setTimeout(() => {
      setAddedHoverAnimation(true);
    }, 1500);
  };

  const handleOnFavClick = () => {
    if (isFavouriteGame) {
      dispatch(removeGameFromFavourites({ gameId: game.gameId?.toString() || game.externalGameId }));
    } else {
      dispatch(addGameToFavourites({ gameId: game.gameId?.toString() || game.externalGameId }));
    }
  };

  return (
    <div className={style.infoCard}>
      <div className={style.actions}>
        <img
          src={BackIcon}
          className={style.backBtn}
          alt="back"
          onClick={handleOnBackClick}
        />
        <img
          className={style.favouriteIcon}
          onClick={handleOnFavClick}
          src={isFavouriteGame ? FavouriteFilledIcon : FavouriteIcon}
          alt="favourite-icon"
        />
      </div>
      <div className={style.gameTitle}>{game.name}</div>
      <div className={style.gameInfoContainer}>
        {!isMobileScreen && (
          <>
            {game.reels && <div className={style.gameInfo}>
              <span>Reels</span>
              <span>{game.reels}</span>
            </div>}
            {game.lines && <div className={style.gameInfo}>
              <span>Lines</span>
              <span>{game.lines}</span>
            </div>}
          </>
        )}
        {isMobileScreen && (
          <p className={style.volatilityText}>{game.volatility}</p>
        )}
        {game.rtp && <div className={style.gameInfo}>
          <span>RTP</span>
          <span>{game.rtp}</span>
        </div>}
      </div>
      <div className={style.volatilitySection}>
        {!isMobileScreen && (
          <p className={style.volatilityText}>{game.volatility}</p>
        )}
        <ButtonRound
          onClick={onClickGame}
          className={style.playButton}
          buttonStyle={'primary'}
        >
          <Typography type="btn3" fontColor="blue-grey">
            Play
          </Typography>
        </ButtonRound>
      </div>
      <div className={style.gameTagsContainer}>
        {!!game.tags?.slice(0, isMobileScreen ? 2 : 3).length && game.tags?.slice(0, isMobileScreen ? 2 : 3).map(tag => <span key={tag} className={style.gameTag}>{tag}</span>)}
      </div>
      <a
        href="/info/rtp-volatility"
        target={'_blank'}
        className={style.explainInfo}
      >
        RTP & Volatility Explained
      </a>
    </div>
  )
}

export default GameCardInfo;