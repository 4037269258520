import clsx from 'clsx';
import { useMemo, useState, useEffect, useCallback } from 'preact/hooks';

import { periods, getDateTime } from './helper';
import { useBankingTransactions } from './useBankingTransactions';

import style from './style.module.scss';

const useBankingTransactionsPage = ({ type }) => {
	const [filteredBankingTransactions, setFilteredBankingTransactions] =
		useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState(periods[2].value);

	const {
		error,
		bankingTransactions,
		areBankingTransactionsLoading,
		areBankingTransactionsReceived,
	} = useBankingTransactions({ type });

	const basicTableColumns = useMemo(() => {
		return [
			{
				header: 'Date',
				field: 'completed',
				renderCell: (value) => (
					<span className={style.fontWeightBold}>{getDateTime(value)}</span>
				),
			},
			{
				align: 'center',
				header: 'Amount',
				field: 'realAmount',
				headerAlign: 'center',
				renderCell: (value) => (
					<span className={style.fontWeightBold}>{value}$</span>
				),
			},
			{
				field: 'transId',
				align: 'center',
				headerAlign: 'center',
				header: 'Transaction number',
			},
			{
				field: 'status',
				align: 'center',
				header: 'Status',
				headerAlign: 'center',
				renderCell: (value) => (
					<span
						className={clsx(
							value === 'Success' && style.successfulStatus,
							value === 'Failed' && style.declinedStatus,
							style.status
						)}
					>
						{value}
					</span>
				),
			},
		];
	}, []);

	const filterBankingTransactions = useCallback(
		(period) => {
			let filteredRecords = [];

			const now = new Date();
			const hour = 60 * 60 * 1000;
			const day = hour * 24;
			const month = day * 30;

			let periodInMilliseconds = 0;

			switch (period) {
				case '1h': {
					periodInMilliseconds = hour;
					break;
				}
				case '24h': {
					periodInMilliseconds = day;
					break;
				}
				case '7d': {
					periodInMilliseconds = day * 7;
					break;
				}
				case '30d': {
					periodInMilliseconds = month;
					break;
				}
				case '3mo': {
					periodInMilliseconds = month * 3;
					break;
				}
			}

			filteredRecords = bankingTransactions.filter((bankingTransaction) => {
				const bankingTransactionCompletedDate = new Date(
					bankingTransaction.completed
				);
				const difference =
					Math.abs(bankingTransactionCompletedDate - now) /
					periodInMilliseconds;
				return difference <= 1;
			});

			setFilteredBankingTransactions(filteredRecords);
		},
		[bankingTransactions]
	);

	const onChangePeriodSelect = useCallback(
		(event) => {
			if (!bankingTransactions) {
				return;
			}

			const newSelectedPeriod = event.target.value;
			setSelectedPeriod(newSelectedPeriod);

			filterBankingTransactions(newSelectedPeriod);
		},
		[bankingTransactions, filterBankingTransactions]
	);

	const onClickPreviousButton = useCallback(() => {
		console.log('in on previous button click');
	}, []);

	const onClickNextButton = useCallback(() => {
		console.log('in on next button click');
	}, []);

	useEffect(() => {
		if (!bankingTransactions) {
			return;
		}

		filterBankingTransactions(selectedPeriod);
	}, [selectedPeriod, bankingTransactions, filterBankingTransactions]);

	return {
		error,
		selectedPeriod,
		basicTableColumns,
		onClickNextButton,
		bankingTransactions,
		onChangePeriodSelect,
		onClickPreviousButton,
		filteredBankingTransactions,
		areBankingTransactionsLoading,
		areBankingTransactionsReceived,
	};
};

export { useBankingTransactionsPage };
