import ToggleButtons from "../toggle-button";
import {route} from "preact-router";
import useCategoriesTab from "../../hooks/useCategoriesTab";
import {useCallback, useState} from "preact/hooks";

const CategoryTabs = ({categories, categoryId}) => {
  const [activeCategory, setActiveCategory] = useState(categoryId ? categoryId : '');
  const { tabButtons } = useCategoriesTab(categories);

  const handleChange = useCallback(
    (_, newActiveCategory) => {
      newActiveCategory && setActiveCategory(newActiveCategory);
      route(`/casino/game-categories/${newActiveCategory}`);
    },
    []
  );

  return (
    <ToggleButtons
      items={tabButtons}
      alignment={activeCategory}
      handleChange={handleChange}
    />
  )
}

export default CategoryTabs;