import { useEffect, useMemo } from 'preact/hooks';

import { Typography } from '../../../../components/typography';

import { RestrictionPanel } from '../restriction-panel';
import { AddRestrictionPanel } from '../add-restriction-panel';

import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSession } from '../../../../store/authentication-slice';
import { getPlayerTimeout, selectTimeout } from '../../../../store/timeout-slice';

export const Timeout = () => {
  const dispatch = useDispatch();

  const userSession = useSelector(selectUserSession);
  const timeout = useSelector(selectTimeout);

  useEffect(() => {
    if (userSession)
		  dispatch(getPlayerTimeout(userSession.id));
	}, [dispatch, userSession]);

	return (
		<>
			<Typography type="btn1" fontColor="white">
				Timeout
			</Typography>

			<AddRestrictionPanel isButtonDisabled={timeout?.length} playerId={userSession?.id} type='timeout' />

      { timeout ?
        <div className={style.restrictionPanelWrapper}>
          <RestrictionPanel
            data={timeout}
            removeButtonText="Remove timeout"
            confirmationModalTitle="Are you sure you want to delete this timeout?"
            type='timeout'
            playerId={userSession?.id}
          />
        </div>  : null
      }
		</>
	);
};
