import { useMemo } from 'preact/hooks';

import clsx from 'clsx';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, getCurrentUrl, Router as PreactRouter } from 'preact-router';

import WalletPage from './wallet-page';
import BonusesPage from './bonuses-page';
import ProfilePage from './profile-page';
import Redirect from '../../components/redirect';
import TransactionsPage from './transactions-page';
import { pathnames } from '../../constants/pathnames';
import { Typography } from '../../components/typography';
import PlayerProtectionPage from './player-protection-page';

import style from './style.module.scss';

import MyAccountIcon from '../../assets/icons/my-account-orange.svg';

const {
	account: {
		baseWalletPath,
		restWalletPath,
		baseProfilePath,
		restProfilePath,
		baseBonusesPath,
		restBonusesPath,
		baseTransactionsPath,
		restTransactionsPath,
		basePlayerProtectionPath,
		restPlayerProtectionPath,
	},
} = pathnames;

const MyAccountPage = () => {
	const { t } = useTranslation();
	const currentUrl = getCurrentUrl();

	const navigationItems = useMemo(
		() => [
			{
				text: t('profile'),
				url: baseProfilePath,
			},
			{
				text: t('wallet'),
				url: baseWalletPath,
			},
			{
				text: t('transactions'),
				url: baseTransactionsPath,
			},
			{
				text: t('bonuses'),
				url: baseBonusesPath,
			},
			{
				text: t('player_protection'),
				url: basePlayerProtectionPath,
			},
		],
		[t]
	);

	return (
		<div className={style.pageWrapper}>
			<div className={style.titleWrapper}>
				<img src={MyAccountIcon} />

				<Typography type="h6" fontColor="white">
					{t('my_account')}
				</Typography>
			</div>

			<div className={style.contentWrapper}>
				<Card className={style.navigation}>
					{navigationItems.map((item, index) => (
						<Link
							key={`${item.text.toLowerCase().replace(/\s/g, '')}-${index}`}
							href={item.url}
							className={clsx(
								currentUrl.includes(item.url) && style.activeNavItem
							)}
						>
							{item.text}
						</Link>
					))}
				</Card>

				<Card className={style.content}>
					<PreactRouter>
						<ProfilePage path={restProfilePath} />

						<WalletPage path={restWalletPath} />

						<TransactionsPage path={restTransactionsPath} />

						<BonusesPage path={restBonusesPath} />

						<PlayerProtectionPage path={restPlayerProtectionPath} />

						<Redirect path="/account/:rest*" to={restProfilePath} />
					</PreactRouter>
				</Card>
			</div>
		</div>
	);
};

export default MyAccountPage;
