import style from './style.module.scss';
import { Typography } from '../../components/typography';
import { getCurrentUrl } from 'preact-router';
import { tournamentsMockData } from '../tournaments-page/tournaments-mock-data';
import { formatCurrency } from '../../utils/currency';
import Countdown from 'react-countdown';
import clsx from 'clsx';
import { BasicTable } from '../../components/basic-table/basic-table';
import { useMemo } from 'preact/hooks';
import CrownIconGold from '../../assets/icons/crown-icon-gold.svg';
import CrownIconGrey from '../../assets/icons/crown-icon-grey.svg';
import CrownIconBronze from '../../assets/icons/crown-icon-bronze.svg';
import TournamentDetailsStaticText from './partials/tournament-details-static-text';

const TournamentDetailsPage = () => {
	const id = getCurrentUrl().split('/').pop();
	const tournament = tournamentsMockData.find(
		(tournament) => tournament.id === +id
	);

	const basicTableColumns = useMemo(() => {
		return [
			{
				header: 'Rank',
				field: 'rank',
				renderCell: (value) => {
					if (value === 1) {
						return (
							<div className={style.winnerIconContainer}>
								<Typography type="st3">{value}</Typography>
								<img src={CrownIconGold} />
							</div>
						);
					} else if (value === 2) {
						return (
							<div className={style.winnerIconContainer}>
								<Typography type="st3">{value}</Typography>
								<img src={CrownIconGrey} />
							</div>
						);
					} else if (value === 3) {
						return (
							<div className={style.winnerIconContainer}>
								<Typography type="st3">{value}</Typography>
								<img src={CrownIconBronze} />
							</div>
						);
					}
					return value;
				},
				isVisible: true,
			},
			{
				header: 'Username',
				field: 'username',
				isVisible: true,
			},
			{
				header: 'Points',
				field: 'points',
				isVisible: true,
			},
			{
				header: 'Prize',
				field: 'prize',
				renderCell: (value) => formatCurrency(value),
				isVisible: true,
			},
		];
	}, []);

	if (!tournament) {
		return null;
	}

	return (
		<div className={style.tournamentContainer}>
			{/* <img className={style.image} src={promotion.image} alt={promotion.title} /> */}
			<div className={style.leftSection}>
				<Typography type="h3">{tournament?.name}</Typography>
				<Typography type="st3">{tournament?.description}</Typography>
				<div className={style.rowContainer}>
					<Typography type="st3">Current participants:</Typography>
					<Typography type="st3">{tournament?.current_participants}</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="st3">Min. participants:</Typography>
					<Typography type="st3">{tournament?.min_participants}</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="st3">Entry fee:</Typography>
					<Typography type="st3">
						{formatCurrency(tournament?.entry_fee)}
					</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="st3">Min. bet:</Typography>
					<Typography type="st3">
						{formatCurrency(tournament?.min_bet)}
					</Typography>
				</div>
				<div className={style.rowContainer}>
					<Typography type="st3">Max. bet:</Typography>
					<Typography type="st3">
						{formatCurrency(tournament?.max_bet)}
					</Typography>
				</div>
				<div className={clsx(style.rowContainer, style.countDown)}>
					<Typography type="st3">Ends in: </Typography>
					<Typography type="st3">
						<Countdown date={Date.now() + Math.random() * 1000000000} />
					</Typography>
				</div>
				<Typography type="h3">{formatCurrency(tournament?.payout)}</Typography>
				<TournamentDetailsStaticText tournament={tournament} style={style} />
			</div>
			<div className={style.rightSection}>
				<Typography type="h3" className={style.leaderboardTitle}>
					Leaderboard
				</Typography>
				<BasicTable
					columns={basicTableColumns}
					rows={tournament.leaderboard}
					withPagination={false}
				/>
			</div>
		</div>
	);
};

export default TournamentDetailsPage;
