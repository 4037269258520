import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import { sapphirePlayerApiUrl } from './constants';

const getPlayerTimeout = (playerId) => {
	return handleFetch(`${sapphirePlayerApiUrl}/timeouts?playerId=${playerId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const createPlayerTimeout = (data) => {
	return handleFetch(`${sapphirePlayerApiUrl}/timeouts`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify(data),
		method: 'POST',
	});
};

const deletePlayerTimeout = (playerTimeoutId) => {
	return handleFetch(`${sapphirePlayerApiUrl}/timeouts/${playerTimeoutId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		method: 'DELETE',
	});
};

export { getPlayerTimeout, createPlayerTimeout, deletePlayerTimeout };
