import BTCIcon from '../assets/icons/btc-icon.svg';
import ETHIcon from '../assets/icons/eth-icon.svg';
import USDTIcon from '../assets/icons/usdt-icon.svg';

const currenciesIcons = {
	BTC: BTCIcon,
	ETH: ETHIcon,
	USDT: USDTIcon,
};

const getCurrencyIcon = (currency = '') => currenciesIcons[currency];

export { getCurrencyIcon };
