import { useCallback } from 'preact/hooks';

import style from './style.module.scss';

const FooterImagesSection = ({images}) => {

	const renderImage = useCallback(
		(src = '', index = 0) => (
			<div key={index} className={style.imageWrapper}>
				<img className={style.imageStyle} src={src} alt="footer" />
			</div>
		),
		[]
	);

	if (!images) {
		return null;
	}

	return <div className={style.imagesWrapper}>{images.map(renderImage)}</div>;
};

export default FooterImagesSection;

