import clsx from 'clsx';
import { Modal as MUIModal } from '@mui/material';

import { Typography } from '../typography';

import CloseModalIcon from '../../assets/icons/close-modal-icon.svg';

import style from './style.module.scss';

export const Modal = ({
	open,
	setOpen,
	children,
	onCloseModal,
	title = '',
	subtitle = '',
	SubtitleIcon,
	modalClassName = '',
	titleWrapperClassName = '',
	showCloseButton = true,
	closeOnBackdropClick = true,
	...rest
}) => {
	const handleClose = () => {
		if (onCloseModal) onCloseModal();

		setOpen && setOpen(false);
	};
	return (
		<MUIModal
			open={open}
			onClose={closeOnBackdropClick ? handleClose : undefined}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			{...rest}
		>
			<div className={clsx(style.modal, modalClassName)}>
			{showCloseButton && ( 
					<img
						className={style.icon}
						src={CloseModalIcon}
						onClick={handleClose}
						alt="close_icon"
					/>
				)}

				{title && (
					<div
						className={clsx(
							style.title,
							SubtitleIcon && style.iconTitle,
							titleWrapperClassName
						)}
					>
						<Typography type="h5" fontColor="white">
							{title}
						</Typography>

						{subtitle && (
							<div className={style.subtitle}>
								{SubtitleIcon && SubtitleIcon}
								<Typography type="h7" fontColor="white">
									{subtitle}
								</Typography>
							</div>
						)}
					</div>
				)}
				{children}
			</div>
		</MUIModal>
	);
};
