import { configureStore } from '@reduxjs/toolkit';

import userReducer from './user-slice';
import themeReducer from './theme-slice';
import categoryReducer from './categories-slice';
import leaderBoardReducer from './leaderBoard';
import authenticationReducer from './authentication-slice';
import configurationReducer from './configuration-slice';
import gameReducer from './game-slice';
import walletReducer from './wallet-slice';
import contentReducer from './content-slice';
import limitReducer from './limit-slice';
import exclusionReducer from './exclusion-slice';
import timeoutReducer from './timeout-slice';
import filterReducer from './filter-slice';
import playerReducer from './player-slice';
import depositReducer from './deposit-slice';
import realityCheckReducer from './realityCheck-slice';

export default configureStore({
	reducer: {
		user: userReducer,
		themeSlice: themeReducer,
		categories: categoryReducer,
		leaderBoard: leaderBoardReducer,
		authentication: authenticationReducer,
		configuration: configurationReducer,
		game: gameReducer,
		wallet: walletReducer,
		content: contentReducer,
		limits: limitReducer,
		exclusion: exclusionReducer,
		timeout: timeoutReducer,
		filter: filterReducer,
		player: playerReducer,
		deposit: depositReducer,
		realityCheck: realityCheckReducer,
	},
});
