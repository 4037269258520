import { Typography } from '../typography';
import classes from './style.module.scss';
import { formatDate } from '../../utils/date';
import { updateWithdrawalsTransactionStatus } from '../../services/transactions-service';
import clsx from 'clsx';
import { useContext, useMemo } from 'preact/hooks';
import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import CloseIcon from '../../assets/icons/close-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerTransactions } from '../../store/player-slice';
import { selectUserSession } from '../../store/authentication-slice';

const TransactionRow = ({ transaction, tableMapping, columntCount }) => {
	const { onPushMessage } = useContext(ToastMessagesContext);
	const dispatch = useDispatch()
	const userSession = useSelector(selectUserSession);

	const cancelWithdrawal = async (id) => {
		try {
			const response = await updateWithdrawalsTransactionStatus(id, 'Declined');
			if (response.status === 400 || response.status === 500) {
				onPushMessage({
					message: 'An error occured',
					type: 'error',
					title: 'Error',
				});
			} else {
				dispatch(getPlayerTransactions(userSession.id));
				onPushMessage({
					message: 'Successfully cancelled',
					type: 'info',
					title: 'Updated',
				});
			}
		} catch (e) {
			onPushMessage({
				message: e.message || 'An error occured',
				type: 'error',
				title: 'Error',
			});
		}
	};

	const isWithdrawalRow = useMemo(
		() => transaction.transactionType === 'Withdrawal',
		[transaction]
	);

	const componentFactory = (transaction, mapping) => {
		const field = transaction[mapping.field];
		const ccy = transaction.currency;
		if (mapping.hasCurrency) {
			return (
				<td>
					<Typography type="st3">
						{Math.round(field * 100) / 100} {ccy}
					</Typography>
				</td>
			);
		} else if (mapping.isDate) {
			return (
				<td>
					<Typography type="st3">{formatDate(field, true)}</Typography>
				</td>
			);
		} else if (mapping.isWithdrawalRow) {
			return (
				<td>
					{isWithdrawalRow && transaction.status === 'Pending' ? (
						<>
							<button
								onClick={async () => {
									await cancelWithdrawal(field);
								}}
								className={classes.cancelButton}
							>
								<Typography display="none" fontColor="primary">
									Cancel
								</Typography>
								<img src={CloseIcon} />
							</button>
						</>
					) : (
						<Typography type="st3">-</Typography>
					)}
				</td>
			);
		} else {
			return (
				<td>
					<Typography type="st3">{field}</Typography>
				</td>
			);
		}
	};

	return (
		<tr
			className={clsx(
				classes.rowWrapper,
				isWithdrawalRow ? classes.rowWrapperWithdrawal : '',
				classes[`col-${columntCount}`]
			)}
		>
			{tableMapping.map((mapping) =>
				componentFactory(transaction, mapping)
			)}
		</tr>
	);
};

export default TransactionRow;
