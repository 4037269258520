import { CurrencySigns } from '../../../utils/consts';
import style from './style.module.scss';

const DashboardWalletRow = ({
	title,
	value,
	toggleIcon,
	actionIcon,
	handleToggle,
	handleClick,
	currency = 'EUR',
}) => {
	const currencySymbol = CurrencySigns[currency]
	return (
		<div className={style.walletRow}>
			<div className={style.rowValues}>
				<div className={style.flex}>
					<p className={style.rowTitle}>{title}</p>
					<img
						onClick={handleToggle}
						className={style.imageButton}
						src={toggleIcon}
					/>
				</div>
				<p>
					<span className={style.moneyValue}>{value}</span> {currencySymbol}
				</p>
			</div>

			<img
				className={style.imageButton}
				src={actionIcon}
				onClick={handleClick}
			/>
		</div>
	);
};

export default DashboardWalletRow;
