import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import store from './store/store';
import ToastMessageProvider from './providers/toast-messages-provider';
import UrlProvider from './providers/url-provider';
import  {
	SocketContextProvider,
} from './providers/socket-provider';
import { Router } from './router';

import theme from './style/theme';
import classes from './style/theme.module.scss';

const App = () => (
	<Provider store={store}>
		<SocketContextProvider>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ThemeProvider theme={theme}>
          <div className={classes.theme}>
            <ToastMessageProvider>
              <UrlProvider>
                <Router />
              </UrlProvider>
            </ToastMessageProvider>
          </div>
				</ThemeProvider>
			</LocalizationProvider>
		</SocketContextProvider>
	</Provider>
);

export default App;
