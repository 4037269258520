// const promotionFields = [
// 	'promo_title',
// 	'description',
// 	'cover_image',
// 	'mobile_image',
// 	'end',
// ];

const mapMenuItemsArrayToObject = (menuItems = []) => {
	return menuItems.map(({slug, section_items}) => ({
		id: slug,
		items: section_items.map(({subsection_name, subsection_items}) => ({
			categoryName: subsection_name,
			items: subsection_items
				.map(({id, label, link, icon, is_protected}) => ({
					id,
					label,
					icon,
					href: link,
					requiresAuth: Boolean(Number(is_protected)),
				}))
		})),
	}));
};

const mapFooter = (data) => {
	const excludedFooterLinks = ['VIP Club', 'Affiliate'];

	// Use for switching between internal and external link generation
	// Format:
	// Replacement link name as key
	// Label which should replace old label as a new key:value
	// Link which should replace old link as a new key:value
	const replacedFooterLinks = {
		'Responsible Gambling': {
			label: 'Responsible Gaming',
			link: '/responsible-gaming',
		},
	};

	const shouldReplace = (label) =>
		Array.from(Object.keys(replacedFooterLinks)).includes(label);

	const processLabel = (label) => {
		const forReplacement = shouldReplace(label);
		if (forReplacement) {
			return replacedFooterLinks[label].label;
		}
		return label;
	};

	const processHref = (
		label,
		internal_link,
		external_link,
		info_page,
		page_link
	) => {
		const forReplacement = shouldReplace(label);
		if (forReplacement) {
			return replacedFooterLinks[label].link;
		}
		return (
			internal_link ||
			external_link ||
			`${info_page === '1' ? '/info/' : '/'}${page_link.post_name}`
		);
	};

	return data.map(footer => (
		{
			slug: footer.slug,
			aboutUs: footer.content.rendered,
			paymentMethods: footer.payment_methods
				? footer.payment_methods.map((method) => method.guid)
				: [],
			sections: footer.sections.map(({ section_name, links }) => ({
				sectionName: section_name,
				sectionLinks: links
					? links
						.map(
							({
								 label,
								 page_link,
								 external_link,
								 info_page,
								 ID,
								 internal_link,
							 }) => ({
								label: processLabel(label),
								id: ID,
								href: processHref(
									label,
									internal_link,
									external_link,
									info_page,
									page_link
								),
								external: !!external_link,
							})
						)
						.filter(({ label }) => !excludedFooterLinks.includes(label))
					: [],
			})),
			copyright: footer.copyright,
		}));
};

const mapPromotions = (data) => {
	return data.map(
		({
			promo_title,
			description,
			short_description,
			cover_image,
			end,
			promo_type,
			cta_button_text,
			slug,
		}) => ({
			title: promo_title,
			description,
			shortDescription: short_description,
			image: cover_image.guid,
			end,
			promoType: promo_type !== '' ? promo_type[0] : undefined,
			ctaButtonText: cta_button_text,
			slug,
		})
	);
};

const mapBanners = (data) => {
	return data.map(
		({
			banner_title,
			subtitle,
			cta_first_time_button_text,
			image,
			image_multiple,
			location,
			banner_type,
			short_description,
		}) => ({
			title: banner_title,
			subtitle,
			ctaFirstTimeButtonText: cta_first_time_button_text,
			image: image_multiple
				? image_multiple.map((image) => image.guid)
				: image.guid,
			location,
			shortDescription: short_description,
			bannerType: banner_type !== '' ? banner_type[0] : undefined,
		})
	);
};

const mapPages = (data) => {
	return data.map(({ page_title, content, page_type, seo_items, slug }) => ({
		title: page_title,
		slug,
		mainContent: content.rendered,
		pageType: page_type !== '' ? page_type[0] : undefined,
		seoItems: seo_items,
	}));
};

const mapProviders = (data) => {
	return data.map(({ title: { rendered }, image: { guid }, is_live, is_casino }) => {
		return { title: rendered, logo: guid, isLive: !!Number(is_live), isCasino: !!Number(is_casino) };
	});
};

const mapCategories = (data) => {
	return data.map(category => ({
		id: category.category_id,
		name: category.category_name,
		games: {
			count: category.games.length,
			items: category.games.map(item => ({
				name: item.game_name,
				gameId: Number(item.game_id),
				subVendor: item.provider,
				thumbnail: item.thumbnail,
				volatility: item.volatility,
				tags: item.visible_tags[0] !== "" ? item.visible_tags : [],
				rtp: item.rtp,
				lines: item.lines,
				reels: item.reels,
				lobby: item.lobby,
			}))
		},
		isLive: !!Number(category.live_casino),
	}))
}

export {
	mapMenuItemsArrayToObject,
	mapPromotions,
	mapBanners,
	mapPages,
	mapFooter,
	mapProviders,
	mapCategories,
};
