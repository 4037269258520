import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';

import { Modal } from '../modal';
import ButtonRound from '../button-round';

import classes from './style.module.scss';

// type ConfirmationModalProps = {
//   title: string;
//   subtitle?: string;
//   isModalOpen: boolean;
//   onCloseModal: () => void;
//   confirmDeleteHandler: () => void;

//   modalClassName?: string;
// };

const ConfirmationModal = ({
	title,
	subtitle,
	isModalOpen,
	onCloseModal,
	modalClassName,
	confirmDeleteHandler,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={title}
			open={isModalOpen}
			subtitle={subtitle}
			onCloseModal={onCloseModal}
			modalClassName={clsx(classes.modal, modalClassName)}
			titleWrapperClassName={classes.title}
		>
			<div className={classes.buttonWrapper}>
				<ButtonRound
					onClick={confirmDeleteHandler}
					className={classes.confirmDelete}
				>
					{t('yes')}
				</ButtonRound>

				<ButtonRound onClick={onCloseModal} className={classes.cancelDelete}>
					<div className={classes.cancelDeleteInnerWrapper}>{t('no')}</div>
				</ButtonRound>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
