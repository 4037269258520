import Transactions from '../common/transactions';
import { useBankingTransactionsPage } from '../common/useBankingTransactionsPage';

const Deposits = () => {
	const {
		error,
		selectedPeriod,
		basicTableColumns,
		onClickNextButton,
		bankingTransactions,
		onChangePeriodSelect,
		onClickPreviousButton,
		filteredBankingTransactions,
		areBankingTransactionsLoading,
		areBankingTransactionsReceived,
	} = useBankingTransactionsPage({ type: 'Deposit' });

	return (
		<Transactions
			error={error}
			transactionsTitle="Deposits"
			selectedPeriod={selectedPeriod}
			transactions={bankingTransactions}
			noTransactionsMessage="No deposits"
			basicTableColumns={basicTableColumns}
			onClickNextButton={onClickNextButton}
			onChangePeriodSelect={onChangePeriodSelect}
			onClickPreviousButton={onClickPreviousButton}
			filteredTransactions={filteredBankingTransactions}
			noDataMessage="No deposits for the selected period"
			areTransactionsLoading={areBankingTransactionsLoading}
			areTransactionsReceived={areBankingTransactionsReceived}
		/>
	);
};

export default Deposits;
