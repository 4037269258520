import Select from '../forms-new/select/select';

import ToggleHideIcon from '../../assets/icons/dropdown-arrow-up-orange.svg';
import ToggleShowIcon from '../../assets/icons/dropdown-arrow-down-orange.svg';
import ExportIcon from '../../assets/icons/export.svg';
import classes from './style.module.scss';
import { Typography } from '../typography';
import Button from '../forms-new/button/button';
import { DatePicker } from '../forms/date-picker';
import { DATE_FILTERS, TRANSACTION_FILTERS } from '../../utils/consts';
import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import { useContext } from 'preact/hooks';
import ApplyIcon from '../../assets/icons/apply-icon.svg';
import {useTranslation} from "react-i18next";

const TransactionFilterBar = ({
	filters,
	setFilters,
	setTriggerFilter,
	filteredData,
	runDataExport,
	reportName,
	withTransactionFilter,
}) => {
	const { t } = useTranslation();
	const { onPushMessage } = useContext(ToastMessagesContext);

	return (
		<div className={classes.filterBar}>
			<div className={classes.dropdownWrapper}>
				{withTransactionFilter && (
					<Select
						value={t(filters.transaction)}
						placeholder={t(filters.transaction)}
						options={TRANSACTION_FILTERS.map(filter => ({
							label: t(filter),
							value: filter,
						}))}
						dropdownIconOpen={ToggleHideIcon}
						className={classes.transactionFilterDropdown}
						dropdownIconClosed={ToggleShowIcon}
						onChange={(value) => {
							setFilters((prev) => ({ ...prev, transaction: value.value }));
							setTriggerFilter((prev) => !prev);
						}}
						selectedValueClassName={classes.selectValueCustom}
						selectOptionsClassName={classes.selectOptionsCustom}
					/>
				)}
				<Select
					value={t(filters.date)}
					placeholder={t(filters.date)}
					options={DATE_FILTERS.map(filter => ({
						label: t(filter),
						value: filter,
					}))}
					dropdownIconOpen={ToggleHideIcon}
					className={classes.dateFilterDropdown}
					dropdownIconClosed={ToggleShowIcon}
					onChange={(value) => {
						setFilters((prev) => ({ ...prev, date: value.value }));
						if (value !== DATE_FILTERS[5]) {
							setTriggerFilter((prev) => !prev);
						}
					}}
					selectedValueClassName={classes.selectValueCustom}
					selectOptionsClassName={classes.selectOptionsCustom}
				/>
				<Button
					className={classes.exportButton}
					onClick={() => {
						runDataExport(filteredData, filters, reportName);
					}}
				>
					<Typography
						type="st3"
						fontColor="white"
						className={classes.exportText}
					>
						{t('export')}
					</Typography>
					<img src={ExportIcon} />
				</Button>
			</div>

			{filters.date === DATE_FILTERS[5] && (
				<div className={classes.dateWrapper}>
					<DatePicker
						value={filters.from}
						placeholder={!filters.from ? 'Start Date' : ''}
						wrapperClassName={classes.datePicker}
						onChange={(value) => {
							setFilters((prev) => ({ ...prev, from: value }));
						}}
					/>

					<DatePicker
						value={filters.to}
						placeholder={!filters.to ? 'End Date' : ''}
						wrapperClassName={classes.datePicker}
						onChange={(value) => {
							setFilters((prev) => ({ ...prev, to: value }));
						}}
					/>

					<Button
						className={classes.applyButton}
						onClick={() => {
							if (filters.from && filters.to) {
								if (filters.from > filters.to) {
									onPushMessage({
										message: 'Start date must be before end date',
										type: 'error',
										title: 'Error',
									});
								} else {
									setTriggerFilter((prev) => !prev);
								}
							} else {
								onPushMessage({
									message: 'Please select a start and end date',
									type: 'error',
									title: 'Error',
								});
							}
						}}
					>
						<Typography type="st3" fontColor="blue" display="none">
							Apply
						</Typography>
						<img src={ApplyIcon} />
					</Button>
				</div>
			)}
		</div>
	);
};

export default TransactionFilterBar;
