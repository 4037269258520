import { route } from 'preact-router';
import { useState, useMemo, useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@mui/material';

import Search from '../search';
import ButtonRound from '../button-round';

import { logoutUser, selectUserSession } from '../../store/authentication-slice';

import userIcon from '../../assets/icons/user-icon.svg';
import notificationIcon from '../../assets/icons/notification-bell.svg';

import userSettingsIcon from '../../assets/icons/user-settings-icon.svg';
import walletIcon from '../../assets/icons/wallet-icon.svg';
import transactionsIcon from '../../assets/icons/transactions-icon.svg';
import bonusesIcon from '../../assets/icons/bonuses-icon.svg';
import kycIcon from '../../assets/icons/kyc-icon.svg';
import playerProtectionIcon from '../../assets/icons/player-protection-icon.svg';
import liveSupportIcon from '../../assets/icons/live-support-icon.svg';
import logoutIcon from '../../assets/icons/logout-icon.svg';

import headerSearchIcon from '../../assets/icons/header-search-icon.svg';

import style from './style.module.scss';
import PlayerDashboard from '../player-dashboard';
import { useTranslation } from 'react-i18next';
import { getPlayerData } from '../../store/player-slice';
import {useViewport} from "../../hooks/useViewport";

const userMenuId = 'userMenu';

export const HeaderRightUserSection = () => {
	const { t } = useTranslation();

	const [isHeaderSearchVisible, setIsHeaderSearchVisible] = useState(false);
	const dispatch = useDispatch();
	const { isMobileScreen } = useViewport();
	const userSession = useSelector(selectUserSession);

	const [open, setOpen] = useState(null);

	const onUserMenuButtonClick = () => {
		setOpen(true);
	};
	const closeUserMenu = () => {
		setOpen(false);
	};

	const onClickSearchButton = () => {
		setIsHeaderSearchVisible(true);
	};

	const onSearchOutsideClick = () => {
		setIsHeaderSearchVisible(false);
	};

	const userMenuItems = useMemo(
		() => [
			[
				{
					icon: userSettingsIcon,
					text: t('profile'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/profile/personal-details');
					},
				},
				{
					icon: walletIcon,
					text: t('wallet'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/wallet/deposit');
					},
				},
				{
					icon: transactionsIcon,
					text: t('transactions'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/transactions/transaction-history');
					},
				},
				{
					icon: bonusesIcon,
					text: t('bonuses'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/bonuses/active');
					},
				},
			],
			[
				{
					icon: kycIcon,
					text: t('kyc'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/profile/kyc');
					},
				},
				{
					icon: playerProtectionIcon,
					text: t('player_protection'),
					onClickHandler: () => {
						closeUserMenu();
						route('/account/player-protection');
					},
				},
				{
					icon: liveSupportIcon,
					text: t('support'),
					onClickHandler: () => {
						closeUserMenu();
						route('/live-support');
					},
				},
				{
					icon: logoutIcon,
					text: t('logout'),
					onClickHandler: () => {
						closeUserMenu();
						dispatch(logoutUser());
					},
				},
			],
		],
		[dispatch, t]
	);

	useEffect(() => {
		if (open && userSession) {
			dispatch(getPlayerData(userSession.id));
		}
	}, [dispatch, open, userSession])

	return (
		<>
			<Box className={style.headerRightUserSection}>
				{!isMobileScreen && (
					<ButtonRound
					disableRipple
					buttonStyle="primary"
					className={style.searchButton}
					onClick={onClickSearchButton}
				>
					<img src={headerSearchIcon} alt="search-icon" />

					<p className={style.btnText} type="st3" fontColor="white">
						{t('search')}
					</p>
				</ButtonRound>
				)}

				<IconButton
					aria-haspopup="true"
					className={style.userMenuButton}
					aria-expanded={open ? 'true' : undefined}
					aria-controls={open ? userMenuId : undefined}
					onClick={onUserMenuButtonClick}
				>
					<img src={userIcon} />
				</IconButton>
				<img className={style.notificationIcon} src={notificationIcon} />
			</Box>

			{isHeaderSearchVisible &&  (
				<div className={style.searchContainer}>
					<Search
						isFocused={true}
						isDropdownVisible={true}
						onBackgroundClickCallback={onSearchOutsideClick}
					/>
				</div>
			)}
			<PlayerDashboard
				onClose={closeUserMenu}
				open={open}
				userMenuItems={userMenuItems}
			/>
		</>
	);
};
