import clsx from "clsx";
import styles from "./style.module.scss";
import {useEffect, useState} from "preact/hooks";
const JackpotCard = ({backgroundClass, avatar, initialAmount, targetAmount, storageKey}) => {

  const [amount, setAmount] = useState(Number(initialAmount));
  const [secondAmount, setSecondAmount] = useState(Number(initialAmount) - ((20 / 100) * Number(initialAmount)));
  const [thirdAmount, setThirdAmount] = useState(Number(initialAmount) - ((10 / 100) * Number(initialAmount)));

  useEffect(() => {
    const amountLocalStorage = localStorage.getItem(storageKey);
    if (amountLocalStorage) {
      setAmount(Number(amountLocalStorage));
    }
    // const item = JSON.parse(amountLocalStorage);
    // const now = new Date();
    //
    // if (now.getTime() >= item.expiryDate) {
    //   localStorage.removeItem(storageKey);
    // } else {
    //   setAmount(Number(amountLocalStorage));
    // }
  }, [initialAmount, storageKey]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (amount >= targetAmount) {
        setAmount(initialAmount);
      }
      setAmount(prev => prev + (Math.floor(Math.random() * 11) / 100));
      setSecondAmount(prev => prev + (Math.floor(Math.random() * 11) / 100));
      setThirdAmount(prev => prev + (Math.floor(Math.random() * 11) / 100));
    }, 10);

    return () => {
      // const date = new Date();
      // date.setHours(0, 0, 0, 0);
      // date.setDate(date.getDate() + 1);
      // const expiryDate = date.getTime();
      localStorage.setItem(storageKey, amount);
      clearInterval(interval);
    };
  }, [targetAmount, amount, storageKey, initialAmount]);

  return (
    <div className={styles.borderContainer}>
      <div className={styles.container}>
        <img className={styles.smallImage} src={avatar} alt={"jackpot-image"} />
        <div className={clsx(styles.infoContainer, backgroundClass)}>
          <div className={clsx(styles.jackpotSumContainer, styles.firstSum)}>
            <span>{`${amount.toFixed(2)} EUR`}</span>
          </div>
          <div className={clsx(styles.jackpotSumContainer, styles.secondSum)}>
            <span>{`${secondAmount.toFixed(2)} EUR`}</span>
          </div>
          <div className={clsx(styles.jackpotSumContainer, styles.thirdSum)}>
            <span>{`${thirdAmount.toFixed(2)} EUR`}</span>
          </div>
        </div>
      </div>
    </div>
  )
};

export default JackpotCard;