import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as service from "../services/realityCheck-service";

const initialState = {
    data: null,
    error: null,
};

// Async thunk to fetch reality checks
const getPlayerRealityChecks = createAsyncThunk(
    'realityCheck/get',
    async (_,{ rejectWithValue }) => {
        try {
            const response = await service.getPlayerRealityChecks();
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk to create a reality check
const createPlayerRealityCheck = createAsyncThunk(
    'realityCheck/create',
    async (data, { rejectWithValue }) => {
        try {
            const response = await service.createPlayerRealityCheck(data);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Async thunk to delete a reality check
const deletePlayerRealityCheck = createAsyncThunk(
    'realityCheck/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await service.deletePlayerRealityCheck(id);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
const realityCheckSlice = createSlice({
    name: 'realityCheck',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPlayerRealityChecks.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(getPlayerRealityChecks.rejected, (state, action) => {
            state.error = action.payload; 
        });
    },
});

const selectRealityCheck = (state) => state.realityCheck?.data || null;

export { selectRealityCheck }; // selectors

export { getPlayerRealityChecks, createPlayerRealityCheck, deletePlayerRealityCheck }; // thunks

export default realityCheckSlice.reducer;