import { useEffect } from 'preact/hooks';

import { useDispatch, useSelector } from 'react-redux';

import {
	selectTimeout,
	getPlayerTimeout,
	selectShouldRefetchTimeouts,
} from '../../../../store/timeout-slice';
import RestrictionPanel from '../restriction-panel';
import AddRestrictionPanel from '../add-restriction-panel';
import { selectUserSession } from '../../../../store/authentication-slice';

import commonClasses from '../../style.module.scss';
import { t } from 'i18next';
import { Typography } from '../../../../components/typography';

const TakeABreak = () => {
	const dispatch = useDispatch();

	const timeout = useSelector(selectTimeout);
	const userSession = useSelector(selectUserSession);
	const shouldRefetchTimeouts = useSelector(selectShouldRefetchTimeouts)

	useEffect(() => {
		if (userSession) {
			dispatch(getPlayerTimeout(userSession.id));
		}
	}, [dispatch, userSession]);

	useEffect(() => {
		if (userSession && shouldRefetchTimeouts) {
			dispatch(getPlayerTimeout(userSession.id));
		}
	}, [dispatch, userSession, shouldRefetchTimeouts]);

	return (
		<>
			<div className={commonClasses.tabContent}>
				<AddRestrictionPanel isButtonDisabled={!!timeout} type="timeout" />
				{!timeout && (
					<Typography type="st2" className={commonClasses.noLimitsText}>
						{t('no_timeout_defined')}
					</Typography>
				)}
			</div>

			{timeout && (
				<div className={commonClasses.tabContent}>
					<RestrictionPanel
						data={timeout}
						type="timeout"
						playerId={userSession?.id}
					/>
				</div>
			)}
		</>
	);
};

export default TakeABreak;
