import { styled, Menu } from '@mui/material';

// type = 'dark' | 'light'
export const StyledMenu = styled(Menu)(({ type = 'dark' }) => {
	const bgColor =
		type === 'dark' ? 'var(--color-blue-3)' : 'var(--color-blue-2)';
	const hoverBgColor =
		type === 'dark' ? 'var(--color-blue-2)' : 'var(--color-blue-3)';

	return {
		'& .MuiPaper-root': {
			overflow: 'visible',
			backgroundColor: bgColor,
			boxShadow:
				'0px 4px 6px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.2)',

			'&:before': {
				content: '""',
				position: 'absolute',
				top: 0,
				zIndex: 0,
				right: 'calc(50% - 5px)',
				display: 'block',
				width: 10,
				height: 10,
				backgroundColor: 'inherit',
				transform: 'translateY(-50%) rotate(45deg)',
			},
		},

		'& .MuiList-root': {
			paddingBlock: '4px',

			'.MuiMenuItem-root': {
				display: 'flex',
				gap: '8px',
				alignItems: 'center',

				padding: '12px',
				fontWeight: 600,
				fontSize: '14px',
				color: 'var(--color-white)',
				backgroundColor: bgColor,

				'&:hover': { backgroundColor: hoverBgColor },
			},
		},
	};
});
