import { tagsApiUrl } from './constants';

const getGamesByTagName = (tagName = '', offset = 0, limit = 0, datasource = 'Casino') => {
	return fetch(
		`${tagsApiUrl}/${tagName}?expand=games&pagination=games(offset=${offset},limit=${limit})&datasource=${datasource}`
	);
};

export { getGamesByTagName };

