import { getAuthHeaders } from '../utils/auth';
import {
	playerApiUrlV1,
	playerApiUrlV2,
	sapphireWithdrawalApiUrl,
} from './constants';

export const getBankingTransactions = (playerId) => {
	return fetch(`${playerApiUrlV2}/${playerId}/transactions/banking`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

export const getWageringTransactions = (playerId) => {
	return fetch(`${playerApiUrlV1}/${playerId}/transactions/wagering `, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

export const updateWithdrawalsTransactionStatus = async (id, status) => {
	return fetch(`${sapphireWithdrawalApiUrl}/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({
			status,
		}),
		method: 'PUT',
	});
};
