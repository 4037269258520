import { useEffect } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectLiveCasinoProviders } from '../../../../store/content-slice';
import useTags from '../../../../hooks/useTags';

import LeaderboardWraper from '../../../../components/leaderboard-wrapper';
import GameCard from '../../../../components/game-card-new';
import LoadMore from '../../../../components/load-more';
import AboutUs from '../../../../components/about-us';
import Slider from '../../../../components/slider';
import Search from '../../../../components/search';

import ProvidersIcon from '../../../../assets/icons/providers-icon.svg';

import style from './style.module.scss';

const LiveCasinoGroupsPage = ({ tagName }) => {
	const { t } = useTranslation();

	const providers = useSelector(selectLiveCasinoProviders);
	const {
		gamesByTagName,
		totalGamesCount,
		loadedGamesCount,
		fetchGamesByTagName,
	} = useTags(tagName, 'LiveCasino');

	useEffect(() => {
		fetchGamesByTagName();
	}, [tagName]);

	return (
		<div className={style.wrapper}>
			<div className={style.tagName}>{tagName}</div>
			<Search isLiveCasino />
			<div className={style.gameContainer}>
				<div className={style.groupWrapper}>
					{gamesByTagName.map((game) => {
						return (
							<GameCard
								key={game.id}
								gameId={game.id}
								gameImageURL={game.thumbnail}
								gameName={game.name}
								provider={game.subVendor ? game.subVendor : game.provider}
							/>
						);
					})}
				</div>
				<LoadMore
					displayedGamesCount={loadedGamesCount}
					totalGamesCount={totalGamesCount}
					onLoadMore={fetchGamesByTagName}
				/>
			</div>
			<Slider
				isProvider
				title={t('providers')}
				items={providers}
				icon={ProvidersIcon}
			/>
			<div className={style.leaderboardWraper}>
				<LeaderboardWraper />
			</div>
			{/*<AboutUs />*/}
		</div>
	);
};

export default LiveCasinoGroupsPage;
