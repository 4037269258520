import { useCallback, useState, useEffect, useRef } from 'preact/hooks';
import clsx from 'clsx';

import Label from '../label/label';
import InputError from '../input-error/input-error';

import style from './style.module.scss';

// type TProps = {
//   options: string[];
//   label?: string;
//   className?: string;
//   placeholder?: string;
//   value?: string;
//   hasError?: boolean;
//   isRequired?: boolean;
//   errorMessage?: string;
//   placeholderClassName?: string;
//   dropdownIconOpen?: JSX.Element;
//   arrowWrapperClassName?: string;
//   labelWrapperClassName?: string;
//   selectButtonClassName?: string;
//   selectOptionsClassName?: string;
//   selectedValueClassName?: string;
//   dropdownIconClosed?: JSX.Element;
//   onChange: (value: string) => void;
//   onBlur?: () => void;
// };

const Select = ({
	label,
	options,
	value,
	hasError = false,
	errorMessage,
	className,
	placeholder,
	dropdownIconOpen,
	isRequired = true,
	dropdownIconClosed,
	placeholderClassName = '',
	arrowWrapperClassName = '',
	labelWrapperClassName = '',
	selectButtonClassName = '',
	selectOptionsClassName = '',
	selectedValueClassName = '',
	onChange,
	onBlur = () => {},
}) => {
	const [isSelectOpened, setIsSelectOpened] = useState(false);
	const selectRef = useRef(null);
	const selectButtonRef = useRef(null);
	const selectOptionsRef = useRef(null);

	const handleSelect = useCallback(
		// (newValue: string) => () => {
		(newValue) => () => {
			onChange(newValue);
			setIsSelectOpened(false);
		},
		[onChange]
	);

	// const handleClickOutside = (event: MouseEvent) => {
	const handleClickOutside = useCallback(
		(event) => {
			if (
				selectRef.current &&
				// !selectRef.current.contains(event.target as Node)
				!selectRef.current.contains(event.target)
			) {
				setIsSelectOpened(false);
				onBlur();
			}
		},
		[onBlur, selectRef]
	);

	const showAndPositionSelectOptions = useCallback(() => {
		if (selectOptionsRef.current) {
			const marginFromSelectButton = 10;
			const positionBelow =
				Number(style.selectButtonHeight) +
				selectButtonRef.current.getBoundingClientRect().top +
				marginFromSelectButton;
			const selectOptionsOffsetHeight = selectOptionsRef.current.offsetHeight;

			if (selectOptionsOffsetHeight + positionBelow > window.outerHeight) {
				selectOptionsRef.current.style.top = `${
					selectButtonRef.current.getBoundingClientRect().top -
					marginFromSelectButton -
					selectOptionsOffsetHeight
				}px`;
			} else {
				selectOptionsRef.current.style.top = `${positionBelow}px`;
			}

			selectOptionsRef.current.style.width = `${selectButtonRef.current.offsetWidth}px`;
			selectOptionsRef.current.style.visibility = 'visible';
		}
	}, []);

	const handleScroll = useCallback(() => {
		showAndPositionSelectOptions();
	}, [showAndPositionSelectOptions]);

	const handleClick = useCallback(() => {
		setIsSelectOpened((prev) => {
			if (prev) {
				onBlur();
			}

			if (selectButtonRef.current) {
				setTimeout(() => {
					showAndPositionSelectOptions();
				}, 0);
			}

			return !prev;
		});
	}, [onBlur, showAndPositionSelectOptions]);

	useEffect(() => {
		document.addEventListener('scroll', handleScroll);

		const appResizeObserver = new ResizeObserver(() => {
			showAndPositionSelectOptions();
		});

		appResizeObserver.observe(document.querySelector('#app'));

		return () => {
			document.removeEventListener('scroll', handleScroll);
			appResizeObserver.disconnect();
		};
	}, [handleScroll, showAndPositionSelectOptions]);

	useEffect(() => {
		if (isSelectOpened) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isSelectOpened, handleClickOutside]);

	return (
		<div className={clsx(style.selectContainer, className)} ref={selectRef}>
			{label ? (
				<Label
					isRequired={isRequired}
					label={label}
					hasError={hasError}
					wrapperClassName={labelWrapperClassName}
				/>
			) : null}

			<div
				className={clsx(
					style.selectButton,
					isSelectOpened && style.selectFocused,
					selectButtonClassName
				)}
				ref={selectButtonRef}
				onClick={handleClick}
			>
				{value ? (
					<span className={clsx(style.selectedValue, selectedValueClassName)}>
						{value}
					</span>
				) : (
					<span className={clsx(style.placeholder, placeholderClassName)}>
						{placeholder}
					</span>
				)}

				<div className={clsx(style.arrowWrapper, arrowWrapperClassName)}>
					{dropdownIconOpen && dropdownIconClosed ? (
						isSelectOpened ? (
							<img src={dropdownIconOpen} />
						) : (
							<img src={dropdownIconClosed} />
						)
					) : (
						<div
							className={clsx(
								style.dropdownArrow,
								isSelectOpened ? style.up : style.down
							)}
						/>
					)}
				</div>
			</div>

			{hasError && errorMessage && <InputError errorMessage={errorMessage} />}

			{isSelectOpened && (
				<ul
					className={clsx(
						style.selectOptions,
						selectOptionsClassName,
						label && style.withLabel
					)}
					ref={selectOptionsRef}
				>
					{options.map((option) => (
						<li
							key={option?.value ? option.value : option}
							className={style.selectOption}
							onClick={handleSelect(option)}
						>
							{option?.label ? option.label : option}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Select;
