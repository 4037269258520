import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';
import { ButtonNew } from '../button-design-system';

import PlayIcon from '../../assets/icons/play-icon.svg';
import PlayIconGrey from '../../assets/icons/play-icon-grey.svg';

import style from './style.module.scss';

const GameModePopUp = ({
	game = null,
	isAuthenticated = false,
	onRealPlayClick = () => {},
	onFunPlayClick = () => {},
	onRegisterClick = () => {},
}) => {
	const { t } = useTranslation();

	return (
		<div className={style.buttonsWrapper}>
			<div className={style.btnsWrapper}>
				<ButtonNew
					startIcon={PlayIcon}
					className={clsx(
						style.realPlayBtn,
						style.moreSpecificSelector,
						!game?.hasFunMode && style.realBtnWide
					)}
					onClick={isAuthenticated ? onRealPlayClick : onRegisterClick}
				>
					<Typography
						type="btn3"
						fontColor="white"
						className={style.realBtnLabel}
					>
						{isAuthenticated ? t('real_play') : t('register')}
					</Typography>
				</ButtonNew>

				{game?.hasFunMode && (
					<ButtonNew
						startIcon={PlayIconGrey}
						color="grey1"
						className={clsx(style.funPlayBtn, style.moreSpecificSelector)}
						onClick={onFunPlayClick}
					>
						<Typography
							type="btn3"
							fontColor="grey-2"
							className={style.funBtnLabel}
						>
							{t('fun_play')}
						</Typography>
					</ButtonNew>
				)}
			</div>
		</div>
	);
};

export default GameModePopUp;
