const regexes = {
	username: /^[a-zA-Z0-9]+$/,
	email: /^[a-z0-9_\.]+@[a-z0-9_]+\.(com|gr)$/,
	password:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
	firstName: /^[A-Z\-]+$/,
	lastName: /^[A-Z\-]+$/,
};

export { regexes };
