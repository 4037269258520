import RealityCheckPopup from "../../pages/my-account-page/player-protection-page/realityCheck-popup-modal";
import { useSocketContext } from "../../providers/socket-provider";


const RealityCheckListener = () => {
  const socket = useSocketContext();

  return <RealityCheckPopup socket={socket} />;
};

export default RealityCheckListener;