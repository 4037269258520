import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../utils/date';
import { Typography } from '../../../../components/typography';

import playerProtectionCommonClasses from '../style.module.scss';

const RestrictionPanel = ({ data = {} }) => {
	const { t } = useTranslation();

	return (
		<div className={playerProtectionCommonClasses.appliedLimitWrapper}>
			<div className={playerProtectionCommonClasses.limitFieldsWrapper}>
				<Typography type="st2" fontColor="primary">
					{t('started_on')}:
				</Typography>

				<Typography type="st2" fontColor="white">
					{formatDate(data?.startDate, true)}
				</Typography>

				<Typography type="st2" fontColor="primary">
					{t('ending_on')}:
				</Typography>

				<Typography type="st2" fontColor="white">
					{formatDate(data?.endDate, true)}
				</Typography>
			</div>
		</div>
	);
};

export default RestrictionPanel;
