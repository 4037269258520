import { useEffect, useMemo } from 'preact/hooks';

import { Typography } from '../../../../components/typography';

import { LimitPanel } from '../limit-panel';
import { AddLimitPanel } from '../add-limit-panel';

import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerLimits, selectLimits } from '../../../../store/limit-slice';
import { selectUserSession } from '../../../../store/authentication-slice';

export const BettingLimits = () => {
  const dispatch = useDispatch();

  const userSession = useSelector(selectUserSession);
  const bettingLimits = useSelector((state) => selectLimits(state, 'bet'));

  useEffect(() => {
    if (userSession)
		  dispatch(getPlayerLimits(userSession.id));
	}, [dispatch, userSession]);

	return (
		<>
			<Typography type="btn1" fontColor="white">
				Betting Limits
			</Typography>

			<AddLimitPanel isButtonDisabled={bettingLimits?.length > 2} playerId={userSession?.id} limitType="bet" />

			{bettingLimits.length === 0 ? (
				<Typography type="st2" fontColor="white" className={style.noLimitsText}>
					No limit defined
				</Typography>
			) : (
				<div className={style.limitsWrapper}>
					{bettingLimits?.map((limit, index) => (
						<LimitPanel key={`deposit-limit-${index}`} data={limit} />
					))}
				</div>
			)}
		</>
	);
};
