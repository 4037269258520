import {
	createSlice,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import * as service from '../services/game-service';
import {getTop10} from "../services/content-service";

const initialState = {
	game: null,
	topTenGames: [],
};

const fetchGame = createAsyncThunk('game/fetchOne', async (gameId = '') => {
	const response = await service.getGame(gameId);
	const data = await response.json();

	// returns an array with one game in it
	return data[0];
});

const fetchTopTenGames = createAsyncThunk(
	'game/fetchTopTen',
	async (country) => {
		// const response = await service.getTopTenGames(country);
		// return response.games;
		const response = await getTop10()
		return response;
	}
);

const gameSlice = createSlice({
	name: 'game',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchGame.fulfilled, (state, action) => {
			state.game = action.payload;
		});
		builder.addCase(fetchGame.rejected, (state) => {
			state.game = null;
		});
		builder.addCase(fetchTopTenGames.fulfilled, (state, action) => {
			state.topTenGames = action.payload;
		});
		builder.addCase(fetchTopTenGames.rejected, (state) => {
			state.topTenGames = [];
		});
	},
});

const selectGame = (state) => state.game.game;

const selectTopTenGames = (state) => state.game.topTenGames;

const selectFilteredByProviderCasinoGames = createSelector(
	(state) => state.categories.casinoCategories,
	(state) => state.filter.selectedProviders,
	(categories, selectedProviders) => {
		const gamesToFilter = categories.flatMap(
			(category) => category.games.items
		);
		return gamesToFilter.filter((game) =>
			selectedProviders.includes(game.subVendor)
		);
	}
);

const selectFilteredByProviderLiveCasinoGames = createSelector(
	(state) => state.categories.liveCasinoCategories,
	(state) => state.filter.selectedProviders,
	(categories, selectedProviders) => {
		const gamesToFilter = categories.flatMap(
			(category) => category.games.items
		);
		return gamesToFilter.filter((game) =>
			selectedProviders.includes(game.subVendor)
		);
	}
);

export {
	selectGame,
	selectTopTenGames,
	selectFilteredByProviderCasinoGames,
	selectFilteredByProviderLiveCasinoGames,
}; // selectors

export { fetchGame, fetchTopTenGames }; // thunks

export default gameSlice.reducer;
