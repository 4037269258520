import config from '../config';

const getPathName = () => window.location.pathname;

const getLangFromPathName = () => {
	const pathName = getPathName();
	const firstElFromPathName = pathName?.split('/')[1]
		? window.location.pathname.split('/')[1]
		: null;
	if (!firstElFromPathName) {
		return null;
	}

	const lang = config.languages.find((l) => l.code === firstElFromPathName);

	return lang?.code || null;
};

const getUrlWithoutLang = () => {
	const lang = getLangFromPathName();
	if (!lang) {
		return window.location.href;
	}

	return window.location.href.replace(`${lang}`, '');
};
const getDomainWithoutLang = () => {
	if (typeof window !== 'undefined') {
		const url = new URL(getUrlWithoutLang());
		return url.host;
	}
};

export { getUrlWithoutLang, getDomainWithoutLang };
