import { useCallback, useContext, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';
import ButtonRound from '../button-round';

import {
	selectSendVerificationEmailState,
	sendVerificationEmail,
} from '../../store/authentication-slice';
import { ToastMessagesContext } from '../../providers/toast-messages-provider';

import ExpiredLinkImage from '../../assets/images/email-link-expired.png';

import style from './style.module.scss';

const EmailLinkExpired = ({ token }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const verificationEmailState = useSelector(selectSendVerificationEmailState);
	const { onPushMessage } = useContext(ToastMessagesContext);

	useEffect(() => {
		if (verificationEmailState === 'success') {
			onPushMessage({
				title: t('email_verification'),
				message: t('email_verification_sent_successfully'),
				type: 'info',
			});
			route('/');
		}

		if (verificationEmailState === 'rejected') {
			onPushMessage({
				title: t('email_verification'),
				message: t('email_address_already_verified'),
				type: 'info',
			});
		}
	}, [t, onPushMessage, verificationEmailState]);

	const handleSendVerificationEmail = useCallback(() => {
		dispatch(sendVerificationEmail(token));
	}, [dispatch, token]);

	return (
		<div className={style.container}>
			<img src={ExpiredLinkImage} alt="image" />
			<div className={style.textWrapper}>
				<Typography type="h7" fontColor="white">
					{t('the_confirmation_link_has_expired')}
				</Typography>
				<ButtonRound onClick={handleSendVerificationEmail}>
					{t('resend_email')}
				</ButtonRound>
			</div>
		</div>
	);
};

export default EmailLinkExpired;
