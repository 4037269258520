import { CurrencySigns } from './consts';

export const formatCurrency = (value, currency = 'EUR') => {
	if (isNaN(value) || value === undefined) {
		return '';
	}

	const currencySign = Object.keys(CurrencySigns).includes(currency)
		? CurrencySigns[currency]
		: '';
	const formattedValue = parseFloat(value).toLocaleString('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: ['BTC', 'ETH'].includes(currency) ? 8 : 2,
	});

	return `${formattedValue} ${currencySign}`;
};
