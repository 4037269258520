import { useCallback, useMemo, useState } from 'preact/hooks';

import * as service from '../services/tags-service';

const useTags = (tagName, datasource = 'Casino') => {
	const [gamesByTagName, setGamesByTagName] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [totalGamesCount, setTotalGamesCount] = useState(0);

	const itemsPerPage = useMemo(() => 50, []);

	const offset = useMemo(
		() => itemsPerPage * pageNumber,
		[itemsPerPage, pageNumber]
	);

	const handleResetState = useCallback(() => {
		setTotalGamesCount(0);
		setPageNumber(0);
		setGamesByTagName([]);
	}, []);

	const fetchGamesByTagName = useCallback(async () => {
		try {
			const response = await service.getGamesByTagName(
				tagName,
				offset,
				itemsPerPage,
				datasource,
			);
			const data = await response.json();

			setTotalGamesCount(data.games.total);
			setPageNumber((currentPageNumber) => currentPageNumber + 1);
			setGamesByTagName((currentGames) => [
				...currentGames,
				...data.games.items,
			]);
		} catch (e) {
			handleResetState();
		}
	}, [offset, itemsPerPage]);

	return {
		gamesByTagName,
		totalGamesCount,
		loadedGamesCount: gamesByTagName.length,
		fetchGamesByTagName,
	};
};

export default useTags;

