import {
	useCallback,
	useEffect,
	useMemo,
	useContext,
} from 'preact/hooks';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CompanyLogo from '../company-logo';
import HeaderWallet from '../header-wallet';
import constants from '../../constants/general';
import Select from '../forms-new/select/select';
import { getLanguages } from '../../translations';
import { selectUser } from '../../store/user-slice';
import { useViewport } from '../../hooks/useViewport';
import { updatePlayerData } from '../../store/player-slice';
import AuthenticationActions from '../authentication-actions';
import * as playerService from '../../services/player-service';
import { HeaderRightUserSection } from '../header-right-user-section';
import { ToastMessagesContext } from '../../providers/toast-messages-provider';

import style from './style.module.scss';

import ToggleHideIcon from '../../assets/icons/dropdown-arrow-up-orange.svg';
import ToggleShowIcon from '../../assets/icons/dropdown-arrow-down-orange.svg';

const languages = getLanguages();

const Header = ({language, setLanguage}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const { isMobileScreen } = useViewport();

	const { onPushMessage } = useContext(ToastMessagesContext);

	const UserMenu = useMemo(
		() =>
			user ? (
				<>
					<HeaderWallet />
					<HeaderRightUserSection />
				</>
			) : (
				<AuthenticationActions />
			),
		[user]
	);

	const onChangeLanguageSelect = useCallback(
		async (selectedLanguage) => {
			if (selectedLanguage === language) {
				return;
			}

			if (user) {
				try {
					const response = await playerService.updatePlayerData(user.id, {
						language: selectedLanguage.toUpperCase(),
					});

					dispatch(updatePlayerData(response.data));

					i18n.changeLanguage(selectedLanguage);
				} catch {
					onPushMessage({
						message: t('an_error_occurred'),
						type: 'error',
						title: t('error'),
					});
				}
			} else {
				sessionStorage.setItem(
					constants.SESSION_STORAGE_SITE_LANGUAGE,
					selectedLanguage
				);
				i18n.changeLanguage(selectedLanguage);
			}
		},
		[t, user, dispatch, language, onPushMessage]
	);

	const onLanguageChanged = (language) => {
		setLanguage(language);
	};

	useEffect(() => {
		if (!i18n.isInitialized) {
			return;
		}

		i18n.on('languageChanged', onLanguageChanged);

		return () => {
			i18n.off('languageChanged', onLanguageChanged);
		};
	}, []);

	return (
		<header class={style.header}>
			<div class={style.wrapper}>
				{isMobileScreen && <CompanyLogo variant="4" />}
				<div>&nbsp;</div>

				<div className={style.rightWrapper}>
					{UserMenu}
					<Select
						value={language}
						options={languages}
						className={style.languageSelect}
						dropdownIconOpen={ToggleHideIcon}
						dropdownIconClosed={ToggleShowIcon}
						selectButtonClassName={style.languageSelectButton}
						onChange={onChangeLanguageSelect}
					/>
				</div>
			</div>
		</header>
	);
};

export default Header;
