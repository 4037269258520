import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// variant = "primary" | "secondary" | "ghost";
// zise = "large" | "mid" | "big1" | "big2" | "mid1" | "mid2" | "mid3" | "small"
// color = error | yellow | blue | light1 | white | dark1 | grey1 | grey2;

const MUIButton = styled(Button)(({ theme, colorStyle, size }) => {
	const bgColor = theme.palette[colorStyle]['100'];
	let width = 'fit-content';
	let height = '60px';
	let fontSize = '16px';
	let color = theme.palette.white['100'];

	if (colorStyle === 'error' || colorStyle === 'yellow') {
		color = theme.palette.black['100'];
	}

	if (size === 'big1') {
		width = '620px';
	} else if (size === 'big2') {
		width = '500px';
	} else if (size === 'mid') {
		height = '40px';
		fontSize = '14px';
	} else if (size === 'mid1') {
		height = '48px';
		fontSize = '14px';
		width = '360px';
	} else if (size === 'mid2') {
		height = '44px';
		fontSize = '14px';
		width = '400px';
	} else if (size === 'mid3') {
		height = '40px';
		fontSize = '14px';
		width = '360px';
	} else if (size === 'small') {
		height = '36px';
		fontSize = '14px';
		width = '160px';
	}
	return {
		[`&.MuiButton-root`]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			width,
			height,
			borderRadius: '6px',
			padding: '4px 12px',
			textTransform: 'none',

			p: {
				fontSize,
			},
		},

		[`&.MuiButton-primary`]: {
			background: bgColor,
			border: `1px solid ${bgColor}`,

			p: {
				color,
				whiteSpace: 'nowrap',
			},
		},

		[`&.MuiButton-secondary`]: {
			background: 'transparent',
			border: `1px solid ${bgColor}`,

			p: {
				color: bgColor,
			},
		},

		[`&.MuiButton-ghost`]: {
			background: 'transparent',
			border: 'none',

			p: {
				color: bgColor,
			},
		},

		'&:hover': {
			opacity: 0.8,
		},

		'&:disabled': {
			background: 'var(--color-grey-2-100)',
			border: '1px solid var(--color-grey-2-100)',
		},
	};
});

export default MUIButton;
