import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useContext, useCallback } from 'preact/hooks';

import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';
import { SwitchWithLabel } from '../../../../components/forms/switch';

import { useConsent } from './useConsent';
import { ToastMessagesContext } from '../../../../providers/toast-messages-provider';

import style from './../style.module.scss';

const Communication = () => {
	const {
		error,
		consentItems,
		updateConsent,
		setConsentItems,
		areConsentItemsLoading,
		areConsentItemsReceived,
		areConsentItemsUpdating,
		areConsentItemsSuccessfullyUpdated,
	} = useConsent();

	const { onPushMessage } = useContext(ToastMessagesContext);

	const onChangeConsentItemStatus = useCallback((item) => {
		return () => {
			if (item.status === 'Accepted') {
				item.status = 'Expired';
			} else {
				item.status = 'Accepted';
			}

			setConsentItems((items) =>
				items.map((consentItem) => {
					if (item.tagCode === consentItem.tagCode) {
						consentItem.status = item.status;
					}

					return consentItem;
				})
			);
		};
	}, []);

	useEffect(() => {
		if (error) {
			onPushMessage({
				type: 'error',
				title: 'Error',
				message: error,
			});
		}
	}, [error]);

	useEffect(() => {
		if (areConsentItemsSuccessfullyUpdated) {
			onPushMessage({
				type: 'info',
				title: 'Saved',
				message: 'The settings were saved',
			});
		}
	}, [areConsentItemsSuccessfullyUpdated]);

	return (
		<>
			{areConsentItemsLoading && (
				<div className={style.loaderWrapper}>
					<CircularProgress color="primary" />
				</div>
			)}

			{!areConsentItemsLoading && (
				<div className={style.contentWrapper}>
					<Typography
						type="h5"
						fontColor="white"
						className={style.contentTitle}
					>
						Communication
					</Typography>

					<div className={style.switchesWrapper}>
						{consentItems.map((item) => (
							<SwitchWithLabel
								key={item.tagCode}
								className={style.switch}
								checked={item.status === 'Accepted'}
								onClick={onChangeConsentItemStatus(item)}
								label={
									<>
										<Typography
											type="caption3"
											fontColor="white"
											className={style.bolded}
										>
											{item.friendlyName}
										</Typography>
									</>
								}
							/>
						))}
					</div>

					<div className={style.bottomContainer}>
						<ButtonRound
							className={style.saveButton}
							disabled={!areConsentItemsReceived || areConsentItemsUpdating}
							onClick={updateConsent}
						>
							<Typography type="btn3" fontColor="blue-3">
								Save
							</Typography>
						</ButtonRound>
					</div>
				</div>
			)}
		</>
	);
};

export default Communication;
