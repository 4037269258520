import {
	useRef,
	//  ChangeEventHandler
} from 'react';

import clsx from 'clsx';

import classes from './style.module.scss';

// type SwitchButtonProps = {
// 	isChecked: boolean;
// 	onChange: ChangeEventHandler<HTMLInputElement>;

// 	label?: React.ReactNode;
// 	labelWrapperClassName?: string;
// 	switchWrapperClassName?: string;
// };

const SwitchButton = ({
	label,
	isChecked = true,
	labelWrapperClassName = '',
	switchWrapperClassName = '',
	onChange,
}) => {
	const inputRef = useRef(null);

	return (
		<div className={clsx(classes.switchWrapper, switchWrapperClassName)}>
			<label className={classes.switch}>
				<input
					ref={inputRef}
					type="checkbox"
					checked={isChecked}
					onChange={onChange}
				/>
				<span className={classes.slider} />
			</label>

			{label && (
				<div className={clsx(classes.labelWrapper, labelWrapperClassName)}>
					{label}
				</div>
			)}
		</div>
	);
};

export default SwitchButton;
