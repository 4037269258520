import { baseSapphireApiUrl } from './constants';
import handleFetch from '../utils/handleFetch';
import { getAuthHeaders } from '../utils/auth';

export const getLastSuccessfulDeposit = (playerId) => {
    return handleFetch(
      `${baseSapphireApiUrl}/casino/rules/last-approved-deposit`,
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), 
        },
      }
    );
  };


  // should return median value and 2 suggested values
  export const getMedianValues=()=>{
    return handleFetch(
      `${baseSapphireApiUrl}/casino/rules/median-deposit`,
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), 
        },
      }
    );
  }