import { useSocketContext } from '../providers/socket-provider';
import { selectUser } from '../store/user-slice';

const setRealityCheckTimer = (intervalInMinutes) => {
	clearTimeout(window.realityCheckTimeout);
	const expirationTime = new Date().getTime() + intervalInMinutes * 60 * 1000;
	localStorage.setItem('realityCheckTimer', expirationTime);
	window.realityCheckTimeout = setTimeout(() => {
		handleRealityCheckExpiry();
	}, intervalInMinutes * 60 * 1000);
};

const clearRealityCheckTimer = () => {
	clearTimeout(window.realityCheckTimeout);
	localStorage.removeItem('realityCheckTimer');
};

const handleRealityCheckExpiry = () => {
    const expirationTime = localStorage.getItem('realityCheckTimer');
	const currentTime = new Date().getTime();
	if (expirationTime && currentTime >= expirationTime) {
		const socket = useSocketContext();
		const user = useSelector(selectUser);

		if (socket && user?.id) {
			socket.emit('reality-check');
			localStorage.removeItem('realityCheckTimer');
		}
	}
};

export {
	setRealityCheckTimer,
	clearRealityCheckTimer,
	handleRealityCheckExpiry,
};
