import {useEffect} from "preact/hooks";
import {useViewport} from "./useViewport";

const useClickOutside = (ref, action) => {
  const {isMobileScreen} = useViewport();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileScreen) {
        if (ref.current && !ref.current.contains(event.target)) {
          action(event);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [action, isMobileScreen, ref]);
}

export default useClickOutside;