import MUIButton from './style';

const IconWrapper = ({ iconUrl }) => {
	return iconUrl ? (
		<div className="icon-wrapper">
			<img src={iconUrl} alt="cards-icon" />
		</div>
	) : null;
};

export const ButtonNew = ({
	children,
	startIcon,
	size = 'large',
	color = 'blue',
	variant = 'primary',
	...rest
}) => {
	return (
		<MUIButton
			size={size}
			colorStyle={color}
			variant={variant}
			startIcon={startIcon ? <IconWrapper iconUrl={startIcon} /> : undefined}
			className="browser-default"
			{...rest}
		>
			{children}
		</MUIButton>
	);
};
