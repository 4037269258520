import { useMemo, useCallback } from 'preact/hooks';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

import { loginUser } from '../../store/authentication-slice';
import { getDeviceInfo } from '../../utils/device';
import { useTranslation } from 'react-i18next';
import {
	clearRealityCheckTimer,
	setRealityCheckTimer,
} from '../../utils/reality-check';
import { getPlayerRealityChecks } from '../../store/realityCheck-slice';

export const useSignInModal = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const SignInSchema = useMemo(
		() =>
			Yup.object().shape({
				username: Yup.string().required(t('username_is_required')),
				password: Yup.string().required(`${t('password_is_required')}!`),
			}),
		[t]
	);

	const handleSubmit = useCallback(
		async (values) => {
			clearRealityCheckTimer();
			const deviceInfo = await getDeviceInfo();
			await dispatch(loginUser({ ...values, ...deviceInfo }));
			const realityCheck = await dispatch(getPlayerRealityChecks()).unwrap();
			if (realityCheck) {
				setRealityCheckTimer(realityCheck.intervalInMinutes);
			}
		},
		[dispatch]
	);

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			currentCasinoSessionId: null,
		},

		validationSchema: SignInSchema,
		onSubmit: handleSubmit,
	});

	return { formik };
};
