import commonClasses from '../../style.module.scss';
import style from './style.module.scss';
import AmountContainer from '../components/amount-container';
import PaymentProvidersContainer from '../components/providers-container';
import Button from '../../../../components/forms-new/button/button';
import { useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getMedianValueAndSugestedOnes, selectLastDeposit, selectSuggestedAmounts } from '../../../../store/deposit-slice';
import { getLastSuccessfullDeposit } from '../../../../store/deposit-slice';
import { paymentProviders } from '../constants';
import { useTranslation } from 'react-i18next';

const DepositWithdrawal = ({ type }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [submit, setSubmit] = useState(false);
	const [amountSelected, setIsAmountSelected] = useState(false);
	const lastDeposit = useSelector(selectLastDeposit);
	const suggestedAmounts= useSelector(selectSuggestedAmounts);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('bank_transfer');
	
	const onDepositClick = () => {
		if (amountSelected) {
			setSubmit(true);
		}
	};

	 useEffect(() => {
    if (lastDeposit) {
      const lastDepositPaymentMethod = lastDeposit.paymentMethod;
      const validPaymentMethod = paymentProviders.some(
        (provider) => provider.payment_method === lastDepositPaymentMethod
      )
        ? lastDepositPaymentMethod
        : 'bank_transfer';

      setSelectedPaymentMethod(validPaymentMethod);
    }
  }, [lastDeposit]);

	const handlePaymentProviderSelect = (providerId) => {
		setSelectedPaymentMethod(providerId);
	  };

	if (submit) {
		return (
			<p className={style.submitMessage}>
				{t(
					`the_${
						type === 'deposit' ? 'deposit' : 'withdrawal'
					}_request_cannot_be_processed_at_the_moment`
				)}
			</p>
		);
	}

	useEffect(() => {
		dispatch(getLastSuccessfullDeposit());
		dispatch(getMedianValueAndSugestedOnes());
	}, [dispatch]);

	return (
		<div className={commonClasses.tabContent}>
			<div className={commonClasses.contentContainer}>
				<PaymentProvidersContainer
				onPaymentProviderSelect={handlePaymentProviderSelect}
				selectedPaymentProvider={selectedPaymentMethod}
				/>
				<AmountContainer
					type={type}
					setIsAmountSelected={setIsAmountSelected}
					initialAmount={lastDeposit ? lastDeposit.amount : null}
					initialCurrency={lastDeposit?.currency || 'EUR'}
					suggestedAmounts={suggestedAmounts}
				/>
				<Button className={style.depositButton} onClick={onDepositClick}>
					<span>{t(type)}</span>
				</Button>
			</div>
		</div>
	);
};

export default DepositWithdrawal;
