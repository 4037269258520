import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import {playerApiUrlV1, ipInfoApiUrl, sapphireIntegrationsApiUrl, baseSapphireApiUrl} from './constants';

const getUserInfoByIp = () => {
	return fetch(ipInfoApiUrl);
};

const getUser = (userId = '') => {
	return fetch(`${playerApiUrlV1}/${userId}/profile`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getFavouriteGames = () => {
	return fetch(`${baseSapphireApiUrl}/casino/games/favourites`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getRecentlyPlayedGames = () => {
	return fetch(`${baseSapphireApiUrl}/casino/games/recent`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getRecommendedGames = () => {
	return fetch(`${playerApiUrlV1}/recommendedGames`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const addGameToFavourites = (gameId = '') => {
	return fetch(`${baseSapphireApiUrl}/casino/games/addFavourite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({
			gameId,
		}),
	});
};

const removeGameFromFavourites = (gameId = '') => {
	return fetch(`${baseSapphireApiUrl}/casino/games/removeFavourite`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({
			gameId,
		}),
	});
};

const addToRecentlyPlayed = (gameId = '') => {
	return fetch(`${baseSapphireApiUrl}/casino/games/addRecent`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({
			gameId,
		}),
	});
};

const getBonusWallet = (userId) => {
	return fetch(`${playerApiUrlV1}/${userId}/bonusWallet`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getConsent = (userId) => {
	return fetch(`${playerApiUrlV1}/${userId}/consent`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const updateConsent = (userId, data) => {
	return fetch(`${playerApiUrlV1}/${userId}/consent`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		method: 'POST',
		body: JSON.stringify(data),
	});
};

const getUserLocationByIp = (ip) => {
	return handleFetch(`${sapphireIntegrationsApiUrl}/ip-location/${ip}`);
};

export {
	getUserInfoByIp,
	getUser,
	getConsent,
	updateConsent,
	getBonusWallet,
	getFavouriteGames,
	getRecentlyPlayedGames,
	getRecommendedGames,
	addGameToFavourites,
	removeGameFromFavourites,
	addToRecentlyPlayed,
	getUserLocationByIp,
};
