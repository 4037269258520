import { useState, useCallback } from 'preact/hooks';

import ButtonRound from '../../../../components/button-round';
import { Typography } from '../../../../components/typography';
import InputFile from '../../../../components/forms/input-file';

import style from './style.module.scss';

const Verify = () => {
	const [proofOfIdentityFrontFileName, setProofOfIdentityFrontFileName] =
		useState('');
	const [proofOfIdentityBackFileName, setProofOfIdentityBackFileName] =
		useState('');

	const onFileSelectProofOfIdentityFront = useCallback((files) => {
		if (files.length > 0) {
			setProofOfIdentityFrontFileName(files[0].name);
		}
	}, []);

	const onFileSelectProofOfIdentityBack = useCallback((files) => {
		if (files.length > 0) {
			setProofOfIdentityBackFileName(files[0].name);
		}
	}, []);

	return (
		<div className={style.pageWrapper}>
			<form className={style.formWrapper}>
				<Typography type="h5" fontColor="white" className={style.contentTitle}>
					Verification
				</Typography>

				<Typography type="st3" fontColor="white" className={style.note}>
					Please upload your proof of identity. All documents must be laying on
					a flat surface with all 4 corners inside the frame. All information
					should be clear and identifiable.
				</Typography>

				<InputFile
					label="Proof of Identity (Front)"
					filename={proofOfIdentityFrontFileName}
					inputProps={{
						accept: 'image/jpeg,image/png,application/pdf',
					}}
					wrapperClassName={style.proofOfIdentityFrontInput}
					onFileSelect={onFileSelectProofOfIdentityFront}
				/>

				<InputFile
					label="Proof of Identity (Back)"
					filename={proofOfIdentityBackFileName}
					inputProps={{
						accept: 'image/jpeg,image/png,application/pdf',
					}}
					wrapperClassName={style.proofOfIdentityBackInput}
					onFileSelect={onFileSelectProofOfIdentityBack}
				/>

				<Typography
					type="st3"
					fontColor="blue-100"
					className={style.acceptedFilesNote}
				>
					Following file types are accepted: .png, .jpg, .pdf
				</Typography>

				<Typography
					type="st3"
					fontColor="white"
					className={style.dataSafetyNote}
				>
					All data is safely stored and encrypted.
				</Typography>

				<ButtonRound
					disabled
					backgorundColor="blue"
					className={style.submitButton}
					onClick={undefined}
				>
					<Typography type="btn3" fontColor="blue-3">
						Submit
					</Typography>
				</ButtonRound>
			</form>
		</div>
	);
};

export default Verify;
