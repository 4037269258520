import { useMemo } from 'preact/hooks';

import clsx from 'clsx';

import Label from '../label';
import InputError from '../input-error';
import { Typography } from '../../typography';

import style from './style.module.scss';

const Input = ({
	name = '',
	label = '',
	value = '',
	helperText = '',
	errorMessage = '',
	placeholder = '',
	type = 'text',
	hasError = false,
	isRequired = true,
	Icon = null,
	wrapperClassName = '',
	disabled = false,
	customType = 'white', // 'white' || 'dark'
	isEmptyBottomTextContainerVisible = true,
	onBlur = () => {},
	onChange = () => {},
}) => {
	const isBottomTextWrapperVisible = useMemo(
		() =>
			isEmptyBottomTextContainerVisible
				? true
				: (hasError && errorMessage) || (helperText && !hasError),
		[isEmptyBottomTextContainerVisible, hasError, errorMessage, helperText]
	);

	return (
		<div
			className={clsx(
				style.commonWrapper,
				customType === 'white' ? style.lightWrapper : style.darkWrapper,
				wrapperClassName
			)}
		>
			{label && (
				<Label label={label} hasError={hasError} isRequired={isRequired} />
			)}

			<div
				className={clsx(style.inputWrapper, disabled && style.disabledInput)}
			>
				<input
					type={type}
					name={name}
					value={value}
					disabled={disabled}
					placeholder={placeholder}
					className={clsx(
						style.input,
						hasError && style.inputError,
						Icon && style.withIcon
					)}
					onChange={onChange}
					onBlur={onBlur}
				/>

				{Icon && <div className={style.iconWrapper}>{Icon}</div>}
			</div>

			{isBottomTextWrapperVisible && (
				<div className={style.bottomTextWrapper}>
					{hasError && errorMessage && (
						<InputError errorMessage={errorMessage} />
					)}

					{helperText && !hasError && (
						<Typography type="st3" fontColor="white">
							{helperText}
						</Typography>
					)}
				</div>
			)}
		</div>
	);
};

export default Input;
