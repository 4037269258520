import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import {
	brandId,
	playerApiUrlV1,
	playerApiUrlV2,
	sapphirePlayerApiUrl,
} from './constants';

const verifyUserSession = (shouldRefreshToken = false) => {
	return handleFetch(
		`${sapphirePlayerApiUrl}/session?refreshToken=${shouldRefreshToken}`,
		{
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
		}
	);
};

const loginUser = (data) => {
	return fetch(`${sapphirePlayerApiUrl}/login`, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
		method: 'POST',
	});
};

const getEmailAlreadyExists = (email) => {
	return fetch(`${sapphirePlayerApiUrl}/email-exists`, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ email }),
		method: 'POST',
	});
};

const registerUser = (data) => {
	return fetch(`${sapphirePlayerApiUrl}/register?brandId=${brandId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
		method: 'PUT',
	});
};

const logoutUser = () => {
	return fetch(`${sapphirePlayerApiUrl}/logout`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		method: 'POST',
	});
};

const sendResetPasswordEmail = (email = '') => {
	return fetch(`${sapphirePlayerApiUrl}/send-forgot-password-email`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify({ email }),
	});
};

const resetPassword = async (data) => {
	const { email, temporaryPassword, newPassword } = data;
	const response = await fetch(
		`${sapphirePlayerApiUrl}/change/forgotten-password?email=${email}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ temporaryPassword, newPassword }),
		}
	);
	return response.json();
};

const verifyEmail = (token = '') => {
	return fetch(`${sapphirePlayerApiUrl}/verify-email`, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ token }),
		method: 'POST',
	});
};

const sendVerificationEmail = (token = '') => {
	return fetch(`${sapphirePlayerApiUrl}/send-verification-email`, {
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ token }),
		method: 'POST',
	});
};

const verifyFirstStep = (data) => {
	return handleFetch(
		`${sapphirePlayerApiUrl}/first-step/validation?brandId=${brandId}`,
		{
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
			body: JSON.stringify(data),
			method: 'POST',
		}
	);
};

const verifySecondStep = (data) => {
	return handleFetch(
		`${sapphirePlayerApiUrl}/second-step/validation?brandId=${brandId}`,
		{
			headers: {
				'Content-Type': 'application/json',
				...getAuthHeaders(),
			},
			body: JSON.stringify(data),
			method: 'POST',
		}
	);
};

export {
	verifyUserSession,
	loginUser,
	registerUser,
	logoutUser,
	sendResetPasswordEmail,
	sendVerificationEmail,
	resetPassword,
	verifyEmail,
	getEmailAlreadyExists,
	verifyFirstStep,
	verifySecondStep,
};
