import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectCasinoCategories,
	fetchCasinoCategories,
} from '../../../../store/categories-slice';
import useUrl from '../../../../hooks/useUrl';

import GamesPage from '../../../games-page';

const CasinoGamesPage = () => {
	const dispatch = useDispatch();
	const casinoCategories = useSelector(selectCasinoCategories);
	const { getLastParam } = useUrl();

	const categoryId = useMemo(() => getLastParam(), [getLastParam]);

	useEffect(() => {
		dispatch(fetchCasinoCategories());
	}, []);

	return <GamesPage categoryId={categoryId} categories={casinoCategories} />;
};

export default CasinoGamesPage;

