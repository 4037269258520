import { useMemo, useState, useEffect } from 'preact/hooks';

export const useViewport = () => {
	const [sizes, setSizes] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const isPortraitMode = useMemo(() => sizes.width < sizes.height, [sizes]);

	const isMobileScreen = useMemo(() => sizes.width <= 768, [sizes.width]);

	useEffect(() => {
		const sizeListener = () => {
			setSizes({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', sizeListener);

		return () => {
			removeEventListener('resize', sizeListener);
		};
	}, []);

	return {
		...sizes,
		isPortraitMode,
		isMobileScreen,
	};
};
