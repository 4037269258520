import { useMemo, useEffect, useRef } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectCasinoCategories,
	fetchCasinoCategories,
} from '../../../../store/categories-slice';
import useCategoriesTab from '../../../../hooks/useCategoriesTab';

import LeaderboardWraper from '../../../../components/leaderboard-wrapper';
import ProvidersSlider from '../../../../components/providers-slider';
import Slider from '../../../../components/slider';
import Search from '../../../../components/search';
import Promotions from '../../../../components/promotions';

import style from './style.module.scss';
import {
	selectCasinoPromotions,
	selectCasinoProviders,
} from '../../../../store/content-slice';
import GamesFilterCasinoLayout from '../../../../components/games-filter-casino-layout';
import { selectFilterProviders } from '../../../../store/filter-slice';
import GamesTopTenLayout from '../../../../components/games-top-ten-layout';
import JackpotSection from '../../../../components/jackpot-section';
import CategoryTabs from '../../../../components/category-tabs';
import {
	fetchUserFavouriteGames,
	getUserLocationByIp,
	selectIpLocation, selectUser,
} from '../../../../store/user-slice';
import {
	fetchTopTenGames,
	selectTopTenGames,
} from '../../../../store/game-slice';

const CasinoHomePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const categoriesContainerRef = useRef(null);
	const categories = useSelector(selectCasinoCategories);
	const selectedProviders = useSelector(selectFilterProviders);
	const { casinoCategories } = useCategoriesTab(categories);
	const promotions = useSelector(selectCasinoPromotions);
	const location = useSelector(selectIpLocation);
	const topTenGames = useSelector(selectTopTenGames);
	const providers = useSelector(selectCasinoProviders);

	useEffect(() => {
		dispatch(fetchCasinoCategories());
		dispatch(getUserLocationByIp());
	}, [dispatch]);

	useEffect(() => {
		if (user) {
			dispatch(fetchUserFavouriteGames());
		}
	}, [user, dispatch]);

	useEffect(() => {
		if (location) {
			dispatch(fetchTopTenGames(location));
		}
	}, [dispatch, location]);

	const Categories = useMemo(() => {
		return Object.keys(casinoCategories).map((categoryId) => {
			const { id, name, items } = casinoCategories[categoryId];

			return (
				<Slider
					key={id}
					categoryId={id}
					title={name}
					items={items}
					backgroundProperty="thumbnail"
					isLive={false}
				/>
			);
		});
	}, [casinoCategories]);

	return (
		<div className={style.wrapper}>
			<Promotions promotions={promotions} />
			<div className={style.searchAndToggleButtonsContainer}>
				<Search searchBarClassName={style.searchBar} />

				<div className={style.toggleButtonsWrapper}>
					<CategoryTabs categories={categories} />
				</div>
			</div>

			<div className={style.categoriesContainer} ref={categoriesContainerRef}>
				<ProvidersSlider categoriesContainerRef={categoriesContainerRef} providers={providers} />
				{topTenGames.length && (
					<GamesTopTenLayout
						games={topTenGames}
						title={`${t('most_played')} ${t(location)}`}
					/>
				)}
				{!selectedProviders.length && Categories}
				{!!selectedProviders.length && <GamesFilterCasinoLayout />}
			</div>
			<JackpotSection />
			<div className={style.leaderboardWraper}>
				<LeaderboardWraper />
			</div>
			{/*{<AboutUs />}*/}
		</div>
	);
};

export default CasinoHomePage;
