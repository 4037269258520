import clsx from 'clsx';
import { Checkbox as MUICheckbox } from '@mui/material';
import { Typography } from '../../typography';
import InputError from '../input-error';

import style from './style.module.scss';

const Checkbox = ({
	name = '',
	label = '',
	errorMessage = '',
	checked = false,
	hasError = false,
	wrapperClassName = '',
	onBlur = () => {},
	onChange = () => {},
}) => {
	return (
		<div className={clsx(style.container, wrapperClassName)}>
			<div className={style.wrapper}>
				<MUICheckbox
					name={name}
					checked={checked}
					className={clsx(style.checkbox, hasError && style.inputError)}
					onBlur={onBlur}
					onChange={onChange}
				/>
				<Typography type="st3" fontColor="white">
					{label}
				</Typography>
			</div>

			{hasError && <InputError errorMessage={errorMessage} />}
		</div>
	);
};

export default Checkbox;
