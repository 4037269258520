import { useState } from "preact/hooks";
import clsx from "clsx";

import { Typography } from "../typography/index.js";

import Visibility from "../../assets/icons/visibility-on-password.svg";
import infoIcon from "../../assets/icons/field-info.svg";
import ErrorIcon from "../../assets/icons/error-icon.svg"

import {
  PasswordWrapper,
  StyledInputField,
  StyledFieldWrapper,
  StyledErrorMessage,
} from "./style.js";

export const InputField = ({
  name,
  onChange,
  onBlur,
  className,
  error,
  label = "ops",
  errorMessage,
  isPasswordField = false,
  isInfoField = false,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <StyledFieldWrapper className={clsx(error && "input-error")}>
      <StyledInputField
        label={label}
        onBlur={handleBlur}
        id="filled-helperText"
        onChange={handleChange}
        type={
          !isPasswordField || (isPasswordField && showPassword)
            ? "text"
            : "password"
        }
        className={clsx(className , "browser-default")}
        inputProps={{
          className: "browser-default",
          name,
        }}
        {...props}
      />

      {isPasswordField && (
        <PasswordWrapper
          showPassword={showPassword}
          onClick={handleClickShowPassword}
        >
          <img src={Visibility} />
        </PasswordWrapper>
      )}

      {isInfoField && (
        <PasswordWrapper showPassword={true}>
          <img src={infoIcon} />
        </PasswordWrapper>
      )}

      <StyledErrorMessage error={error}>
        <img src={ErrorIcon} alt="Error" />
        <Typography type="body3" fontColor="danger">
          {errorMessage}
        </Typography>
      </StyledErrorMessage>
    </StyledFieldWrapper>
  );
};
