import { useEffect, useMemo } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectVerifyEmailState,
	verifyEmail,
} from '../../store/authentication-slice';
import useUrl from '../../hooks/useUrl';
import EmailVerified from '../../components/email-verified';
import EmailLinkExpired from '../../components/email-link-expired';

const EmailVerificationPage = () => {
	const verifyEmailState = useSelector(selectVerifyEmailState);
	const dispatch = useDispatch();
	const { getQueryParam } = useUrl();

	const token = useMemo(() => getQueryParam('token'), [getQueryParam]);

	useEffect(() => {
		dispatch(verifyEmail(token));
	}, [dispatch, token]);

	if (verifyEmailState === 'rejected') {
		return <EmailLinkExpired token={token} />;
	}

  if (verifyEmailState === 'success') {
	  return <EmailVerified />;
  }

	return null;
};

export default EmailVerificationPage;

