import handleFetch from '../utils/handleFetch';
import { gamesApiUrl, dataSource, baseSapphireApiUrl } from './constants';

const getGame = (gameId = '') => {
	return fetch(`${gamesApiUrl}/${gameId}?expand=tags&language=en`);
};

const getTopTenGames = async (country) => {
	const res = await handleFetch(`${baseSapphireApiUrl}/casino/games/top-10/${country}`);
	return res
};

const getGamesByName = async (searchQuery = '', isLiveCasino = false) => {
	try {
		const { casino, liveCasino } = dataSource;
		const source = isLiveCasino ? liveCasino : casino;
		const response = await fetch(
			`${gamesApiUrl}?datasource=${source}&filter=name%3D${searchQuery}&language=en`
		);
		const data = await response.json();

		return data.items;
	} catch (e) {
		return [];
	}
};

export { getGame, getTopTenGames, getGamesByName };
