import { Typography } from '../../../components/typography';
import { formatCurrency } from '../../../utils/currency';

const TournamentDetailsStaticText = ({ tournament, style }) => {
	return (
		<div className={style.staticTextContainer}>
			<Typography type="h5" className={style.subHeader}>
				Join our weekly {tournament.name} tournament!
			</Typography>
			<Typography type="st3">
				Love the thrill of online casino games? Now you have the opportunity to
				win additional prizes by participating in the special {tournament.name}
				tournament! With a prize fund of {formatCurrency(
					tournament.payout
				)}{' '}
				distributed among the top 20 participants, you have the chance to win an
				additional prize in the form of bonus funds!
			</Typography>
			<Typography type="h5" className={style.subHeader}>
				How to register and participate in the tournament
			</Typography>
			<Typography type="st3">
				Registering for the tournament is quick and easy. Follow these steps:
			</Typography>
			<ul className={style.stepList}>
				<li>
					<Typography type="st3">
						Register - Register a game account if you don't have one yet.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Make a deposit - Load it to start playing with real money.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Select tournament - Visit the Casino Tournaments section and select
						the current Fazi tournament.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Play your favorite casino games - Play the most popular Fazi slot
						games and collect points. The more you play, the better your chance
						of finishing in the top 3 for a great bonus prize. Track your
						progress in real-time on the tournament page, and after making the
						minimum number of qualifying spins, you'll see your name on the
						leaderboard!
					</Typography>
				</li>
			</ul>
			<Typography type="h5" className={style.subHeader}>
				Why play online casino tournaments?
			</Typography>
			<Typography type="st3">
				Online casino tournaments are fun and offer an additional opportunity
				for winnings that make the game even more exciting:
			</Typography>
			<ul className={style.stepList}>
				<li>
					<Typography type="st3">
						Competitive Spirit - Enjoy the thrill of competing with other
						players.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Additional Prizes - Tournaments offer additional prizes and bonuses
						that go beyond the usual slot winnings.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Fun and Variety - Being able to play a variety of casino and slot
						games created by top casino manufacturers like Fazi makes the
						experience unique.
					</Typography>
				</li>
				<li>
					<Typography type="st3">
						Play your favorite casino games - Play the most popular Fazi slot
						games and collect points. The more you play, the better your chance
						of finishing in the top 3 for a great bonus prize. Track your
						progress in real-time on the tournament page, and after making the
						minimum number of qualifying spins, you'll see your name on the
						leaderboard!
					</Typography>
				</li>
			</ul>
		</div>
	);
};

export default TournamentDetailsStaticText;
