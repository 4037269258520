import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { route } from 'preact-router';

import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import {
	clearLoginError,
	selectLoginError,
	selectUserSession,
} from '../../store/authentication-slice.js';

import { Modal } from '../modal';
import { Typography } from '../typography';
import Input from '../forms/input';

import { useSignInModal } from './use-sign-in-modal.js';

import PasswordIcon from '../../assets/icons/password-icon.svg';
import PasswordIconHidden from '../../assets/icons/password-icon-hidden.svg';

import style from './style.module.scss';
import ButtonRound from '../button-round';

const SignInModal = ({
	open,
	onCloseModal,
	handleRedirectToSignUp,
	handleRedirectToForgotPassword,
}) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { onPushMessage } = useContext(ToastMessagesContext);
	const loginError = useSelector(selectLoginError);
	const userSession = useSelector(selectUserSession);
	const [shouldShowPassword, setShouldShowPassword] = useState(false);
	const { formik } = useSignInModal();

	useEffect(() => {
		formik.resetForm();
	}, [open]);

	useEffect(() => {
		if (loginError) {
			onPushMessage({ message: loginError, type: 'error' });
			dispatch(clearLoginError());
		}
	}, [loginError, dispatch, onPushMessage]);

	useEffect(() => {
		if (userSession) {
			onCloseModal();
			route('/casino/home');
		}
	}, [onCloseModal, userSession]);

	const handleClickPasswordIcon = useCallback(
		() => setShouldShowPassword((prev) => !prev),
		[]
	);

	const handlePressEnter = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				formik.handleSubmit();
			}
		},
		[formik]
	);

	return (
		<Modal
			open={open}
			onCloseModal={onCloseModal}
			className={style.modal}
			title={t('sign_In')}
		>
			<div className={style.loginWrapper}>
				<form onKeyDown={handlePressEnter} className={style.formWrapper}>
					<div className={style.inputsWrapper}>
						<Input
							name="username"
							label={t('email_or_username')}
							value={formik.values.username}
							errorMessage={formik.errors.username}
							hasError={formik.errors.username && formik.touched.username}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<Input
							name="password"
							label={t('password')}
							type={shouldShowPassword ? 'text' : 'password'}
							value={formik.values.password}
							errorMessage={formik.errors.password}
							hasError={formik.errors.password && formik.touched.password}
							Icon={
								<img
									className={style.icon}
									src={shouldShowPassword ? PasswordIconHidden : PasswordIcon}
									alt="show_password_icon"
									onClick={handleClickPasswordIcon}
								/>
							}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
						/>
					</div>

					<ButtonRound
						className={clsx(style.signInButton, style.continueButton)}
						onClick={formik.handleSubmit}
						buttonStyle={'primary'}
					>
						<Typography type="btn3" fontColor="blue-3">
							{t('continue')}
						</Typography>
					</ButtonRound>
				</form>

				<div className={style.footer}>
					<Typography
						type="st1"
						fontColor="white"
						className={style.forgotPassword}
						onClick={handleRedirectToForgotPassword}
					>
						{t('forgot_password')}?
					</Typography>
					<Typography type="st1" fontColor="white">
						{t('dont_have_an_account')}?{' '}
						<label className={style.link} onClick={handleRedirectToSignUp}>
							{t('register_an_account')}
						</label>
					</Typography>
				</div>
			</div>
		</Modal>
	);
};

export default SignInModal;
