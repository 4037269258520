import Transactions from '../common/transactions';
import { useBankingTransactionsPage } from '../common/useBankingTransactionsPage';

const Withdraws = () => {
	const {
		error,
		selectedPeriod,
		basicTableColumns,
		onClickNextButton,
		bankingTransactions,
		onChangePeriodSelect,
		onClickPreviousButton,
		filteredBankingTransactions,
		areBankingTransactionsLoading,
		areBankingTransactionsReceived,
	} = useBankingTransactionsPage({ type: 'Withdraw' });

	return (
		<Transactions
			error={error}
			transactionsTitle="Withdrawals"
			selectedPeriod={selectedPeriod}
			transactions={bankingTransactions}
			basicTableColumns={basicTableColumns}
			onClickNextButton={onClickNextButton}
			noTransactionsMessage="No withdrawals"
			onChangePeriodSelect={onChangePeriodSelect}
			onClickPreviousButton={onClickPreviousButton}
			filteredTransactions={filteredBankingTransactions}
			noDataMessage="No withdrawals for the selected period"
			areTransactionsLoading={areBankingTransactionsLoading}
			areTransactionsReceived={areBankingTransactionsReceived}
		/>
	);
};

export default Withdraws;
