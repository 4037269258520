import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    margin: "20px 0 !important",
    boxShadow: "none",
    borderRadius: "8px",
    background: "none",

    "&:before": {
      backgroundColor: "transparent",
    },
  };
});

export const StyledAccordionSummary = styled(AccordionSummary)(({}) => {
  return {
    ".MuiAccordionSummary-root.Mui-expanded": {
      height: "56px",
      maxHeight: "56px",
    },

    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",

      height: "56px",
      maxHeight: "56px",

      "&.Mui-expanded": {
        margin: "12px 0",
      },
    },
  };
});

export const StyledAccordionDetails = styled(AccordionDetails)(({}) => {});
