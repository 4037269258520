import clsx from 'clsx';

import { Button as DefaultButton } from '../../button';

import style from './style.module.scss';

const Button = ({
	backgroundColor = 'blue',
	onClick = () => {},
	className = '',
	disabled = false,
	children,
}) => {
	return (
		<DefaultButton
			className={clsx(
				style.button,
				style.moreSpecific,
				style.selector,
				className
			)}
			onClick={onClick}
			backgorundColor={backgroundColor}
			size="large"
			disabled={disabled}
		>
			{children}
		</DefaultButton>
	);
};

export default Button;
