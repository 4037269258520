import { useState, useEffect, useCallback, useMemo } from 'preact/hooks';

import RecentSearchService from '../utils/recentSearches';
import { getGamesByName } from '../services/game-service';

export const useSearch = (searchQuery = '', isLiveCasino = false) => {
	const [isLoading, setIsLoading] = useState(false);
	const [recentSearches, setRecentSearches] = useState([]);
	const [data, setData] = useState([]);

	const recentSearchService = useMemo(
		() => new RecentSearchService(isLiveCasino),
		[isLiveCasino]
	);

	const handleResetSearch = useCallback(() => {
		setData([]);
		setIsLoading(false);
	}, []);

	const handleSetRecentSearches = useCallback(() => {
		const newRecentSearches = recentSearchService.retrive();
		setRecentSearches(newRecentSearches);
	}, [recentSearchService]);

	const handleSearch = useCallback(async () => {
		if (searchQuery.length < 3) {
			handleResetSearch();
			return;
		}

		const games = await getGamesByName(searchQuery, isLiveCasino);

		recentSearchService.push(searchQuery);
		handleSetRecentSearches();
		setData(games);
		setIsLoading(false);
	}, [
		searchQuery,
		isLiveCasino,
		handleResetSearch,
		recentSearchService,
		handleSetRecentSearches,
	]);

	const handleClearSearch = useCallback(() => {
		recentSearchService.removeAll();
		handleSetRecentSearches();
	}, [recentSearchService, handleSetRecentSearches]);

	const handleDeleteSearchItem = useCallback(
		(id = 0) => {
			recentSearchService.remove(id);
			handleSetRecentSearches();
		},
		[recentSearchService, handleSetRecentSearches]
	);

	useEffect(() => {
		handleSetRecentSearches();
	}, [handleSetRecentSearches]);

	useEffect(() => {
		setIsLoading(true);

		const timeoutId = setTimeout(() => {
			handleSearch();
		}, 800);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [handleSearch]);

	return {
		data,
		isLoading,
		recentSearches,
		onClearSearch: handleClearSearch,
		onDeleteSearchItem: handleDeleteSearchItem,
	};
};
