import {
	useState,
	useCallback,
	useMemo,
	useEffect,
	useContext,
} from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ToastMessagesContext } from '../../providers/toast-messages-provider';
import {
	clearRegistrationData,
	clearRegistrationError,
	registerUser,
	selectRegistrationData,
	selectRegistrationError,
	selectStepOneValidation,
	selectStepTwoValidation,
	verifyFirstStep,
	verifySecondStep,
} from '../../store/authentication-slice';
import { fetchRegistrationConfiguration } from '../../store/configuration-slice';
import { getDeviceInfo } from '../../utils/device';

import RegistrationStepOne from '../registration-step-one';
import RegistrationStepTwo from '../registration-step-two';
import RegistrationStepThree from '../registration-step-three';

import { Modal } from '../modal';
import { Typography } from '../typography';

import BackIcon from '../../assets/icons/back-icon.svg';

import style from './style.module.scss';
import { Status } from '../../constants/enums';

const SignUpModal = ({ open, onCloseModal, handleRedirectToLogin }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { onPushMessage } = useContext(ToastMessagesContext);

	const registrationStateData = useSelector(selectRegistrationData);
	const registrationError = useSelector(selectRegistrationError);

	const [step, setStep] = useState(1);
	const [registrationData, setRegistrationData] = useState({});
	const stepOneValidationResult = useSelector(selectStepOneValidation);
	const stepTwoValidationResult = useSelector(selectStepTwoValidation);

	useEffect(() => {
		if (stepTwoValidationResult.status === Status.SUCCESS) setStep(3);
		else if (stepOneValidationResult.status === Status.SUCCESS) setStep(2);
	}, [stepOneValidationResult, stepTwoValidationResult]);

	useEffect(() => {
		dispatch(fetchRegistrationConfiguration());
	}, [dispatch]);

	useEffect(() => {
		if (registrationStateData) {
			onPushMessage({
				title: t('registration_successful'),
				message: t(
					'your_registration_is_underway_please_check_your_inbox_for_an_email_with_a_confirmation_link_you_need_to_verify_your_email_to_unlock_all_the_excitement_ahead'
				),
				type: 'info',
			});
			onCloseModal();
			dispatch(clearRegistrationData());
			setRegistrationData({});
			setStep(1);
		}
	}, [t, registrationStateData, onPushMessage, onCloseModal, dispatch]);

	useEffect(() => {
		if (registrationError) {
			onPushMessage({ message: registrationError, type: 'error' });
			dispatch(clearRegistrationError());
		}
	}, [registrationError]);

	const onClickStepOne = useCallback(
		(newData) => {
			setRegistrationData((currentData) => ({
				...currentData,
				[step]: newData,
			}));
			dispatch(verifyFirstStep(newData));
		},
		[step]
	);

	const onClickStepTwo = useCallback(
		(newData) => {
			setRegistrationData((currentData) => ({
				...currentData,
				[step]: newData,
			}));
			dispatch(verifySecondStep(newData));
		},
		[step]
	);

	const handleGoBack = useCallback(
		() => setStep((currentStep) => currentStep - 1),
		[]
	);

	const handleSubmit = useCallback(
		async (finalStepData) => {
			const { browserName, ip } = await getDeviceInfo();
			const {
				birth,
				email,
				firstName,
				lastName,
				mobile,
				password,
				username,
				title,
			} = registrationData['1'];

			const {
				address,
				answer,
				city,
				country,
				currency,
				language,
				postalCode,
				securityQuestion,
			} = registrationData['2'];

			const { acceptedTerms } = finalStepData;

			const data = {
				username,
				firstName,
				lastName,
				address,
				birth,
				city,
				country,
				currency,
				email,
				postalCode,
				password,
				securityAnswer: answer,
				securityQuestion,
				mobile,
				title,
				userConsents: {
					termsAndConditions: acceptedTerms,
					emailMarketing: true,
					sms: true,
				},
				language,
				browser: browserName,
				userIP: ip,
			};

			dispatch(registerUser(data));
		},
		[dispatch, registrationData]
	);

	const BackButton = useMemo(
		() => (
			<img
				className={style.icon}
				src={BackIcon}
				alt="back_arrow"
				onClick={handleGoBack}
			/>
		),
		[]
	);

	const { title, Component, SubtitleIcon } = useMemo(() => {
		const steps = {
			1: {
				title: t('step_1_of_3_fill_out_your_details'),
				Component: (
					<RegistrationStepOne
						buttonText={t('continue')}
						values={registrationData['1']}
						onSubmit={onClickStepOne}
					/>
				),
			},
			2: {
				title: t('step_2_of_3_fill_out_your_details'),
				Component: (
					<RegistrationStepTwo
						buttonText={t('continue')}
						values={registrationData['2']}
						onSubmit={onClickStepTwo}
					/>
				),
				SubtitleIcon: BackButton,
			},
			3: {
				title: t('step_3_of_3_read_and_accept_the_terms_and_conditions'),
				Component: (
					<RegistrationStepThree
						buttonText={t('play_now')}
						onSubmit={handleSubmit}
					/>
				),
				SubtitleIcon: BackButton,
			},
		};

		return steps[step];
	}, [
		t,
		step,
		BackButton,
		registrationData,
		onClickStepOne,
		onClickStepTwo,
		handleSubmit,
	]);

	return (
		<Modal
			open={open}
			onCloseModal={onCloseModal}
			title={t('create_an_account')}
			subtitle={title}
			SubtitleIcon={SubtitleIcon}
			className={style.modal}
		>
			<div>
				<form className={style.formWrapper}>{Component}</form>
				<div className={style.footer}>
					<Typography type="st1" fontColor="white" className={style.footerText}>
						{t('already_have_an_account')}{' '}
						<span className={style.link} onClick={handleRedirectToLogin}>
							{t('sign_in')}?
						</span>
					</Typography>
				</div>
			</div>
		</Modal>
	);
};

export default SignUpModal;
