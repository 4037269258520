import { styled, TextField } from "@mui/material";

export const StyledFieldWrapper = styled("div")(({}) => {
  return {
    position: "relative",
    
    [`&.input-error`]: {
      border: "1px solid red",
      borderRadius: "4px"
    },
  };
});

export const StyledInputField = styled(TextField)(
  ({ fullWidth, isFullfiled }) => {
    return {
      [`.MuiFormHelperText-root`]: {
        display: "none",
      },

      [`&.MuiFormControl-root`]: {
        width: fullWidth ? "100%" : "auto",
      },

      [`.MuiInputBase-input`]: {
        padding: "9.5px 14px",
      },

      [`.MuiInputLabel-root`]: {
        top: isFullfiled ? "0px" : "-5px",
      },

      [`.Mui-focused`]: {
        top: 0,
      },
    };
  }
);

export const StyledErrorMessage = styled("div")(({ error }) => {
  return {
    position: "absolute",
    bottom: "-22px",
    display: error ? "flex" : "none",
    color: "red",
    textAlign: "left",
    marginBottom: "0px",
  };
});

export const PasswordWrapper = styled("div")(({ showPassword }) => {
  return {
    position: "absolute",
    bottom: "6px",
    right: "16px",

    "&:after": {
      content: '""',
      position: "absolute",
      height: "14px",
      width: "1px",
      backgroundColor: "#000",
      right: "8px",
      top: "1px",
      transform: "rotate(45deg)",
      display: showPassword ? "none" : "block",
    },
  };
});
