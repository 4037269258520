const errorMessages = {
	dateOfBirth: {
		year: 'Year must be a number',
	},
	usernameMaxLength: 'Your username must be maximum 14 characters long',
	password:
		'Your password must be at least 8 characters with at least one capital and one lower case and one digit and one special character',
	// "Password: must contain 8 Latin characters, min. one digit and a symbol",
	number: 'Invalid number',
	// phoneNumber: "The number must start with 69 followed by 8 numbers",
};

const regexes = {
	username: /^[a-zA-Z0-9]+$/,
	email: /^[a-z0-9_\.]+@[a-z0-9_]+\.(com|gr)$/,
	password:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
	firstName: /^[A-Z\-]+$/,
	lastName: /^[A-Z\-]+$/,
	nationality: /^[A-Z]+$/,
	documentNumber: /^[A-Z0-9]+$/,
	phoneNumber: /^69\d{8}$/,
};

export { regexes, errorMessages };
