import { useCallback, useContext } from 'preact/hooks';

import { UrlContext } from '../providers/url-provider';

const useUrl = () => {
	const { url } = useContext(UrlContext);

	const getQueryParam = useCallback(
		(param = '') => {
			const searchQueryUrl = url.split('?').pop();
			const searchQuery = new URLSearchParams(searchQueryUrl);

			return searchQuery.get(param);
		},
		[url]
	);

	const getLastParam = useCallback(() => {
		const params = url.split('/');
		const lastParam = params.pop();

		return lastParam;
	}, [url]);

	return { url, getQueryParam, getLastParam };
};

export default useUrl;

