import { useMemo } from 'preact/hooks';

import { Router as PreactRouter } from 'preact-router';

import GamingHistory from './gaming-history';
import Redirect from '../../../components/redirect';
import TransactionHistory from './transaction-history';
import CommonContentPage from './../common-content-page';
import { pathnames } from '../../../constants/pathnames';

import TransactionsIcon from '../../../assets/icons/transactions-orange.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSession } from '../../../store/authentication-slice';
import { getPlayerTransactions } from '../../../store/player-slice';
import { useEffect } from 'preact/hooks';

const {
	account: { transactionHistoryPath, gamingHistoryPath, restTransactionsPath },
} = pathnames;

const TransactionsPage = () => {
	const { t } = useTranslation();
  const dispatch = useDispatch();
  const userSession = useSelector(selectUserSession);

  useEffect(() => {
    if (userSession) {
      dispatch(getPlayerTransactions(userSession.id));
    }
  }, [dispatch, userSession]);

	const tabs = useMemo(
		() => [
			{
				path: transactionHistoryPath,
				name: t('transaction_history'),
			},
			{
				path: gamingHistoryPath,
				name: t('gaming_history'),
			},
		],
		[t]
	);

	return (
		<CommonContentPage
			tabs={tabs}
			title={
				<>
					<img src={TransactionsIcon} />

					<div>{t('transactions')}</div>
				</>
			}
		>
			<PreactRouter>
				<TransactionHistory path={transactionHistoryPath} />

				<GamingHistory path={gamingHistoryPath} />

				<Redirect path={restTransactionsPath} to={transactionHistoryPath} />
			</PreactRouter>
		</CommonContentPage>
	);
};

export default TransactionsPage;
