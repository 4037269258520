import clsx from 'clsx';

import { Typography } from '../typography';

import backgroundImage from '../../assets/images/group-banner-default.png.png';

import styles from './style.module.scss';

const Banner = ({ label = '', containerClassName = '' }) => {
	return (
		<div className={clsx(styles.container, containerClassName)}>
			<Typography fontColor="white" className={styles.label} type="h4">
				{label}
			</Typography>
			<img src={backgroundImage} alt="banner" />
		</div>
	);
};

export default Banner;
