import clsx from 'clsx';
import { memo } from 'preact/compat';
import { MenuItem } from '@mui/material';

import { StyledSelect } from './style';
import classes from './style.module.scss';

import dropdownIcon from '../../assets/icons/dropdown-icon.svg';

const Icon = memo(() => (
	<img src={dropdownIcon} className={classes.dropdownIcon} />
));

export const Select = ({ items, value, onChange }) => {
	return (
		<StyledSelect
			value={value}
			IconComponent={Icon}
			onChange={onChange}
			MenuProps={{
				disableScrollLock: true,
				slotProps: {
					paper: {
						className: clsx(classes.styledSelect, classes.overriden),
					},
				},
			}}
		>
			{items.map((item, index) => (
				<MenuItem key={`${item.value.toString()}-${index}`} value={item.value}>
					{item.name}
				</MenuItem>
			))}
		</StyledSelect>
	);
};
