import DashboardNavigationIcon from './dashboard-navigation-icon';
import style from './style.module.scss';

const DashboardNavigation = ({ userMenuItems }) => {
	return (
		<div className={style.navigation}>
			{userMenuItems.map((menuRow, index) => {
				return (
					<div className={style.navigationRow} key={index}>
						{menuRow.map((menuItem, index) => (
							<DashboardNavigationIcon
								key={`menu-item-${index}`}
								handleClick={menuItem.onClickHandler}
								icon={menuItem.icon}
								text={menuItem.text}
							/>
						))}
					</div>
				);
			})}
		</div>
	);
};

export default DashboardNavigation;
