class RecentSearchService {
	#localStorageKey;

	constructor(isLiveCasino = false) {
		this.#localStorageKey = isLiveCasino
			? 'recentSearchesLiveCasino'
			: 'recentSearches';
	}

	#getRecentSearchesFromLocalStorage() {
		try {
			const persistedSearches = localStorage.getItem(this.#localStorageKey);

			if (persistedSearches) {
				return JSON.parse(persistedSearches);
			}

			return [];
		} catch (error) {
			return [];
		}
	}

	#setRecentSearches(recentSearches = []) {
		localStorage.setItem(this.#localStorageKey, JSON.stringify(recentSearches));
	}

	retrive() {
		return this.#getRecentSearchesFromLocalStorage();
	}

	push(searchQuery = '') {
		const recentSearches = this.#getRecentSearchesFromLocalStorage();
		const itemId = recentSearches[recentSearches.length - 1]
			? recentSearches[recentSearches.length - 1].id + 1
			: 1;
		const newItem = { id: itemId, value: searchQuery };

		if (!recentSearches.length) {
			this.#setRecentSearches([newItem]);
			return;
		}

		if (recentSearches.length >= 5) {
			recentSearches.shift();
		}

		const filteredRecentSearches = recentSearches.filter(
			({ value }) => value !== searchQuery
		);
		const newRecentSearches = [...filteredRecentSearches, newItem];
		this.#setRecentSearches(newRecentSearches);
	}

	remove(id = 0) {
		const recentSearches = this.#getRecentSearchesFromLocalStorage();
		const newRecentSearches = recentSearches.filter((item) => item.id !== id);

		this.#setRecentSearches(newRecentSearches);
	}

	removeAll() {
		this.#setRecentSearches([]);
	}
}

export default RecentSearchService;
