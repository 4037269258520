import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import { sapphireIntegrationsApiUrl } from './constants';

const generateSumsubAccessToken = () =>
	handleFetch(`${sapphireIntegrationsApiUrl}/sumsub/generate-access-token`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		method: 'POST',
	});

export { generateSumsubAccessToken };
