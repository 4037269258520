import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getErrorMessage } from '../utils/errors';
import * as service from '../services/authentication-service';
import { removeSessionToken, setSessionToken } from '../utils/auth';
import { Status } from '../constants/enums';

const initialState = {
	userSession: null,
	loginData: null,
	loginError: null,
	registrationData: null,
	registrationError: null,
	resetPasswordEmailData: null,
	resetPasswordData: null,
	resetPasswordError: null,
	isInitiallyVerifyCompleted: false,
	verifyEmailState: Status.PENDING,
	sendVerificationEmailState: Status.PENDING,
	stepOneValidationResult: {
		data: null,
		error: null,
		status: Status.PENDING,
	},
	stepTwoValidationResult: {
		data: null,
		error: null,
		status: Status.PENDING,
	},
};

const verifyUserSession = createAsyncThunk(
	'user/verifySession',
	async (shouldRefreshToken = false, { rejectWithValue }) => {
		try {
			const response = await service.verifyUserSession(shouldRefreshToken);
			const {
				data: { token, player },
			} = response;

			setSessionToken(token);

			return player;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const loginUser = createAsyncThunk(
	'user/login',
	async (data, { rejectWithValue }) => {
		const response = await service.loginUser(data);
		const userData = await response.json();

		if (response.status !== 200) {
			return rejectWithValue(userData.data);
		}

		const {
			data: { token, player },
		} = userData;
		setSessionToken(token);

		return player;
	}
);

const registerUser = createAsyncThunk(
	'user/register',
	async (data, { rejectWithValue }) => {
		const response = await service.registerUser(data);

		if (!response.ok) {
			const error = await response.json();
			const errorMessage = error.data;

			return rejectWithValue(errorMessage);
		}

		const userData = await response.json();
		return userData;
	}
);

const logoutUser = createAsyncThunk('user/logout', async () => {
	await service.logoutUser();
	removeSessionToken();
});

const sendResetPasswordEmail = createAsyncThunk(
	'user/sendResetPasswordEmail',
	async (email) => {
		const response = await service.sendResetPasswordEmail(email);
		const data = await response.json();

		return data;
	}
);

const resetPassword = createAsyncThunk(
	'user/resetPassword',
	async (passwordsData, { rejectWithValue }) => {
		const response = await service.resetPassword(passwordsData);
		if (response.success === false) {
			const errorMessage = getErrorMessage(response.data);
			return rejectWithValue(errorMessage);
		}
		return response;
	}
);

const verifyEmail = createAsyncThunk(
	'user/verifyEmail',
	async (token, { rejectWithValue }) => {
		const response = await service.verifyEmail(token);
		const data = await response.json();

		if (!data.success) {
			const errorMessage = data.data;
			return rejectWithValue(errorMessage);
		}

		return data;
	}
);

const sendVerificationEmail = createAsyncThunk(
	'user/sendVerificationEmail',
	async (token, { rejectWithValue }) => {
		const response = await service.sendVerificationEmail(token);
		const data = await response.json();

		if (!data.success) {
			const errorMessage = data.data;
			return rejectWithValue(errorMessage);
		}

		return data;
	}
);

const getEmailExists = createAsyncThunk(
	'user/getEmailExists',
	async (email, { rejectWithValue }) => {
		const response = await service.getEmailAlreadyExists(email);
		const data = await response.json();

		if (!data.success) {
			const errorMessage = data.data;
			return rejectWithValue(errorMessage);
		}

		return data;
	}
);

const verifyFirstStep = createAsyncThunk(
	'user/verifyFirstStep',
	async (data, { rejectWithValue }) => {
		try {
			const response = await service.verifyFirstStep(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const verifySecondStep = createAsyncThunk(
	'user/verifySecondStep',
	async (data, { rejectWithValue }) => {
		try {
			const response = await service.verifySecondStep(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		clearLoginError: (state) => {
			state.loginError = null;
		},
		clearRegistrationError: (state) => {
			state.registrationError = null;
		},
		clearResetPasswordError: (state) => {
			state.resetPasswordError = null;
		},
		clearStepsValidationResult: (state) => {
			state.stepOneValidationResult = {
				status: Status.PENDING,
				error: null,
				data: null,
			};
			state.stepTwoValidationResult = {
				status: Status.PENDING,
				error: null,
				data: null,
			};
		},
		clearRegistrationData: (state) => {
			state.registrationData = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(verifyUserSession.fulfilled, (state, action) => {
			state.userSession = action.payload;

			if (state.isInitiallyVerifyCompleted === false) {
				state.isInitiallyVerifyCompleted = true;
			}
		});
		builder.addCase(verifyUserSession.rejected, (state) => {
			state.userSession = null;
			state.loginData = null;

			if (state.isInitiallyVerifyCompleted === false) {
				state.isInitiallyVerifyCompleted = true;
			}
		});

		builder.addCase(logoutUser.fulfilled, (state) => {
			state.userSession = null;
			state.loginData = null;
		});

		builder.addCase(loginUser.fulfilled, (state, action) => {
			state.userSession = action.payload;
		});
		builder.addCase(loginUser.rejected, (state, action) => {
			state.userSession = null;
			state.loginError = action.payload;
		});

		builder.addCase(verifyFirstStep.fulfilled, (state, action) => {
			state.stepOneValidationResult = {
				data: action.payload,
				error: null,
				status: Status.SUCCESS,
			};
		});
		builder.addCase(verifyFirstStep.rejected, (state, action) => {
			state.stepOneValidationResult = {
				data: null,
				error: action.payload,
				status: Status.REJECTED,
			};
		});

		builder.addCase(verifySecondStep.fulfilled, (state, action) => {
			state.stepTwoValidationResult = {
				data: action.payload,
				error: null,
				status: Status.SUCCESS,
			};
		});
		builder.addCase(verifySecondStep.rejected, (state, action) => {
			state.stepTwoValidationResult = {
				data: null,
				error: action.payload,
				status: Status.REJECTED,
			};
		});

		builder.addCase(registerUser.fulfilled, (state, action) => {
			state.registrationData = action.payload;
			state.stepOneValidationResult = initialState.stepOneValidationResult;
			state.stepTwoValidationResult = initialState.stepTwoValidationResult;
		});
		builder.addCase(registerUser.rejected, (state, action) => {
			state.registrationError = action.payload;
		});

		builder.addCase(sendResetPasswordEmail.fulfilled, (state, action) => {
			state.resetPasswordEmailData = action.payload;
		});

		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.resetPasswordData = action.payload;
			window.location.href = '/password-reset-done';
		});
		builder.addCase(resetPassword.rejected, (state, action) => {
			state.resetPasswordError = action.payload;
		});

		builder.addCase(verifyEmail.fulfilled, (state) => {
			state.verifyEmailState = Status.SUCCESS;
		});
		builder.addCase(verifyEmail.rejected, (state) => {
			state.verifyEmailState = Status.REJECTED;
		});

		builder.addCase(sendVerificationEmail.fulfilled, (state) => {
			state.sendVerificationEmailState = Status.SUCCESS;
		});
		builder.addCase(sendVerificationEmail.rejected, (state) => {
			state.sendVerificationEmailState = Status.REJECTED;
		});

		builder.addCase(getEmailExists.fulfilled, (state) => {
			state.emailExists = {
				status: Status.SUCCESS,
				error: null,
			};
		});
		builder.addCase(getEmailExists.rejected, (state) => {
			state.emailExists = {
				status: Status.REJECTED,
				error: 'Email already exists',
			};
		});
	},
});

const selectUserSession = (state) => state.authentication.userSession;
const selectLoginData = (state) => state.authentication.loginData;
const selectLoginError = (state) => state.authentication.loginError;
const selectRegistrationData = (state) => state.authentication.registrationData;
const selectRegistrationError = (state) =>
	state.authentication.registrationError;
const selectResetPasswordEmailData = (state) =>
	state.authentication.resetPasswordEmailData;
const selectResetPasswordData = (state) =>
	state.authentication.resetPasswordData;
const selectResetPasswordError = (state) =>
	state.authentication.resetPasswordError;
const selectIsInitiallyVerifyCompleted = (state) =>
	state.authentication.isInitiallyVerifyCompleted;
const selectVerifyEmailState = (state) => state.authentication.verifyEmailState;
const selectSendVerificationEmailState = (state) =>
	state.authentication.sendVerificationEmailState;
const selectStepOneValidation = (state) =>
	state.authentication.stepOneValidationResult;
const selectStepTwoValidation = (state) =>
	state.authentication.stepTwoValidationResult;

export const {
	clearLoginError,
	clearRegistrationError,
	clearResetPasswordError,
	clearStepsValidationResult,
	clearRegistrationData,
} = authenticationSlice.actions;

export {
	selectUserSession,
	selectLoginData,
	selectLoginError,
	selectRegistrationData,
	selectRegistrationError,
	selectResetPasswordEmailData,
	selectResetPasswordData,
	selectResetPasswordError,
	selectIsInitiallyVerifyCompleted,
	selectVerifyEmailState,
	selectSendVerificationEmailState,
	selectStepOneValidation,
	selectStepTwoValidation,
}; // selectors

export {
	verifyUserSession,
	loginUser,
	registerUser,
	logoutUser,
	sendResetPasswordEmail,
	resetPassword,
	verifyEmail,
	verifyFirstStep,
	verifySecondStep,
	sendVerificationEmail,
	getEmailExists,
}; // thunks

export default authenticationSlice.reducer;
