import TabsNavigation from '../tabs-navigation';

import style from './../style.module.scss';

const CommonContentPage = ({ tabs, title, children }) => {
	return (
		<>
			<div className={style.tabsContentTitle}>{title}</div>

			<TabsNavigation tabs={tabs} />

			{children}
		</>
	);
};

export default CommonContentPage;
