import { useMemo, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import Select from '../../../../components/forms-new/select/select';
import Button from '../../../../components/forms-new/button/button';
import {
	createPlayerRealityCheck,
	getPlayerRealityChecks,
} from '../../../../store/realityCheck-slice';
import style from './style.module.scss';
import ToggleHideIcon from '../../../../assets/icons/dropdown-arrow-up-orange.svg';
import ToggleShowIcon from '../../../../assets/icons/dropdown-arrow-down-orange.svg';

const AddRealityCheckPanel = () => {
	const dispatch = useDispatch();
	const realityCheckOptions = useMemo(
        () => Array.from({ length: 24 }, (_, i) => `${i + 1} hour${i === 0 ? '' : 's'}`),
        []
      );

	const [realityCheck, setRealityCheck] = useState(realityCheckOptions[0]);

	const addRealityCheckHandler = async () => {
		const selectedOption = realityCheckOptions.find(
			(option) => option === realityCheck
		);
		let numberOfPeriods = 1;

		if (selectedOption) {
			const hoursMatch = selectedOption.match(/\d+/);
			if (hoursMatch) {
				numberOfPeriods = parseInt(hoursMatch[0], 10);
			}
		}
		const intervalInMinutes = numberOfPeriods * 60;
		const intervalInMilliseconds = intervalInMinutes * 60 * 1000;
		const endTime = Date.now() + intervalInMilliseconds;
		try {
			await dispatch(
				createPlayerRealityCheck({
					interval: intervalInMinutes,
				})
			).unwrap();

			const updatedRealityChecks = await dispatch(
				getPlayerRealityChecks()
			).unwrap();
			localStorage.setItem('realityCheckTimer', endTime.toString());
		} catch (error) {
			console.error('Failed to update reality checks:', error);
		}

		setIsModalOpen(false);
	};

	return (
		<div className={style.inputControlsWrapper}>
			<Select
				label="Interval"
				isRequired={false}
				value={realityCheck}
				options={realityCheckOptions}
				dropdownIconOpen={ToggleHideIcon}
				dropdownIconClosed={ToggleShowIcon}
				className={style.select}
				onChange={(value) => setRealityCheck(value)}
			/>
			<Button onClick={addRealityCheckHandler}>Apply</Button>
		</div>
	);
};

export default AddRealityCheckPanel;
