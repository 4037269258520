import { useSelector } from 'react-redux';
import { useMemo, useState, useEffect } from 'preact/hooks';

import { selectUser } from '../../../../store/user-slice';
import * as transactionService from '../../../../services/transactions-service';

const useBankingTransactions = ({ type }) => {
	const [error, setError] = useState('');
	const [bankingTransactions, setBankingTransactions] = useState([]);
	const [areBankingTransactionsLoading, setAreBankingTransactionsLoading] =
		useState(true);
	const [areBankingTransactionsReceived, setAreBankingTransactionsReceived] =
		useState(false);

	const user = useSelector(selectUser);

	const userId = useMemo(() => {
		if (user) {
			return user.id;
		}
		return -1;
	}, [user]);

	useEffect(async () => {
		if (userId === -1) {
			return;
		}

		try {
			setError('');
			setAreBankingTransactionsLoading(true);
			setAreBankingTransactionsReceived(false);

			const response = await transactionService.getBankingTransactions(userId);
			const result = await response.json();

			if (result.transactions) {
				const depositsOnly = result.transactions.filter(
					(transaction) =>
						transaction.type === type || transaction.type === `System${type}`
				);
				setBankingTransactions(depositsOnly);
				setAreBankingTransactionsLoading(false);
				setAreBankingTransactionsReceived(true);
			} else {
				setError(result.error);
			}
		} catch (error) {
			setError('An error has occured');
		}
	}, [userId]);

	return {
		error,
		bankingTransactions,
		areBankingTransactionsLoading,
		areBankingTransactionsReceived,
	};
};

export { useBankingTransactions };
