import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'preact/hooks';

import { clearUser } from '../store/user-slice.js';
import { setRecentWinners, setTopWinners } from '../store/leaderBoard.js';

export const useWebWorker = (worker) => {
	const dispatch = useDispatch();
	const [running, setRunning] = useState(false);

	const startProcessing = useCallback(
		(data) => {
			setRunning(true);
			worker.postMessage(data);
		},
		[worker]
	);

	useEffect(() => {
		const onMessage = (message) => {
			setRunning(false);

			if (message?.data?.error === 'Unautorised') {
				dispatch(clearUser());
			} else if (message?.data?.action === 'getTopWinners') {
				dispatch(setTopWinners(message?.data?.response));
			} else if (message?.data?.action === 'getRecentWinners') {
				dispatch(setRecentWinners(message?.data?.response));
			}
		};
		worker.addEventListener('message', onMessage);
		return () => worker.removeEventListener('message', onMessage);
	}, [dispatch, worker]);

	return {
		startProcessing,
		running,
	};
};
