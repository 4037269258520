import style from "./style.module.scss";
import {Typography} from "../typography";
import ButtonRound from "../button-round";
import {clsx} from "clsx";
import {useState} from "preact/hooks";
import { Modal } from '../modal';
import {useViewport} from "../../hooks/useViewport";

const PromotionCard = ({promotion, classes}) => {
  const {isMobileScreen} = useViewport();
  const [showInfo, setShowInfo] = useState(false);

  const onReadMoreClick = () => {
    setShowInfo(true);
  };

  const onCloseModal = () => {
    setShowInfo(false);
  }

  return (
    <>
      <div className={clsx(style.promotion, classes?.promotion)}>
        <div className={style.innerPromotionContainer}>
          <Typography
            type="h5"
            fontColor="white"
            className={style.promotionTitle}
          >
            {promotion.title}
          </Typography>

          <Typography
            type="caption3"
            fontColor="white"
            display="-webkit-box"
            className={style.description}
            dangerouslySetInnerHTML={{__html: promotion.shortDescription}}
          />

          <div className={style.btnWrapper}>
            <ButtonRound buttonStyle={'primary'} onClick={onReadMoreClick}>
              <Typography type="st4" fontColor="blue-grey">
                {promotion.ctaButtonText}
              </Typography>
            </ButtonRound>
          </div>
        </div>
        <img
          src={promotion.image}
          alt="promotion-image"
          className={style.promotionImage}
        />
      </div>
      {showInfo && (
        <Modal
          open={open}
          onCloseModal={onCloseModal}
          modalClassName={style.promoInfoModal}
        >
          <div className={style.promoWrapper}>
            <div className={style.headerWrapper}>
              {!isMobileScreen && <h5>{promotion.title}</h5>}
              <img
                src={promotion.image}
                alt="promotion-image"
                className={style.promotionImage}
              />
              {isMobileScreen && <h6>{promotion.title}</h6>}
            </div>
            <p dangerouslySetInnerHTML={{__html: promotion.description}} className={style.descriptionWrapper} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default PromotionCard;