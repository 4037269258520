import { useTranslation } from 'react-i18next';
import style from './style.module.scss';

const PasswordReset = () => {
	const { t } = useTranslation();

	return (
		<div className={style.modal}>
			<div className={style.header}>
				<h3 className={style.popUpHeader}>{t('password_change_successful')}</h3>
			</div>
			<div className={style.amountHeaderContainer}>
				<p>
					{t(
						'your_password_has_been_updated_be_sure_to_use_your_new_password_the_next_time_you_log_in'
					)}
				</p>
			</div>
		</div>
	);
};

export default PasswordReset;
