import { getCookie } from './auth';

export const mapMessageToWebWorker = ({
	method = 'GET',
	action = 'action',
	collection = '',
	pathname = '',
	api = '',
	body = {},
	params = {},
	headers = { 'Content-Type': 'application/json' },
	shouldInvalidate = false,
}) => {
	if (api === 'player') {
		const token = getCookie('auth');

		if (token) {
			headers = { ...headers, 'X-SessionId': token };
		}
	}
	return {
		method,
		action,
		collection,
		requestConfig: {
			body,
			pathname,
			params,
			headers,
		},
		shouldInvalidate,
	};
};
