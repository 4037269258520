import { useTranslation } from 'react-i18next';

import { Typography } from '../typography';

import EmailVerifiedImage from '../../assets/images/email-verified.png';

import style from './style.module.scss';

const EmailVerified = () => {
	const { t } = useTranslation();

	return (
		<div className={style.container}>
			<img src={EmailVerifiedImage} alt="image" />
			<div className={style.textWrapper}>
				<Typography type="h7" fontColor="white">
					{t('your_email_has_been_successfully_confirmed')}
				</Typography>
				<Typography type="h7" fontColor="white">
					{t('thank_you_for_verifying_your_address')}
				</Typography>
			</div>
		</div>
	);
};

export default EmailVerified;
