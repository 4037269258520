import { useSelector } from 'react-redux';

import {selectFooterSections} from '../../store/content-slice';

import FooterCategory from '../footer-category';

import style from './style.module.scss';

const FooterCategories = ({language}) => {
	const footerItems = useSelector(selectFooterSections(language));

	return (
		<div className={style.categories}>
			{!!footerItems && footerItems.map((props) => (
				<FooterCategory key={props.label} {...props} />
			))}
		</div>
	);
};

export default FooterCategories;

