import variables from '../style/_font-size-util.module.scss';

const { baseFontSizePx, minViewportWidthPx, maxViewportWidthPx } = variables;

const convertPxToRem = (sizePx) => {
	return sizePx / baseFontSizePx;
};

const calculateSlope = (
	fontSizeMinRem,
	fontSizeMaxRem,
	widthMinRem,
	widthMaxRem
) => {
	const slope = (fontSizeMaxRem - fontSizeMinRem) / (widthMaxRem - widthMinRem);
	return slope;
};

const calculateYIntercept = (
	fontSizeMinRem,
	fontSizeMaxRem,
	widthMinRem,
	widthMaxRem
) => {
	const slope = calculateSlope(
		fontSizeMinRem,
		fontSizeMaxRem,
		widthMinRem,
		widthMaxRem
	);
	const yIntercept = fontSizeMinRem - widthMinRem * slope;

	return yIntercept;
};

const calculatePrefferedFontSize = (
	fontSizeMinRem,
	fontSizeMaxRem,
	widthMinPx = minViewportWidthPx,
	widthMaxPx = maxViewportWidthPx
) => {
	const widthMinRem = widthMinPx / baseFontSizePx;
	const widthMaxRem = widthMaxPx / baseFontSizePx;
	const yInterceptRem =
		`${calculateYIntercept(
			fontSizeMinRem,
			fontSizeMaxRem,
			widthMinRem,
			widthMaxRem
		)  }rem`;

	const slopeVw =
		`${calculateSlope(fontSizeMinRem, fontSizeMaxRem, widthMinRem, widthMaxRem) *
			100 
		}vw`;

	return `${yInterceptRem  } + ${  slopeVw}`;
};

const getClampSizeFromRem = (
	fontSizeMinRem,
	fontSizeMaxRem,
	widthMinPx = minViewportWidthPx,
	widthMaxPx = maxViewportWidthPx
) => {
	const minFontSize = `${fontSizeMinRem  }rem`;
	const maxFontSize = `${fontSizeMaxRem  }rem`;

	return `clamp(
    ${minFontSize},
		${calculatePrefferedFontSize(
			fontSizeMinRem,
			fontSizeMaxRem,
			widthMinPx,
			widthMaxPx
		)},
		${maxFontSize}
	)`;
};

const getClampSizeFromPx = (
	fontSizeMinPx,
	fontSizeMaxPx,
	widthMinPx = minViewportWidthPx,
	widthMaxPx = maxViewportWidthPx
) => {
	const fontSizeMinRem = convertPxToRem(fontSizeMinPx);
	const fontSizeMaxRem = convertPxToRem(fontSizeMaxPx);

	return getClampSizeFromRem(
		fontSizeMinRem,
		fontSizeMaxRem,
		widthMinPx,
		widthMaxPx
	);
};

export { getClampSizeFromPx, getClampSizeFromRem };
