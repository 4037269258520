import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';

const StyledFormControlLabel = styled(FormControlLabel)(() => {
	return {
		gap: '16px',

		marginLeft: 0,
	};
});

export default StyledFormControlLabel;
