import { UAParser } from 'ua-parser-js';
import { getUserInfoByIp } from '../services/user-service';

const getUserLocation = async () => {
	try {
		const response = await getUserInfoByIp();
		const data = await response.json();
		const { ip, country, city } = data;

		return {
			ip,
			country,
			city,
		};
	} catch (e) {
		return {
			ip: 'unknown',
			country: 'unknown',
			city: 'unknown',
		};
	}
};

const getDeviceInfo = async () => {
	const parser = new UAParser();
	const browserInfo = parser.getResult();
	const browserName = browserInfo.browser.name || 'Unknown';
	const operatingSystem = browserInfo.os.name || 'Unknown';
	const deviceType = window.matchMedia('(max-width: 767px)').matches
		? 'Mobile'
		: 'Desktop';
	const { ip, country, city } = await getUserLocation();

	return {
		browserName,
		operatingSystem,
		deviceType,
		ip,
		country,
		location: city,
	};
};

export { getDeviceInfo };

