import { styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { getClampSizeFromPx } from './../../../utils/font-size';

export const StyledDatePicker = styled(DatePicker)(() => {
	return {
		width: '100%',
		height: '44px',
		minWidth: '165px',
		paddingLeft: '12px',
		borderRadius: '30px',
		backgroundColor: 'var(--color-light-1-100)',
		border: '2px solid var(--color-grey-1-100)',

		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
		},

		'input.MuiInputBase-input': {
			height: '40px',
			borderBottom: 0,
			marginBottom: 0,
			fontWeight: 600,
			lineHeight: 1.2,
			fontFamily: `'Figtree', 'Roboto'`,
			fontSize: getClampSizeFromPx(14, 16),
		},

		'input.MuiInputBase-input:focus:not([readonly])': {
			borderBottom: 0,
			boxShadow: 'none',
		},

		'input.MuiInputBase-input[aria-invalid="true"]': {
			opacity: 0.5,
			color: 'var(--color-bg-secondary)',
		},

		'.MuiInputAdornment-root': {
			marginLeft: 0,
		},

		'.MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
	};
});
