import clsx from 'clsx';
import TransactionRow from '../transaction-row';
import { Typography } from '../typography';
import classes from './style.module.scss';
import {useTranslation} from "react-i18next";

const TransactionTable = ({ transactions, columntCount, tableMapping }) => {
	const { t } = useTranslation();

	return (
		<div>
			<table className={classes.table}>
				<thead>
					<tr
						className={clsx(
							classes.tableHeaderRow,
							classes[`col-${columntCount}`]
						)}
					>
						{tableMapping.map((mapping, index) => (
							<th key={`head-${index}`} className={classes.headerColumn}>
								<Typography type="st3" fontColor="primary">
									{mapping.header}
								</Typography>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{transactions?.length > 0 &&
						transactions?.map((transaction, index) => (
							<TransactionRow
								key={`row-${index}`}
								transaction={transaction}
								tableMapping={tableMapping}
								columntCount={columntCount}
							/>
						))}

					{!transactions?.length && (
						<tr>
							<td colSpan={columntCount} className="noRecords">
								{t('no_transactions')}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default TransactionTable;
