import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/exclusion-service';

const initialState = {
	data: null,
	error: '',
};

const getPlayerExclusion = createAsyncThunk(
	'exclusion/get',
	async (playerId, { rejectWithValue }) => {
		try {
			const response = await service.getPlayerExclusion(playerId);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const createPlayerExclusion = createAsyncThunk(
	'exclusion/create',
	async (data, { rejectWithValue }) => {
		try {
			const response = await service.createPlayerExclusion(data);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const deletePlayerExclusion = createAsyncThunk(
	'exclusion/delete',
	async (playerExclusionId, { rejectWithValue }) => {
		try {
			const response = await service.deletePlayerExclusion(playerExclusionId);
			return response.data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const exclusionSlice = createSlice({
	name: 'exclusion',
	initialState,
	reducers: {
		resetExclusionError: (state) => {
			state.error = '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(createPlayerExclusion.fulfilled, (state, action) => {
			state.data = action.payload;
		});
		builder.addCase(createPlayerExclusion.rejected, (state, action) => {
			state.error = action.payload.data;
		});
		builder.addCase(getPlayerExclusion.fulfilled, (state, action) => {
			state.data = action.payload;
		});
	},
});

const selectExclusion = (state) =>
	state.exclusion?.data ? state.exclusion?.data[0] : null;
const selectExclusionError = (state) => state.exclusion?.error;

export const { resetExclusionError } = exclusionSlice.actions;

export { selectExclusion, selectExclusionError }; // selectors

export { getPlayerExclusion, createPlayerExclusion, deletePlayerExclusion }; // thunks

export default exclusionSlice.reducer;
