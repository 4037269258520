import {useSelector} from "react-redux";
import {selectFilteredByProviderCasinoGames} from "../../store/game-slice";
import GamesLayout from "../games-layout";

const GamesFilterCasinoLayout = () => {
  const filteredGames = useSelector(selectFilteredByProviderCasinoGames);

  return <GamesLayout games={filteredGames} />;
}

export default GamesFilterCasinoLayout;

