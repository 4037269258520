import clsx from 'clsx';
import { Card } from '@mui/material';
import { Link, getCurrentUrl, Router as PreactRouter } from 'preact-router';

import Redirect from '../../components/redirect';
import { Typography } from '../../components/typography';

import Verify from './components/verify';
// import Offers from './components/offers';
import General from './components/general';
import Communication from './components/communication';

import { pathnames } from '../../constants/pathnames';

import style from './style.module.scss';

import gearIcon from '../../assets/icons/gear-icon.svg';

const {
	settings: {
		// offersPath,
		verifyPath,
		generalPath,
		// securityPath,
		communicationPath,
	},
} = pathnames;

const navigationItems = [
	{
		text: 'General',
		url: generalPath,
	},
	// {
	// 	text: 'Security',
	// 	url: securityPath,
	// },
	{
		text: 'Communication',
		url: communicationPath,
	},
	{
		text: 'Verify',
		url: verifyPath,
	},
	// {
	// 	text: 'Offers',
	// 	url: offersPath,
	// },
];

const SettingsPage = () => {
	const currentUrl = getCurrentUrl();

	return (
		<div className={style.pageWrapper}>
			<div className={style.titleWrapper}>
				<img src={gearIcon} />

				<Typography type="h6" fontColor="white">
					Settings
				</Typography>
			</div>

			<div className={style.contentWrapper}>
				<Card className={style.navigation}>
					{navigationItems.map((item, index) => (
						<Link
							key={`${item.text.toLowerCase().replace(/\s/g, '')}-${index}`}
							href={item.url}
							className={clsx(item.url === currentUrl && style.activeNavItem)}
						>
							{item.text}
						</Link>
					))}
				</Card>

				<Card className={style.content}>
					<PreactRouter>
						<General path={generalPath} />

						{/* <Security path={securityPath} /> */}

						<Communication path={communicationPath} />

						<Verify path={verifyPath} />

						{/* <Offers path={offersPath} /> */}

						<Redirect path="/settings/:rest*" to={generalPath} />
					</PreactRouter>
				</Card>
			</div>
		</div>
	);
};

export default SettingsPage;
