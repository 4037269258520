import styles from './style.module.scss';
import {useState, useEffect} from "preact/hooks";
import OrangeLeftArrowIcon from '../../assets/icons/orange-arrow-left-arrow.svg';
import OrangeRightArrowIcon from '../../assets/icons/orange-arrow-right-arrow.svg';
import clsx from "clsx";
import {useViewport} from "../../hooks/useViewport";

const CarouselArrows = ({
                        children,
                        containerRef,
                        showArrows,
                        classNames,
                      }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRightMax, setScrollRightMax] = useState(0);
  const { isMobileScreen } = useViewport();

  const tolerance = 2;

  useEffect(() => {
    const updateScrollState = () => {
      const currentScrollLeft = containerRef.current?.scrollLeft || 0;
      const currentScrollWidth = containerRef.current?.scrollWidth || 0;
      const currentClientWidth = containerRef.current?.clientWidth || 0;
      const maxScrollLeft = currentScrollWidth - currentClientWidth;

      setScrollLeft(currentScrollLeft);
      setScrollRightMax(maxScrollLeft);
    };

    updateScrollState();
    containerRef.current?.addEventListener("scrollend", updateScrollState);

    return () => {
      containerRef.current?.removeEventListener("scrollend", updateScrollState);
    };
  }, [containerRef, containerRef.current?.scrollWidth]);

  useEffect(() => {
    const resetScroll = () => {
      containerRef.current?.scrollTo(0, 0);
    };

    window.addEventListener("resize", resetScroll);

    return () => {
      window.removeEventListener("resize", resetScroll);
    };
  }, [containerRef]);

  const leftScroll = () => {
    containerRef.current?.scrollBy(-(containerRef.current?.offsetWidth + (isMobileScreen ? 20 : 0)), 0);
  };

  const rightScroll = () => {
    containerRef.current?.scrollBy(containerRef.current?.offsetWidth + (isMobileScreen ? 20 : 0), 0);
  };

  return (
    <>
      {showArrows && scrollLeft > tolerance && !isMobileScreen && (
        <div
          className={clsx(
            styles.arrow,
            classNames?.arrow,
            styles.leftArrow,
            classNames?.leftArrow,
          )}
          onClick={leftScroll}
        >
          <img src={OrangeLeftArrowIcon}  />
        </div>
      )}
      {children}
      {showArrows && scrollLeft < scrollRightMax - tolerance && !isMobileScreen && (
        <div
          className={clsx(
            styles.arrow,
            classNames?.arrow,
            styles.rightArrow,
            classNames?.rightArrow,
          )}
          onClick={rightScroll}
        >
          <img src={OrangeRightArrowIcon} />
        </div>
      )}
    </>
  );
};

export default CarouselArrows;