import { useCallback } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setShowLogin, setShowSignUp } from '../../store/theme-slice';

import ButtonRound from '../button-round';
import { Typography } from '../typography';

import style from './style.module.scss';

const AuthenticationActions = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleSignUpClick = useCallback(
		() => dispatch(setShowSignUp(true)),
		[dispatch]
	);

	const handleSignInClick = useCallback(
		() => dispatch(setShowLogin(true)),
		[dispatch]
	);

	return (
		<div className={style.authButtonContainers}>
			<ButtonRound
				buttonStyle="ghost"
				className={style.authButton}
				onClick={handleSignInClick}
			>
				<Typography type="st4" fontColor="white">
					{t('sign_in')}
				</Typography>
			</ButtonRound>

			<ButtonRound
				onClick={handleSignUpClick}
				className={style.authButton}
				buttonStyle={'primary'}
			>
				<Typography type="btn3" fontColor="blue-grey">
					{t('register')}
				</Typography>
			</ButtonRound>
		</div>
	);
};

export default AuthenticationActions;
