import { getAuthHeaders } from '../utils/auth';
import handleFetch from '../utils/handleFetch';
import { sapphirePlayerApiUrl } from './constants';

const getPlayerData = (id) => {
	return handleFetch(`${sapphirePlayerApiUrl}/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const getPlayerTransactions = (id) => {
	return handleFetch(`${sapphirePlayerApiUrl}/${id}/transactions`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
	});
};

const updatePlayerData = (id, data) => {
	return handleFetch(`${sapphirePlayerApiUrl}/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify(data),
		method: 'PUT',
	});
};

const resetPlayerPassword = (id, data) => {
	return handleFetch(`${sapphirePlayerApiUrl}/reset-password/${id}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify(data),
		method: 'POST',
	});
};

// const getPlayerSessionTransactions = async (params) => {
// 	const { playerId, page, rowsPerPage, fromDate, toDate } = params;

// 	const searchParams = {
// 		...params,
// 		page,
// 		rowsPerPage,
// 		fromDate: fromDate || '',
// 		toDate: toDate || '',
// 		playerId,
// 	};

// 	const searchQuery = new URLSearchParams(searchParams).toString();
// 	return await axios.get(
// 		`${sapphirePlayerApiUrl}/player-session-transactions?${searchQuery}`,
// 		{
// 			headers: {
// 				'Content-Type': 'application/json',
// 				...getAuthHeaders(),
// 			},
// 		}
// 	);
// };

// const updatePlayerActiveStatus = async (data) => {
// 	return await axios.put(`${sapphirePlayerApiUrl}/player-active-status`, data, {
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...getAuthHeaders(),
// 		},
// 	});
// };

export {
	getPlayerData,
	getPlayerTransactions,
	updatePlayerData,
	resetPlayerPassword,
	// getPlayerSessionTransactions,
	// updatePlayerActiveStatus,
};
