import { useSelector } from 'react-redux';

import {selectFooterContent} from '../../store/content-slice';

import MoreInfo from '../more-info';

const AboutUs = () => {
	const footerContent = useSelector(selectFooterContent);

	return <MoreInfo section={footerContent.aboutUs} />;
};

export default AboutUs;

