import clsx from "clsx";
import style from "./style.module.scss";

const ProvidersItem = ({item, index, onClick, isActive}) => {

  return (
    <div
      onClick={() => onClick(item.title)}
      key={index}
      className={clsx(style.providerItemContainer, isActive && style.selectedProviderItem)}
    >
      <div
        className={style.providerItem}
      >
        <img
          src={item['logo']}
          alt={item['logo']}
          loading="lazy"
        />
      </div>
    </div>
  )
};

export default ProvidersItem;