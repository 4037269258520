import { useMemo } from 'preact/hooks';

import { Router as PreactRouter } from 'preact-router';

import Redirect from '../../../components/redirect';
import DepositWithdrawal from './deposit-withdrawal';
import CommonContentPage from './../common-content-page';
import { pathnames } from '../../../constants/pathnames';

import WalletIcon from '../../../assets/icons/wallet-orange.svg';
import { useTranslation } from 'react-i18next';

const {
	account: { depositsPath, withdrawalsPath, restWalletPath },
} = pathnames;

const WalletPage = () => {
	const { t } = useTranslation();

	const tabs = useMemo(
		() => [
			{
				path: depositsPath,
				name: t('deposit'),
			},
			{
				path: withdrawalsPath,
				name: t('withdrawal'),
			},
		],
		[t]
	);

	return (
		<CommonContentPage
			tabs={tabs}
			title={
				<>
					<img src={WalletIcon} />

					<div>{t('wallet')}</div>
				</>
			}
		>
			<PreactRouter>
				<DepositWithdrawal type="deposit" path={depositsPath} />

				<DepositWithdrawal type="withdrawal" path={withdrawalsPath} />

				<Redirect path={restWalletPath} to={depositsPath} />
			</PreactRouter>
		</CommonContentPage>
	);
};

export default WalletPage;
