import {
	mapBanners, mapCategories,
	mapFooter,
	mapMenuItemsArrayToObject,
	mapPages,
	mapPromotions,
	mapProviders,
} from '../utils/wordpress';
import {wordpressApiUrl} from './constants';

const getCasinoMenu = async () => {
	const response = await fetch(`${wordpressApiUrl}/menu_section`);
	const data = await response.json();
	return mapMenuItemsArrayToObject(data);
};

const getFooterContent = async () => {
	const response = await fetch(`${wordpressApiUrl}/footer`);
	const data = await response.json();
	return mapFooter(data);
};

const getPromotionsContent = async () => {
	const response = await fetch(`${wordpressApiUrl}/promotions`);
	const data = await response.json();
	return mapPromotions(data);
};

const getBannerContent = async () => {
	const response = await fetch(`${wordpressApiUrl}/banners`);
	const data = await response.json();
	return mapBanners(data);
};

const getAllPages = async () => {
	const response = await fetch(`${wordpressApiUrl}/pages`);
	const data = await response.json();
	return mapPages(data);
};

const getProviders = async () => {
	const response = await fetch(`${wordpressApiUrl}/providers?per_page=100`);
	const data = await response.json();
	return mapProviders(data);
};

const getGameCategories = async () => {
	const response = await fetch(`${wordpressApiUrl}/game_category`);
	const data = await response.json();
	return mapCategories(data);
};

const getTop10 = async () => {
	const response = await fetch(`${wordpressApiUrl}/top_10`);
	const data = await response.json();
	return data?.[0].games.map(item => ({
		name: item.game_name,
		gameId: Number(item.game_id),
		subVendor: item.provider,
		thumbnail: item.thumbnail,
		volatility: item.volatility,
		tags: item.visible_tags[0] !== "" ? item.visible_tags : [],
		rtp: item.rtp,
		lines: item.lines,
		reels: item.reels,
		lobby: item.lobby,
	}))
};

export {
	getCasinoMenu,
	getFooterContent,
	getPromotionsContent,
	getBannerContent,
	getAllPages,
	getProviders,
	getGameCategories,
	getTop10,
};
