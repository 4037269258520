import CarouselArrows from "../carousel-arrows";
import {useRef} from "preact/hooks";
import styles from "./style.module.scss";
import clsx from "clsx";

const Carousel = ({children, classes}) => {
  const carouselRef = useRef(null);

  return (
    <div className={styles.carouselContainer}>
      <CarouselArrows containerRef={carouselRef} showArrows={true} classNames={{arrow: classes?.arrows}} >
        <div className={clsx(styles.itemContainer, classes?.itemContainer)} ref={carouselRef}>
          {children}
        </div>
      </CarouselArrows>
    </div>
  )
}

export default Carousel;