export default () => {
	const fetchInstance = (
		pathname,
		version = 'v1',
		api = 'player',
		options = {},
		params = {}
	) => {
		const fetchUrl = new URL(
			`https://betsino-api.stage.norway.everymatrix.com/${version}/${api}/${pathname}`
		);

		const paramKeys = Object.keys(params);

		if (paramKeys.length) {
			paramKeys.forEach((key) =>
				fetchUrl.searchParams.append(key, params[key])
			);
		}

		return fetch(fetchUrl, options).then((response) => {
			if (response.status !== 200) {
				console.log('the error ===>', response);
				throw new Error('Unautorised');
			}

			return response.json();
		});
	};

	const signIn = (method, body, params, headers) => {
		return fetchInstance(`login/player`, 'v1', 'player', {
			method,
			body,
			headers,
		});
	};

	const getCategories = (method, body, params, headers) => {
		return fetchInstance(
			`categories`,
			'v1',
			'casino',
			{
				method,
				body,
				headers,
			},
			{ language: 'en', expand: 'games' }
		);
	};

	const getLiveCasinoCategories = (method, body, params, headers) => {
		return fetchInstance(
			`liveCasinoCategories`,
			'v1',
			'casino',
			{
				method,
				body,
				headers,
			},
			{ language: 'en', expand: 'games' }
		);
	};

	const getGames = (method, body, params, headers) => {
		return fetchInstance(`login/player`, {
			method,
			body,
			headers,
		});
	};

	const getVendors = (method, body, params, headers) => {
		return fetchInstance(
			`vendors`,
			'v1',
			'casino',
			{
				method,
				body,
				headers,
			},
			{ language: 'en' }
		);
	};

	const quickRegister = (method, body, params, headers) => {
		return fetchInstance(`quickRegister`, 'v1', 'player', {
			method,
			body,
			headers,
		});
	};

	const sendResetPasswordEmail = (method, body, params, headers) => {
		return fetchInstance(
			`sendResetPasswordEmail`,
			'v1',
			'player',
			{
				method,
				body,
				headers,
			},
			params
		);
	};

	const verifySession = (method, body, params, headers) => {
		return fetchInstance('session/player', 'v1', 'player', {
			method,
			body,
			headers,
		});
	};

	const getTopWinners = (method, body, params, headers) => {
		return fetchInstance('top-winners', 'v1', 'casino', {
			method,
			body,
			headers,
		});
	};

	const getRecentWinners = (method, body, params, headers) => {
		return fetchInstance(
			'recent-winners',
			'v1',
			'casino',
			{
				method,
				body,
				headers,
			},
			params
		);
	};

	const getGamesByTagName = (method, body, params, headers, pathname) => {
		return fetchInstance(
			`tags/${pathname}`,
			'v1',
			'casino',
			{
				method,
				body,
				headers,
			},
			params
		);
	};

	const cachedData = [];

	const services = {
		getVendors: {
			fetch: getVendors,
			action: 'getVendors',
			collection: 'vendors',
		},

		getCategories: {
			fetch: getCategories,
			action: 'getCategories',
			collection: 'categories',
		},

		getLiveCasinoCategories: {
			fetch: getLiveCasinoCategories,
			action: 'getLiveCasinoCategories',
			collection: 'liveCasionoCategories',
		},

		getGames: {
			fetch: getGames,
			action: 'signIn',
			collection: 'game',
		},

		signIn: {
			fetch: signIn,
			action: 'signIn',
			collection: 'user',
		},

		quickRegister: {
			fetch: quickRegister,
			action: 'quickRegister',
			collection: 'user',
		},
		sendResetPasswordEmail: {
			fetch: sendResetPasswordEmail,
			action: 'sendResetPasswordEmail',
			collection: 'user',
		},

		verifySession: {
			fetch: verifySession,
			action: 'verifySession',
			collection: 'session',
		},

		getTopWinners: {
			fetch: getTopWinners,
			action: 'getTopWinners',
			collection: 'topWinners',
		},

		getRecentWinners: {
			fetch: getRecentWinners,
			action: 'getRecentWinners',
			collection: 'recentWinners',
		},

		getGamesByTagName: {
			fetch: getGamesByTagName,
			action: 'getGamesByTagName',
			collection: 'gamesByTagName',
		},
	};

	self.addEventListener('message', (message) => {
		try {
			const method = message?.data?.method;
			const action = message?.data?.action;
			let body = message?.data?.requestConfig?.body;
			const params = message?.data?.requestConfig?.params;
			const headers = message?.data?.requestConfig?.headers;
			const pathname = message?.data?.requestConfig?.pathname;

			body = Object.keys(body).length ? JSON.stringify(body) : null;

			if (action) {
				const service = services[action];

				const collection = service.collection;

				const shouldInvalidate = message?.data?.shouldInvalidate;

				if (shouldInvalidate) {
					cachedData[collection] = null;
				}

				try {
					if (cachedData[collection]) {
						console.log('data');
						self.postMessage({ data: cachedData[collection], collection });
					} else {
						if (!service) {
							console.log('error');
							self.postMessage({ error: 'Invalid service', collection });
						}

						service
							.fetch(method, body, params, headers, pathname)
							.then((response) => {
								// console.log('3 fetch success for ===>', action);
								// console.log('responscheto mi ===>', response);
								// console.log('4');
								cachedData[collection] = response;
								self.postMessage({ response, collection, action });
							})
							.catch((error) => {
								console.log('error in da catch', error);
								self.postMessage({ error: error?.message });
							});
					}
				} catch (error) {
					console.log('error', error);
					self.postMessage({ error: error?.message });
				}
			}
		} catch (error) {
			console.log('error', error);
			return postMessage({ error: error?.message });
		}
	});
};
