import { useMemo } from 'preact/hooks';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
	selectCasinoMenu,
	selectGeneralMenu,
	selectLiveCasinoMenu,
} from '../../store/content-slice';
// import { pathnames } from '../../constants/pathnames';
import { selectUserSession } from '../../store/authentication-slice';

import useUrl from '../../hooks/useUrl';

import CategoryMenu from '../category-menu';

// import MyAccountIcon from '../../assets/icons/my-account-white.svg';
import CasinoIcon from '../../assets/icons/casino-icon.svg';
import LiveCasinoIcon from '../../assets/icons/live-casino-icon.svg';

import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const LeftPanelMenu = ({
	onTrigger = () => {},
	onMouseEnter = () => {},
	onMouseLeave = () => {},
	isMenuOpen,
	menuCategoryClass = '',
	leftPanelMenuClass = '',
	expandedSideMenuItemClass = '',
	collapsedSideMenuItemClass = '',
	lang,
}) => {
	const { t } = useTranslation();
	const userSession = useSelector(selectUserSession);
	const generalMenu = useSelector(selectGeneralMenu(lang));
	const casinoMenu = useSelector(selectCasinoMenu(lang));
	const liveCasinoMenu = useSelector(selectLiveCasinoMenu(lang));

	const { url } = useUrl();

	const isAuthenticated = useMemo(() => Boolean(userSession), [userSession]);

	const categoryMenuProps = useMemo(
		() => ({
			isAuthenticated,
			menuCategoryClass,
			expandedSideMenuItemClass,
			collapsedSideMenuItemClass,
			onToggleSidebarVisibility: onTrigger,
		}),
		[
			onTrigger,
			isAuthenticated,
			menuCategoryClass,
			expandedSideMenuItemClass,
			collapsedSideMenuItemClass,
		]
	);

	const ActiveCasinoCategory = useMemo(() => {
		const isLiveCasinoCategoryActive = url.includes('live-casino');
		const isCasinoCategoryActive = url.includes('casino');

		if (isLiveCasinoCategoryActive) {
			return (
				<CategoryMenu
					categoryMenuItems={liveCasinoMenu.items}
					{...categoryMenuProps}
					isMenuOpen={isMenuOpen}
				/>
			);
		}

		if (isCasinoCategoryActive) {
			return (
				<CategoryMenu
					categoryMenuItems={casinoMenu.items}
					{...categoryMenuProps}
					isMenuOpen={isMenuOpen}
				/>
			);
		}

		return null;
	}, [url, casinoMenu, liveCasinoMenu, categoryMenuProps, isMenuOpen]);

	const mainCasinoCategory = useMemo(
		() => ({
			items: [
				{
					categoryName: '',
					items: [
						{
							label: t('casino'),
							requiresAuth: false,
							href: '/casino/home',
							id: 'casino-page-link',
							icon: { guid: CasinoIcon },
						},
						{
							label: t('live_casino'),
							requiresAuth: false,
							href: '/live-casino/home',
							id: 'live-casino-page-link',
							icon: { guid: LiveCasinoIcon },
						},
					],
				},
			],
		}),
		[t]
	);

	// const myAccountCategoryMenu = useMemo(
	// 	() => ({
	// 		items: [
	// 			{
	// 				categoryName: '',
	// 				items: [
	// 					{
	// 						label: 'My Account',
	// 						requiresAuth: true,
	// 						id: 'my-account-link',
	// 						icon: { guid: MyAccountIcon },
	// 						href: pathnames.account.profilePath,
	// 					},
	// 				],
	// 			},
	// 		],
	// 	}),
	// 	[]
	// );

	return (
		<div
			className={clsx(
				style.leftPanelMenu,
				leftPanelMenuClass,
				'jsLeftPanelMenu'
			)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<CategoryMenu
				categoryMenuItems={mainCasinoCategory.items}
				{...categoryMenuProps}
				isMenuOpen={isMenuOpen}
			/>

			{ActiveCasinoCategory}

			{/* 
			<CategoryMenu
				categoryMenuItems={myAccountCategoryMenu.items}
				{...categoryMenuProps}
				isMenuOpen={isMenuOpen}
			/> */}

			<CategoryMenu
				categoryMenuItems={generalMenu.items}
				{...categoryMenuProps}
				isMenuOpen={isMenuOpen}
			/>
		</div>
	);
};

export default LeftPanelMenu;
