import { Router as PreactRouter } from 'preact-router';
import CasinoHomePage from './components/casino-home';
import CasinoGroupsPage from './components/casino-groups';
import Redirect from '../../components/redirect';
import RecentlyPlayedGamesPage from '../recently-played-games';
import FavouriteGamesPage from '../favourite-games-page';
import CasinoGamesPage from './components/casino-games-page';
import ProtectedRoute from '../../components/protected-route';

const CasinoPage = () => {
	return (
		<PreactRouter>
			<CasinoHomePage path="/casino/home" />
			<CasinoGroupsPage path="/casino/group/:tagName" />
			<CasinoGamesPage path="/casino/game-categories/:categoryId" />
			<ProtectedRoute
				component={RecentlyPlayedGamesPage}
				path="/casino/recently-played-games"
			/>
			<ProtectedRoute
				component={FavouriteGamesPage}
				path="/casino/favourite-games"
			/>
			<Redirect path="/casino/:rest*" to="/casino/home" />
			<Redirect path="/" to="/casino/home" />
		</PreactRouter>
	);
};

export default CasinoPage;

