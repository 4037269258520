import clsx from 'clsx';
import { useMemo } from 'preact/hooks';
import { Button } from '@mui/material';

import { Typography } from '../typography';

import { getCurrencyIcon } from '../../utils/currencies';

import style from './style.module.scss';

const CurrencySelectorOption = ({ amount, currency, onSelect }) => {
	const CurrencyIcon = useMemo(() => getCurrencyIcon(currency), [currency]);
	const isSmallScreen = window.innerWidth <= 768;
	return (
		<Button
			disableRipple
			className={clsx(style.selectWrapper, style.currency)}
			onClick={onSelect}
		>
			<Typography type={isSmallScreen ? "btn2" : "btn1"} fontColor="white" className={style.label}>
				{amount}
			</Typography>

			<div className={style.wrapper}>
				<Typography type={isSmallScreen ? "btn2" : "btn1"} fontColor="contrast-primary" className={style.label}>
					{currency}
				</Typography>
			</div>
		</Button>
	);
};

export default CurrencySelectorOption;
