import { useEffect, useMemo } from 'preact/hooks';

import { Typography } from '../../../../components/typography';

import { RestrictionPanel } from '../restriction-panel';
import { AddRestrictionPanel } from '../add-restriction-panel';

import style from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSession } from '../../../../store/authentication-slice';
import { getPlayerExclusion, selectExclusion } from '../../../../store/exclusion-slice';

export const SelfExclusion = () => {
  const dispatch = useDispatch();

  const userSession = useSelector(selectUserSession);
  const selfExclusion = useSelector(selectExclusion);

  useEffect(() => {
    if (userSession)
		  dispatch(getPlayerExclusion(userSession.id));
	}, [dispatch, userSession]);

	return (
		<>
			<Typography type="btn1" fontColor="white">
				Self Exclusion
			</Typography>

			<AddRestrictionPanel isButtonDisabled={selfExclusion?.length} playerId={userSession?.id} type='exclusion' />

      { selfExclusion ?
        <div className={style.restrictionPanelWrapper}>
          <RestrictionPanel
            data={selfExclusion}
            removeButtonText="Remove exclusion"
            confirmationModalTitle="Are you sure you want to delete this self exclusion?"
            type='exclusion'
            playerId={userSession?.id}
          /> 
			</div> : null
      }
		</>
	);
};
