import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import config from '../config';
import enTranslations from './en';
import bgTranslations from './bg';
import constants from '../constants/general';
import { getDomainWithoutLang } from '../utils/common';

const defaultLang = config.languages.find((l) => !!l.default)?.code || 'en';

const languageDetectionSettings = {
	order: ['path'],
	lookupQuerystring: 'lang',
	lookupFromSubdomainIndex: 0,
	lookupCookie: constants.LANGUAGE_COOKIE_NAME,
	cookieDomain: `${getDomainWithoutLang()}`,
	caches: ['cookie'],
};

const initDict = async (userLanguage) => {
	await i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			lng: userLanguage || defaultLang,
			detection: languageDetectionSettings,
			debug: true,
			resources: {
				en: { translation: enTranslations },
				bg: { translation: bgTranslations },
			},
			supportedLngs: config.languages.map((l) => l.code),
			fallbackLng: defaultLang,
			interpolation: {
				escapeValue: false,
			},
		});

	return i18n.language;
};

const getLanguages = () => config.languages.map((lang) => lang.code);

export { initDict, getLanguages };
