import clsx from 'clsx';
import style from './style.module.scss';
import DashboardHeader from './components/dashboard-header';
import DashboardWallet from './components/dashboard-wallet';
import DashboardNavigation from './components/dashboard-navigation';

const PlayerDashboard = ({ open, onClose, userMenuItems }) => {
	return (
		<>
			{open && <div onClick={onClose} className={style.overlay} />}

			<div
				className={clsx(
					style.wrapper,
					open === null
						? style.invisibleWrapper
						: open
						? style.open
						: style.close
				)}
			>
				<DashboardHeader />
				<DashboardWallet />
				<DashboardNavigation userMenuItems={userMenuItems} />
			</div>
		</>
	);
};

export default PlayerDashboard;
