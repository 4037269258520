import handleFetch from '../utils/handleFetch';
import { getAuthHeaders } from '../utils/auth';
import { sapphirePlayerApiUrl } from './constants';

const claimBonus = async (playerId, code) => {
	return handleFetch(`${sapphirePlayerApiUrl}/bonus/${playerId}`, {
		headers: {
			'Content-Type': 'application/json',
			...getAuthHeaders(),
		},
		body: JSON.stringify({ code }),
		method: 'POST',
	});
};

export { claimBonus };
