import { useMemo } from 'preact/hooks';
import clsx from 'clsx';

import { Button } from '@mui/material';

import { getCurrencyIcon } from '../../utils/currencies';

import DropdownIcon from '../../assets/icons/dropdown-icon.svg';

import style from './style.module.scss';

const CurrencySelect = ({
	selectedBalance = null,
	className = '',
	onClick = () => {},
}) => {
	const Label = useMemo(() => {
		if (!selectedBalance) {
			return '';
		}

		const { currency, amount } = selectedBalance;
		const CurrencyIcon = getCurrencyIcon(currency);
		return (
			<span className={clsx(style.selectedBalanceWrapper, style.label)}>
				{`${amount} ${currency}`}
			</span>
		);
	}, [selectedBalance]);

	return (
		<Button
			className={clsx(
				style.selectButton,
				style.button,
				style.overriden,
				className
			)}
			endIcon={<img src={DropdownIcon} alt="dropdown-icon" />}
			onClick={onClick}
		>
			{Label}
		</Button>
	);
};

export default CurrencySelect;
