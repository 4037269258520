import {createAsyncThunk, createSelector, createSlice,} from '@reduxjs/toolkit';
import * as service from '../services/content-service';

const initialState = {
	menu: {
		slug: '',
		items: [],
	},
	footer: [],
	promotions: [],
	banners: [],
	pages: [],
	providers: [],
	isFetchCasinoMenuCompleted: false,
};

const fetchCasinoMenu = createAsyncThunk(
	'content/fetchCasinoMenu',
	async () => {
		const menu = await service.getCasinoMenu();
		return menu;
	}
);

const fetchFooterContent = createAsyncThunk(
	'content/fetchFooterContent',
	async () => {
		const footer = await service.getFooterContent();
		return footer;
	}
);

const fetchPromotions = createAsyncThunk(
	'content/fetchPromotions',
	async () => {
		return await service.getPromotionsContent();
	},
	{
		condition: (_, { getState }) => {
			const {
				content: { promotions },
			} = getState();

			return !promotions.length;
		},
	}
);

const fetchBanners = createAsyncThunk(
	'content/fetchBanners',
	async () => {
		return await service.getBannerContent();
	},
	{
		condition: (_, { getState }) => {
			const {
				content: { banners },
			} = getState();

			return !banners.length;
		},
	}
);

const fetchPages = createAsyncThunk(
	'content/fetchPages',
	async () => {
		return await service.getAllPages();
	},
	{
		condition: (_, { getState }) => {
			const {
				content: { pages },
			} = getState();

			return !pages.length;
		},
	}
);

const fetchProviders = createAsyncThunk(
	'content/fetchProviders',
	async () => {
		return await service.getProviders();
	},
	{
		condition: (_, { getState }) => {
			const {
				content: { providers },
			} = getState();

			return !providers.length;
		},
	}
);

const contentSlice = createSlice({
	name: 'content',
	initialState,
	extraReducers: (builder) => {
		builder.addCase(fetchCasinoMenu.fulfilled, (state, action) => {
			state.menu = action.payload;

			if (state.isFetchCasinoMenuCompleted === false) {
				state.isFetchCasinoMenuCompleted = true;
			}
		});
		builder.addCase(fetchCasinoMenu.rejected, (state) => {
			if (state.isFetchCasinoMenuCompleted === false) {
				state.isFetchCasinoMenuCompleted = true;
			}
		});
		builder.addCase(fetchFooterContent.fulfilled, (state, action) => {
			state.footer = action.payload;
		});
		builder.addCase(fetchPromotions.fulfilled, (state, action) => {
			state.promotions = action.payload;
		});
		builder.addCase(fetchBanners.fulfilled, (state, action) => {
			state.banners = action.payload;
		});
		builder.addCase(fetchPages.fulfilled, (state, action) => {
			state.pages = action.payload;
		});
		builder.addCase(fetchProviders.fulfilled, (state, action) => {
			state.providers = action.payload;
		});
	},
});

const selectMenuContent = (lang) => createSelector(
	(state) => state.content.menu,
	(menu) => {
		return lang === "bg" ? menu.filter(menu => menu.id.endsWith('bg')) : menu.filter(menu => !menu.id.endsWith('bg'));
	}
);

const selectGeneralMenu = (lang) => createSelector(
	selectMenuContent(lang),
	(menu) => {
		return menu.find(m => m.id.includes('general-menu'));
	}
)

const selectCasinoMenu = (lang) => createSelector(
	selectMenuContent(lang),
	(menu) => {
		return menu.find(m => m.id.includes('casino-menu'));
	}
)

const selectLiveCasinoMenu = (lang) => createSelector(
	selectMenuContent(lang),
	(menu) => {
		return menu.find(m => m.id.includes('live-casino-menu'));
	}
)

const selectFooterContent = (lang) => createSelector(
	(state) => state.content.footer,
	(footer) => {
		return lang === "bg" ? footer.find(footer => footer.slug.endsWith('bg')) : footer.find(footer => !footer.slug.endsWith('bg'));
	}
);
const selectFooterSections = (lang) => createSelector(
	(state) => state.content.footer,
	(footer) => {
		const result = lang === "bg" ? footer.find(footer => footer.slug.endsWith('bg')) : footer.find(footer => !footer.slug.endsWith('bg'));
		return result.sections;
	}
	);
const selectPromotions = (state) => state.content.promotions;
const selectCasinoProviders = (state) => state.content.providers.filter(provider => provider.isCasino);
const selectLiveCasinoProviders = (state) => state.content.providers.filter(provider => provider.isLive);
const selectIsFetchCasinoMenuCompleted = (state) =>
	state.content.isFetchCasinoMenuCompleted;
const selectCasinoPromotions = createSelector(
	(state) => state.content.promotions,
	(promotions) =>
		promotions.filter((promo) => promo.promoType === 'casino')
);
const selectLiveCasinoPromotions = createSelector(
	(state) => state.content.promotions,
	(promotions) =>
		promotions.filter((promo) => promo.promoType === 'liveCasino')
);
const selectInfoPages = (lang) => createSelector(
	(state) => state.content.pages,
	(pages) =>
		pages.filter((page) => page.pageType === 'info' && lang === "bg" ? page.slug.endsWith('bg') : !page.slug.endsWith('bg'))
);
const selectMainBanner = createSelector(
	(state) => state.content.banners,
	(banners) => banners.find((banner) => banner.bannerType === 'main_banner')
);
const selectCardBanners = createSelector(
	(state) => state.content.banners,
	(banners) => banners.filter((banner) => banner.bannerType === 'card_banner')
);
const selectLeftBanners = createSelector(
	(state) => state.content.banners,
	(banners) => banners.filter((banner) => banner.bannerType === 'left_image')
);
const selectRightBanners = createSelector(
	(state) => state.content.banners,
	(banners) => banners.filter((banner) => banner.bannerType === 'right_image')
);
const selectCenterBanners = createSelector(
	(state) => state.content.banners,
	(banners) => banners.filter((banner) => banner.bannerType === 'center_image')
);

export {
	selectGeneralMenu,
	selectCasinoMenu,
	selectLiveCasinoMenu,
	selectMenuContent,
	selectFooterContent,
	selectFooterSections,
	selectPromotions,
	selectCasinoProviders,
	selectLiveCasinoProviders,
	selectIsFetchCasinoMenuCompleted,
	selectMainBanner,
	selectCardBanners,
	selectLeftBanners,
	selectRightBanners,
	selectCenterBanners,
	selectInfoPages,
	selectCasinoPromotions,
	selectLiveCasinoPromotions,
};

export {
	fetchCasinoMenu,
	fetchFooterContent,
	fetchPromotions,
	fetchBanners,
	fetchPages,
	fetchProviders,
};

export default contentSlice.reducer;
