import { useEffect, useState } from "preact/hooks"

import Space777 from '../assets/game-images/777-space.png'
import ChiefHawksSpiritWalk from '../assets/game-images/chief-hawks-spirit-walk.png'

export const useCustomGameImages = ({ apiGame }) => {
    const [game, setGame] = useState(apiGame)

    useEffect(() => {
        if (apiGame) {
            const gameId = apiGame.externalGameId ?? apiGame.id;
            switch (+gameId) {
                case 68107:
                    setGame((prev) => ({ ...prev, thumbnail: ChiefHawksSpiritWalk }))
                    break;
                case 49136:
                    setGame((prev) => ({ ...prev, thumbnail: Space777 }))
                    break;
                default:
                    break;
            }
        }
    }, [apiGame])

    return { game }
}