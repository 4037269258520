import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
// import * as service from '../services/categories-service';
import {getGameCategories} from "../services/content-service";

const initialState = {
	casinoCategories: [],
	liveCasinoCategories: [],
};

const fetchCasinoCategories = createAsyncThunk(
	'categories/fetchCasinoCategories',
	async () => {
		const response = await getGameCategories();
		const returnCasinoOnly = response.filter(category => category.isLive === false);
		return returnCasinoOnly;
		// const response = await service.getCasinoCategories();
		// const data = await response.json();
		// return [
		// 	{...data.items.find(item => item.id === 'Casino$new-games'), name: 'new_games'},
		// 	{...data.items.find(item => item.id === 'Casino$gamevy'), name: 'special_for_you'},
		// 	{...data.items.find(item => item.id === 'Casino$egt'), name: 'jackpot_games'},
    //   {
    //     id: 'Casino$all-games',
    //     games: data.items.map(item => item.games).reduce((acc, curr) => {
    //       return {
    //         total: acc.total += curr.total,
    //         items: [...acc.items, ...curr.items],
    //       }
    //     }, {total: 0, items: [] }),
    //     name: 'all_games'}
		//
		// ];
	},
	{
		condition: (_, { getState }) => {
			const {
				categories: { casinoCategories },
			} = getState();

			return !casinoCategories.length > 0;
		},
	}
);

const fetchLiveCasinoCategories = createAsyncThunk(
	'categories/fetchLiveCasinoCategories',
	async () => {
		const response = await getGameCategories();
		const returnLiveCasinoOnly = response.filter(category => category.isLive === true);
		return returnLiveCasinoOnly;
		// const response = await service.getLiveCasinoCategories();
		// const data = await response.json();
		// return [
		// 	{...data.items.find(item => item.id === 'LiveCasino$luckystreak'), name: 'table_games'},
		// 	{...data.items.find(item => item.id === 'LiveCasino$tvbet'), name: 'live_games'},
		// 	{...data.items.find(item => item.id === 'LiveCasino$roulette'), name: 'roulette'},
		// 	{...data.items.find(item => item.id === 'LiveCasino$blackjack'), name: 'blackjack'},
		// 	{
		// 		id: 'LiveCasino$all-games',
		// 		games: data.items.map(item => item.games).reduce((acc, curr) => {
		// 			return {
		// 				total: acc.total += curr.total,
		// 				items: [...acc.items, ...curr.items],
		// 			}
		// 		}, {total: 0, items: [] }),
		// 		name: 'all_games'},
		// 	// currently no poker games available
		// 	// {...data.items.find(item => item.id === 'LiveCasino$luckystreak'), id: 'LiveCasino$poker', name: "Poker"},
		// ];
	},
	{
		condition: (_, { getState }) => {
			const {
				categories: { liveCasinoCategories },
			} = getState();

			return !liveCasinoCategories.length > 0;
		},
	}
);

const categoriesSlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCasinoCategories.fulfilled, (state, action) => {
			state.casinoCategories = action.payload;
		});
		builder.addCase(fetchLiveCasinoCategories.fulfilled, (state, action) => {
			state.liveCasinoCategories = action.payload;
		});
	},
});

const selectCasinoCategories = (state) => state.categories.casinoCategories;
const selectLiveCasinoCategories = (state) =>
	state.categories.liveCasinoCategories;

const selectGames = (state) => state.categories.casinoCategories.flatMap(category => category.games.items);

export { selectCasinoCategories, selectLiveCasinoCategories, selectGames };

export { fetchCasinoCategories, fetchLiveCasinoCategories };

export default categoriesSlice.reducer;

