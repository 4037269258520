import { useMemo } from 'preact/hooks';

import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import { Typography } from '../typography';

import style from './style.module.scss';

const LoadMore = ({
	displayedGamesCount = 0,
	totalGamesCount = 0,
	onLoadMore = () => {},
}) => {
	const { t } = useTranslation();

	const labelText = useMemo(
		() => t('displaying_of_games', { displayedGamesCount, totalGamesCount }),
		[t, displayedGamesCount, totalGamesCount]
	);

	const progress = useMemo(
		() => (displayedGamesCount / totalGamesCount) * 100,
		[displayedGamesCount, totalGamesCount]
	);

	return (
		<div className={style.container}>
			<LinearProgress variant="determinate" value={progress} />
			<Typography type="st3">{labelText}</Typography>
			<Button
				className={style.button}
				disabled={displayedGamesCount === totalGamesCount}
				backgorundColor="blue"
				onClick={onLoadMore}
			>
				{t('load_more')}
			</Button>
		</div>
	);
};

export default LoadMore;
