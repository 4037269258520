import { useTranslation } from 'react-i18next';
import style from './style.module.scss';

const ResponsibleGamingPage = () => {
	const { t } = useTranslation();

	return (
		<div
			className={style.wrapper}
			dangerouslySetInnerHTML={{
				__html: t('responsibleGamingContent', {
					interpolation: { escapeValue: false },
				}),
			}}
		/>
	);
};

export default ResponsibleGamingPage;
