import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'preact/hooks';

import { selectUser } from '../../../../store/user-slice';
import * as userService from '../../../../services/user-service';

const useConsent = () => {
	const [error, setError] = useState('');
	const [
		areConsentItemsSuccessfullyUpdated,
		setAreConsentItemsSuccessfullyUpdated,
	] = useState(false);
	const [consentItems, setConsentItems] = useState([]);
	const [areConsentItemsLoading, setAreConsentItemsLoading] = useState(true);
	const [areConsentItemsReceived, setAreConsentItemsReceived] = useState(false);
	const [areConsentItemsUpdating, setAreConsentItemsUpdating] = useState(false);

	const user = useSelector(selectUser);

	const updateConsent = useCallback(async () => {
		try {
			setError('');
			setAreConsentItemsUpdating(true);
			setAreConsentItemsSuccessfullyUpdated(false);

			const items = consentItems.map((consentItem) => {
				return {
					tagCode: consentItem.tagCode,
					status: consentItem.status,
				};
			});
			const response = await userService.updateConsent(user.id, { items });
			const result = await response.json();

			if (result.error) {
				setError(result.error);
			} else {
				setAreConsentItemsSuccessfullyUpdated(true);
			}
		} catch {
			setError('An error has occured');
		} finally {
			setAreConsentItemsUpdating(false);
		}
	}, [user, consentItems]);

	const getConsent = useCallback(async () => {
		try {
			setError('');
			setAreConsentItemsLoading(true);
			setAreConsentItemsReceived(false);

			const response = await userService.getConsent(user.id);
			const result = await response.json();

			if (result.items) {
				setConsentItems(result.items);
				setAreConsentItemsReceived(true);
			} else if (result.error) {
				setError(result.error);
			}
		} catch {
			setError('An error has occured');
		} finally {
			setAreConsentItemsLoading(false);
		}
	}, [user]);

	useEffect(async () => {
		if (!user) {
			return;
		}

		await getConsent();
	}, [user, getConsent]);

	return {
		error,
		consentItems,
		updateConsent,
		setConsentItems,
		areConsentItemsLoading,
		areConsentItemsReceived,
		areConsentItemsUpdating,
		areConsentItemsSuccessfullyUpdated,
	};
};

export { useConsent };
