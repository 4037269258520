import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/user-service';
import { getDeviceInfo } from '../utils/device';

const initialState = {
	user: null,
	favouriteGames: [],
	recentlyPlayedGames: [],
	recommendedGames: [],
	ipLocation: null,
};

const fetchUserFavouriteGames = createAsyncThunk(
	'user/fetchFavouriteGames',
	async () => {
		const response = await service.getFavouriteGames();
		const data = await response.json();
		return data.items;
	},
	{
		condition: (_, { getState }) => {
			const {
				user: { favouriteGames },
			} = getState();
			// executes thunk only when recommendedGames is empty
			return !favouriteGames.length > 0;
		},
	}
);

const fetchRecentlyPlayedGames = createAsyncThunk(
	'user/fetchRecentlyPlayedGames',
	async () => {
		const response = await service.getRecentlyPlayedGames();
		const data = await response.json();
		return data.items;
	},	{
	condition: (_, { getState }) => {
		const {
			user: { recentlyPlayedGames },
		} = getState();
		// executes thunk only when recommendedGames is empty
		return !recentlyPlayedGames.length > 0;
	},
}
);

const fetchRecommendedGames = createAsyncThunk(
	'user/fetchRecommendedGames',
	async () => {
		const response = await service.getRecommendedGames();
		const { items } = await response.json();

		if (!items) {
			throw new Error();
		}

		return items;
	},
	{
		condition: (_, { getState }) => {
			const {
				user: { recommendedGames },
			} = getState();
			// executes thunk only when recommendedGames is empty
			return !recommendedGames.length > 0;
		},
	}
);

const addGameToFavourites = createAsyncThunk(
	'user/addGameToFavourites',
	async ({ gameId = '' }, {rejectWithValue}) => {
		try {
			const response = await service.addGameToFavourites(gameId);
			const data = await response.json();
			const items = data.items;

			if (!items) {
				throw new Error();
			}
			return items;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const removeGameFromFavourites = createAsyncThunk(
	'user/removeGameFromFavourites',
	async ({ gameId = '' }, {rejectWithValue}) => {
		try {
			const response = await service.removeGameFromFavourites(gameId);
			const data = await response.json();
			return data.items;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const addRecentlyPlayedGames = createAsyncThunk(
	'user/addRecentlyPlayedGames',
	async ({ gameId = '' }, {rejectWithValue}) => {
		try {
			const response = await service.addToRecentlyPlayed(gameId);
			const data = await response.json();
			const items = data.items;

			if (!items) {
				throw new Error();
			}

			return items;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const getUserLocationByIp = createAsyncThunk(
	'user/getUserLocationByIp',
	async () => {
		const { ip } = await getDeviceInfo();
		const existingIp = localStorage.getItem('userIp');

		if (existingIp === ip) {
			return localStorage.getItem('userCountryCode');
		}

		const response = await service.getUserLocationByIp(ip);

		localStorage.setItem('userIp', ip);
		localStorage.setItem('userCountryCode', response?.country_code);

		return response?.country_code;
	}
);

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		fetchUser: (state, action) => {
			state.user = action.payload;
		},
		clearUser: (state) => {
			state.user = null;
			state.favouriteGames = [];
			state.recentlyPlayedGames = [];
			state.recommendedGames = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserFavouriteGames.fulfilled, (state, action) => {
			state.favouriteGames = action.payload;
		});
		builder.addCase(fetchRecentlyPlayedGames.fulfilled, (state, action) => {
			state.recentlyPlayedGames = action.payload;
		});
		builder.addCase(fetchRecommendedGames.fulfilled, (state, action) => {
			state.recommendedGames = action.payload;
		});
		builder.addCase(addGameToFavourites.fulfilled, (state, action) => {
			state.favouriteGames = action.payload;
		});
		builder.addCase(removeGameFromFavourites.fulfilled, (state, action) => {
			state.favouriteGames = action.payload;
		});
		builder.addCase(addRecentlyPlayedGames.fulfilled, (state, action) => {
			state.recentlyPlayedGames = action.payload;
		});
		builder.addCase(getUserLocationByIp.fulfilled, (state, action) => {
			state.ipLocation = action.payload;
		});
	},
});

const selectUser = (state) => state.user.user;
const selectFavouriteGames = (state) => state.user.favouriteGames;
const selectRecentlyPlayedGames = (state) => state.user.recentlyPlayedGames;
const selectRecommendedGames = (state) => state.user.recommendedGames;
const selectIpLocation = (state) => state.user.ipLocation;

export const { clearUser, fetchUser } = userSlice.actions;

export {
	selectUser,
	selectFavouriteGames,
	selectRecentlyPlayedGames,
	selectRecommendedGames,
	selectIpLocation,
};

export {
	fetchUserFavouriteGames,
	fetchRecentlyPlayedGames,
	fetchRecommendedGames,
	addGameToFavourites,
	removeGameFromFavourites,
	addRecentlyPlayedGames,
	getUserLocationByIp,
};

export default userSlice.reducer;
