import {
	useMemo,
	useState,
	useContext,
	useCallback,
} from 'preact/hooks';

import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../../../../components/forms/input';
import {
	getPlayerData,
	selectPlayerData,
	updatePlayerData,
} from '../../../../store/player-slice';
import Button from '../../../../components/forms-new/button/button';
import * as playerService from '../../../../services/player-service';
import { errorMessages, regexes } from '../../../../utils/form-constants';
import ConfirmationModal from '../../../../components/confirmation-modal';
import { ToastMessagesContext } from '../../../../providers/toast-messages-provider';

import commonClasses from '../../style.module.scss';
import classes from './style.module.scss';

import PasswordIcon from '../../../../assets/icons/password-icon-blue.svg';
import PasswordIconHidden from '../../../../assets/icons/password-icon.svg';

const PersonalDetails = () => {
	const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
		useState(false);
	const [isEditUserDetailsModalOpen, setIsEditUserDetailsModalOpen] =
		useState(false);
	const [shouldShowOldPassword, setShouldShowOldPassword] = useState(false);
	const [shouldShowNewPassword, setShouldShowNewPassword] = useState(false);
	const [shouldShowConfirmedPassword, setShouldShowConfirmedPassword] =
		useState(false);

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const playerData = useSelector(selectPlayerData);

	const { onPushMessage } = useContext(ToastMessagesContext);

	const OldPasswordIcon = useMemo(
		() => (shouldShowOldPassword ? PasswordIconHidden : PasswordIcon),
		[shouldShowOldPassword]
	);

	const NewPasswordIcon = useMemo(
		() => (shouldShowNewPassword ? PasswordIconHidden : PasswordIcon),
		[shouldShowNewPassword]
	);

	const ConfirmedPasswordIcon = useMemo(
		() => (shouldShowConfirmedPassword ? PasswordIconHidden : PasswordIcon),
		[shouldShowConfirmedPassword]
	);

	const handleClickOldPasswordIcon = useCallback(
		() => setShouldShowOldPassword((prev) => !prev),
		[]
	);

	const handleClickNewPasswordIcon = useCallback(
		() => setShouldShowNewPassword((prev) => !prev),
		[]
	);

	const handleClickConfirmedPasswordIcon = useCallback(
		() => setShouldShowConfirmedPassword((prev) => !prev),
		[]
	);

	const onCloseChangePasswordModal = () => {
		setIsChangePasswordModalOpen(false);
	};

	const onCloseEditUserDetailsModal = () => {
		setIsEditUserDetailsModalOpen(false);
	};

	const onChangePasswordButtonClick = () => {
		setIsChangePasswordModalOpen(true);
	};

	const onEditUserDetailsButtonClick = () => {
		setIsEditUserDetailsModalOpen(true);
	};

	const onClickConfirmEditUserDetails = () => {
		formikEditUserDetails.handleSubmit();
		setIsEditUserDetailsModalOpen(false);
	};

	const onClickConfirmChangePassword = () => {
		formikChangePassword.handleSubmit();
		setIsChangePasswordModalOpen(false);
	};

	const EditUserDetailsSchema = useMemo(
		() =>
			Yup.object().shape({
				address1: Yup.string().required(t('street_field_is_mandatory')),
				city: Yup.string().required(t('city_field_is_mandatory')),
			}),
		[t]
	);

	const initialValuesEditUserDetails = useMemo(() => {
		if (playerData) {
			const { email, phoneNumber, city, address1 } = playerData;
			return { email, phoneNumber, city, address1 };
		}

		return {
			email: '',
			phoneNumber: '',
			city: '',
			address1: '',
		};
	}, [playerData]);

	const onEditUserDetails = useCallback(
		async (data) => {
			try {
				const response = await playerService.updatePlayerData(
					playerData.id,
					data
				);
				dispatch(updatePlayerData(response.data));
				onPushMessage({
					message: t('update_successful'),
					type: 'info',
					title: t('saved'),
				});
			} catch (e) {
				onPushMessage({
					message: e.message || t('an_error_occured'),
					type: 'error',
					title: t('error'),
				});
			}
		},
		[t, dispatch, onPushMessage, playerData?.id]
	);

	const formikEditUserDetails = useFormik({
		enableReinitialize: true,
		validationSchema: EditUserDetailsSchema,
		initialValues: initialValuesEditUserDetails,
		onSubmit: onEditUserDetails,
	});

	const ChangePasswordSchema = useMemo(
		() =>
			Yup.object().shape({
				oldPassword: Yup.string()
					.min(8, t('the_password_must_be_at_least_8_characters'))
					.matches(regexes.password, errorMessages.password)
					.required(t('password_is_mandatory')),
				newPassword: Yup.string()
					.min(8, t('the_password_must_be_at_least_8_characters'))
					.matches(regexes.password, errorMessages.password)
					.required(t('password_is_mandatory')),
				confirmedPassword: Yup.string()
					.min(8, t('the_password_must_be_at_least_8_characters'))
					.matches(regexes.password, errorMessages.password)
					.required(t('password_is_mandatory'))
					.oneOf([Yup.ref('newPassword'), ''], t('passwords_must_match')),
			}),
		[]
	);

	const initialValuesChangePassword = {
		oldPassword: '',
		newPassword: '',
		confirmedPassword: '',
	};

	const formikChangePassword = useFormik({
		initialValues: initialValuesChangePassword,
		validationSchema: ChangePasswordSchema,
		onSubmit: async (data) => {
			try {
				const response = await playerService.resetPlayerPassword(
					playerData.id,
					{
						currentPassword: data.oldPassword,
						newPassword: data.newPassword,
					}
				);
				dispatch(getPlayerData(playerData.id));
				onPushMessage({
					message: response.data || t('update_successful'),
					type: 'info',
					title: t('saved'),
				});
			} catch (e) {
				onPushMessage({
					message: e.message || t('an_error_occured'),
					type: 'error',
					title: t('error'),
				});
			} finally {
				formikChangePassword.resetForm();
			}
		},
	});

	return (
		<div className={commonClasses.tabContent}>
			<form className={classes.formWrapper}>
				<div className={classes.userDataSection}>
					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('first_name')}</label>

						<label className={classes.valueLabel}>
							{playerData?.firstname}
						</label>
					</div>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('last_name')}</label>

						<label className={classes.valueLabel}>{playerData?.lastname}</label>
					</div>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('email')}</label>

						<label className={classes.valueLabel}>{playerData?.email}</label>
					</div>

					<div className={classes.hiddenOnMobile} />

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('country_code')}</label>

						<label className={classes.valueLabel}>
							{playerData?.phoneNumberPrefix}
						</label>
					</div>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('phone_number')}</label>

						<label className={classes.valueLabel}>
							{playerData?.phoneNumber}
						</label>
					</div>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('vip_status')}</label>

						<label className={classes.valueLabel}>{playerData?.vipLevel}</label>
					</div>

					<div className={classes.hiddenOnMobile} />

					<Input
						name="city"
						label={t('city')}
						customType="dark"
						isRequired={false}
						value={formikEditUserDetails.values.city}
						errorMessage={formikEditUserDetails.errors?.city}
						wrapperClassName={classes.inputWrapper}
						labelWrapperClassName={classes.inputLabel}
						hasError={
							Boolean(formikEditUserDetails.errors?.city) &&
							formikEditUserDetails.touched?.city
						}
						isEmptyBottomTextContainerVisible={false}
						onBlur={formikEditUserDetails.handleBlur}
						onChange={formikEditUserDetails.handleChange}
					/>

					<Input
						name="address1"
						isRequired={false}
						customType="dark"
						label={t('address')}
						wrapperClassName={classes.inputWrapper}
						labelWrapperClassName={classes.inputLabel}
						value={formikEditUserDetails.values.address1}
						hasError={
							Boolean(formikEditUserDetails.errors?.address1) &&
							formikEditUserDetails.touched?.address1
						}
						errorMessage={formikEditUserDetails.errors?.address1}
						isEmptyBottomTextContainerVisible={false}
						onBlur={formikEditUserDetails.handleBlur}
						onChange={formikEditUserDetails.handleChange}
					/>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('id_type')}</label>

						<label className={classes.valueLabel}>
							{playerData?.documentType}
						</label>
					</div>

					<div className={classes.fieldWrapper}>
						<label className={classes.textLabel}>{t('id_number')}</label>

						<label className={classes.valueLabel}>
							{playerData?.documentNumber}
						</label>
					</div>
				</div>

				<div className={classes.saveButtonWrapper}>
					<Button
						className={clsx(classes.saveButton, classes.moreSpecificSelector)}
						onClick={onEditUserDetailsButtonClick}
					>
						{t('save')}
					</Button>
				</div>
			</form>

			<form className={classes.formWrapper}>
				<div className={classes.header}>{t('password')}</div>

				<div
					className={clsx(
						classes.userDataSection,
						classes.changePasswordSection
					)}
				>
					<Input
						name="oldPassword"
						label={t('old_password')}
						customType="dark"
						value={formikChangePassword.values?.oldPassword}
						type={shouldShowOldPassword ? 'text' : 'password'}
						errorMessage={formikChangePassword.errors?.oldPassword}
						Icon={
							<img src={OldPasswordIcon} onClick={handleClickOldPasswordIcon} />
						}
						wrapperClassName={classes.inputWrapper}
						labelWrapperClassName={classes.inputLabel}
						hasError={
							Boolean(formikChangePassword.errors?.oldPassword) &&
							formikChangePassword.touched?.oldPassword
						}
						onBlur={formikChangePassword.handleBlur}
						onChange={formikChangePassword.handleChange}
					/>

					<Input
						name="newPassword"
						label={t('new_password')}
						customType="dark"
						wrapperClassName={classes.inputWrapper}
						labelWrapperClassName={classes.inputLabel}
						value={formikChangePassword.values?.newPassword}
						type={shouldShowNewPassword ? 'text' : 'password'}
						hasError={
							Boolean(formikChangePassword.errors?.newPassword) &&
							formikChangePassword.touched?.newPassword
						}
						errorMessage={formikChangePassword.errors?.newPassword}
						Icon={
							<img src={NewPasswordIcon} onClick={handleClickNewPasswordIcon} />
						}
						onBlur={formikChangePassword.handleBlur}
						onChange={formikChangePassword.handleChange}
					/>

					<Input
						name="confirmedPassword"
						label={t('confirm_new_password')}
						customType="dark"
						wrapperClassName={classes.inputWrapper}
						labelWrapperClassName={classes.inputLabel}
						Icon={
							<img
								src={ConfirmedPasswordIcon}
								onClick={handleClickConfirmedPasswordIcon}
							/>
						}
						value={formikChangePassword.values?.confirmedPassword}
						type={shouldShowConfirmedPassword ? 'text' : 'password'}
						hasError={
							Boolean(formikChangePassword.errors?.confirmedPassword) &&
							formikChangePassword.touched?.confirmedPassword
						}
						errorMessage={formikChangePassword.errors?.confirmedPassword}
						onBlur={formikChangePassword.handleBlur}
						onChange={formikChangePassword.handleChange}
					/>
				</div>

				<div className={classes.saveButtonWrapper}>
					<Button
						className={clsx(classes.saveButton, classes.moreSpecificSelector)}
						onClick={onChangePasswordButtonClick}
					>
						{t('save')}
					</Button>
				</div>
			</form>

			<ConfirmationModal
				title={t('are_you_sure_you_want_to_change_your_details')}
				isModalOpen={isEditUserDetailsModalOpen}
				onCloseModal={onCloseEditUserDetailsModal}
				confirmDeleteHandler={onClickConfirmEditUserDetails}
			/>

			<ConfirmationModal
				title={t('are_you_sure_you_want_to_change_your_password')}
				isModalOpen={isChangePasswordModalOpen}
				onCloseModal={onCloseChangePasswordModal}
				confirmDeleteHandler={onClickConfirmChangePassword}
			/>
		</div>
	);
};

export default PersonalDetails;
