import { styled, Select } from '@mui/material';

import { getClampSizeFromPx } from './../../../utils/font-size';

const StyledSelect = styled(Select)(() => {
	return {
		'&:before': {
			borderBottom: 0,
		},

		'&.Mui-focused': {
			border: '2px solid var(--color-contrast-primary) !important',
		},

		'&:hover:not(.Mui-disabled, .Mui-error):before': {
			borderBottom: 0,
		},

		'&.Mui-disabled': {
			opacity: 0.3,
		},

		'.MuiSelect-icon': {
			right: '10px',
			top: 'inherit',
		},

		'.MuiFilledInput-input': {
			paddingBlock: 0,
			fontWeight: 600,
			paddingLeft: '12px',
			fontFamily: `'Figtree', 'Roboto'`,
			fontSize: getClampSizeFromPx(14, 16),
		},

		'.MuiFilledInput-input:focus': {
			backgroundColor: 'transparent',
		},

		'&:after': {
			borderBottom: 0,
		},
	};
});

export default StyledSelect;
