import { useTranslation } from 'react-i18next';
import { Typography } from '../typography';

import { StyledSwitch, StyledWrapper } from './style.js';

export const Switch = ({ onChange, ...rest }) => {
	const { t } = useTranslation();

	const handleChange = (event) => {
		if (onChange) {
			onChange(event.target.checked);
		}
	};
	return (
		<StyledWrapper>
			<Typography fontColor="white" type="st4">
				{t('fun_play')}
			</Typography>

			<StyledSwitch {...rest} onClick={handleChange} />

			<Typography fontColor="white" type="st4">
				{t('real_play')}
			</Typography>
		</StyledWrapper>
	);
};
