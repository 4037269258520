import { useEffect , useMemo} from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	fetchRecentlyPlayedGames,
	selectRecentlyPlayedGames,
	selectUser,
} from '../../store/user-slice';

import LeaderboardWraper from '../../components/leaderboard-wrapper/index.js';
import Banner from '../../components/banner';
import Search from '../../components/search';
import GameCard from '../../components/game-card-new';

import styles from './style.module.scss';
import {fetchCasinoCategories, fetchLiveCasinoCategories, selectGames} from "../../store/categories-slice";

const RecentlyPlayedGamesPage = () => {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const recentlyPlayedGames = useSelector(selectRecentlyPlayedGames);
	const games = useSelector(selectGames);

	const gamesMappedCMS = useMemo(() => recentlyPlayedGames?.map(game => games?.find(g => g.gameId === Number(game.externalGameId))).filter(game => game)
		, [recentlyPlayedGames, games])

	const { t } = useTranslation();

	useEffect(() => {
		if (user) {
			dispatch(fetchRecentlyPlayedGames(user.id));
			dispatch(fetchCasinoCategories());
			dispatch(fetchLiveCasinoCategories());
		}
	}, [user, dispatch]);

	return (
		<div className={styles.page}>
			<div className={styles.searchWrapper}>
				<Banner label={t('recent')} />
				<Search />
			</div>
			<div className={styles.gamesContainer}>
				{gamesMappedCMS?.map((game) => (
					<GameCard
						key={game.id}
						game={game}
					/>
				))}
			</div>
			<LeaderboardWraper />
		</div>
	);
};

export default RecentlyPlayedGamesPage;
