import ReactPaginate from 'react-paginate';

import classes from './style.module.scss';

const TransactionPagination = ({ pageCount, selectedPage, onPageChange }) => {
	return (
		<ReactPaginate
			nextLabel="Next"
			pageCount={pageCount}
			forcePage={selectedPage}
			previousLabel="Previous"
			activeClassName={classes.activePage}
			className={classes.pagination}
			onPageChange={onPageChange}
		/>
	);
};

export default TransactionPagination;
