const baseSapphireApiUrl = process.env.PREACT_APP_SAPPHIRE_API_URL;
const sapphirePlayerApiUrl = `${baseSapphireApiUrl}/casino/players`;
const brandId = process.env.PREACT_APP_SAPPHIRE_BRAND_ID;

const baseApiUrl = process.env.PREACT_APP_BASE_API_URL; // @todo, needs to be removed once we integrate with Sapphire API
const socketConnectionUrl = process.env.PREACT_APP_BASE_WS_URL;
const playerApiUrlV1 = `${baseApiUrl}/v1/player`; // @todo, needs to be removed once we integrate with Sapphire API
const playerApiUrlV2 = `${baseApiUrl}/v2/player`; // @todo, needs to be removed once we integrate with Sapphire API
const gamesApiUrl = `${baseApiUrl}/v1/casino/games`; // @todo, needs to be removed once we integrate with Sapphire API
const tagsApiUrl = `${baseApiUrl}/v1/casino/tags`; // @todo, needs to be removed once we integrate with Sapphire API
const categoriesApiUrl = `${baseApiUrl}/v1/casino/groups`; // @todo, needs to be removed once we integrate with Sapphire API
const sapphireIntegrationsApiUrl = `${baseSapphireApiUrl}/integrations`;
const sapphireWithdrawalApiUrl = `${baseSapphireApiUrl}/casino/withdrawal-transactions`;

const ipInfoApiUrl = 'https://ipinfo.io/json';
const dataSource = {
	casino: 'Casino',
	liveCasino: 'LiveCasino',
};
const wordpressApiUrl = process.env.PREACT_APP_WORDPRESS_API_URL;

export {
	baseApiUrl,
	wordpressApiUrl,
	baseSapphireApiUrl,
	socketConnectionUrl,
	ipInfoApiUrl,
	sapphirePlayerApiUrl,
	gamesApiUrl,
	playerApiUrlV1,
	playerApiUrlV2,
	tagsApiUrl,
	categoriesApiUrl,
	dataSource,
	brandId,
	sapphireIntegrationsApiUrl,
	sapphireWithdrawalApiUrl
};
