import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as service from '../services/wallet-service';

const initialState = {
    balance: null,
    wallets: [],
    selectedWallet: null,
};

const fetchBalance = createAsyncThunk(
    'wallet/fetchBalance',
    async (userId = '') => {
        const response = await service.getUserBalance(userId);
        return response.json();
    }
);

const fetchWallets = createAsyncThunk('wallet/fetchWallets', async (userId) => {
    const response = await service.getUserWallets(userId);
    return response.json();
});

const fetchWalletByCurrency = createAsyncThunk('wallet/fetchWalletByCurrency', async (currency) => {
    const response = await service.getUserWalletByCurrency(currency);
    return response.json();
});

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        clearUserBalance: (state) => {
            state.balance = null;
			state.selectedWallet = null;
        },
        selectWallet: (state, action) => {
            state.selectedWallet = action.payload;
            localStorage.setItem('preferredCurrency', action.payload.currency);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBalance.fulfilled, (state, action) => {
            state.balance = action.payload.error ? null : action.payload;
        });
        builder.addCase(fetchWallets.fulfilled, (state, action) => {
            state.wallets = action.payload.data;
            const preferredCurrency = localStorage.getItem('preferredCurrency');
            if (preferredCurrency) {
                const preferredWallet = state.wallets.find(
                    wallet => wallet.currency === preferredCurrency
                );
                if (preferredWallet) {
                    state.selectedWallet = preferredWallet;
                }
            }
        });
        builder.addCase(fetchWalletByCurrency.fulfilled, (state, action) => {
            state.selectedWallet = action.payload.data;
        });
    },
});

export const { selectWallet, clearUserBalance } = walletSlice.actions;
export const selectBalance = (state) => state.wallet.balance;
export const selectWallets = (state) => state.wallet.wallets;
export const selectSelectedWallet = (state) => state.wallet.selectedWallet;

export { fetchBalance, fetchWallets, fetchWalletByCurrency };

export default walletSlice.reducer;
