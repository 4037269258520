import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)(() => {
	return {
		boxShadow: 'none',

		'.MuiSelect-select': {
			paddingBlock: 0,
			fontSize: '14px',
			paddingLeft: '4px',
			lineHeight: '21px',
			fontFamily: `'Figtree', 'Roboto'`,
			color: 'var(--color-grey-1-100)',

			'&.MuiInputBase-input.MuiOutlinedInput-input': {
				paddingRight: '28px',
			},
		},

		'& .MuiMenu-root': {
			backgroundColor: 'red',
		},

		'.MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
	};
});
